import { CztImage } from '~/utils/atoms/image';
import { CztWidgets } from '~/utils/views/widgets';
import { HtmlContentWidget } from '~/app/core/apiClient/api';
import ThemeStyleEnum = HtmlContentWidget.ThemeStyleEnum;
export { ThemeStyleEnum };

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ContentBlockInterface {
  anchorId?: string;
  caption?: string;
  className: CztWidgets;
  content: string;
  image?: CztImage;
  imageSource?: string;
  imageUrl?: string;
  isBottomSpacingCollapsed?: boolean;
  isTopSpacingCollapsed?: boolean;
  position?: Position;
  side?: boolean;
  themeStyle: ThemeStyleEnum;
  title: string;
  isFirst?: boolean;
}
