export default {
  app: {
    panorama: {
      loadingText: '影像載入中。。。',
      autorotate: '自動旋轉',
      zoom: '縮放',
      zoomOut: '縮小',
      zoomIn: '放大',
      move: '移動',
      download: '下載',
      fullscreen: '全螢幕',
      menu: '目錄',
      twoFingers: '使用兩隻手指滑動',
      ctrlZoom: '使用Ctrl＋滾輪縮放影像',
      loadError: '全景影像無法載入',
    },
  },
};
