import { Component, Prop } from 'vue-property-decorator';
import { link, LinkTarget } from '~/utils/molecules';
import { VueComponent } from '~/utils/vue-component';

import style from './Link.scss';
import { LocaleMessage } from 'vue-i18n';
import { createAnchorId } from '~/utils/anchor';

export interface LinkInterface {
  url: string | LocaleMessage;
  disabled?: boolean;
  forcedLocale?: string;
  light?: boolean;
  title?: string;
  target?: LinkTarget;
}

@Component({
  style,
})
export default class Link extends VueComponent<LinkInterface>
  implements LinkInterface {
  @Prop({ required: true, type: String })
  public url!: string | LocaleMessage;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  @Prop({ type: String })
  public forcedLocale?: string;

  @Prop({ type: String })
  public title?: string;

  @Prop({ type: String })
  public target?: LinkTarget;

  protected className: string = 'czt-link';

  protected get isInternal(): boolean {
    return typeof this.url === 'string' && link.isInternal(this.url);
  }

  public render() {
    const classes: string[] = [this.className];
    if (this.light) {
      classes.push(`${this.className}--light`);
    }
    if (this.isInternal) {
      return (
        <router-link
          class={classes.join(' ')}
          to={this.to?.toLowerCase()}
          target={this.linkTarget}
          title={this.title}
          nativeOnClick={this.handleClick}
          disabled={this.disabled}
          event={!this.disabled ? 'click' : ''}
        >
          {this.$slots.default}
        </router-link>
      );
    } else {
      return (
        <a
          class={classes.join(' ')}
          href={this.href}
          target={this.linkTarget}
          title={this.title}
          rel={
            this.linkTarget === LinkTarget.BLANK
              ? 'noopener nofollow'
              : undefined
          }
          onclick={this.handleClick}
        >
          {this.$slots.default}
        </a>
      );
    }
  }

  protected get href(): string | undefined {
    return this.isInternal
      ? undefined
      : this.url.toString().indexOf('#') === 0
      ? this.url.toString()
      : typeof this.url === 'string'
      ? link.createExternal(this.url)
      : undefined;
  }

  protected get to(): string | undefined {
    return this.isInternal && typeof this.url === 'string'
      ? link.createInternal(this.url, this.forcedLocale || this.$i18n.locale)
      : undefined;
  }

  protected get linkTarget(): string | undefined {
    if (this.target && this.target.trim() !== '') {
      return this.target;
    }
    return this.isInternal ? undefined : LinkTarget.BLANK;
  }

  protected handleClick(e: Event): void {
    if (this.disabled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }
    if (this.url.toString().indexOf('#') === 0) {
      const anchor = document.querySelector(this.url.toString());
      if (anchor && anchor instanceof HTMLElement) {
        this.$vuetify.goTo(anchor);
      } else {
        this.$vuetify.goTo(createAnchorId('content'));
      }
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    this.$emit('click');
    return;
  }
}
