export default {
  app: {
    common: {
      backToMenu: 'Indietro al menu',
      blog: 'Blog',
      exploreButton: 'Esplorare',
      language: 'Lingua',
      loadMore: 'Vedi altri',
      moreInfo: 'Maggiori informazioni',
      opensInNewWindow: 'Si apre in una nuova finestra',
      pageTypes: {
        article: 'Articolo',
        category: 'Categoria',
        event: 'Evento',
        page: 'Pagina',
        place: 'Posto',
        region: 'Regione',
      },
      selectLanguage: 'Seleziona la lingua',
      homepageBreadcrumb: 'Home',
      translationVoting: 'Come valuti questa traduzione?',
      translationVotingSuccess: 'Grazie per la tua risposta!',
    },
  },
};
