import { Component, Prop } from 'vue-property-decorator';

import { GridItem } from '~/components/organisms/gridList/types';
import { VueComponent } from '~/utils/vue-component';
import { Headline } from '~/components/atoms';
import { Link, MediaBackground } from '~/components/molecules';
import { Align } from '~/components/atoms/headline/Headline';
import { ThemeRatios } from '~/utils/theme';
import { format } from '~/utils/date-fns';
import { parse } from 'date-fns';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import Icon, { isIconName } from '~/components/atoms/icons/Icon';

interface SimpleGridItemInterface {
  item: GridItem;
}

const rootClass = 'czt-grid-list__item';

@Component
export default class SimpleGridItem
  extends VueComponent<SimpleGridItemInterface>
  implements SimpleGridItemInterface {
  @Prop({ required: true })
  public item!: GridItem;

  protected get flag(): JSX.Element | undefined {
    if (
      !this.item.showFlag ||
      !this.item.flag ||
      this.item.flag.trim() === ''
    ) {
      return;
    }
    const iconName = ['flags', this.item.flag];
    return isIconName(iconName) ? (
      <Icon name={iconName} size={1.5} />
    ) : (
      undefined
    );
  }

  public render() {
    return (
      <v-col class={`${rootClass} ${rootClass}--simple`} cols={12}>
        <v-row>
          <v-col cols='12' md='4'>
            <Link url={this.item.link} forcedLocale={this.item.forcedLocale}>
              <MediaBackground
                ratio={ThemeRatios['4x3']}
                image={this.item.image}
              >
                <div class='pa-1'>{this.flag}</div>
              </MediaBackground>
            </Link>
          </v-col>
          <v-col cols='12' md='8'>
            {this.item.date && (
              <div class='grey--text text--darken-2 font-weight-bold mb-1'>
                {format(
                  parse(this.item.date),
                  'D. MM. YYYY',
                  this.$i18n.locale
                )}
              </div>
            )}
            <Headline
              level={3}
              align={Align.LEFT}
              class='mb-2 font-weight-bold'
            >
              <Link url={this.item.link} forcedLocale={this.item.forcedLocale}>
                {this.item.title}
              </Link>
            </Headline>
            <div class={`${rootClass}__content font-weight-bold`}>
              {this.item.perex && <HtmlRenderer content={this.item.perex} />}
              <Link url={this.item.link} forcedLocale={this.item.forcedLocale}>
                {this.$t('app.common.moreInfo')}
              </Link>
            </div>
          </v-col>
        </v-row>
      </v-col>
    );
  }
}
