export default {
  app: {
    footer: {
      aboutUs: 'Over ons',
      contactUs: 'Contacteer ons',
      pressAndMedia: 'Pers & Media',
      travelProfessionals: 'Reisexperts',
      brochures: 'Brochures',
      news: 'Nieuw',
      covid19: 'Covid-19',
      destinations: 'Bestemmingen',
      thingsToDo: 'Dingen om te doen',
      travelInfo: 'Reisinformatie',
      czechTourism: 'CzechTourism',
      menu: 'Menu',
      mobileApp: 'Mobiele app',
      visitCzechAndroid: 'VisitCzech Mobiele applicatie voor Android',
      followUs: 'Volg ons',
      privacyPolicy: 'Privacybeleid',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookie instellingen',
    },
  },
};
