export default {
  app: {
    cookies: {
      title: 'Wir respektieren Ihre Privatsphäre',
      text:
        'Wir verwenden Cookies, um Inhalte zu personalisieren, den Datenverkehr zu analysieren und Funktionen für soziale Medien bereitzustellen. Wir geben Informationen über Ihr Nutzungsverhalten unserer Webseite an unsere Social-Media-Partner und Web-Analytics-Partner weiter. Partner können diese Informationen mit anderen Informationen kombinieren, die Sie ihnen zur Verfügung stellen oder die sie als Ergebnis Ihrer Nutzung ihrer Dienste erhalten. Für weitere Informationen klicken Sie {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'hier',
      },
      buttons: {
        accept: 'Alle Cookies akzeptieren',
        save: 'Ausgewählte speichern',
      },
      settings: {
        button: 'Einstellungen verwalten',
        required: 'Erforderlich (für das richtige Funktionieren der Website)',
        analytics:
          'Analysen (für das Verständnis, wie sich Besucher auf der Webseite verhalten oder wie sie sich auf ihr bewegen)',
        statistics:
          'Statistiken (um zu verstehen, wie Besucher die Website nutzen)',
        marketing:
          'Marketing (um das Nutzunsverhalten zu verfolgen und relevante Werbung anzuzeigen)',
        reject: 'Alle ablehnen',
        accept: 'Alle zulassen',
      },
    },
  },
};
