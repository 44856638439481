export default {
  app: {
    user: {
      button: {
        logIn: 'Log på',
        logOut: 'Log af',
      },
      error: {
        username: 'Username kan ikke være tom',
        password: 'Password kan ikke være tom',
        undefined: 'Kan ikke logge på',
      },
      title: 'Log på',
      placeholder: {
        user: 'Username',
        pass: 'Password',
      },
      loggedIn: 'Du er logget på som {0}',
      admin: 'Administration',
    },
  },
};
