export default {
  app: {
    cookies: {
      title: 'Tiszteletben tartjuk a magánéletét',
      text:
        'A tartalom személyre szabásához, a forgalom elemzéséhez és a közösségi médiafunkciók biztosításához sütiket használunk. A weboldalunk használatára vonatkozó információkat megosztjuk közösségi média- és elemző partnereinkkel. A partnerek kombinálhatják ezeket az információkat egyéb olyan információkkal, amelyeket Ön megad nekik, vagy amelyeket szolgáltatásaik használatának eredményeként szereznek meg. További részleteket {0} talál. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'itt',
      },
      buttons: {
        accept: 'Minden cookie elfogadása',
        save: 'A kiválasztott(ak) mentése',
      },
      settings: {
        button: 'Részletes beállítások',
        required: 'Szükséges (a webhely megfelelő működéséhez)',
        analytics:
          'Analytics (annak megértéséhez, hogyan viselkednek a látogatók a webhelyen, vagy hogyan mozognak a webhelyen)',
        statistics:
          'Statisztikai (annak megértése, hogy a látogatók hogyan használják a weboldalt)',
        marketing:
          'Marketing (a látogatók nyomon követése a hirdetések megjelenítéséhez)',
        reject: 'Az összes elutasítása',
        accept: 'Az összes elfogadása',
      },
    },
  },
};
