import { Action, Module, Mutation } from 'vuex-module-decorators';
import { ItemData } from '~/components/atoms/select/Select';
import { SafeTravelsListItem } from '~/components/templates/safeTravels/types';
import AbstractModule from './AbstractModule';

// TODO: remove this interface and import it from Api when it's there
/**
 *
 * @export
 * @interface SafeTravelsStampItem
 */
export interface SafeTravelsStampItem {
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  typeName?: string;
  /**
   *
   * @type {number}
   * @memberof SafeTravelsStampItem
   */
  formItemID?: number;
  /**
   *
   * @type {Date}
   * @memberof SafeTravelsStampItem
   */
  formInserted?: Date;
  /**
   *
   * @type {Date}
   * @memberof SafeTravelsStampItem
   */
  formUpdated?: Date;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  nazevSpolecnosti?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  nazevProvozovny?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  ico?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  ulice?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  cisloPopisne?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  mesto?: string;
  /**
   *
   * @type {number}
   * @memberof SafeTravelsStampItem
   */
  psc?: number;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  gpsSouradnice?: string;
  /**
   *
   * @type {number}
   * @memberof SafeTravelsStampItem
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof SafeTravelsStampItem
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  telefon?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  web?: string;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  oblastCestovnihoRuchu?: string;
  /**
   *
   * @type {boolean}
   * @memberof SafeTravelsStampItem
   */
  souhlasBezpecnostHygienaPodminky?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SafeTravelsStampItem
   */
  souhlasZpracovaniOsobnichUdaju?: boolean;
  /**
   *
   * @type {string}
   * @memberof SafeTravelsStampItem
   */
  zobrazeniNaWebech?: string;
  /**
   *
   * @type {boolean}
   * @memberof SafeTravelsStampItem
   */
  schvaleno?: boolean;
}

interface Translation {
  value: string;
  translation: string;
}

function isTranslation(data: any): data is Translation {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.value === 'string' &&
    typeof data.translation === 'string'
  );
}

@Module({
  name: 'SafeTravelsModule',
  stateFactory: true,
  namespaced: true,
})
export default class SafeTravelsModule extends AbstractModule {
  protected promiseCache: Promise<void> | null = null;
  public items: SafeTravelsListItem[] = [];
  public categories: ItemData[] = [];
  public loading: boolean = false;

  @Action({ rawError: true })
  public getSafeTravels(locale: string): Promise<void> {
    if (this.promiseCache) {
      return this.promiseCache;
    }
    this.setLoading(true);
    // TODO: remove type from result when we have it in the API types
    const promise: Promise<void> = this.$api
      .resources()
      .resourcesGetResource('VCR.Forms.SafeTravels.TourismAreas', locale)
      .then((resource) => {
        const translations: Translation[] =
          resource.resourceTextJson && Array.isArray(resource.resourceTextJson)
            ? resource.resourceTextJson.filter(isTranslation)
            : [];
        return this.$api
          .safeTravels()
          .safeTravelStampsGetItems()
          .then((result: SafeTravelsStampItem[]) => {
            const categories: string[] = [];
            const items: SafeTravelsListItem[] = result
              .filter((item) => {
                return (
                  item.zobrazeniNaWebech &&
                  item.schvaleno === true &&
                  item.zobrazeniNaWebech.split('|').indexOf('CZT') !== -1
                );
              })
              .map((item) => {
                const cats = item.oblastCestovnihoRuchu
                  ? item.oblastCestovnihoRuchu.split('|')
                  : [];
                cats.forEach((cat) => {
                  if (categories.indexOf(cat) === -1) {
                    categories.push(cat);
                  }
                });
                return {
                  id: item.formItemID ? item.formItemID.toString() : 'unknown',
                  name: item.nazevProvozovny || '',
                  link: item.web || '',
                  categories: cats.map((cat) => {
                    return cat
                      .toLowerCase()
                      .split(' ')
                      .join('_');
                  }),
                };
              });
            this.setCategories(
              categories.map((category) => {
                return {
                  text:
                    translations.find((translation) => {
                      return translation.value === category;
                    })?.translation || category,
                  value: category
                    .toLowerCase()
                    .split(' ')
                    .join('_'),
                };
              })
            );
            this.setItems(items);
          });
      })
      .finally(() => {
        this.setLoading(false);
      });
    this.setPromise(promise);
    return promise;
  }

  @Mutation
  protected setPromise(promise: Promise<void>) {
    this.promiseCache = promise;
  }

  @Mutation
  protected setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setItems(items: SafeTravelsListItem[]) {
    this.items = items;
  }

  @Mutation
  protected setCategories(categories: ItemData[]) {
    this.categories = categories;
  }
}
