export default {
  app: {
    maps: {
      more: 'Открыть подробную карту',
      style: {
        tooltip: 'Стиль карты',
        basic: 'Основной',
        outdoor: 'Турист',
        aerial: 'Аэродром',
        winter: 'Зима',
      },
      loadPoI: 'Местный поиск',
      currentPosition: 'Текущее положение',
      categories: 'Категории',
      location: 'Местонахождение',
    },
  },
};
