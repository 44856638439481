export default {
  app: {
    winter: {
      snowReport: 'Snow report',
      snow: '{type} {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: '粉雪',
        3: '濕雪',
        4: '霜',
        5: '粒雪',
        6: '人造雪',
        7: '潮濕',
      },
      lifts: '電梯',
      open: '開放',
      slopes: '滑雪道',
      webcam: '現場實況',
    },
  },
};
