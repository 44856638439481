export default {
  app: {
    common: {
      backToMenu: '返回菜單',
      blog: '博客',
      exploreButton: '探索',
      language: '語言',
      loadMore: '了解更多',
      moreInfo: '更多訊息',
      opensInNewWindow: '在新視窗開啟',
      pageTypes: {
        article: '文章',
        category: '類別',
        event: '事件',
        page: '頁',
        place: '地方',
        region: '地區',
      },
      selectLanguage: '選擇語言',
      homepageBreadcrumb: '首頁',
      translationVoting: '請問您是否滿意這則翻譯？',
      translationVotingSuccess: '謝謝您的回饋！',
    },
  },
};
