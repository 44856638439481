export default {
  app: {
    cookies: {
      title: 'Vi respekterar din integritet',
      text:
        'Vi använder cookies för att anpassa innehåll, analysera trafik och tillhandahålla funktioner för sociala medier. Vi delar information om hur du använder vår webbplats med våra sociala medier och analyspartners. Partners kan kombinera denna information med annan information som du tillhandahåller dem eller som de får som ett resultat av din användning av deras tjänster. Du hittar mer information {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'här',
      },
      buttons: {
        accept: 'Acceptera alla cookies',
        save: 'Spara valda',
      },
      settings: {
        button: 'Detaljerade inställningar',
        required: 'Nödvändiga (för att webbplatsen ska fungera optimalt)',
        analytics:
          'Analytiska (för att förstå hur besökarna upplever webbplatsen eller för att förbättra möjligheten att anpassa hemsidans funktion efter användarnas behov)',
        statistics:
          'Statistik (för att förstå hur besökare använder webbplatsen)',
        marketing:
          'Marknadsföring (för att spåra besökare för att visa relevanta annonser)',
        reject: 'Avvisa alla',
        accept: 'Acceptera alla',
      },
    },
  },
};
