export default {
  app: {
    winter: {
      snowReport: 'Parte de nieve',
      snow: '{snow} cm de {type}',
      snowTypeTranslation: {
        1: '',
        2: 'nieve en polvo',
        3: 'nieve mojada',
        4: 'nieve helada',
        5: 'nieve firme',
        6: 'nieve artificial',
        7: 'nieve húmeda',
      },
      lifts: 'Teleféricos',
      open: 'abierto',
      slopes: 'Pistas de esquí',
      webcam: 'Webcam',
    },
  },
};
