export default {
  app: {
    user: {
      button: {
        logIn: 'Pierakstīties',
        logOut: 'Izrakstīties',
      },
      error: {
        username: 'Lietotājvārds nevar būt tukšs',
        password: 'Parole nevar būt tukša',
        undefined: 'Neizdevās pierakstīties',
      },
      title: 'Pierakstīties',
      placeholder: {
        user: 'Lietotājvārds',
        pass: 'Parole',
      },
      loggedIn: 'Tu esi pierakstījies kā {0}',
      admin: 'Administrācija',
    },
  },
};
