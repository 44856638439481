export default {
  app: {
    search: {
      button: 'найти',
      noResults: 'результатов не найдено',
      title: 'Поиск',
      widgetTitle: 'Найти что делать в стране',
    },
  },
};
