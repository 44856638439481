export default {
  app: {
    maps: {
      more: 'Otwórz szczegółową mapę',
      style: {
        tooltip: 'Styl mapy',
        basic: 'Podstawowe',
        outdoor: 'Turysta',
        aerial: 'Z powietrza',
        winter: 'Zima',
      },
      loadPoI: 'Wyszukiwanie lokalne',
      currentPosition: 'Obecna pozycja',
      categories: 'Kategorie',
      location: 'Lokalizacja',
    },
  },
};
