/**
 * Generic function to generate enum typeguards
 * @param e
 */
const isEnumGenerator = <T>(e: T) => {
  return (token: unknown): token is T[keyof T] => {
    return Object.values(e).includes(token as T[keyof T]);
  };
};

export default isEnumGenerator;
