export default {
  app: {
    maps: {
      more: 'Atidaryti išsamų žemėlapį',
      style: {
        tooltip: 'Žemėlapio stilius',
        basic: 'Pagrindinis',
        outdoor: 'Turistinis',
        aerial: 'Oro',
        winter: 'Žiema',
      },
      loadPoI: 'Vietinė paieška',
      currentPosition: 'Dabartinė padėtis',
      categories: 'Kategorijos',
      location: 'Vieta',
    },
  },
};
