export default {
  app: {
    safeTravels: {
      search: 'Trovare realtà',
      categories: 'Scegliere categoria',
      button: 'Cercare',
      table: {
        name: 'Denominazione',
        link: 'Sito web',
      },
      categoryTranslations: {
        eventy: 'Eventi',
        kongresy_a_veletrhy: 'Fiere e congressi',
        restaurace: 'Ristorante',
        ubytovací_zařízení: 'Struttura ricettiva',
        sauny: 'Sauna',
        přírodní_a_umělá_koupaliště: 'Piscine naturali ed artificiali',
        lanová_doprava: 'Funivie',
        maloobchod: 'Vendita al dettaglio',
        fitness_centra: 'Fitness',
        kina: 'Cinema',
        golfové_areály: 'Campi da golf',
      },
    },
  },
};
