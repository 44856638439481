export default {
  app: {
    maps: {
      more: 'Aprire la mappa dettagliata',
      style: {
        tooltip: 'Stile della mappa',
        basic: 'Base',
        outdoor: 'Turista',
        aerial: 'Aereo',
        winter: 'Inverno',
      },
      loadPoI: 'Ricerca locale',
      currentPosition: 'Posizione attuale',
      categories: 'Categorie',
      location: 'Posizione',
    },
  },
};
