export default {
  app: {
    search: {
      button: 'Finne',
      noResults: 'Ingen resultater',
      title: 'Søk',
      widgetTitle: 'Finn ut hva du skal gjøre i landet',
    },
  },
};
