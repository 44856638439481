import { Component, Watch } from 'vue-property-decorator';
import Page from './Page';
import favicons from '~/assets/favicons';
import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { NotFound, PageLoader } from '~/components/templates/common';
import { VueComponentMixin } from '~/utils/vue-component';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import Region from '~/views/Region';
import Event from '~/views/Event';
import Place from '~/views/Place';
import { OneOfThePages } from '~/utils/views';
import {
  isEventPage,
  isItineraryPage,
  isPlacePage,
  isRegionPage,
  isTradeOfferPage,
} from '~/utils/views/typeguards';
import {
  getPathWithLocale,
  getPathWithoutLocale,
  getUrlFromPath,
} from '~/app/core/router';
import NavigationModule from '~/app/core/store/modules/NavigationModule';
import TradeOffer from './TradeOffer';
import Redirect from '~/utils/http/Redirect';
import Itinerary from './Itinerary';

@Component
export default class DynamicResource extends VueComponentMixin<
  {},
  PrefetchComponent
>(Prefetch) {
  protected component: JSX.Element | null = null;

  protected get routerModule(): RouterModule {
    return getModule(RouterModule, this.$store);
  }

  protected get loading(): boolean {
    return this.routerModule.loading;
  }

  protected get resource(): OneOfThePages | null {
    return this.routerModule.resource;
  }

  protected get navigationModule(): NavigationModule {
    return getModule(NavigationModule, this.$store);
  }

  public favicons() {
    return favicons;
  }

  public prefetch() {
    const path = this.$route.meta.friendlyUrl
      ? getPathWithoutLocale(this.$router)
      : this.$route.params.guid;
    const locale = this.$i18n.locale;

    return this.routerModule
      .loadResourceByPath({
        guid: path,
        locale,
        query: this.$route.query || undefined,
        withNav: this.$isServer,
      })
      .then(() => {
        if (!this.$isServer) {
          return getModule(NavigationModule, this.$store).load({
            path: (this.resource && this.resource.navigation) || undefined,
            locale,
            query: this.$route.query,
          });
        } else {
          this.setComponent();
        }
        return Promise.resolve();
      });
  }

  public render() {
    if (!this.component) {
      return;
    }

    return this.component;
  }

  @Watch('loading', { immediate: true })
  protected setComponent() {
    if (this.routerModule.loading) {
      this.component = <PageLoader />;
      return;
    }

    if (this.resource === null || !this.routerModule.found) {
      this.component = <NotFound />;
      return;
    }

    if (this.$isServer && this.$route.params.guid) {
      this.component = (
        <Redirect
          statusCode={301}
          location={getUrlFromPath(
            this.resource.canonicalPath,
            this.$router,
            true
          )}
        />
      );
    } else if (
      this.$isServer &&
      decodeURIComponent(this.$router.currentRoute.path) !==
        getPathWithLocale(this.resource.canonicalPath, this.$router)
    ) {
      this.component = (
        <Redirect
          statusCode={303}
          location={getUrlFromPath(
            this.resource.canonicalPath,
            this.$router,
            true
          )}
        />
      );
    } else if (isRegionPage(this.resource)) {
      this.component = <Region data={this.resource} key={this.resource.guid} />;
    } else if (isEventPage(this.resource)) {
      this.component = <Event data={this.resource} key={this.resource.guid} />;
    } else if (isPlacePage(this.resource)) {
      this.component = <Place data={this.resource} key={this.resource.guid} />;
    } else if (isTradeOfferPage(this.resource)) {
      this.component = (
        <TradeOffer data={this.resource} key={this.resource.guid} />
      );
    } else if (isItineraryPage(this.resource)) {
      this.component = (
        <Itinerary data={this.resource} key={this.resource.guid} />
      );
    } else {
      this.component = <Page data={this.resource} key={this.resource.guid} />;
    }
  }
}
