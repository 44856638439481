import Accomodation from './Accomodation';
import Culture from './Culture';
import Event from './Event';
import Experience from './Experience';
import Lifestyle from './Lifestyle';
import Landmark from './Landmark';
import Nature from './Nature';
import Pin from './Pin';
import Restaurant from './Restaurant';
import Spa from './Spa';
import SummerSports from './SummerSports';
import WinterSports from './WinterSports';

export default {
  Accomodation,
  Culture,
  Event,
  Experience,
  Lifestyle,
  Landmark,
  Nature,
  Pin,
  Restaurant,
  Spa,
  SummerSports,
  WinterSports,
};
