import { CztWidgets } from '~/utils/views/widgets';
import { ContentBlockInterface } from './types';

export default function(data: any): data is ContentBlockInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.HTML &&
    typeof data.content !== 'undefined'
  );
}
