import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class PlFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--poland'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M16.893 32.687c8.836 0 16-7.162 16-15.999h-32c0 8.837 7.164 16 16 16z'
            fill='#d61f3c'
            fill-rule='nonzero'
          />
          <path
            d='M16.893.688c-8.836 0-16 7.164-16 16h32c0-8.836-7.164-16-16-16z'
            fill='#e9e8e7'
            fill-rule='nonzero'
          />
        </svg>
      </i>
    );
  }
}
