import { Position } from './types';

export default function(data: any): data is Position {
  for (const value in Position) {
    if (Position.hasOwnProperty(value)) {
      if (data === (Position as any)[value]) {
        return true;
      }
    }
  }

  return false;
}
