import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class RuFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--russia'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g fill-rule='nonzero'>
            <path
              d='M16.892 32.026c6.456 0 12.016-3.827 14.544-9.333H2.349c2.528 5.506 8.087 9.333 14.543 9.333z'
              fill='#ed1f24'
            />
            <path
              d='M16.892.027C10.436.027 4.877 3.853 2.35 9.36h29.087C28.908 3.853 23.348.027 16.892.027z'
              fill='#fff'
            />
            <path
              d='M.892 16.027c0 2.379.524 4.635 1.457 6.666h29.087a15.947 15.947 0 001.456-6.666c0-2.38-.525-4.638-1.456-6.667H2.349a15.925 15.925 0 00-1.457 6.667z'
              fill='#3853a4'
            />
          </g>
        </svg>
      </i>
    );
  }
}
