import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class BrFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--brazil'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33 33'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g id='XMLID_2284_'>
            <g id='XMLID_2313_'>
              <circle
                id='XMLID_2367_'
                fill='#009b3a'
                cx='16.5'
                cy='16.5'
                r='16'
              />
              <path
                id='XMLID_2366_'
                fill='#fedf00'
                d='M16.5 26.2 1.2 16.5l15.3-9.7 15.3 9.7z'
              />
              <g id='XMLID_2314_'>
                <g id='XMLID_2361_'>
                  <g id='XMLID_2364_'>
                    <circle
                      id='XMLID_2365_'
                      cx='16.5'
                      cy='16.5'
                      r='6.4'
                      fill='#002776'
                    />
                  </g>
                  <g id='XMLID_2362_'>
                    <path
                      id='XMLID_2363_'
                      fill='#fff'
                      d='M10.6 13.9c-.1.3-.3.6-.3 1 .4-.1 6.9-1.4 12.3 3.5.1-.3.2-.7.2-1-5.2-4.5-11.3-3.6-12.2-3.5z'
                    />
                  </g>
                </g>
                <path
                  id='XMLID_2357_'
                  fill='#009b3a'
                  d='M11.3 14.2s-.1-.1 0 0c-.1-.1-.1-.1-.1-.2H11s-.1 0-.1.1c0 0 0 .1-.1.1v.2s0 .1.1.1c0 0 .1 0 .1.1h.2s.1 0 .1-.1c0 0 0-.1.1-.1v-.1c-.1 0-.1-.1-.1-.1zm-.1.2c-.1 0-.1.1 0 0 0 .1-.1.1-.1.1H10.8v-.4h.4V14.4z'
                />
                <path
                  id='XMLID_2353_'
                  fill='#009b3a'
                  d='M12 14.4s0-.1 0 0l-.1-.1s.1 0 .1-.1V14h-.5v.6h.1v-.2h.1l.1.1h.1l.1-.1zm-.1-.2s-.1 0 0 0c-.1 0-.1 0 0 0h-.2V14H12s-.1.1-.1.2c0-.1 0 0 0 0z'
                />
                <path
                  id='XMLID_2349_'
                  fill='#009b3a'
                  d='M12.7 14c-.1 0-.1-.1-.2-.1h-.2v.6h.2c.2 0 .3-.1.3-.3 0-.1 0-.2-.1-.2zm-.1.4c-.1.1-.1.1-.2.1h-.1V14H12.6v.1c.1.2.1.2 0 .3z'
                />
                <path
                  id='XMLID_2347_'
                  fill='#009b3a'
                  d='M13.2 14.4v-.2h.3v-.1h-.3V14h.3v-.1h-.4v.6h.5v-.1h-.4z'
                />
                <path
                  id='XMLID_2345_'
                  fill='#009b3a'
                  d='m14.3 14-.2.5-.1-.5h-.2v.6h.1v-.4l.1.5h.1l.2-.5v.4h.1V14h-.1z'
                />
                <path
                  id='XMLID_2343_'
                  fill='#009b3a'
                  d='m15.6 14.3-.4-.1-.1.5.4.1v-.1h-.3v-.1h.3v-.1h-.3v-.1l.4-.1z'
                />
                <path
                  id='XMLID_2339_'
                  fill='#009b3a'
                  d='m16.8 14.5-.1-.1h-.1l-.2-.1-.2.6h.1l.1-.2h.4v-.1c.1 0 .1 0 0-.1.1.1.1.1 0 0zm-.1.1c0 .1 0 .1 0 0 0 .1 0 .1 0 0l-.1.1h-.1l.1-.2h.2c-.1 0-.1 0-.1.1 0-.1 0-.1 0 0 0-.1 0 0 0 0z'
                />
                <path
                  id='XMLID_2335_'
                  fill='#009b3a'
                  d='M17.6 14.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0l-.1-.1h-.1l-.3-.1-.1.5h.1l.1-.2h.1v.2h.1v-.4h.1s.1 0 .1-.1v.2zm-.1 0s0 .1 0 0c0 .1 0 .1 0 0 0 .1-.1.1 0 0l-.1.1h-.1l.1-.2h.1v.1z'
                />
                <path
                  id='XMLID_2331_'
                  fill='#009b3a'
                  d='M18.4 15c-.1-.1-.1-.1-.2-.1H18s-.1 0-.1.1 0 .1-.1.1v.2s0 .1.1.1c0 0 .1 0 .1.1h.2s.1 0 .1-.1c0 0 0-.1.1-.1V15zm-.1.2c0 .1 0 .1 0 0l-.1.1H17.9v-.4l.1-.1H18.3v.4z'
                />
                <path
                  id='XMLID_2329_'
                  fill='#009b3a'
                  d='M19.1 15.5c.1-.1.1-.1 0 0v-.2s0-.1-.1-.1c0 0-.1 0-.1-.1h-.2s-.1 0-.1.1l-.1.1v.2s0 .1.1.1l.1.1h.2v.1h.1l.1-.3h-.3v.1l.1.1v.1H18.6v-.4h.4l.1.1z'
                />
                <path
                  id='XMLID_2325_'
                  fill='#009b3a'
                  d='M19.8 15.7c0-.1 0-.1 0 0 0-.1 0-.1 0 0l-.1-.1-.2-.1-.3.5.1.1.1-.2.1.1v.2l.1.1v-.4h.1s.1 0 .1-.1c.1 0 .1-.1 0-.1.1 0 .1 0 0 0zm-.1 0c0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0h-.1l-.1-.1.1-.2.1.1v.2c.1 0 0 0 0 0z'
                />
                <path
                  id='XMLID_2323_'
                  fill='#009b3a'
                  d='m20.6 16.1-.3-.3-.3.5.4.2v-.1l-.3-.2.1-.1.3.2v-.1l-.3-.2.1-.1.3.2z'
                />
                <path
                  id='XMLID_2321_'
                  fill='#009b3a'
                  d='m21.2 16.4-.1-.1h-.4V16.6l.1.1v-.1l-.1-.1v.2c0 .1.1.1.1.2H21.1l.1-.1v-.2c.1 0 .1.1.1.1l.1.1c-.2-.2-.2-.2-.2-.4 0 .1 0 .1 0 0z'
                />
                <path
                  id='XMLID_2319_'
                  fill='#009b3a'
                  d='M21.9 16.9s0-.1 0 0l-.1-.1h-.4V17.1l.1.1h-.1v-.1l-.1-.1v.2c0 .1.1.1.1.1H21.7l.1-.1V17c.1 0 .1.1.1.1l.1.1c-.1-.2-.1-.2-.1-.3z'
                />
                <path
                  id='XMLID_2315_'
                  fill='#009b3a'
                  d='M22.5 17.4v-.1s0-.1-.1-.1h-.2s-.1 0-.1.1l-.1.1s0 .1-.1.1v.2l.1.1h.2s.1 0 .1-.1l.1-.1s0-.1.1-.1v-.1zm-.1.1s0 .1 0 0l-.1.1h-.4V17.3s0-.1.1-.1H22.3l.1.1v.2c0-.1 0 0 0 0z'
                />
              </g>
            </g>
            <g id='XMLID_2285_'>
              <path
                id='XMLID_2312_'
                fill='#fff'
                d='m18.5 14.2.1.2h.2l-.2.1.1.2-.2-.1-.2.1.1-.2-.2-.1h.2z'
              />
              <path
                id='XMLID_2311_'
                fill='#fff'
                d='m18.2 16 .1.1h.1l-.1.1.1.1-.2-.1-.1.1.1-.1-.2-.1h.2z'
              />
              <path
                id='XMLID_2310_'
                fill='#fff'
                d='m16.5 17.3.1.2h.1l-.1.1v.1l-.1-.1-.1.1v-.1l-.1-.1h.1z'
              />
              <path
                id='XMLID_2309_'
                fill='#fff'
                d='M14.1 16.5v.2h.2l-.1.1v.2l-.1-.2-.2.2.1-.2-.2-.1h.2z'
              />
              <path
                id='XMLID_2308_'
                fill='#fff'
                d='m11.8 17.8.1.2h.2l-.2.1.1.2-.2-.2-.1.2v-.2l-.1-.1h.2z'
              />
              <path
                id='XMLID_2307_'
                fill='#fff'
                d='m11 18.4.1.1h.2l-.2.1.1.2-.2-.1-.1.1v-.2l-.1-.1h.2z'
              />
              <path
                id='XMLID_2306_'
                fill='#fff'
                d='m13.9 19.6.1.2h.2l-.2.1.1.2-.2-.1-.1.1v-.2l-.1-.1h.2z'
              />
              <path
                id='XMLID_2305_'
                fill='#fff'
                d='M13 19.1v.2h.2l-.2.1.1.1-.1-.1-.1.1v-.1l-.1-.1h.1z'
              />
              <path
                id='XMLID_2304_'
                fill='#fff'
                d='M13.2 18.5v.1h.2l-.2.1.1.2-.1-.1-.2.1.1-.2-.2-.1h.2z'
              />
              <path
                id='XMLID_2303_'
                fill='#fff'
                d='M12.5 17.5v.1h.1l-.1.1v.1-.1l-.1.1v-.1l-.1-.1h.1z'
              />
              <path
                id='XMLID_2302_'
                fill='#fff'
                d='M11.3 15v.2h.2l-.1.2v.2l-.1-.2-.2.2v-.2l-.1-.2h.2z'
              />
              <path
                id='XMLID_2301_'
                fill='#fff'
                d='m17.2 17.9.1.1h.2l-.2.1.1.2-.2-.1-.1.1.1-.2-.2-.1h.2z'
              />
              <path
                id='XMLID_2300_'
                fill='#fff'
                d='m16.5 19.1.1.2h.2l-.2.1.1.2-.2-.1-.2.1.1-.2-.2-.1h.2z'
              />
              <path
                id='XMLID_2299_'
                fill='#fff'
                d='m18.4 20.6.1.2h.2l-.2.1.1.1-.2-.1-.1.1v-.1l-.1-.1h.2z'
              />
              <path
                id='XMLID_2298_'
                fill='#fff'
                d='m21 18.5.1.2h.1l-.1.1.1.2-.2-.1-.1.1v-.2l-.1-.1h.1z'
              />
              <path
                id='XMLID_2297_'
                fill='#fff'
                d='M21.3 19.1v.2h.2l-.1.1v.1l-.1-.1-.2.1.1-.1-.1-.1h.1z'
              />
              <path
                id='XMLID_2296_'
                fill='#fff'
                d='m21.9 18.6.1.2h.1-.1v.2l-.1-.1-.1.1v-.2h-.1.2z'
              />
              <path
                id='XMLID_2295_'
                fill='#fff'
                d='M20.9 19.6v.2h.2l-.1.1v.1l-.1-.1-.2.1.1-.1-.1-.1h.1z'
              />
              <path
                id='XMLID_2294_'
                fill='#fff'
                d='m19.7 20.4.1.2h.2l-.2.1.1.2-.2-.1-.1.1v-.2l-.1-.1h.2z'
              />
              <path
                id='XMLID_2293_'
                fill='#fff'
                d='M19 20.1v.1h.2l-.2.1.1.1-.1-.1-.1.1v-.1l-.1-.1h.1z'
              />
              <path
                id='XMLID_2292_'
                fill='#fff'
                d='M19.7 19.8v.2h.2l-.1.1v.1l-.1-.1-.1.1v-.1l-.1-.1h.2z'
              />
              <path
                id='XMLID_2291_'
                fill='#fff'
                d='M20.4 20v.1h.1l-.1.1.1.1-.1-.1-.1.1v-.1l-.1-.1h.1z'
              />
              <path
                id='XMLID_2290_'
                fill='#fff'
                d='m19.7 21.1.1.2h.1-.1v.2l-.1-.1-.1.1v-.2h-.1.2z'
              />
              <path
                id='XMLID_2289_'
                fill='#fff'
                d='m17.9 19.9.1.1h.1l-.1.1v.2l-.1-.1-.1.1v-.2l-.1-.1h.2z'
              />
              <path
                id='XMLID_2288_'
                fill='#fff'
                d='M16.5 21.4v.1h.1l-.1.1h.1-.2.1l-.1-.1h.1z'
              />
              <path
                id='XMLID_2287_'
                fill='#fff'
                d='m15.8 17.9.1.2h.1-.1l.1.2-.2-.1-.1.1.1-.2h-.2.2z'
              />
              <path
                id='XMLID_2286_'
                fill='#fff'
                d='M16.2 18.4v.1h.1l-.1.1.1.1-.1-.1-.1.1v-.1l-.1-.1h.1z'
              />
            </g>
          </g>
        </svg>
      </i>
    );
  }
}
