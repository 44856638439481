export default {
  app: {
    maps: {
      more: 'Detaillierte Karte öffnen',
      style: {
        tooltip: 'Karte Stil',
        basic: 'Grundlegende',
        outdoor: 'Touristische',
        aerial: 'Luftbild',
        winter: 'Winter',
      },
      loadPoI: 'Lokale Suche',
      currentPosition: 'Derzeitige Position',
      categories: 'Kategorien',
      location: 'Standort',
    },
  },
};
