import { Component, Vue } from 'vue-property-decorator';
import {
  getFontTags,
  getTitle,
  getFavicons,
  getHeadTags,
  getInlineCss,
} from '~/mixins/HeadManagement';
import { isServerPrefetchComponent } from '~/mixins/prefetch';

@Component
export default class HeadManagement extends Vue {
  public serverPrefetch() {
    if (isServerPrefetchComponent(this) && this.prefetchPromise) {
      return this.prefetchPromise.then(() => {
        this.replaceHead();
      });
    }

    this.replaceHead();
    return Promise.resolve();
  }

  protected replaceHead() {
    const title = getTitle(this);

    if (title) {
      this.$ssrContext.title = title;
    }

    const fontTags = getFontTags(this);

    if (fontTags) {
      this.$ssrContext.fontTags = fontTags;
    }

    const headTags = getHeadTags(this);

    if (headTags) {
      this.$ssrContext.headTags = headTags;
    }

    const favicons = getFavicons();

    if (favicons) {
      this.$ssrContext.favicons = favicons;
    }

    const inlineCss = getInlineCss(this);

    if (inlineCss) {
      this.$ssrContext.inlineCss = inlineCss;
    }
  }
}
