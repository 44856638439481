export interface CztImage {
  alt: string;
  src: string;
  width?: number;
  height?: number;
  srcset?: string;
  sizes?: string;
  title?: string;
}

export function isCztImage(data: any): data is CztImage {
  return data.src && data.alt;
}

export const ImageIntersectionObserverOptions: IntersectionObserverInit = {
  root: null,
  threshold: 0.1,
};

export const lazyLoadedImageClass = 'is-lazy';

export const noLazyImageClass = 'is-notLazy';
