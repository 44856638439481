export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Elfogadom {0}',
          link: 'személyes adatok feldolgozása.',
          error: 'El kell fogadnia a személyes adatok feldolgozását.',
        },
      },
      send: 'Küld',
      sent: 'Az űrlapot sikeresen elküldtük.',
    },
  },
};
