import { defaultLocale } from '~/app/localization';
import { getSrcSet } from '~/components/atoms/image/Image';
import { MapPin } from '~/components/organisms/map/Map';
import {
  createExternal,
  createInternal,
  isInternal,
  Target,
} from '../molecules/link';

interface CreateCardReturnType {
  header: string;
  body: string;
  footer: string;
}

export default function createCard(
  place: MapPin,
  link: string,
  linkTitle: string,
  locale: string = defaultLocale
): CreateCardReturnType {
  const parsedLink = isInternal(link)
    ? createInternal(link, locale)
    : createExternal(link);
  const image = place.image
    ? `<div class="czt-image-wrapper czt-image-wrapper--ratio-1x1 czt-image-wrapper--cover-height"><img src="${
        place.image
      }" srcset="${getSrcSet(place.image, [
        320,
      ])}" sizes="320px" style="position: absolute"></div>`
    : undefined;
  const placeLink = place.url
    ? `<a
    class="v-btn v-btn--contained theme--light v-size--default czt-button"
    href="${parsedLink}"
    target="${Target.BLANK}
    title="${linkTitle}"
  >
    ${linkTitle}
  </a>`
    : undefined;

  return {
    body: image || '',
    footer: placeLink || '',
    header: place.title,
  };
}
