export default {
  app: {
    cookies: {
      title: 'Rešpektujeme vaše súkromie',
      text:
        'Súbory cookies používame na prispôsobenie obsahu, analýzu návštevnosti a poskytovanie funkcií sociálnych médií. Informácie o tom, ako používate našu webovú stránku, zdieľame s našimi partnermi v oblasti sociálnych médií a analytických služieb. Partneri môžu tieto informácie kombinovať s inými informáciami, ktoré im poskytnete alebo ktoré získajú v dôsledku vášho používania ich služieb. Viac informácií nájdete {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'tu',
      },
      buttons: {
        accept: 'Prijať všetky súbory cookie',
        save: 'Uložiť vybrané',
      },
      settings: {
        button: 'Podrobné nastavenia',
        required: 'Vyžaduje sa (pre správne fungovanie stránky)',
        analytics:
          'Analytika (na pochopenie toho, ako sa návštevníci správajú alebo pohybujú po webovej stránke)',
        statistics:
          'Štatistiky (na pochopenie toho, ako návštevníci používajú stránku)',
        marketing:
          'Marketing (na sledovanie návštevníkov, aby sa im zobrazovali relevantné reklamy)',
        reject: 'Odmietnuť všetko',
        accept: 'Prijať všetko',
      },
    },
  },
};
