import { CztWidgets } from '~/utils/views/widgets';
import { SafeTravelsListInterface } from './types';

export default function(data: any): data is SafeTravelsListInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.SAFE_TRAVELS &&
    typeof data.guid === 'string'
  );
}
