export default {
  app: {
    cookies: {
      title: '我們尊重您的隱私',
      text:
        '我們使用 cookies 於個人化內容、分析網頁流量以及提供社群媒體服務。我們會與社群媒體、流量分析的合作夥伴分享您如何使用我們的網站；而您提供給合作夥伴的資訊，或者他們因您使用服務而獲得的資訊，也有可能會與上述資訊一併被整合使用。{0}。 {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: '您可以在此處找到更多詳細信息',
      },
      buttons: {
        accept: '接受所有 cookie',
        save: '儲存選定的項目。',
      },
      settings: {
        button: '詳細設置',
        required: '所需訊息(為了讓本網站正常運作)',
        analytics: '分析（瞭解使用者如何與網站互動、在網頁中移動）',
        statistics: '數據(了解訪客如何使用本網站)',
        marketing: '行銷(追蹤訪客並呈現相關廣告)',
        reject: '全部拒絕。',
        accept: '全部接受。',
      },
    },
  },
};
