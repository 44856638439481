export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: "Page d'accueil",
      destinations: 'Destinations',
      thingsToDo: 'Que faire',
      new: 'Nouveautés',
      covid: 'Covid-19',
      travelInfo: 'Bon à savoir',
    },
  },
};
