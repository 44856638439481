export default {
  app: {
    search: {
      button: '찾다',
      noResults: '검색 결과가 없습니다',
      title: '검색',
      widgetTitle: '나라에서 할 일 찾기',
    },
  },
};
