import { Component, Prop, Emit } from 'vue-property-decorator';
import { ValueProp, VueComponent } from '~/utils/vue-component';
import Icon, { IconName } from '~/components/atoms/icons/Icon';
import { Colors } from '~/utils/theme/colors';

import style from './Multiselect.scss';
import { LocaleMessage } from 'vue-i18n';

export interface ItemData {
  value: string | number;
  text: string;
}

interface MultiselectInterface extends ValueProp<(string | number)[]> {
  disabled?: boolean;
  iconName?: IconName;
  iconSize?: number;
  iconColor?: Colors;
  items?: ItemData[];
  label?: string | LocaleMessage;
  loading?: boolean;
  placeholder?: string | LocaleMessage;
  readonly?: boolean;
  menuProps?: any;
  attach?: string;
  itemSlot?: (data: {
    parent: VueComponent<Multiselect>;
    item: ItemData;
    on: any;
    attrs: any;
  }) => JSX.Element | JSX.Element[];
  selectionSlot?: (data: {
    parent: VueComponent<Multiselect>;
    item: ItemData;
    index: number;
    select: unknown;
    selected: boolean;
    disabled: boolean;
  }) => JSX.Element | JSX.Element[];
}

@Component({
  style,
})
export default class Multiselect extends VueComponent<MultiselectInterface>
  implements MultiselectInterface {
  @Prop({ default: '', type: String })
  public placeholder!: string | LocaleMessage;

  @Prop({ default: '', type: String })
  public label!: string | LocaleMessage;

  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public readonly!: boolean;

  @Prop({ default: () => [], type: Array })
  public items!: ItemData[];

  @Prop()
  public iconName?: IconName;

  @Prop({ default: 1, type: Number })
  public iconSize!: number;

  @Prop({ default: Colors.PRIMARY, type: String })
  public iconColor!: Colors;

  @Prop({ default: () => [], type: Array })
  public value?: (string | number)[];

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop()
  public menuProps?: any;

  @Prop()
  public attach?: string;

  @Prop()
  public itemSlot?: MultiselectInterface['itemSlot'];

  @Prop()
  public selectionSlot?: MultiselectInterface['selectionSlot'];

  public render() {
    const scopedSlots: { [key: string]: unknown } = {};
    if (this.itemSlot) {
      const key = 'item';
      scopedSlots[key] = this.itemSlot;
    }
    if (this.selectionSlot) {
      const key = 'selection';
      scopedSlots[key] = this.selectionSlot;
    }
    const icon = this.iconName ? (
      <Icon
        name={this.iconName}
        size={this.iconSize}
        style={`color: ${this.iconColor} !important`}
        slot={'prepend-inner'}
      />
    ) : null;
    return (
      <v-autocomplete
        attach={this.attach}
        class='czt-multiselect'
        placeholder={this.placeholder}
        label={this.label}
        disabled={this.disabled}
        readonly={this.readonly}
        items={this.items}
        value={this.value}
        onChange={this.change}
        solo
        hide-no-data
        clearable={!this.readonly}
        multiple
        loading={this.loading}
        menu-props={this.menuProps}
        scopedSlots={scopedSlots}
      >
        {icon}
      </v-autocomplete>
    );
  }

  @Emit('input')
  protected change(value: (string | number)[]): (string | number)[] {
    return value;
  }
}
