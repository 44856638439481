export default {
  app: {
    tradeOffers: {
      country: 'Ország',
      loginPrompt:
        'A privát kereskedelmi ajánlatok megtekintéséhez {0} szükséges.',
      logIn: 'belépés',
    },
  },
};
