import { Component, Emit, Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import appEnv from '~/app/core/appEnv';
import RecaptchaModule from '~/app/core/store/modules/RecaptchaModule';
import { VueComponent } from '~/utils/vue-component';

interface RecaptchaWrapperInterface {
  onRecaptchaCallback?: (token: string) => void;
}

const rootClass = 'czt-recaptcha-wrapper';

@Component
export default class RecaptchaWrapper extends VueComponent<
  RecaptchaWrapperInterface
> {
  @Ref('recaptchaContainer')
  public recaptchaContainer?: HTMLDivElement;

  protected grecaptchaId: string | number | null = null;

  protected get recaptchaModule(): RecaptchaModule {
    return getModule(RecaptchaModule, this.$store);
  }

  protected execute() {
    if (typeof grecaptcha !== 'undefined' && this.grecaptchaId !== null) {
      const token = grecaptcha.getResponse(this.grecaptchaId);
      if (token) {
        grecaptcha.reset(this.grecaptchaId);
      }
      grecaptcha.execute(this.grecaptchaId);
    } else {
      this.callback('');
    }
  }

  protected reset() {
    if (typeof grecaptcha !== 'undefined' && this.grecaptchaId !== null) {
      grecaptcha.reset(this.grecaptchaId);
    }
  }

  public mounted() {
    if (!appEnv.RECAPTCHA_SITE_KEY || !this.recaptchaContainer) {
      return;
    }
    this.recaptchaModule.loadRecaptcha().then(() => {
      if (grecaptcha) {
        grecaptcha.ready(() => {
          if (grecaptcha && this.recaptchaContainer) {
            const grecaptchaId = grecaptcha.render(this.recaptchaContainer, {
              sitekey: appEnv.RECAPTCHA_SITE_KEY,
              size: 'invisible',
              callback: this.callback,
              badge: 'inline',
            });
            this.grecaptchaId = grecaptchaId;
          }
        });
      }
    });
  }

  public render() {
    return (
      <div class={rootClass}>
        {this.$scopedSlots.default &&
          this.$scopedSlots.default({
            on: {
              click: this.execute,
            },
          })}
        {appEnv.RECAPTCHA_SITE_KEY && (
          <div class='mt-3 d-flex justify-center' ref='recaptchaContainer' />
        )}
      </div>
    );
  }

  public beforeUnmount() {
    this.reset();
  }

  @Emit('recaptchaCallback')
  protected callback(token: string) {
    return token;
  }
}
