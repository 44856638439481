import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class ItFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--italy'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g fill-rule='nonzero'>
            <path
              d='M.5 16.743c0 6.455 3.828 12.014 9.334 14.543V2.198C4.328 4.727.5 10.286.5 16.743z'
              fill='#068d46'
            />
            <path
              d='M32.5 16.743c0-6.457-3.825-12.016-9.334-14.545v29.088c5.509-2.529 9.334-8.088 9.334-14.543z'
              fill='#d4232d'
            />
            <path
              d='M16.5.743c-2.38 0-4.635.523-6.666 1.455v29.088a15.927 15.927 0 006.666 1.457c2.38 0 4.637-.525 6.666-1.457V2.198A15.923 15.923 0 0016.5.743z'
              fill='#fff'
            />
          </g>
        </svg>
      </i>
    );
  }
}
