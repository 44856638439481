export default {
  app: {
    panorama: {
      loadingText: 'Descargando imagen...',
      autorotate: 'Giro automático',
      zoom: 'Zoom',
      zoomOut: 'Reducir zoom',
      zoomIn: 'Ampliar zoom',
      move: 'Mover',
      download: 'Bajar',
      fullscreen: 'Pantalla completa',
      menu: 'Menu',
      twoFingers: 'Usa dedos para navegar',
      ctrlZoom: 'Usa ctrl + scroll del ratón para cambiar zoom',
      loadError: 'Panorama no se puede descargar',
    },
  },
};
