export default {
  app: {
    safeTravels: {
      search: 'Nájsť prevádzku',
      categories: 'Vyberte kategóriu',
      button: 'Hľadať',
      table: {
        name: 'Názov prevádzky',
        link: 'Webové stránky',
      },
      categoryTranslations: {
        eventy: 'Podujatia',
        kongresy_a_veletrhy: 'Kongresy a veľtrhy',
        restaurace: 'Reštaurácie',
        ubytovací_zařízení: 'Ubytovacie zariadenia',
        sauny: 'Sauny',
        přírodní_a_umělá_koupaliště: 'Prírodné a umelé kúpaliská',
        lanová_doprava: 'Lanové dráhy',
        maloobchod: 'Maloobchod',
        fitness_centra: 'Fitness centrá',
        kina: 'Kiná',
        golfové_areály: 'Golfové areály',
      },
    },
  },
};
