import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class LandmarkIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--landmark'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M136.4 73.7v11.8H124V73.7h-12.4v11.8H99.3V73.7H86.9v11.8H74.5V31.4L39 1.4l-35.7 30v114.9h145.6V73.7h-12.4Z'
          />
        </svg>
      </i>
    );
  }
}
