import { VueComponent } from '~/utils/vue-component';
import { Advertisement as AdvertisementIterface } from '~/utils/views/advertisements';
import { Component, Prop } from 'vue-property-decorator';
import { AdvertisementThemeColors } from '~/utils/views/advertisements';
import { Headline } from '~/components/atoms';
import { ImageWrapper, Link } from '~/components/molecules';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';
import { Target } from '~/utils/molecules/link';
import appEnv from '~/app/core/appEnv';

import style from './Advertisement.scss';
import { Align } from '~/components/atoms/headline/Headline';

interface AdvertisementProps {
  data: AdvertisementIterface;
}

const sizes: Sizes[] = [
  {
    minWidth: 1008,
    size: (1000 / 12) * 4,
    unit: 'px',
  },
  {
    minWidth: 768,
    size: (710 / 12) * 5,
    unit: 'px',
  },
  {
    size: 100,
    unit: 'vw',
  },
];

const rootClass = 'czt-advertisement';

@Component({ style })
export default class Advertisement extends VueComponent<AdvertisementProps>
  implements AdvertisementProps {
  @Prop()
  public data!: AdvertisementIterface;

  protected get theme(): string {
    switch (this.data.theme) {
      case AdvertisementThemeColors.DARK_BLUE:
        return `${rootClass}--dark-blue`;
      case AdvertisementThemeColors.GRAY:
        return `${rootClass}--gray`;
      case AdvertisementThemeColors.BLUE:
      default:
        return `${rootClass}--blue`;
    }
  }

  public mounted() {
    this.sendStatistic('loaded');
  }

  public render() {
    const classes = [rootClass, this.theme, 'my-5'];

    const content = this.data.link ? (
      <Link url={this.data.link} target={Target.BLANK}>
        {this.renderAd()}
      </Link>
    ) : (
      this.renderAd()
    );

    return (
      <v-hover
        scopedSlots={{
          default: ({ hover }: { hover: boolean }) => {
            return (
              <v-sheet
                onClick={() => this.sendStatistic('clicked')}
                class={[
                  ...classes,
                  this.data.link && hover ? `${rootClass}--hover` : '',
                ].join(' ')}
              >
                {content}
              </v-sheet>
            );
          },
        }}
      />
    );
  }

  protected renderAd() {
    return [
      this.data.title && (
        <div
          class={`d-flex flex-nowrap align-center${
            !this.data.icon ? ' justify-center' : ''
          }`}
        >
          {this.data.icon && (
            <div class='ma-3'>
              <v-avatar>
                <img src={this.data.icon.src} alt={this.data.icon.alt} />
              </v-avatar>
            </div>
          )}
          <Headline level={4} class='pb-0 ma-3' light align={Align.LEFT}>
            {this.data.title}
          </Headline>
        </div>
      ),
      this.data.image && (
        <div class={`${rootClass}__image`}>
          <ImageWrapper
            image={this.data.image}
            ratio={this.data.ratio}
            sizes={sizes}
          />
        </div>
      ),
      this.data.content && (
        <div class='pa-3'>
          <HtmlRenderer light content={this.data.content} />
        </div>
      ),
    ];
  }

  protected sendStatistic(bannerAction: 'clicked' | 'loaded') {
    this.$api.banner().bannerStatitsticsAutomatedTranslationVoting(
      {
        bannerGUID: this.data.id,
        bannerAction,
      },
      appEnv.NEWSLETTER_API_KEY
    );
  }
}
