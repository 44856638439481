export default {
  app: {
    user: {
      button: {
        logIn: 'Se connecter',
        logOut: 'Se déconnecter',
      },
      error: {
        username: 'Le nom d’utilisataire à remplir obligatoirement',
        password: 'Le mot de passe à remplir obligatoirement',
        undefined: 'La connexion à échoué',
      },
      title: 'Se connecter',
      placeholder: {
        user: 'Nom d’utilisateur',
        pass: 'Mot de passe',
      },
      loggedIn: 'Vous êtes connecté(e) comme {0}',
      admin: 'Administration',
    },
  },
};
