export default {
  app: {
    maps: {
      more: 'Open Detailed Map',
      style: {
        tooltip: 'Map Style',
        basic: 'Basic',
        outdoor: 'Tourist',
        aerial: 'Aerial',
        winter: 'Winter',
      },
      loadPoI: 'Local Search',
      currentPosition: 'Current position',
      categories: 'Categories',
      location: 'Location',
    },
  },
};
