import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { getPathWithoutLocale } from '~/app/core/router';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { supportedLocales } from '~/app/localization';
import { Icons } from '~/components/atoms';
import { VueComponent } from '~/utils/vue-component';
import { LanguageLocaleInterface } from '~/utils/localization';

import style from './LanguageSelector.scss';

export interface LanguageSelectorInterface {
  currentLanguage: LanguageLocaleInterface | null;
  hideUnallowedLocales?: boolean;
}

@Component({
  style,
})
export default class LanguageSelector
  extends VueComponent<LanguageSelectorInterface>
  implements LanguageSelectorInterface {
  @Prop({ type: Object })
  public currentLanguage!: LanguageLocaleInterface | null;

  @Prop({ default: false, type: Boolean })
  public hideUnallowedLocales!: boolean;

  protected rootClass: string = 'czt-language-selector';

  protected currentPath: string = '';

  protected get routerStore() {
    return getModule(RouterModule, this.$store);
  }

  // TODO: figure out why v-menu doesn't support SSR
  protected renderMenu: boolean = false;

  public mounted() {
    this.renderMenu = true;
  }

  public render() {
    if (!this.renderMenu) {
      return;
    }
    const items = supportedLocales.filter(
      (item) =>
        item.public &&
        item.slug !== this.$i18n.locale &&
        (!this.routerStore.resource ||
          (this.routerStore.resource &&
            this.routerStore.resource?.allowedCultures.includes(item.slug) &&
            (!this.hideUnallowedLocales ||
              this.routerStore.resource?.availableCultures.includes(
                item.slug
              ))))
    );
    if (items.length < 1) {
      return;
    }

    return (
      <div class={this.rootClass}>
        <v-menu
          eager
          offset-y
          content-class={`${this.rootClass}__languages`}
          scopedSlots={{
            activator: (scope: any) => {
              return (
                <a
                  class={`${this.rootClass}__button`}
                  title={this.$t('app.common.selectLanguage')}
                  {...{
                    on: scope.on,
                  }}
                >
                  <Icons.common.Language size={2} />
                  <span class={`${this.rootClass}__label`}>
                    {this.$t('app.common.language')}
                  </span>
                  {this.currentLanguage && (
                    <span class={`${this.rootClass}__current`}>
                      {this.currentLanguage.name}
                    </span>
                  )}
                </a>
              );
            },
          }}
        >
          <v-list>
            {items
              .sort((a, b) => a.order - b.order)
              .map((lang) => (
                <v-list-item key={`language-selector-lang-${lang.slug}`}>
                  <router-link
                    to={this.getLocaleLink(lang.slug).toLowerCase()}
                    class={`${this.rootClass}__item`}
                    key={this.getLocaleLink(lang.slug)}
                  >
                    {lang.name}
                  </router-link>
                </v-list-item>
              ))}
          </v-list>
        </v-menu>
      </div>
    );
  }

  @Watch('$route', { immediate: true, deep: true })
  protected computeCurrentPath() {
    this.currentPath = getPathWithoutLocale(this.$router);
  }

  protected getLocaleLink(locale: string): string {
    if (
      this.routerStore.resource &&
      this.routerStore.resource.availableCultures.indexOf(locale) >= 0
    ) {
      return `/${locale}${this.currentPath}`;
    }

    return `/${locale}`;
  }
}
