export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Homepage',
      destinations: 'Destinazioni',
      thingsToDo: 'Cosa fare',
      new: 'Novità',
      covid: 'Covid-19',
      travelInfo: 'Info per viaggiatori',
    },
  },
};
