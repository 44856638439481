import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class FrFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--france'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M.892 16.914c0 6.456 3.827 12.015 9.333 14.543V2.37C4.72 4.898.892 10.458.892 16.914z'
            fill='#1d3364'
            fill-rule='nonzero'
          />
          <path
            d='M32.892 16.914c0-6.456-3.828-12.016-9.333-14.544v29.087c5.505-2.528 9.333-8.087 9.333-14.543z'
            fill='#ef4135'
            fill-rule='nonzero'
          />
          <path
            d='M16.892.915c-2.38 0-4.636.523-6.667 1.455v29.087a15.933 15.933 0 006.667 1.457c2.38 0 4.636-.524 6.667-1.457V2.37A15.946 15.946 0 0016.892.915z'
            fill='#fff'
            fill-rule='nonzero'
          />
        </svg>
      </i>
    );
  }
}
