export default {
  app: {
    newsletter: {
      bottomText:
        'Mit Ihrer Newsletter-Anmeldung stimmen Sie unserer <a href="~/Privacy-Policy" target="_blank">Datenschutzrichtlinie</a> zu.',
      button: 'Abonnieren',
      ctaDialogParagraph1: '',
      ctaDialogParagraph2:
        'Hätten Sie gerne wichtige Informationen und inspirierende Tipps für Ihren nächsten Urlaub in Tschechien?',
      ctaDialogParagraph3:
        'Abonnieren Sie unseren Newsletter und freuen Sie sich auf Ihre Reise!',
      ctaDialogTitle: 'Klopf, klopf!',
      ctaDialogBtn: 'Abonnieren',
      ctaDialogBtnLink: '~/Campaigns/2022/DE-Newsletter',
      dialog: {
        headline: 'Wähle deinen Newsletter aus: Land / Region',
        notice:
          'Ihre vorab angeführte E-Mail-Adresse wird nur zum Zweck des Zusendens unseres Newsletters verwendet und wird keinem anderen Subjekt übermittelt. Ihre E-Mail-Adresse werden wir zu diesem Zweck bis zu dem Tag in Evidenz halten, an dem Sie sich vom Newsletter abmelden. Melden Sie sich an, um aktuelle Informationen, Tipps, kommende Veranstaltungen und neue Geschichten zu erhalten. Ihre E-Mail-Adresse kann vom IT-Unternehmen verarbeitet werden, das für den Betrieb unserer Webseiten und Web-Applikationen zuständig ist. {0}',
        company:
          'Verantwortlicher für die Verarbeitung personenbezogener Daten ist die {0}, {1}',
        link: {
          privacy: 'Unsere Privacy Policy finden Sie hier.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Deine E-Mail-Adresse',
      newsletterError:
        'There seem to be no newsletters for you to subscribe to right now',
      serverError:
        'There was an error while executing the request. Please, try again',
      success:
        'Um Ihre Newsletter-Anmeldung erfolgreich abzuschließen,  bestätigen Sie bitte die Registrierung in der gesendeten E-Mail.',
      title: 'Aktuelle Urlaubstipps 1×/Monat via Newsletter',
      topText:
        'Melden Sie sich an, um aktuelle Informationen, Tipps, kommende Veranstaltungen und neue Geschichten zu erhalten.',
      b2bTitle: 'B2B-Newsletter 1x im Monat',
      b2bTopText:
        'Melden Sie sich an, um aktuelle Informationen, News, Termine und Einladungen zu erhalten.',
      validityError: 'Your email address seems to be invalid',
    },
  },
};
