import { Action, getModule, Module, Mutation } from 'vuex-module-decorators';
import { COOKIE_NAME } from '~/utils/cookies/config';
import getCookieValue from '~/utils/cookies/getCookieValue';
import setCookieValue from '~/utils/cookies/setCookieValue';
import {
  VISITOR_CONTACT_COOKIE_NAME,
  VISITOR_CONTACT_EXPIRATION_TIME,
  VISITOR_SESSION_COOKIE_NAME,
  VISITOR_SESSION_EXPIRATION_TIME,
} from '~/utils/visitMetrics/cookies';
import { ContactVisitLog } from '../../apiClient/api';
import appEnv from '../../appEnv';

import AbstractModule from './AbstractModule';
import CookieSettingsModule from './CookieSettingsModule';

export import VisitorContactType = ContactVisitLog.ContactTypeEnum;

@Module({
  name: 'VisitMetricsModule',
  stateFactory: true,
  namespaced: true,
})
export default class VisitMetricsModule extends AbstractModule {
  @Action({ rawError: true })
  public recordVisit() {
    const cookieSettings = getModule(CookieSettingsModule, this.store);
    return cookieSettings.readCookie(document.cookie).then(() => {
      const visitorSession = getCookieValue(
        VISITOR_SESSION_COOKIE_NAME,
        document.cookie
      );
      const cookieSet = getCookieValue(COOKIE_NAME, document.cookie);
      if (visitorSession && visitorSession === cookieSet) {
        return;
      }
      const visitType =
        cookieSettings.analytics ||
        cookieSettings.statistics ||
        cookieSettings.marketing
          ? VisitorContactType.Statistics
          : cookieSettings.cookieExists
          ? VisitorContactType.Technical
          : VisitorContactType.Black;
      return this.$api
        .visit()
        .contactVisitSubscribe(
          {
            contactType: visitType,
            userAgent: window.navigator.userAgent,
            contactID:
              getCookieValue(VISITOR_CONTACT_COOKIE_NAME, document.cookie) ||
              undefined,
          },
          appEnv.NEWSLETTER_API_KEY
        )
        .then((response) => {
          setCookieValue(
            VISITOR_CONTACT_COOKIE_NAME,
            response.contactID || '',
            VISITOR_CONTACT_EXPIRATION_TIME
          );
          setCookieValue(
            VISITOR_SESSION_COOKIE_NAME,
            cookieSet || '',
            VISITOR_SESSION_EXPIRATION_TIME
          );
        });
    });
  }
}
