export default {
  app: {
    cookies: {
      title: 'Kunnioitamme yksityisyyttäsi',
      text:
        'Käytämme evästeitä sivuston sisällön personointiin, liikenteen analysointiin ja sosiaalisen median toimintojen tarjoamiseen. Tietoja siitä, kuinka käytät verkkosivustoamme, jaamme kumppaneillemme sosiaalisen median ja analytiikan alueella. Kumppanit voivat yhdistää nämä tiedot muihin tietoihin, jotka annat heille tai joita he saavat käyttäessäsi heidän palveluitaan. Lisätietoja löydät {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'täältä',
      },
      buttons: {
        accept: 'Hyväksy kaikki evästeet',
        save: 'Tallenna valitut',
      },
      settings: {
        button: 'Yksityiskohtaiset asetukset',
        required: 'Pakolliset (sivun asianmukaista toimintaa varten)',
        analytics:
          'Analyysi (sivuston vierailijoiden verkkosivukäyttäytymisen ja liikkumisen ymmärtämiseksi)',
        statistics:
          'Tilastot (ymmärtääksemme, kuinka vierailijat käyttävät sivustoa)',
        marketing:
          'Markkinointi (liikenteen seuraamiseksi ja asianmukaisten mainosten näyttämiseksi)',
        reject: 'Estä kaikki',
        accept: 'Salli kaikki',
      },
    },
  },
};
