export default {
  app: {
    itinerary: {
      transportationInfoTitle: 'Próximo punto de tránsito',
      car: 'Coche',
      publicTransport: 'Transporte público',
      filterLabel: 'Filtrar por etiqueta',
    },
  },
};
