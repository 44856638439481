import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Mx.scss';

@Component({
  style,
})
export default class MxFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--mexico'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33 33'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M7.2 3.5v26a16.32 16.32 0 0 1-5.8-7.7A15.95 15.95 0 0 1 7.2 3.5z'
            fill='#006747'
          />
          <path
            d='M32.5 16.5a15.95 15.95 0 0 1-6.7 13v-26a16.32 16.32 0 0 1 5.8 7.7c.6 1.7.9 3.5.9 5.3z'
            fill='#cd1225'
          />
          <path
            class='st3'
            d='M25.8 3.5v26c-.6.4-1.1.8-1.7 1.1-2.4 1.3-5 1.9-7.6 1.9a15.59 15.59 0 0 1-9.3-3v-26a15.2 15.2 0 0 1 9.2-3h.3c2.6 0 5.2.7 7.5 1.9.5.3 1.1.7 1.6 1.1z'
          />
          <g>
            <g id='XMLID_6121_'>
              <path
                class='st4'
                d='M22.1 19.8c.1 0 .1 0 .1.1s0 .1-.1.1H22v-.1l.1-.1zM21.7 19.7l.3.2-.4-.1-.1-.3.2.2zM21.5 19.3c0 .1 0 .1-.1.1h-.1v-.2h.1c.1 0 .1.1.1.1z'
              />
              <path
                class='st5'
                d='M22.1 19.8c-.1 0-.1 0-.1.1l-.3-.2-.2-.2c.1 0 .1-.1.1-.1 0-.1-.1-.1-.1-.1-.1 0-.1.1-.1.1s0 .1.1.1h.1l.1.3.4.1c0 .1.1.1.1.1.1 0 .1-.1.1-.1 0-.1-.1-.1-.2-.1zm-.7-.3c-.1 0-.1-.1-.1-.1v-.1h.1c.1 0 .1 0 .1.1l-.1.1zm.2.3-.2-.3.2.2v.1l.4.1-.4-.1zm.6.2c-.1 0-.1 0 0 0-.2 0-.2 0-.2-.1s0-.1.1-.1.1 0 .1.1c.1.1 0 .1 0 .1z'
              />
            </g>
            <g id='XMLID_6120_'>
              <path
                class='st4'
                d='M23.1 17.6c.1 0 .1 0 .2.1 0 .1 0 .1-.1.2h-.1s-.1 0-.1-.1 0-.2.1-.2z'
              />
              <path
                class='st4'
                d='m22.6 17.6.4.1h-.4l-.2-.3.2.2zM22.4 17.3c0 .1 0 .1-.1.2h-.1s-.1 0-.1-.1v-.1l.1-.1c.1 0 .2 0 .2.1z'
              />
              <path
                class='st5'
                d='M23.3 17.6c0-.1-.1-.1-.2-.1s-.1.1-.1.1l-.3-.1h-.1l-.2-.2s.1-.1 0-.2c0-.1-.1-.1-.2-.1s-.1.1-.1.2c0 0 0 .1.1.1h.1l.2.3h.4c0 .1.1.1.2.1.2.1.2 0 .2-.1zm-1-.2c-.1 0-.1 0-.2-.1v-.2c.1 0 .1 0 .2.1s.1.2 0 .2zm.3.3-.3-.3.2.2.1.1h.4-.4zm.6.1s-.1 0 0 0c-.1 0-.1 0-.2-.1 0-.1 0-.1.1-.1s.1 0 .2.1c0 0 0 .1-.1.1z'
              />
            </g>
            <g id='XMLID_6119_'>
              <path
                class='st4'
                d='M20.2 21.4c.1 0 .1.1.1.1 0 .1-.1.1-.1.1h-.1v-.1s0-.1.1-.1z'
              />
              <path
                class='st4'
                d='m19.8 21.3.3.2-.4-.2-.1-.3.2.3zM19.7 20.8c0 .1-.1.1-.1.1h-.1v-.2h.1l.1.1z'
              />
              <path
                class='st5'
                d='M20.2 21.4c-.1 0-.1 0-.1.1l-.2-.2h-.1l-.2-.3c.1 0 .1-.1.1-.1 0-.1-.1-.1-.1-.1l-.1.1v.1h.1l.1.3h.1l.3.1c0 .1.1.1.1.1.1 0 .1-.1.1-.1.1.1 0 0-.1 0zm-.6-.4c-.1 0-.1 0 0 0l-.1-.1v-.1h.1c.1 0 .1.1.1.1l-.1.1zm.2.4-.2-.4.1.2v.2l.3.1-.2-.1zm.4.2-.1-.1c0-.1 0-.1.1-.1s.1.1.1.1c0 .1 0 .1-.1.1z'
              />
            </g>
            <g id='XMLID_6116_'>
              <path
                class='st6'
                d='M19.1 22c.1 0 .3-.1.4-.1.1 0 .1.1.2.1-.2-.1-.5.1-.6.2-.1.1-.7.4-1.1.1.1 0 .2.1.4 0s.5-.3.7-.3zM19.6 21.5c-.4 0-.7.1-1.1.3-.4.2-.8.1-.8.1s.1 0 .3-.1c.1-.1.3-.1.5-.2.1-.1.3-.1.5-.1l.2-.2c-.1 0-.1-.1-.2-.1-.3.1-.6.3-.8.4-.1.1-.1.1-.2.1h-.2c-.1 0-.2.1-.3.1-.1 0-.2-.1-.3-.1-.6-.1-1.3.1-1.8.5H15.7c.1 0 .2-.1.3-.2h.1c.4-.2.9-.3 1.4-.2h.2s.1 0 .1.1c.2.1.4.3.8.2.3-.1.7-.2 1.1-.3-.1 0-.2-.1-.2-.1-.1 0 0 0 .1-.2zM23.1 18.2c-.1 0-.3.4-.4.7-.1.3-.3.5-.6.6h-.2c.3-.1.6-.6.7-.9.1-.3.4-.4.5-.4z'
              />
              <path
                class='st6'
                d='M22.7 17.5V16.6c-.1-.2-.3-.5-.4-.6 0 0 .2.2.2.4v.5c.1.2.1.3.1.5v.6c-.1.4-.3 1.2-.7 1.3 0 0 0-.6.4-1.1V18c-.1.1-.4.4-.5.7-.1.2-.1.4 0 .7v.3s0 .1-.1.1v.1c0 .1-.1.1-.2.2-.3.3-.9.7-1 .8 0 0-.1 0-.1.1.1-.2.3-.4.5-.5 0-.2.1-.4 0-.5 0 .3-.1.4-.2.5l-.4.4c0 .1-.1.1-.1.2s0 .1-.1.2c-.2.2-.4.3-.5.4l-.1.1h.1l.3-.3c.2 0 .6-.1.8-.2.2-.1.7-.6.8-.8 0 0-.1 0-.2.1.1-.1.1-.2.1-.3l.2-.2c0-.1.1-.1.1-.1v-.1c.2-.1.4-.5.6-.8 0-.1.1-.2.1-.2v-.7c.3-.6.3-.6.3-.7z'
              />
              <path
                class='st6'
                d='m23.1 17.1-.4.4v-.4c0-.1 0-.2.1-.3.1-.3.2-.4.2-.6v-.4c.1.1.2.3.2.6 0 .1-.1.3-.1.4 0 .2-.3.5-.3.5s.2-.2.3-.4c.1-.2.1-.5.1-.8 0 .3.2.4.1.7.1 0-.1.2-.2.3z'
              />
              <path
                class='st7'
                d='M23.3 16c0 .3 0 .7-.1.8-.1.2-.3.3-.3.4 0 0 .3-.3.3-.5 0-.1 0-.2.1-.4-.1-.1 0-.2 0-.3zM22.7 17.5V16.6c0-.3.1-.7.3-.8v.4c0 .2 0 .3-.2.6 0 .1-.1.2-.1.3.1.1 0 .3 0 .4zM22.4 16.3c0-.2-.1-.3-.1-.4 0 0 .2.2.2.4v.5c.1.2.1.3.1.5v.1c0-.1 0-.2-.1-.3-.1-.3-.1-.6-.1-.8zM21.9 19.5c.2-.1.4-.5.6-.8 0-.1.1-.2.1-.2.1-.2.3-.2.4-.3 0 0-.3.1-.4.4-.1.3-.3.8-.7.9zM22.6 17.8v.1c-.1.4-.3 1.2-.7 1.3 0 0 0-.6.4-1.1 0 0 .1-.2.3-.3z'
              />
              <path
                class='st7'
                d='M21.8 19.3v.3s0 .1-.1.1c0-.1 0-.2.1-.4-.1-.3-.2-.6-.1-.9.2-.3.6-.5.6-.5-.1.1-.4.4-.5.7-.1.3-.1.4 0 .7zM20 21.2c.2 0 .6-.1.8-.2.2-.1.7-.6.8-.8 0 .1-.1.3-.1.3-.1.2-.4.5-.7.6-.2.1-.7.1-.8.1zM20.4 20.2c.2-.2.4-.4.4-.5 0 .3-.1.4-.2.5l-.4.4c0 .1-.1.1-.1.2v-.1c0-.2.1-.3.3-.5zM21.5 19.9c-.3.3-.9.7-1 .8 0 0-.1 0-.1.1.1-.2.3-.4.5-.5 0 0 .1 0 .1-.1.3-.2.3-.3.5-.3zM19.5 21.9c-.1 0-.3.1-.4.1-.2 0-.5.2-.8.3h-.4c-.2-.2-.4-.3-.5-.3h-.1.2s.1 0 .1.1c.2.1.4.3.8.2.3-.3.7-.4 1.1-.4zM19.5 21.5h.1c-.4 0-.7.1-1.1.3-.4.2-.8.1-.8.1s.1 0 .3-.1c.1-.1.3-.1.5-.2.1-.1.3-.1.5-.1h.5zM18.8 21.2h.2c-.3.1-.6.3-.8.4-.1.1-.1.1-.2.1h-.2s.1 0 .1-.1c.2-.1.4-.4.9-.4zM15.4 22.2H15.7h-.2c-.2.1-.1 0-.1 0z'
              />
            </g>
            <g>
              <g id='XMLID_6115_'>
                <path
                  class='st4'
                  d='M13.4 20.8c0 .1 0 .1 0 0l-.1.1c0-.1 0-.1.1-.2-.1 0 0 0 0 .1z'
                />
                <path
                  class='st4'
                  d='M13.5 21c0 .1-.1.1-.2.1s-.1 0-.1-.1v-.1h.2l.1-.1v.2z'
                />
                <path
                  class='st4'
                  d='M13.4 21.4v-.3h.1v.4H13v-.1h.4zM12.8 21.4v.2c-.1 0-.2 0-.2-.1v-.1h.2zM12.8 21.3h.1c.1 0 .1.3.1.3s0 .1-.1.1-.1 0-.1-.1v-.3c0 .1 0 0 0 0z'
                />
                <path
                  class='st5'
                  d='M13.5 20.9c0-.1 0-.1 0 0-.1-.1-.1-.1 0 0l-.1-.1h-.1c-.1 0-.1.1-.1.2h-.1v.1s0 .1.1.1V21.5h-.4c0-.1 0-.1-.1-.1h-.1v.1h-.1v.1c0 .1.1.1.1.1h.1c0 .1.1.1.1.1s.1 0 .1-.1v-.1h.4v-.5h.1c.1-.1.1-.2.1-.2zm-.2-.1c0-.1.1-.1 0 0 .1 0 .1 0 .1.1l-.1.1c-.1-.2-.1-.2 0-.2zm-.7.8c0-.1 0-.1 0 0 0-.1.1-.1.1-.1v.2c.1-.1 0-.1-.1-.1zm.4.1c-.1.1-.2 0-.2 0V21.4h.1c.1 0 .1.2.1.3zm.5-.3-.5.1v-.1h.5l-.1-.3h.1v.3zm-.2-.3c-.1 0-.1 0-.1-.1v-.1h.2l.1-.1v.1c0 .2-.1.2-.2.2z'
                />
              </g>
              <g id='XMLID_6114_'>
                <path
                  class='st4'
                  d='M11.7 19.2s-.1.1 0 0c-.1.1-.1.1-.1 0s0-.1.1-.1c-.1 0 0 0 0 .1z'
                />
                <path
                  class='st4'
                  d='M11.7 19.4h-.2c-.1 0-.1-.1-.1-.1v-.1h.2l.1-.1c.1.2.1.3 0 .3zM11.5 19.8l.1-.3h.1l-.2.3h-.3v-.1l.3.1zM10.9 19.7v.2c-.1 0-.2 0-.2-.1v-.1h.2zM11 19.6h.1c.1.1 0 .3 0 .3H11c-.1 0-.1-.1-.1-.1.1 0 .1-.2.1-.2z'
                />
                <path
                  class='st5'
                  d='M11.8 19.3c0-.1 0-.1 0 0-.1-.1-.1-.1 0 0l-.1-.1h-.1c-.1 0-.1.1-.1.1h-.1v.1s0 .1.1.1l-.1.2v.1H11c0-.1 0-.1-.1-.2h-.1v.1h-.1v.1c0 .1.1.1.1.1h.1c0 .1.1.1.1.1s.1 0 .1-.1v-.1h.5v-.4h.1c.1 0 .1-.1.1-.1zm-.9.6c-.1 0-.2 0-.2-.1v-.1h.1c.1 0 .1.1.1.2zm.2 0c0 .1 0 .1 0 0-.1 0-.2 0-.2-.1V19.5h.1c.2.1.1.4.1.4zm.5-.8s.1 0 0 0c.1 0 .1.1.1.1s-.1 0-.1.1c-.1-.1-.1-.2 0-.2zm0 .6-.4.1v-.1h.4v-.3h.1l-.1.3zm.1-.3h-.2c-.1 0-.1-.1-.1-.1v-.1h.2l.1-.1c.1.2.1.3 0 .3z'
                />
              </g>
              <g id='XMLID_6113_'>
                <path
                  class='st4'
                  d='M10.8 17.3h-.1c0-.1.1-.1.1-.1v.1zM10.7 17.5c-.1 0-.1 0-.2-.1 0 0-.1-.1 0-.1v-.1h.1v.1c.2.2.2.2.1.2zM10.4 17.8l.2-.3h.1l-.2.3-.5-.2v-.1l.4.3zM9.9 17.4s-.1.1-.1.2c-.1 0-.1-.1-.1-.2 0 0 0-.1.1-.1 0 .1.1.1.1.1z'
                />
                <path
                  class='st4'
                  d='M10 17.4c.2.2 0 .4 0 .4h-.1s-.1-.1 0-.1c0-.2 0-.3.1-.3-.1 0 0 0 0 0z'
                />
                <path
                  class='st5'
                  d='M10.8 17.4s0-.1 0 0v-.1h-.1c-.1 0-.1 0-.2.1h-.1v.1s0 .1.1.1l-.1.1-.1.1-.3-.2v-.2c0-.1-.1-.1-.1-.1l-.1.1c-.1 0-.1-.1-.1-.1l-.1.1c0 .1 0 .1.1.1v.2h.1s0-.1.1-.1l.3.2h.1v-.1l.2-.3h.1c.2.1.2.1.2 0zm-.1-.2c.1 0 .1 0 0 0 .1.1 0 .1 0 .1h-.1c0-.1.1-.1.1-.1zm-1 .2c.1-.1.1 0 .2 0 0 0-.1.1-.1.2 0 0-.1-.1-.1-.2zm.3.3c-.1.1-.1.1 0 0-.1 0-.2-.1-.1-.1 0-.1.1-.2.1-.2s0-.1.1-.1c0 0 .1 0 .1.1-.1.1-.2.3-.2.3zm.5.1h-.1l-.4-.2v-.1l.3.2.2.1v-.1l.1-.1h.1l-.2.2zm.2-.3c-.1 0-.1 0-.2-.1 0 0-.1-.1 0-.1v-.1h.1v.1c.2.2.2.2.1.2z'
                />
              </g>
              <g id='XMLID_6111_'>
                <g id='XMLID_6112_'>
                  <path
                    class='st7'
                    d='M17.4 22.3c0-.1.1-.1.2-.1.1.2 0 .2-.2.1 0 .1 0 0 0 0z'
                  />
                  <path
                    class='st6'
                    d='M17 21.9c-.1 0-.1-.1-.2-.1s-.1 0-.2-.1h-1.1c-.1.1-.2.1-.2.2 0-.1-.1-.2-.1-.2v.2s-.2.2-.2.3c0-.1.1-.3.1-.3-.2 0-.4-.3-.4-.3 0 .1.1.3.1.3-.1 0-.3.2-.3.2 0-.1 0-.2.1-.2-.1 0-.2 0-.2-.1 0 0 0-.1-.1-.1 0-.1-.1-.2-.1-.2.1.2.2.2.1.3 0 0-.2.1-.3.1.1-.1.1-.2.1-.2s-.2 0-.3-.1l-.2-.2s.1.1.1.2h-.5l-.1.1c0 .1.3.1.4 0h.1s-.1.1-.1.2.1.1.1.1c.1 0 .1 0 .3-.1h.1s.1.1.1.2-.1.1-.1.2h.2c.2 0 .1-.2.3-.2h.1c0 .1-.2.2-.1.2h.2c.1 0 .2-.1.3-.2.1-.1.2-.2.4-.3.1 0 .2-.1.3-.1h.6c.1 0 .1 0 .2.1.1 0 .1 0 .2.1.2.1.4.2.6.4 0-.1.1-.1.2-.1-.1-.1-.3-.2-.5-.3z'
                  />
                  <path
                    class='st7'
                    d='m15.3 21.7-.1-.1-.1-.1s0 .1-.1.1l-.2-.2c-.1-.1-.2-.2-.3-.2-.1 0-.1 0-.1.1s.2.2.2.2l-.1.1s0-.2-.1-.2c-.1-.1-.2-.1-.3-.1 0 0-.1.1-.1.2v.1s-.1-.2-.3-.2h-.1v.1s-.1 0-.2.1c.2-.1.4 0 .5 0l-.1-.2s.2.1.2.2c.1.1.3.1.3.1s0 .1-.1.2c.1-.1.2-.1.3-.1 0-.1-.1-.2-.1-.3l.2.2c0 .1.1.1.1.1l.2.1-.1.2c0-.1.2-.2.3-.2 0 0-.1-.2-.1-.3 0 0 .2.3.4.3 0 0-.1.2-.1.3l.2-.3v-.2s.1.1.1.2l.2-.2h-.5zM11.8 19.8v-.2s.1 0 .1.1c.1.1.1.2.1.2s0-.1.1-.2l.1-.1s0 .1.1.2c0 .3 0 .3.1.4 0 0 .1 0 .1-.1.1-.1.1-.2.1-.2.1 0 .2.1.2.2.1.2 0 .4 0 .4h.2c.1 0 0 .1 0 .2v.1l.1.1c-.2 0-.3-.3-.3-.3v.1l-.2.1.2-.1s-.1-.1-.2-.1-.4.1-.4.1c.1 0 .2 0 .4-.1v-.5c0 .2-.1.4-.1.4l-.2-.2c-.1 0-.4 0-.5-.1h.4c0-.1-.1-.4-.1-.4v.3L12 20s0 .1-.2.1h.1c0-.1 0-.2-.1-.3z'
                  />
                  <path
                    class='st6'
                    d='M13.6 21.5c-.1-.1-.2-.2-.4-.3-.2 0-.3-.3-.3-.3v.1l-.2.1.2-.1s-.1-.1-.2-.1-.4.1-.4.1c.1 0 .2 0 .4-.1v-.5c0 .2-.1.4-.1.4l-.2-.2c-.1 0-.4 0-.5-.1h.4c0-.1-.1-.4-.1-.4v.3l-.1-.1c-.1-.3-.1-.2-.3-.2h.1c0-.1-.1-.2-.1-.3v.2l-.2-.2-.3-.3c-.1 0-.1-.1-.2-.2h-.4.3s.1-.2.1-.3l-.1.2c-.1 0-.4-.2-.6-.3.1 0 .3 0 .4.1 0 0 .1-.5.1-.6-.1.2-.2.3-.2.4-.1-.2-.3-.3-.4-.3h.2v-.3s-.1.1-.1.2c-.1-.1-.2-.2-.1-.3v-.7c0-.1 0-.1-.1-.2 0-.1 0-.3.1-.4l.2-.2h-.2s0-.1.1-.1c.1-.2.3-.2.3-.3 0-.1 0-.1-.1-.1s-.1.1-.2.1c0 0-.1 0-.1-.1s.1-.1.2-.1c.1-.1.1-.2.1-.2-.1.1-.3.3-.4.3h-.1l.1.1s-.1.1-.1.3c0 0 .2-.1.3-.1-.1 0-.2.1-.3.2 0-.1-.1-.1-.2-.2.1.1.2.4.2.5 0-.1.3-.1.3-.1l-.3.1s-.2-.1-.3-.1l.3.3-.1-.1s.1 0 .2.1c.1 0 .1.2.1.4v.5c-.1 0-.1.1-.1.2v.1h-.1c-.1 0-.1.1-.1.1 0 .1.1.1.1.1.2.1.2 0 .3.1l.1.1h-.1l-.2-.1-.1.1c0 .1.1.2.1.2.1.1.1 0 .3.1.1 0 .2 0 .2.1H10.4s0 .1.1.1c.1.1.2 0 .3 0h.1l.6.6h-.1v.1c0 .1.4.2.4.2h-.1c-.1 0-.2-.1-.2 0v.1c0 .1.1.2.2.2s.1 0 .3.1c.2 0 .3.1.3.1l-.1.1h-.1c-.2 0-.3-.1-.3 0s.1.2.2.3c0 0 .1.1.2.1s.2-.2.2-.1v.2h.1c.1-.1.3-.1.4-.1.1 0 .1 0 .2.1l.4.4c.1 0 .1-.1.1-.1z'
                  />
                  <path
                    class='st7'
                    d='M11.4 18.8c0 .2-.1.2-.1.4v.3c-.1 0-.1-.1-.2-.2h-.4.3s.1-.2.1-.3l-.1.2c-.1 0-.4-.2-.6-.3.1 0 .3 0 .4.1 0 0 .1-.5.1-.6-.1.2-.2.3-.2.4-.1-.2-.3-.3-.4-.3h.2v-.3s-.1.1-.1.2c-.1-.1-.2-.2-.1-.3 0 0 0 .1.1.1 0 0 0-.1.1-.1h.1V18.4s0-.1.1-.2c0 0 .1-.1.2-.1s.1.2.1.3c0 .2-.1.4 0 .4 0 0 .1-.1.2-.1.2 0 .2.1.2.1zM9.6 16.6h.1c.1 0 .1.1.2.1h.1s0-.1-.1-.2l-.1-.1c0-.1 0-.2.1-.2h.2s0 .1.1.1h.1v-.2c0-.1.1-.3.1-.3h.1s0 .1.1.1l.1-.1c.1 0 .1-.1.2 0-.1.1-.3.3-.4.3h-.1l.1.1s-.1.1-.1.3c0 0 .2-.1.3-.1-.1 0-.2.1-.3.2 0-.1-.1-.1-.2-.2.1.1.2.4.2.5 0-.1.3-.1.3-.1l-.3.1s-.2-.1-.3-.1l.3.3-.4-.1s.1 0 .2.1c.1 0 .1.2.1.4-.1-.1-.1-.3-.2-.4-.2-.2-.2-.3-.3-.3-.1 0-.2-.1-.2-.1-.1 0-.1-.1 0-.1z'
                  />
                </g>
              </g>
            </g>
            <g>
              <g id='XMLID_6110_'>
                <path
                  class='st3'
                  d='M17.3 22.7c-.1 0-.2 0-.2.1 0 0-.1-.2-.4-.4 0 0 .1-.1.2-.1 0 0 .3.2.4.4zM16.6 21.6v.6c-.5.3-.7.6-.7.6-.1-.1-.2-.1-.2-.1.1-.3.6-.5.6-.5v-.6h.3z'
                />
                <path
                  class='st8'
                  d='M16.6 22.3c.3.2.4.4.4.4l-.1.1-.4-.4s.1 0 .1-.1zM16.4 21.6v.6s-.5.2-.6.5c-.1 0-.1 0-.2.1.2-.5.7-.7.7-.7v-.5h.1z'
                />
                <path
                  d='M17.5 22.8c-.1 0-.1 0-.2-.1-.1-.2-.3-.4-.5-.5-.1 0-.1.1-.2.1l-.1.1c-.2.2-.3.4-.4.4l-.1-.1s.2-.3.7-.6v-.6h.2v.5c-.1.1.4.3.6.8z'
                  fill='#cc2029'
                />
              </g>
            </g>
            <g>
              <path
                d='M12.9 19.3s.8.4.8.5c0 .1 0 .2-.2.3-.2.1 0 .1.4.1s.8.2.8.3c0 .2-.3.3-.3.3s.9.1 1.2.3c.3.2 1.1.3 1.4.3.4 0 .8-.2 1-.4.3-.2 1-.2 1-.2s-.4-.1-.5-.3c0-.2.2-.3.5-.4.3-.1.5-.1.5-.1s-.1-.1-.3-.1c-.2.1-.8.3-1.2.3-.3 0-2.5-.1-2.9-.2-.4-.1-.7-.7-.7-.7s.1.5-.1.4c-.2-.1-1.4-.6-1.5-.6-.1 0 .1.2.1.2z'
                fill='#30c1db'
              />
              <path
                class='st3'
                d='M13.1 19.2c0-.1-.1-.1-.2-.1s-.2-.1-.2 0 0 .2.1.2c0 0 .1.2.3.1 0 0 .1 0 .1-.1-.1 0-.1-.1-.1-.1z'
              />
              <path
                class='st11'
                d='M13.2 19.3v-.1c0-.1-.1-.1-.2-.1s-.2-.1-.3 0c-.1.1 0 .3.1.3 0 0 .1.2.3.2h.1s.1 0 .1-.1v-.1c-.1 0-.1-.1-.1-.1zm-.2-.1v.1h-.1c-.1 0-.2-.1-.2-.1 0-.1-.1-.1-.1-.2h.1l.3.2z'
              />
              <path
                class='st3'
                d='M13.6 20.1v.1c0 .1-.1.1-.2.1s-.1-.1-.1-.2c0 0 0-.1.1-.2h.2v.2z'
              />
              <path
                class='st12'
                d='M13.6 20.1c0-.1 0-.1-.1-.2s-.2-.1-.3 0c-.1.1-.1.2-.1.2 0 .1 0 .2.2.3h.1c.1 0 .2-.1.2-.1v-.2c0 .1 0 .1 0 0zm-.3.2c-.1 0-.1-.1-.1-.1s0-.1.1-.1h.2v.1c0 .1-.1.1-.2.1z'
              />
              <path
                class='st3'
                d='M14.6 19.4c0 .1-.1.1 0 0-.2.1-.2.1-.3 0 0 0-.1-.1-.1-.2s.2-.1.2-.1c.1 0 .1 0 .2.1v.2z'
              />
              <path
                class='st12'
                d='M14.7 19.2c-.1-.1-.2-.1-.3-.1 0 0-.2 0-.2.2 0 .1 0 .2.1.3 0 0 .1.1.2.1h.2s.1 0 .1-.1c-.1-.2-.1-.3-.1-.4zm-.2.2h-.1s-.1-.1 0-.1c0-.1.1-.1.1-.1s.1 0 .1.1c-.1 0 0 0-.1.1.1-.1 0 0 0 0z'
              />
              <g>
                <path
                  class='st3'
                  d='M14.5 20.8v.2c-.1.1-.2.1-.3.1-.1-.1-.2-.1-.2-.2s.1-.1.2-.1c0 0 .1-.1.2 0h.1z'
                />
                <path
                  class='st11'
                  d='M14.6 20.8c-.1 0-.1 0 0 0l-.1-.1h-.3c-.1 0-.2.1-.2.2s.1.2.2.2h.2c.1 0 .2 0 .3-.1v-.1l-.1-.1zm-.2.2c0 .1-.2.1-.3.1-.1 0-.1-.1-.1-.1l.1-.1h.2c.1 0 .1 0 .1.1 0-.1 0-.1 0 0z'
                />
              </g>
              <g>
                <path
                  class='st3'
                  d='M18.8 20.5s-.1 0 0 0c-.2.1-.2.2-.2.3 0 .1.1.2.2.2s.2-.1.2-.2c0-.2-.1-.3-.2-.3z'
                />
                <path
                  class='st12'
                  d='M19 20.5c-.1-.1-.1-.1-.2-.1s-.1 0-.1.1c0 0-.1.1-.1.2v.2c0 .1.1.2.2.2s.2-.1.2-.2c.1-.2 0-.3 0-.4zm-.1.3s0 .1 0 0c-.1.1-.2 0-.2 0v-.1s0-.1.1-.1h.2c-.1.1-.1.1-.1.2z'
                />
              </g>
              <g>
                <path
                  class='st3'
                  d='M19.3 19.9c-.1 0-.2.1-.3.2v.2h.2c.1 0 .2-.1.2-.2.1-.1.1-.2-.1-.2.1 0 .1 0 0 0z'
                />
                <path
                  class='st11'
                  d='M19.4 19.8h-.1c-.1 0-.3.1-.3.2v.2s0 .1.1.1H19.4c.1 0 .3-.1.3-.2-.1-.2-.2-.3-.3-.3zm-.1.3h-.1V20l.1-.1h.1l.1.1c-.1.1-.2.1-.2.1z'
                />
              </g>
              <g>
                <g id='XMLID_6109_'>
                  <path
                    class='st13'
                    d='M17.4 21.1h-.3c-.2-.2-.3-.3-.3-.6v-.1c0-.1 0-.2-.1-.3-.1-.1-.1-.3-.3-.4h.6c.1 0 .2 0 .3.1v.2c.1.1.1 0 .1 0 .1 0 .1-.1.1-.1l-.1.1h-.1v-.2c0-.1.1-.1.2-.1s.1.2.1.2c0 .1 0 .1-.1.2 0 .1.1.2.1.3 0 .1 0 .2-.1.3 0 .1.1.1.1.2s0 .2-.1.2c.1.1 0 .1-.1 0 .1 0 .1 0 0 0 0 0 0-.1.1-.1h.1s.1 0 .1.1c0-.1-.1-.1-.1-.1h-.1c-.1-.1-.1 0-.1.1z'
                  />
                  <path
                    class='st13'
                    d='M16.8 20.5c.1.2.1.4.3.6h-.3c-.2-.2-.3-.3-.3-.6v-.1c0-.1 0-.2-.1-.3-.1-.1-.1-.3-.3-.4h.3c.1.1.2.3.3.4 0 .1.1.2.1.2v.2z'
                  />
                  <path
                    class='st13'
                    d='M16.7 21.1h-.3c-.2-.2-.3-.3-.3-.6v-.1c0-.1 0-.2-.1-.3-.1-.1-.1-.3-.3-.4H16c.1.1.2.3.3.4 0 .1.1.2.1.2v.1c.1.3.2.5.3.7z'
                  />
                  <path
                    class='st13'
                    d='M15.3 21s.1 0 0 0c.1 0 .1-.1 0 0l.1-.1h.1v.2c-.1.1-.1.1-.2.1s-.1-.2-.1-.2c0-.1 0-.1.1-.2 0-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3 0-.1-.1-.1-.1-.2s0-.2.1-.2.2 0 .2.1v.2h-.1s-.1 0-.1-.1c0 .1.1.1.1.1h.1c.1-.1.1-.1 0-.2h.1c.1.1.2.2.3.4 0 .1.1.2.1.2v.1c.1.2.1.4.3.6h-.8V21c-.1-.1-.1 0-.1 0 0-.2 0-.1-.1 0 0-.1 0-.1 0 0z'
                  />
                  <path
                    d='M17.9 20.4V20.1c0-.1.1-.1.1-.2s0-.2-.2-.3c-.1 0-.2 0-.3.1H16c-.1 0-.2 0-.3.1-.1-.1-.2-.1-.3-.1-.1.1-.2.2-.2.3 0 .1 0 .1.1.2 0 .1-.1.2-.1.3s0 .2.1.3c0 .1-.1.1-.1.2v.1c0 .1.1.2.1.2h.2s.1 0 .1-.1c.1 0 .2.1.3.1h1.2c.1 0 .2 0 .3-.1 0 .1.1.1.2.1h.1s.1 0 .1-.1c.1-.1.1-.2.1-.2 0-.1 0-.1-.1-.2 0-.2.1-.3.1-.4zm-2 .7h-.3v-.2c-.1-.1-.1 0-.1 0s-.1 0-.1.1v.1h.2v.2h-.2v-.1c0-.1 0-.1.1-.2 0-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3l-.1-.1v-.1c0-.1 0-.2.1-.2s.2 0 .2.1v.2h-.1s-.1 0-.1-.1c0 .1.1.1.1.1h.1c.1-.1.1-.1 0-.2h.1l.1.1c0 .1.1.1.1.2s.1.2.1.2v.1c.1.2.1.4.3.6h-.5zm.6 0c-.2-.2-.3-.3-.3-.6v-.1c0-.1 0-.2-.1-.3-.1-.1-.1-.3-.2-.4H16.2c.1.1.2.2.3.4 0 .1.1.2.1.2v.1c.1.2.1.4.3.6h-.4zm.3 0c-.2-.2-.3-.3-.3-.6V20.2c-.1-.1-.1-.3-.3-.4h.3c.1 0 .1.1.1.2v.2c0 .1 0 .2.1.2v.1c.1.2.1.4.3.6h-.2zm1-.2c0 .1 0 .2-.1.2s-.2 0-.2-.1v-.2h.1s.1 0 .1.1c0-.1-.1-.1-.1-.1h-.1c-.1.1-.1.1 0 .2h-.3c-.2-.2-.3-.3-.3-.6V20.1s0-.1-.1-.1c0 0 0-.1-.1-.1 0 0 0-.1-.1-.1H17.5v.2c.1.1.1 0 .1 0 .1 0 .1-.1.1-.1s0 .1-.1.1h-.1v-.2c0-.1.1-.1.2-.1s.1.2.1.2c0 .1 0 .1-.1.2v.2c0 .1 0 .2-.1.3.2.2.2.2.2.3z'
                    fill='#943120'
                  />
                </g>
              </g>
              <g>
                <path
                  class='st15'
                  d='M12.4 16.8v-.3l.1.3zM12.9 17.2l.2-.2-.2.3zM12 17.3l-.3-.1h.3zM12.2 17.9l-.3-.1h.3zM12.8 18.1l-.2.1.2-.2zM13.4 18.5l-.3.2.3-.3zM13.7 17.6l.1-.2v.3zM15.5 17.9l.1-.3v.3zM16.2 18.1v-.3.3zM15 18.9l-.1.2v-.3zM15.6 19.2l-.1.2v-.3zM16.2 19l.1.3-.2-.3zM17.7 19.3l.1.2-.2-.2zM18.3 19.1v.3l-.1-.3zM18.7 19.3v.3l-.1-.3zM19.6 19.4l.1.2-.2-.2zM19.8 19.3l.1.3-.2-.3zM20.3 18.8h.2l-.3.1zM19.5 18.4l.1-.3v.3zM18.9 18.9v-.3l.1.3zM18.2 18.3l.2-.3-.1.4zM17.4 18.3l-.1-.2.2.2zM16 18v-.3l.1.4zM15.1 18.1l-.1-.3.2.2zM14.4 17.9l.2-.2-.1.3zM14.1 18.7l-.3.2.3-.3z'
                />
                <g id='XMLID_6108_'>
                  <path
                    class='st16'
                    d='M18.5 19s.1 0 .1.1 0 .1.1.1c.1.1.2 0 .2 0l.2-.2c.1-.1.2-.1.3-.2.2-.1.1-.3.3-.3.1 0 .2-.1.3 0l.1.1c.1.1.1.3.1.4-.1.3-.4.4-.6.4-.2.1-.5.1-.5.1-.2 0-.4-.1-.5-.1-.1 0-.1 0-.2-.1 0-.1 0-.2.1-.3z'
                  />
                  <path
                    class='st16'
                    d='M16.6 19.9v-.1.1h.2c.1 0 0-.2.1-.4 0-.1.1-.2.2-.3.1 0 .1 0 .2-.1l.2-.2.1-.1h.4c.1 0 .1-.1.2-.1s.1.2.2.2h.2l.1-.1v.1c0 .1-.1.2-.2.3-.1.1-.2.1-.3.1H18c-.2 0-.3.1-.5.1-.1.1-.2.2-.2.4v.1s0 .1-.1.1h-.5l-.1-.1zM16.8 18.7c-.1.2-.3.3-.5.4-.3.1-.8.2-1.1-.1-.1-.1-.3-.2-.3-.3v-.2c0-.1.1-.1.2-.2.1 0 .2-.1.4-.1.1 0 .3.1.6.2.1 0 .1 0 .1.1.1-.1.3 0 .6.2-.1-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM14.8 18.3s0 .1 0 0c0 .1 0 .1 0 0v.2s0 .1-.1.1H14.1c-.3 0-.5 0-.7-.2-.1-.1-.2-.2-.2-.3 0-.1 0-.1.1-.2s.2-.2.3-.2c.2-.1.4 0 .5 0 .1 0 .3.1.6.3-.1.2-.1.4.1.3-.1 0 0 0 0 0zM12.8 17.2c.3.3.2.7.2.8h-.1c-.2 0-.3-.1-.3-.1-.1 0-.6-.2-.7-.7v-.3l.1-.1s.1-.1.3-.1c.3.2.5.4.5.5z'
                  />
                  <path
                    class='st17'
                    d='M18.8 18.9c.1 0 .2-.1.4-.3l.2-.2c.1-.1.1-.1.2-.1s.2-.1.3-.1c.1 0 .1 0 .2.1h-.3c-.1.1-.1.2-.3.3-.1.1-.2.1-.3.2l-.2.2s-.2.1-.2 0c0 0 0-.1-.1-.1l-.1-.1.1-.1c0 .2 0 .2.1.2zM17.8 18.4c.2-.1.4.1.5.1.1 0 .2.1.2.2l-.1.1c-.1 0-.1.1-.2 0-.1 0-.1-.2-.2-.2s-.1.1-.2.1h-.4l-.1-.1c.3.1.3-.1.5-.2z'
                  />
                  <path
                    class='st17'
                    d='m17.4 18.7.1.1c-.1 0-.1.1-.1.1-.1.1-.1.2-.2.2s-.1 0-.2.1-.2.2-.2.3c-.1.2-.1.4-.1.4h-.1l-.1-.1V19.5c0-.1.1-.2.1-.3.1-.3.1-.1.2-.2s.1-.1.2-.1c.2-.2.3-.2.4-.2zM15 18.2l-.2.2s0-.1.1-.1l.3-.3h.3c.1 0 .3 0 .5.1s.2.2.3.2c.1 0 .2.1.4.1h.2s0 .1-.1.1l-.1.1h-.1c-.2 0-.4-.1-.6-.2-.1 0 0 0-.1-.1-.3-.2-.4-.2-.6-.2-.1-.1-.2.1-.3.1z'
                  />
                  <path
                    class='st17'
                    d='M13.5 17.9c-.1 0-.2.1-.3.2 0 .1-.1.1-.1.2V18c0-.1.1-.1.2-.2s.1-.1.2-.1c.2-.1.4-.1.5-.1.2 0 .4.1.5.2l.3.3s.1.1.1.2c-.1 0-.2-.1-.2-.2-.2-.2-.5-.3-.6-.3-.3 0-.4 0-.6.1z'
                  />
                  <path
                    class='st17'
                    d='M13.1 17.4c.1.1.1.2.2.3v.1l-.1.1v.1c0-.1 0-.5-.2-.8-.1-.1-.3-.3-.5-.3-.3 0-.5.1-.5.1l-.1.1c0-.1 0-.2.1-.3.1-.1.2-.1.3 0 .3 0 .7.4.8.6z'
                  />
                  <path
                    class='st18'
                    d='M20.3 18.7c0-.1 0-.1-.1-.2s-.1-.2-.2-.2h-.5c-.1 0-.1.1-.2.1l-.2.2-.2.2h-.1c-.1 0-.1.1-.1.1h-.2v-.3c0-.1-.1-.2-.2-.2h-.2c-.2-.1-.5 0-.7 0H17.1c-.1 0-.1 0-.1.1 0 0 0-.1-.1-.1 0 0-.1-.1-.2-.1h-.4c-.1 0-.1-.1-.2-.1H16s-.1 0-.1-.1h-.1c-.1-.1-.2-.1-.4-.1H15.1c-.1 0-.1.1-.2.1-.1.1-.2.1-.2.2-.1-.1-.2-.2-.4-.3-.1-.1-.3-.2-.4-.2H13.6c-.1 0-.2 0-.3.1 0 0-.1 0-.2.1 0-.1-.1-.3-.2-.4l-.1-.1c-.1-.2-.3-.3-.5-.4H12c-.1 0-.2 0-.2.1-.1.1-.1.1-.1.2V17.7c0 .2.1.3.2.4 0 0 .1 0 .1.1.2.1.3.2.4.2h.6c0 .2.1.3.2.4.2.2.4.2.6.2.2 0 .4 0 .6-.1H14.7c0 .1 0 .1.1.2 0 0 0 .1.1.1 0 .1.1.1.1.1.1.1.3.2.4.2h.2c.2 0 .3 0 .5-.1h.1c.2-.1.3-.1.4-.2v1c0 .1 0 .1.1.1H17c.1 0 .2 0 .3-.1V20c0-.1.1-.2.2-.3.1 0 .1-.1.2-.1H18.5c.1 0 .1.1.2.1h.1c.1 0 .2.1.4.1H20c.2-.1.4-.2.5-.4v-.2c-.2-.4-.2-.5-.2-.5zm-7.2-.7s0 .1 0 0l-.1.1H12.7s-.1-.1-.2-.1h-.2l-.1-.1-.1-.1V16.8h.5c.1 0 .1.1.2.2h.1l.1.1v.1c0 .1.1.1.1.2.1.1.1.2.2.3v.1l-.2.2zm1.7.4v.2s0 .1-.1.1h-1.3l-.1-.1-.1-.1v-.4c0-.1.1-.1.2-.2s.1-.1.2-.1H14.4c.1 0 .2.1.2.2h.1l.1.1.1.1.1.1c-.3-.1-.2 0-.2.1 0-.1 0 0 0 0zm1.5.6c-.1.1-.1.1 0 0H15.3c-.2-.1-.3-.2-.3-.4v-.2s0-.1.1-.1v-.1l.1-.1.1-.1h.4c.1 0 .3 0 .5.1s.2.2.3.2h.5v.2c-.3.3-.5.5-.7.5zm2.1.1c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0-.1.1h-.7c-.1 0-.1 0-.2.1 0 0-.1 0-.1.1v.4s0 .1-.1.1h-.5v-.4c0-.1.1-.2.1-.3v-.1l.1-.1h.4c.2 0 .2-.3.4-.3.2-.1.4.1.5.1.1 0 .2.1.2.2v.1c.1-.2.1-.1 0 0zm1.9-.1c-.1 0-.1 0 0 0-.1 0-.1 0 0 0v.1H18.7c-.1 0-.1 0-.1-.1-.1 0-.1-.1 0-.2 0 0 0-.1.1-.1l.1-.1h.2c.1 0 .1-.1.2-.2l.2-.2s.1 0 .1-.1h.5s.1 0 .1.1l.1.1V18.6c.1.3.1.3.1.4 0-.1 0-.1 0 0 0-.1 0-.1 0 0z'
                  />
                  <g fill='none' stroke-miterlimit='10'>
                    <path stroke='#39b54a' stroke-width='.25' d='M13.1 18.1' />
                    <path stroke='#00a651' d='M13.2 18.2' />
                    <path stroke='#00a651' stroke-width='.5' d='M14.8 18.4' />
                  </g>
                </g>
                <path
                  class='st18'
                  d='M14.2 18.4s.1.1 0 0c.1.1 0 .1 0 .2-.1 0-.1-.1 0-.2-.1.1-.1 0 0 0-.1 0 0 0 0 0z'
                />
                <path
                  class='st17'
                  d='M14.2 18.4s0 .1 0 0c.1.1 0 .1 0 .1-.1.1-.1 0 0-.1-.1.1-.1 0 0 0-.1 0-.1 0 0 0z'
                />
                <path class='st15' d='m14 18.6.2-.1h-.1z' />
                <path
                  class='st18'
                  d='M13.7 18.4c0 .1 0 .1 0 0-.1.1-.2.1-.2 0 0 0 .1-.1.2 0-.1-.1-.1-.1 0 0z'
                />
                <path
                  class='st17'
                  d='M13.7 18.4s-.1.1 0 0l-.1.1c-.1-.1 0-.2.1-.1-.1-.1-.1-.1 0 0z'
                />
                <path class='st15' d='m13.5 18.3.1.1z' />
                <path
                  class='st18'
                  d='M14 18.3h-.1v-.1c0 .1 0 .1.1.1-.1-.1 0-.1 0 0 0-.1 0 0 0 0z'
                />
                <path
                  class='st17'
                  d='M14 18.3h-.1.1c-.1-.1 0-.1 0 0 0-.1 0 0 0 0z'
                />
                <path class='st15' d='M13.9 18.1v.2h.1z' />
                <path class='st18' d='M19.7 19.3h-.1v-.1h.1v.1c0-.1 0 0 0 0z' />
                <path class='st17' d='M19.7 19.2h-.1c0-.1.1-.1.1 0z' />
                <path class='st15' d='m19.7 19-.1.2h.1z' />
                <path
                  class='st18'
                  d='M18.7 19.1c0 .1 0 .1.1.1h.1v-.1c0-.1 0-.1-.1-.1l-.1.1c.1-.1 0-.1 0 0z'
                />
                <path class='st17' d='M18.8 19c0 .1 0 .1.1.1h.1V19h-.2z' />
                <path class='st15' d='m19 18.9-.1.2-.1-.1z' />
                <path class='st18' d='M19.4 19.1h-.1V19h.1v.1z' />
                <path
                  class='st17'
                  d='M19.4 19.1h-.1c0-.1.1-.1.1 0 0-.1 0-.1 0 0z'
                />
                <path class='st15' d='M19.3 18.9v.1h.1z' />
                <g>
                  <path
                    class='st18'
                    d='M19.7 18.8v-.1h.1v.1c0 .1 0 .1-.1 0 0 .1 0 .1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M19.7 18.9v-.1c.1 0 .1 0 0 .1.1 0 .1 0 0 0 .1 0 0 0 0 0z'
                  />
                  <path class='st15' d='m19.9 18.7-.1.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M19.4 18.8s0-.1 0 0c-.1-.1-.1-.1 0-.1.1-.1.1-.1 0 .1.1-.1.1-.1 0 0 .1-.1.1 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M19.5 18.7c-.1 0-.1 0 0 0-.1 0-.1-.1 0 0-.1-.1-.1-.1 0-.1v.1z'
                  />
                  <path class='st15' d='m19.5 18.5-.1.1.1.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M13.8 18c-.1 0-.1 0 0 0-.1 0-.1-.1-.1-.1l.1.1c0-.1 0-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M13.8 18c-.1 0-.1 0 0 0-.1 0-.1 0-.1-.1s0 0 .1.1c-.1-.1-.1-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0z'
                  />
                  <path class='st15' d='M13.7 17.8v.1h.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M14.5 18.3s.1 0 0 0c.1.1 0 .1 0 .1-.1 0-.1 0 0-.1-.1 0-.1 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M14.5 18.3c.1.1.1.1 0 .1s-.1 0 0-.1c-.1 0-.1 0 0 0-.1 0 0 0 0 0z'
                  />
                  <path class='st15' d='m14.3 18.3.2.1v-.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M15.9 18.5v.2h-.1v-.2h.1c-.1-.1 0-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M15.8 18.5v.2h-.1v-.2c0-.1.1-.1.1 0 0-.1 0 0 0 0z'
                  />
                  <path class='st15' d='m15.5 18.5.2.1.1-.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M16 18.8s.1.1 0 .1c0 0 0 .1-.1.1 0 0-.1-.1 0-.1l.1-.1c-.1 0-.1 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M15.9 18.9s.1.1 0 .1c0 0-.1-.1 0-.1 0-.1 0-.1 0 0z'
                  />
                  <path class='st15' d='m15.8 19 .1-.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M15.6 18.2c0 .1 0 .1 0 0-.1.1-.2.1-.2 0 0 0 .1-.1.2 0-.1-.1 0-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M15.6 18.2s-.1.1 0 0l-.1.1c-.1-.1 0-.2.1-.1-.1-.1 0-.1 0 0z'
                  />
                  <path class='st15' d='m15.4 18.1.1.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M16.3 18.7s0 .1 0 0c-.1.1-.1.1-.2 0 0-.1.1-.1.2 0-.1-.1-.1-.1 0 0 0-.1 0 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M16.3 18.7s-.1 0 0 0l-.1.1c-.1-.2 0-.2.1-.1-.1-.1-.1-.1 0 0 0-.1 0-.1 0 0z'
                  />
                  <path class='st15' d='m16 18.6.2.1v-.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M15.3 18.5s0 .1 0 0c-.1.1-.1.1-.2 0 .1-.1.1-.1.2 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M15.3 18.4c0 .1 0 .1 0 0l-.1.1c0-.1 0-.1.1-.1z'
                  />
                  <path class='st15' d='m15.1 18.4.1.1.1-.1z' />
                </g>
                <g>
                  <path class='st18' d='M15.4 18.8v.1h-.1v-.1h.1z' />
                  <path
                    class='st17'
                    d='M15.4 18.8s0 .1 0 0v.1c-.1 0-.1-.1 0-.1-.1 0-.1 0 0 0z'
                  />
                  <path class='st15' d='M15.2 18.9h.1v-.1z' />
                </g>
                <g>
                  <path class='st18' d='M16.1 18.3v.1H16s0-.1.1-.1z' />
                  <path
                    class='st17'
                    d='M16.1 18.3v.1c-.1 0-.1 0 0-.1-.1.1-.1 0 0 0z'
                  />
                  <path class='st15' d='M16 18.2v.2l.1-.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M13.6 18.1s-.1.1 0 0c-.1.1-.1 0-.1 0-.1 0 0-.1.1 0-.1-.1-.1-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M13.5 18.1c-.1.1-.1.1-.1 0 0 0 .1-.1.1 0 0-.1 0-.1 0 0 0 0 .1 0 0 0z'
                  />
                  <path class='st15' d='M13.4 18v.1l.1-.1z' />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M12.3 17.1c0 .1 0 .1 0 0-.1.1-.1.1-.1 0h.1z'
                  />
                  <path
                    class='st17'
                    d='M12.3 17.1c0 .1 0 .1 0 0l-.1.1c0-.1 0-.1.1-.1z'
                  />
                  <path
                    class='st18'
                    d='M12.3 17.5s-.1.1 0 0c-.1.1-.2.1-.2 0s.1-.1.2 0c-.1-.1-.1-.1 0 0 0-.1 0 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M12.3 17.5s-.1 0 0 0l-.1.1c-.1-.2 0-.2.1-.1-.1-.1-.1-.1 0 0 0-.1 0-.1 0 0z'
                  />
                  <path
                    class='st18'
                    d='M12.6 17.4c0 .1 0 .1 0 0-.1.1-.1.1-.2 0h.2c-.1 0-.1 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M12.6 17.4c0 .1-.1.1 0 0l-.1.1c-.1-.1 0-.1.1-.1-.1 0-.1 0 0 0z'
                  />
                  <path
                    class='st18'
                    d='M12.6 17.2h-.1v-.1l.1.1c-.1-.1 0-.1 0 0 0-.1 0-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M12.6 17.2s-.1 0 0 0h-.1c0-.1 0-.1.1 0-.1-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0z'
                  />
                  <path
                    class='st18'
                    d='M12.9 17.5s-.1 0 0 0c-.1 0-.1-.1-.1-.1s.1 0 .1.1c0-.1 0-.1 0 0 0-.1 0 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M12.9 17.4c0 .1-.1.1 0 0-.1 0-.1 0-.1-.1 0 .1 0 .1.1.1-.1 0 0 0 0 0z'
                  />
                  <path
                    class='st18'
                    d='M12.9 17.8s-.1.1 0 0c-.1.1-.1 0-.1 0-.1-.1 0-.1.1 0-.1-.1-.1-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M12.9 17.8s-.1 0 0 0c-.1.1-.1.1-.1 0-.1-.1 0-.1.1 0-.1-.1-.1-.1 0 0z'
                  />
                  <path
                    class='st18'
                    d='M12.6 17.8c0 .1-.1.1 0 0-.1.1-.1 0-.1 0-.1 0 0-.1.1 0-.1-.1-.1-.1 0 0z'
                  />
                  <path
                    class='st17'
                    d='M12.6 17.8s-.1.1 0 0c-.1.1-.1.1-.1 0-.1 0 0-.1.1 0-.1-.1-.1-.1 0 0z'
                  />
                  <path
                    class='st15'
                    d='M12.2 17v.1h.1zM12.1 17.3l.1.2v-.1zM12.3 17.4l.2.1v-.1zM12.5 17v.1h.1zM12.8 17.2v.2h.1zM12.8 17.6v.2zM12.4 17.7l.1.1z'
                  />
                </g>
                <g>
                  <path
                    class='st18'
                    d='M17.5 19.1c-.1 0-.1-.1-.1-.1s0-.1.1-.1l.1.1c0 .1 0 .1-.1.1.1 0 0 .1 0 0z'
                  />
                  <path
                    class='st17'
                    d='m17.5 19.1-.1-.1c.2 0 .2 0 .1.1.1 0 .1 0 0 0 .1 0 .1 0 0 0z'
                  />
                  <path class='st15' d='M17.7 19h-.2l.1.1z' />
                  <path
                    class='st18'
                    d='M17.1 19.6c-.1 0-.1 0-.2-.1v-.1h.1c.1 0 .1 0 .2.1-.1 0-.1 0-.1.1z'
                  />
                  <path
                    class='st17'
                    d='M17.1 19.6c-.1 0-.1 0-.2-.1v-.1h.1c.1 0 .1 0 .2.1-.1 0-.1 0-.1.1z'
                  />
                  <path class='st15' d='m17.2 19.3-.2.2h.1z' />
                  <path
                    class='st18'
                    d='M16.8 19.2c-.1 0-.1-.1 0 0l-.1-.2c.1 0 .2 0 .1.2.1-.1.1-.1 0 0 .1-.1 0 0 0 0z'
                  />
                  <path
                    class='st17'
                    d='M16.8 19.1s-.1 0 0 0l-.1-.1c.1 0 .2 0 .1.1.1 0 .1 0 0 0 .1 0 0 0 0 0z'
                  />
                  <path class='st15' d='m16.9 18.9-.1.1v.1z' />
                  <g>
                    <path
                      class='st18'
                      d='M17.8 18.7c-.1 0-.1.1 0 0h-.1.1c0-.1 0-.1 0 0z'
                    />
                    <path
                      class='st17'
                      d='M17.8 18.7h-.1c0-.1 0-.1.1 0 0-.1 0-.1 0 0z'
                    />
                    <path class='st15' d='m17.8 18.5-.1.1v.1z' />
                  </g>
                  <g>
                    <path
                      class='st18'
                      d='M17.2 19.1c-.1 0-.1-.1-.1-.1.1 0 .1 0 .1.1.1-.1.1-.1 0 0 .1 0 .1 0 0 0z'
                    />
                    <path
                      class='st17'
                      d='M17.2 19.1c-.1 0-.1 0-.1-.1s0 0 .1.1c0-.1 0-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1 0 0 0z'
                    />
                    <path class='st15' d='M17.2 18.9v.1z' />
                  </g>
                  <g>
                    <path
                      class='st18'
                      d='M17.9 19.2s0-.1 0 0v-.1c.1 0 .1 0 0 .1.1 0 .1 0 0 0z'
                    />
                    <path
                      class='st17'
                      d='M17.9 19.2s0-.1 0 0v-.1c.1 0 .1 0 0 .1.1 0 .1 0 0 0z'
                    />
                    <path class='st15' d='m18.1 19-.2.1.1.1z' />
                  </g>
                  <g>
                    <path
                      class='st18'
                      d='M18.2 18.9s-.1-.1 0 0c-.1-.1-.1-.1 0-.2 0 0 .1 0 0 .2.1-.1.1-.1 0 0 .1 0 0 0 0 0z'
                    />
                    <path
                      class='st17'
                      d='m18.2 18.8-.1-.1c.1 0 .2 0 .1.1.1 0 .1 0 0 0 .1 0 0 0 0 0z'
                    />
                    <path class='st15' d='m18.3 18.6-.1.1.1.1z' />
                  </g>
                </g>
                <g>
                  <radialGradient
                    id='SVGID_1_'
                    cx='-311.68'
                    cy='-56.4'
                    r='0'
                    gradientTransform='matrix(34.9342 8.3394 -11.3311 47.4577 10263.43 5293.13)'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop offset='0' stop-color='#fbc942' />
                    <stop offset='.38' stop-color='#f9c742' />
                    <stop offset='.59' stop-color='#f1c140' />
                    <stop offset='.75' stop-color='#e3b73e' />
                    <stop offset='.9' stop-color='#cfa93a' />
                    <stop offset='1' stop-color='#bd9b37' />
                  </radialGradient>
                  <path
                    d='M14.5 17.2c0 .1-.1.1-.1.1-.1 0-.1-.1-.1-.2s.1-.1.1-.1c.1 0 .1.1.1.2z'
                    fill='url(#SVGID_1_)'
                  />
                  <radialGradient
                    id='SVGID_00000103946141071893031500000004973516837527157686_'
                    cx='-311.75'
                    cy='-58.68'
                    r='.01'
                    gradientTransform='matrix(34.9342 8.3394 -11.2413 47.0806 10245.26 5380.28)'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop offset='0' stop-color='#fff' />
                    <stop offset='1' stop-color='#f3798d' />
                  </radialGradient>
                  <path
                    d='M14.5 17.8c0 .2-.2.3-.3.3-.2-.1-.2-.3-.2-.5s.2-.3.3-.3c.2.1.3.3.2.5z'
                    fill='url(#SVGID_00000103946141071893031500000004973516837527157686_)'
                  />
                  <radialGradient
                    id='SVGID_00000113354433847029523340000008585122476424222621_'
                    cx='-312.02'
                    cy='-481.08'
                    r='0'
                    gradientTransform='rotate(13.43 -79066.87 39035.86) scale(35.9158)'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop offset='0' stop-color='#f6e11c' />
                    <stop offset='1' stop-color='#e82735' />
                  </radialGradient>
                  <path
                    d='M14.6 17.5c0 .1-.1.1-.1.1-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1s.1 0 .1.1z'
                    fill='url(#SVGID_00000113354433847029523340000008585122476424222621_)'
                  />
                  <radialGradient
                    id='SVGID_00000073715808838748326610000010321710416717989283_'
                    cx='-312.03'
                    cy='-481.08'
                    r='0'
                    gradientTransform='rotate(13.43 -79066.87 39035.86) scale(35.9158)'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop offset='0' stop-color='#f6e11c' />
                    <stop offset='1' stop-color='#e82735' />
                  </radialGradient>
                  <path
                    d='M14.3 17.4c0 .1-.1.1-.1.1-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1.1 0 .1 0 .1.1z'
                    fill='url(#SVGID_00000073715808838748326610000010321710416717989283_)'
                  />
                  <radialGradient
                    id='SVGID_00000122716257850688405890000017278337666409374906_'
                    cx='-312.03'
                    cy='-481.08'
                    r='0'
                    gradientTransform='rotate(13.43 -79066.87 39035.86) scale(35.9158)'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop offset='0' stop-color='#f6e11c' />
                    <stop offset='1' stop-color='#e82735' />
                  </radialGradient>
                  <path
                    d='M14.4 17.4c0 .1-.1.1-.1.1-.1 0-.1-.1-.1-.1 0-.1.1-.1.1-.1.1 0 .1 0 .1.1z'
                    fill='url(#SVGID_00000122716257850688405890000017278337666409374906_)'
                  />
                  <g>
                    <radialGradient
                      id='SVGID_00000002355109600862217270000014596641793592612224_'
                      cx='237.47'
                      cy='1345.35'
                      r='0'
                      gradientTransform='matrix(30.5203 -18.9306 25.7185 41.4599 -41836.38 -51266.5)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#fbc942' />
                      <stop offset='.38' stop-color='#f9c742' />
                      <stop offset='.59' stop-color='#f1c140' />
                      <stop offset='.75' stop-color='#e3b73e' />
                      <stop offset='.9' stop-color='#cfa93a' />
                      <stop offset='1' stop-color='#bd9b37' />
                    </radialGradient>
                    <path
                      d='M11.7 16.1v.2c-.1 0-.1 0-.2-.1V16c.1 0 .2 0 .2.1z'
                      fill='url(#SVGID_00000002355109600862217270000014596641793592612224_)'
                    />
                    <radialGradient
                      id='SVGID_00000051378423622295740620000012408277097388875907_'
                      cx='236.98'
                      cy='1353.53'
                      r='.01'
                      gradientTransform='matrix(30.5203 -18.9306 25.5174 41.1331 -41759.25 -51172.13)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#fff' />
                      <stop offset='1' stop-color='#f3798d' />
                    </radialGradient>
                    <path
                      d='M12.2 16.5c.1.2.1.4 0 .5-.1.1-.3 0-.4-.2-.1-.2-.1-.4 0-.5.1-.1.3 0 .4.2z'
                      fill='url(#SVGID_00000051378423622295740620000012408277097388875907_)'
                    />
                    <radialGradient
                      id='SVGID_00000172414566015050459100000012980555742716188308_'
                      cx='237.84'
                      cy='1422.83'
                      r='0'
                      gradientTransform='scale(35.9146) rotate(-31.81 -2376.75 1128.4)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#f6e11c' />
                      <stop offset='1' stop-color='#e82735' />
                    </radialGradient>
                    <path
                      d='M12 16.2v.1h-.1v-.1h.1z'
                      fill='url(#SVGID_00000172414566015050459100000012980555742716188308_)'
                    />
                    <radialGradient
                      id='SVGID_00000033342304973957871240000005249567393531606717_'
                      cx='237.83'
                      cy='1422.83'
                      r='0'
                      gradientTransform='scale(35.9146) rotate(-31.81 -2376.75 1128.4)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#f6e11c' />
                      <stop offset='1' stop-color='#e82735' />
                    </radialGradient>
                    <path
                      d='M11.7 16.4v.1h-.1v-.1c0-.1.1-.1.1 0z'
                      fill='url(#SVGID_00000033342304973957871240000005249567393531606717_)'
                    />
                    <radialGradient
                      id='SVGID_00000038397889415415808130000003850600884935340428_'
                      cx='237.83'
                      cy='1422.83'
                      r='0'
                      gradientTransform='scale(35.9146) rotate(-31.81 -2376.75 1128.4)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#f6e11c' />
                      <stop offset='1' stop-color='#e82735' />
                    </radialGradient>
                    <path
                      d='M11.8 16.2v.1h-.1v-.1h.1z'
                      fill='url(#SVGID_00000038397889415415808130000003850600884935340428_)'
                    />
                  </g>
                  <g>
                    <radialGradient
                      id='SVGID_00000057108665459016516570000015691207684448005803_'
                      cx='-1420.07'
                      cy='-319.43'
                      r='0'
                      gradientTransform='matrix(26.013 24.7632 -36.8629 38.7304 25185.77 47555.16)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#fbc942' />
                      <stop offset='.38' stop-color='#f9c742' />
                      <stop offset='.59' stop-color='#f1c140' />
                      <stop offset='.75' stop-color='#e3b73e' />
                      <stop offset='.9' stop-color='#cfa93a' />
                      <stop offset='1' stop-color='#bd9b37' />
                    </radialGradient>
                    <path
                      d='M20.8 18c-.1.1-.1.1-.2 0v-.2c.1-.1.1-.1.2 0 .1 0 .1.1 0 .2z'
                      fill='url(#SVGID_00000057108665459016516570000015691207684448005803_)'
                    />
                    <radialGradient
                      id='SVGID_00000070096497976987701220000006055278167850385072_'
                      cx='-1419.68'
                      cy='-324.65'
                      r='.01'
                      gradientTransform='matrix(26.013 24.7632 -36.5755 38.4251 25076.22 47648.86)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#fff' />
                      <stop offset='1' stop-color='#f3798d' />
                    </radialGradient>
                    <path
                      d='M20.5 18.6c-.1.2-.3.2-.4.1-.1-.1-.1-.3.1-.5.1-.2.3-.2.5-.1-.1.1-.1.3-.2.5z'
                      fill='url(#SVGID_00000070096497976987701220000006055278167850385072_)'
                    />
                    <radialGradient
                      id='SVGID_00000124879863039293808140000004106919439601120939_'
                      cx='-1419.53'
                      cy='-838.76'
                      r='0'
                      gradientTransform='matrix(26.013 24.7632 -27.1371 28.5091 14185.4 59082.63)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#f6e11c' />
                      <stop offset='1' stop-color='#e82735' />
                    </radialGradient>
                    <path
                      d='M20.7 18.3h-.1v-.1h.1v.1z'
                      fill='url(#SVGID_00000124879863039293808140000004106919439601120939_)'
                    />
                    <radialGradient
                      id='SVGID_00000043457560279642601230000009815377134737021831_'
                      cx='-1419.54'
                      cy='-838.76'
                      r='0'
                      gradientTransform='matrix(26.013 24.7632 -27.1371 28.5091 14185.4 59082.63)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#f6e11c' />
                      <stop offset='1' stop-color='#e82735' />
                    </radialGradient>
                    <path
                      d='M20.5 18.1h-.1V18h.1v.1z'
                      fill='url(#SVGID_00000043457560279642601230000009815377134737021831_)'
                    />
                    <radialGradient
                      id='SVGID_00000140718194470806411660000002607934081982161290_'
                      cx='-1419.54'
                      cy='-838.76'
                      r='0'
                      gradientTransform='matrix(26.013 24.7632 -27.1371 28.5091 14185.4 59082.63)'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop offset='0' stop-color='#f6e11c' />
                      <stop offset='1' stop-color='#e82735' />
                    </radialGradient>
                    <path
                      d='M20.6 18.1h-.1V18h.1c.1 0 .1.1 0 .1z'
                      fill='url(#SVGID_00000140718194470806411660000002607934081982161290_)'
                    />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path class='st37' d='M13.5 11.8c0-.1-.1-.2-.1-.2h.1v.2z' />
              <g id='XMLID_6107_'>
                <path
                  class='st38'
                  d='M13 16.8zM13.4 16.7c0 .1-.1.1-.1.2-.1 0-.2-.1-.3-.1h.2c.1-.1.1-.1.2-.1zM13.5 16.4zM13.5 16.4h.1v.1s-.1 0-.1.1c0 0-.1 0-.1.1 0-.1.1-.2.1-.3zM13.3 16.3s0-.1 0 0c.1-.1.1 0 0 0 .1 0 0 0 0 0z'
                />
                <path
                  class='st38'
                  d='M13.5 16.4c-.1 0-.1.1-.2.1 0-.1.1-.1.1-.2h.1c-.1 0-.1 0 0 .1zM12.9 16.5H13.2c0 .1-.1.1-.2.2h-.1v-.2zM13.1 16.2c0-.1.1-.1.1-.2 0 .1.1.1.1.2v.1s-.1 0-.2-.1c.1.1 0 .1 0 0 0 .1 0 .1 0 0z'
                />
                <path
                  class='st38'
                  d='M12.9 16.5v-.2c.1 0 .2 0 .2.1-.1 0-.1 0-.2.1zM12.8 16.1c0-.1-.1-.2-.2-.2h.3c0 .1-.1.2-.1.2zM12.9 15.8c0-.1.1-.1.1-.2l.2.2c-.1.1-.2.1-.3 0zM13.5 15.5zM13.4 15.6s0-.1 0 0c0-.1 0-.1 0 0zM13.4 15.3zM13.3 15.4l-.1.1c-.1-.1-.1-.2-.2-.3.1.1.2.1.3.2 0-.1 0-.1 0 0 0-.1 0 0 0 0zM13.2 15s.1-.1 0 0c.1-.1.1-.1.1-.2V15.1s0-.1-.1-.1zM13 15.2V15h.1l.1.1c-.1-.1-.1 0-.2.1.1-.1 0-.1 0 0zM13 14.9v-.1.1zM13.3 14.8s0-.1 0 0c0-.1 0-.2.1-.2v.1s-.1 0-.1.1zM13 14.8c0-.1 0-.3.1-.4 0 .1.1.1.1.2l.1.1-.1.1c-.1 0-.1.1-.2 0zM13.4 14.5s0-.1-.1-.1H13.6c-.1 0-.1 0-.2.1.1-.1.1 0 0 0 .1 0 0 0 0 0zM13 14.3c0-.1.1-.2.2-.3 0 .1 0 .2.1.3v.1h-.1s-.1-.1-.2-.1zM13.5 13.7zM13.6 14.3c0-.1 0-.2-.1-.2h.2c0 .1 0 .2-.1.2zM13.6 14.1c-.1 0-.2-.1-.3-.1l.1-.1.1-.1s0 .1.1.3c-.1-.1-.1-.1 0 0zM13.5 13.7l.1-.1h.1V13.9c0-.1-.1-.1-.2-.2zM13.8 14.1v-.2H14.1c-.1.1-.2.2-.3.2.1 0 0 0 0 0zM14 13.4zM14 13.6h-.2l.1-.1c.1 0 .1 0 .1.1zM14.2 13.4H14l.1-.1s0 .1.1.1zM14.1 13.9c0-.1-.1-.2-.1-.3H14.3c0 .1-.1.2-.2.3zM14.4 13.6l-.2-.2c.1 0 .1 0 .1-.1l.1-.1.1.1h.1c0 .1 0 .2-.2.3z'
                />
                <path
                  class='st38'
                  d='M14.2 13.1c0-.1 0-.1 0 0 .1 0 .2.1.2.1s0 .1-.1.1c0-.1 0-.1-.1-.2zM14.1 13.2c0-.1 0-.1 0 0 .1-.1.1 0 .1 0 0 .1.1.1.1.2l-.1.1-.1-.1v-.2zM13.5 12.9c.1-.1.1-.1 0 0h.1-.1zM14.1 13.1c0-.1-.2-.2-.4-.2v-.1h.1c.1 0 .2.1.3.3zM13.3 12.7s.1 0 0 0c.1 0 .1 0 0 0 .1 0 .2 0 .2.1 0 0-.1 0-.1.1H13c.2-.1.3-.2.3-.2zM13.2 12.4h.3c0 .1-.1.1-.2.2l-.1-.2c0 .1 0 .1 0 0zM13.1 12.8s-.1 0-.1-.1c0 0 0-.1.1-.1v.2zM13 12.6v-.2s0 .1.1.1c0-.1 0 0-.1.1 0-.1 0-.1 0 0z'
                />
                <path
                  class='st39'
                  d='M13.6 16.4h.1c0 .1-.1.1-.1.1v-.1zM13.3 16.9c0-.1.1-.1.1-.2.1 0 .1 0 .2-.1 0 0 .1 0 .1-.1V16.8c-.1.1-.2.1-.4.1.1 0 0 0 0 0zM13.2 16.5c.1 0 .2 0 .2-.1 0 .1 0 .1-.1.2H13c.1.1.2 0 .2-.1z'
                />
                <path
                  class='st39'
                  d='M13.3 16.3c0 .1-.1.1-.1.2H12.9l.2-.2h.2zM12.8 16.2c0-.1.1-.3.1-.4.1 0 .2 0 .3.1v.1c0 .1-.1.1-.1.2v.1c-.1 0-.2 0-.3-.1z'
                />
                <path
                  class='st39'
                  d='M12.9 15.8h-.3l.1-.1c.1-.1.2-.1.2-.1l.1.1s-.1 0-.1.1zM13.5 15.5h.1l.1.1c-.1 0-.2 0-.2-.1 0 .1 0 .1 0 0zM13.3 15.5s-.1 0 0 0c0 0 0-.1.1-.1h.1v.2c-.1-.1-.2-.1-.2-.1zM13.2 15c.1 0 .1.1.2.1v.1s0 .1-.1.1-.2-.1-.3-.1c.1-.1.2-.1.2-.2z'
                />
                <path
                  class='st39'
                  d='M13.1 14.9c.1 0 .1-.1.2-.1l.1.1s0 .1-.1.1l-.1.1-.1-.2zM13.2 14.4h.1s0 .1.1.1c0 .1-.1.1-.2.2l-.1-.1c0-.1-.1-.1-.1-.2h.2zM13.3 14.3c0-.1-.1-.2-.1-.3.1 0 .2.1.3.1 0 .1 0 .2.1.3h-.1s-.1 0-.2-.1zM13.6 14v-.2c.1 0 .1.1.2.1v.2c-.1 0-.1 0-.2-.1zM13.8 13.7v-.2h.2c0 .1 0 .2.1.3H13.8v-.1z'
                />
                <path
                  class='st39'
                  d='M14 13.5h.2l.2.2h-.3c-.1-.1-.1-.1-.1-.2zM13.5 12.5c0-.1.1-.1 0 0 .1 0 .2 0 .3.1.1.1.2.1.3.3l.1.1v.1c-.1-.1-.2-.3-.4-.3h-.1c-.1-.1-.1-.1-.2-.1-.1-.1 0-.2 0-.2zM13.2 12.8c0-.1-.1-.1-.1-.2v-.1l.1-.1v.1c0 .1.1.1.1.1 0 .1-.1.2-.1.2zM12.9 12.7c-.1-.1-.1-.2-.2-.3 0 0 .1 0 .2-.1h.1v.2c0 .2 0 .2-.1.2z'
                />
                <path
                  class='st39'
                  d='M13.5 12c-.2.1-.3.1-.4.1 0-.1.1-.1.1-.2.1-.1.2-.2.1-.4h.2-.2s-.2-.1-.5.3c0 .1-.1.1-.1.2v.7s.1 0 .1-.1c0 0 .1 0 .1-.1v-.1c.2 0 .4-.2.5-.3.2-.1.2-.1.1-.1zm-.8 0v-.2c0-.1.1-.1.2-.2s.2-.1.2-.1v.2c0 .1-.1.1-.1.2s-.2.1-.3.1z'
                />
                <path
                  class='st37'
                  d='M14.6 15.5c0-.1.1-.2.1-.2l.1.1v.1s0 .1-.1.1c-.1.1-.2.2-.3.2.1-.2.1-.2.2-.3zM14.6 13.2l.1-.1c0 .1 0 .1-.1.1 0 .1 0 .1 0 0 0 .1 0 0 0 0zM14.5 13.3c-.1 0-.1-.1-.1-.1l.1-.1.1-.1v.1l-.1.2c.1-.1 0-.1 0 0zM14.4 12.9s.1-.1.1-.2c.1.1.1.1.1.2l-.1.1-.1.1-.1-.1.1-.1z'
                />
                <path class='st40' d='M14.5 13.6z' />
                <path
                  class='st41'
                  d='M14.6 15.8h-.2v-.1c.1 0 .2-.1.3-.2 0 0 0-.1.1-.1 0 .1.1.2.1.2-.1.2-.2.2-.3.2zM14.4 16s0-.1 0 0l.1-.1h.2c.1 0 .2-.1.3-.1v.1c-.1 0-.2 0-.3.1h-.3z'
                />
                <path
                  class='st40'
                  d='M14.4 13.6c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0z'
                />
                <path class='st3' d='M14.4 13.6c.1 0 .1 0 0 0z' />
                <path
                  class='st40'
                  d='M14.4 13.6h.1-.1zM14.4 13.6h.1v.1h-.1v-.1z'
                />
                <path class='st3' d='M14.4 13.6zM14.4 13.6z' />
                <path class='st40' d='M14.4 13.2z' />
                <path
                  class='st3'
                  d='M14.3 13.3c.1 0 .1-.1 0 0 .1-.1.1-.1 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0zM14.3 13.3h.1z'
                />
                <path
                  class='st37'
                  d='M14.3 15.7s0-.1 0 0c.1-.1.1-.2.1-.3v-.2c.1 0 .2 0 .2.1s-.1.2-.1.2c0 .1-.1.1-.2.2zM14.2 13c0-.1 0-.1 0 0-.1-.1-.1-.1 0 0-.1-.1 0-.1 0-.2.1-.1.1-.1.1-.2l.1.1c0 .1-.1.2-.1.2 0 .1 0 .1-.1.1z'
                />
                <path
                  class='st37'
                  d='m14.3 15.7-.1-.1c0-.1.1-.1.1-.2v-.2h.1v.2c-.1.1-.1.1-.1.3 0-.1 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M14.2 13.5c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 .1 0 .1.1.2.2-.1-.1-.1-.2-.2-.2z'
                />
                <path class='st40' d='M14.2 13z' />
                <path
                  class='st3'
                  d='m14.3 13.3-.1.1s.1 0 .1-.1zM14.2 13.4zM14.2 13.2c0-.1 0-.1 0 0 0-.1 0-.1 0 0l-.1-.1c.1 0 .1 0 .1.1.1 0 .1.1.1.1s0-.1-.1-.1zM14.2 13.1s0-.1 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM14.2 13s0 .1 0 0c0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0-.1.1-.1.1 0 0-.1.1-.1 0 0 0z'
                />
                <path class='st41' d='M14.1 15.7c0-.1 0-.1 0 0h.1-.1z' />
                <path
                  class='st37'
                  d='M14.1 12.9c0-.1 0-.1 0 0V12.6c.1 0 .1.1.1.1.1 0 0 0-.1.2.1-.1 0-.1 0 0zM14 15.5l.1-.1v-.1h.1v.2c0 .1 0 .1-.1.2 0-.2-.1-.2-.1-.2z'
                />
                <path
                  class='st40'
                  d='m14.1 13.9.3-.3c0 .1-.1.2-.3.3zM14.1 13.9zM14.1 13.9v.1-.1z'
                />
                <path class='st3' d='M14.1 13.9z' />
                <path
                  class='st40'
                  d='M14.1 13.9zM14.1 14c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0zM14.1 13.9z'
                />
                <path
                  class='st3'
                  d='M14.1 13.9zM14.1 13.3l.1.1c-.1 0-.1-.1-.1-.1zM14.2 13.1c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0z'
                />
                <path
                  class='st40'
                  d='M14.1 13.2c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM14.1 13.3c0-.1 0-.1 0 0z'
                />
                <path
                  class='st41'
                  d='M14.1 15.7h-.2l.1-.1s.1 0 .1-.1v.2c0-.1 0-.1 0 0z'
                />
                <path class='st40' d='M14.1 13.3v-.1.1-.1.1c0-.1 0-.1 0 0z' />
                <path
                  class='st37'
                  d='M14 15.4c-.1 0-.1 0 0 0v-.2h.1v.1l-.1.1z'
                />
                <path
                  class='st3'
                  d='M14 13.6h.3H14zM14.1 13.9c0-.1-.1-.2-.1-.3 0 .1.1.2.1.3z'
                />
                <path
                  class='st37'
                  d='M14 12.7c0-.1-.1-.1-.1-.1l.1-.1v-.1h.1v.1c0 .2 0 .2-.1.2z'
                />
                <path class='st40' d='m14 13.4.1-.1-.1.1c.1-.1 0 0 0 0z' />
                <path
                  class='st3'
                  d='M14 13.4h.2c-.1 0-.1.1-.2 0 0 .1 0 .1 0 0zM14 13.4zM14 13.6zM14 13.5c0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0zM14 13.4c0 .1 0 .1 0 0 0 .1 0 .1 0 0z'
                />
                <path class='st40' d='M14 13.4z' />
                <path
                  class='st3'
                  d='M14 13.5s0-.1 0 0c-.1-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0z'
                />
                <path
                  class='st40'
                  d='M13.9 13.4c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0zM13.9 13.4c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0z'
                />
                <path
                  class='st41'
                  d='M13.7 15.5c.1 0 .1-.1.2-.1l.1.1-.1.1-.1.1h-.1l-.1-.1c.1 0 .1 0 .1-.1z'
                />
                <path
                  class='st37'
                  d='M13.9 15.3s-.1 0 0 0v-.2h.1l-.1.2zM13.9 12.6c-.1 0-.1 0 0 0l-.1-.1.1-.1h.1c-.1.1-.1.2-.1.2z'
                />
                <path
                  class='st40'
                  d='m13.9 14.1.2-.2-.2.2zM13.8 14.1c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0zM13.8 14.1s.1 0 0 0c.1 0 .1 0 0 0 0 .1 0 .1 0 0z'
                />
                <path
                  class='st3'
                  d='M13.8 13.6h.2-.2zM13.8 14.1s.1 0 0 0c.1 0 .1 0 0 0z'
                />
                <path
                  class='st40'
                  d='M13.8 14.1zM13.8 14.2c0-.1 0-.1 0 0 0-.1 0-.1 0 0z'
                />
                <path
                  class='st3'
                  d='M13.8 14.2c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM13.8 14.1zM13.8 14.1v-.2.2zM13.8 13.9c.1 0 .2 0 .3.1-.1-.1-.2-.1-.3-.1zM13.8 13.6z'
                />
                <path
                  class='st40'
                  d='M13.8 13.5c.1 0 .1-.1.2-.1-.1.1-.1.1-.2.1z'
                />
                <path class='st3' d='M13.8 13.6z' />
                <path class='st40' d='M13.8 16.4z' />
                <path class='st3' d='M13.8 13.9V13.6 13.9z' />
                <path
                  class='st40'
                  d='M13.8 13.6zM13.8 13.6c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0z'
                />
                <path class='st3' d='M13.8 13.9zM13.8 13.6z' />
                <path
                  class='st40'
                  d='M13.7 13.6c.1-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1 0 .1 0 0 0 .1-.1.1-.1 0 0z'
                />
                <path
                  class='st37'
                  d='M13.7 12.5c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1.1-.1 0 0 .1-.1.1 0 0 0zM13.7 15.1c.1 0 .1 0 0 0 .1.1.1.1 0 0l.1.1-.1-.1c0 .1 0 0 0 0z'
                />
                <path
                  class='st40'
                  d='M13.7 13.6c.1-.1.1-.1 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.8 16.4c0 .1-.1.1-.1.2 0-.1 0-.2.1-.2z'
                />
                <path
                  class='st41'
                  d='M13.7 16.9V16.6c.1-.1.1-.1.1-.2.2-.1.4-.2.6-.4.1 0 .2 0 .2-.1.1 0 .2-.1.3-.1v.1c0 .2-.2.5-.4.6-.1.1-.3.1-.5.2h-.2c0 .1-.1.2-.1.2z'
                />
                <path
                  class='st40'
                  d='M13.7 12.9c.2 0 .3.1.4.2-.1-.1-.2-.2-.4-.2zM13.7 12.9z'
                />
                <path
                  class='st37'
                  d='M13.7 12.4c-.1 0-.1 0 0 0-.1 0 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.6 12.8c.1 0 .1 0 0 0 .3 0 .4.1.5.2-.1-.1-.2-.2-.5-.2.1 0 .1 0 0 0zM13.7 12.9s-.1-.1 0 0c-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0 0-.1 0 0 0 0z'
                />
                <path class='st40' d='M13.7 16.9s-.1 0 0 0c-.1 0-.1 0 0 0z' />
                <path
                  class='st3'
                  d='M13.6 16.7v-.1c.1 0 .1 0 0 .1v.2-.2zM13.6 16.6c0-.1 0-.1 0 0 0-.1 0 0 0 0z'
                />
                <path
                  class='st40'
                  d='M13.6 14.3c0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0z'
                />
                <path class='st3' d='M13.6 12.8z' />
                <path
                  class='st40'
                  d='M13.6 14.4c0-.1 0-.1 0 0 0-.1 0 0 0 0zM13.6 16.4h.2-.2zM13.6 14.4z'
                />
                <path class='st42' d='M13.6 14.4z' />
                <path class='st3' d='M13.6 16.4v.1-.1z' />
                <path class='st40' d='M13.6 16.4z' />
                <path class='st3' d='M13.8 14.1h-.2.2z' />
                <path class='st40' d='M13.6 16.4z' />
                <path
                  class='st3'
                  d='M13.6 14.4c0-.1 0-.2-.1-.3.1.1.1.2.1.3 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0z'
                />
                <path
                  class='st41'
                  d='M13.5 15.4c.1 0 .1 0 .2-.1 0 0 .1 0 .1-.1l.1.1c0 .1-.1.1-.2.1 0 0-.1 0-.1.1s0 0-.1-.1c.1.1.1.1 0 0 .1.1 0 .1 0 0z'
                />
                <path
                  class='st40'
                  d='M13.6 16.4s-.1 0 0 0c-.1 0-.1 0 0 0zM14.7 13.1c0-.1 0-.1-.1-.2 0-.1-.1-.1-.1-.2l-.1-.1-.1-.1H13.4s.1 0 .1.1l.2.2s0 .1.1.1l-.1-.1.1-.1s0-.1.1-.1v-.1l.1.1-.1.1v-.1l.2.2.1-.1c0-.1.1-.1.1-.1.3.4.4.4.4.5l-.1.1h-.2l.1.1h.1c0 .1-.1.2-.2.3.1-.1.2-.2.2-.3.1-.1.1-.1.1-.2zm-1.1-.7s.1 0 0 0c.1 0 .1 0 0 0 .1 0 .1 0 0 0zm.1.1c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1.1-.1 0 0 .1-.1.1 0 0 0zm.1 0v-.1h.2c-.1.1-.1.2-.2.1 0 .1 0 .1 0 0zm.2.2c-.1 0-.1-.1 0 0-.1-.1-.1-.1 0 0l-.1-.1v-.2h.1v.1c.1.2.1.2 0 .2zm.3-.1c0 .1-.1.1-.1.2l-.1.1-.1-.1.1-.1v-.1c.1-.1.2 0 .2 0zm.2.5-.1.1-.1-.1.1-.1s.1-.1.1-.2c0 .1.1.1.1.2l-.1.1zm.2.2c-.1 0-.1 0 0 0-.1 0-.1-.1 0 0v-.2.2z'
                />
                <path class='st3' d='M13.6 14.1z' />
                <path
                  class='st40'
                  d='M13.5 12.4s.1 0 0 0c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0zM13.6 15.5c-.1 0-.1 0 0 0-.1 0 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.5 12.9c0-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1-.1 0 0zM13.8 13.9c-.1 0-.2-.1-.2-.1s.1 0 .2.1zM13.5 13.7c0 .1 0 .1 0 0z'
                />
                <path
                  class='st40'
                  d='M13.5 13.7zM13.5 13.7l.1-.1s.1 0 .1-.1h-.1c0 .2 0 .2-.1.2z'
                />
                <path
                  class='st3'
                  d='M13.5 14v-.2c0 .1.1.1 0 .2.1 0 .1 0 0 0 .1.1.1.1 0 0 .1.1.1.1 0 0z'
                />
                <path
                  class='st41'
                  d='M13.6 14.4c0 .1-.1.1-.1.2 0-.1 0-.1.1-.2-.1 0 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.5 13.8s0-.1 0 0c0-.1 0-.1 0 0zM13.5 13.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0z'
                />
                <path
                  class='st40'
                  d='M13.5 13.7zM13.5 12.9h.2-.2zM13.5 12.9zM13.6 14.4s-.1 0 0 0c-.1.1-.1.1 0 0l-.1.1c0-.1 0-.1.1-.1 0-.1 0-.1 0 0 .1-.1.1-.2.2-.2l-.2.2c0 .1-.1.1-.1.2 0-.1.1-.2.1-.2zM13.5 15.4c0 .1 0 0 0 0zM13.5 16.3s0 .1 0 0c.1.1.1.1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0zM13.5 13.7z'
                />
                <path
                  class='st3'
                  d='M13.5 15.5s0-.1 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0zM13.5 16.4s.1 0 0 0c.1 0 .1 0 0 0 .1 0 .1 0 0 0z'
                />
                <path class='st40' d='M13.5 15.4z' />
                <path class='st42' d='M13.5 15.4z' />
                <path
                  class='st40'
                  d='M13.5 16.3s0 .1 0 0c0 .1 0 .1 0 0 0 .1 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.6 15.5c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0z'
                />
                <path
                  class='st40'
                  d='M13.5 15.4c-.1 0-.1 0 0 0-.1-.1 0-.1 0 0z'
                />
                <path
                  class='st3'
                  d='M13.5 16.4c0-.1 0 0 0 0zM13.4 16.5s0-.1 0 0c0-.1 0-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0zM13.4 15.6c0-.1.1-.1 0 0 .1-.1.1-.1 0 0 .1 0 .1 0 0 0zM13.5 15.5s-.1 0 0 0c-.1 0-.1 0 0 0z'
                />
                <path
                  class='st41'
                  d='M13.4 14.6c.1-.1.1-.1.1-.2v.1c0 .1 0 .1-.1.1.1 0 .1.1 0 0l.1.1c-.1 0-.1 0-.1-.1z'
                />
                <path class='st40' d='M13.4 14.5z' />
                <path
                  class='st41'
                  d='M13.7 15.4c-.1 0-.1.1-.2.1v-.1s0-.1-.1-.1h.4c0-.1 0 0-.1.1.1-.1 0-.1 0 0z'
                />
                <path class='st40' d='M13.4 15.6z' />
                <path
                  class='st3'
                  d='M13.4 14.5zM13.4 14.5zM13.5 16.6c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0l-.1.1c.1 0 .1-.1.1-.1zM13.4 16.7v-.2c.1 0 0 .1 0 .2z'
                />
                <path
                  class='st40'
                  d='M13.4 15.2zM13.5 16.3c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0 0z'
                />
                <path
                  class='st41'
                  d='M13.4 14.8v-.1.1h.1c0 .1 0 .1.1.1-.1 0-.2 0-.2-.1 0 .1 0 0 0 0zM13.4 15.1c.1 0 .1 0 0 0h.1v.1h-.2c.2 0 .2 0 .1-.1 0 .1 0 0 0 0z'
                />
                <path class='st3' d='m13.4 12.6.2-.2-.2.2zM13.4 16.7z' />
                <path class='st40' d='M13.4 16.3z' />
                <path
                  class='st3'
                  d='M13.4 12.7c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 .1 0 .1 0 .2.1-.1-.1-.1-.1-.2-.1zM13.4 12.6c-.1 0 0 0 0 0 0 .1 0 0 0 0zM13.4 14.5s0-.1-.1-.1c.1 0 .1.1.1.1z'
                />
                <path
                  class='st41'
                  d='M13.4 15c0-.1 0-.1 0 0 0-.1 0-.1 0 0v-.1.1c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0h.1s0 .1.1.1c-.2 0-.2 0-.2-.1z'
                />
                <path class='st40' d='M13.4 15.1z' />
                <path
                  class='st3'
                  d='M13.4 14.4H13.7 13.4zM13.3 15.3v-.1c.1 0 .1 0 0 .1.1-.1.1-.1 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0z'
                />
                <path
                  class='st40'
                  d='M14.9 15.7c0-.1 0-.2-.1-.3v-.1c0-.1-.1-.1-.1-.1-.1 0-.2-.1-.3-.1h-.6c-.1 0-.1-.1-.1-.1 0-.1-.1-.1-.1-.2v-.2.1-.1V14.9s0-.1-.1-.1v.2-.1.1l.1.1c0 .1 0 .1.1.1h-.1l-.1-.1V15.4v-.1l.1.1v.1h-.4v-.1.2s0-.1-.1-.1h.4s0 .1.1.1l-.1.1c-.1 0-.1.1-.2.1v.1s0-.1-.1-.1c.1 0 .1 0 .2-.1 0 0 .1 0 .1-.1l.1.1c0 .1-.1.1-.2.1 0 0-.1 0-.1.1v.1l.1.1c-.1 0-.1 0-.2-.1.1 0 .2.1.2.1H14.1l.1.1v.1l-.1.1-.1.1c-.1 0-.1.1-.2.1h-.1H14l.1-.1s.1 0 .1-.1l.1-.1c.1 0 .2 0 .2-.1.1 0 .2-.1.3-.1v.4l-.1.1H14c-.1 0-.1 0-.2.1.1 0 .1 0 .2-.1h.6c.1-.1.2-.1.3-.2v-.1c.1-.1.1-.2.1-.3v-.1c-.1-.1-.1-.1-.1-.2zm-1.2-.6s-.1 0 0 0c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0h-.1V15s0 .1.1.1zm.4.1h.1v.2c0 .1-.1.1-.1.2l-.1-.1.1-.1v-.2zm-.1 0h.1v.1c0 .1-.1.1-.1.1l-.1-.1.1-.1zm-.2.1c0-.1 0-.1 0 0l-.1-.1h.1v.1c0-.1 0-.1 0 0zm.1 0s-.1 0 0 0v-.2h.1l-.1.2zm-.2.4c0-.1-.1-.1 0 0 0-.1 0-.1.1-.1s.1-.1.2-.1l.1.1-.1.1-.1.1c-.1-.1-.2-.1-.2-.1zm.4 0h-.2l.1-.1s.1 0 .1-.1v.2c0-.1 0-.1 0 0zm0 0c0-.1 0-.1 0 0h.1-.1zm.2 0-.1-.1c0-.1.1-.1.1-.2v-.2h.1v.2c-.1.1-.1.1-.1.3 0-.1 0 0 0 0zm0 0s0-.1 0 0c.1-.1.1-.2.1-.3v-.2c.1 0 .2 0 .2.1s-.1.2-.1.2c0 .1-.1.1-.2.2zm.1 0 .2-.2c0-.1.1-.2.1-.2l.1.1v.1s0 .1-.1.1c0 0-.1.1-.3.1zm.1.1c.1-.1.2-.1.3-.2 0 0 0-.1.1-.1 0 .1.1.2.1.2-.1.1-.2.1-.3.1h-.2zm.1.1c-.1 0-.1 0-.2.1 0 0 0-.1.1-.1h.1c.2-.1.3-.2.3-.2v.1c-.1.1-.2.1-.3.1z'
                />
                <path
                  class='st3'
                  d='M13.3 15.3s.1 0 0 0c.1 0 .1 0 0 0 .1 0 .1 0 0 0h.1c0 .1 0 .1-.1 0z'
                />
                <path
                  class='st40'
                  d='M13.4 16.3s0-.1 0 0c-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0 0-.1 0-.1 0 0zM13.4 14.8c-.1 0-.1 0 0 0-.1 0 0 0 0 0zM13.3 14.8s.1 0 0 0c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0zM13.3 14.8z'
                />
                <path
                  class='st3'
                  d='M13.4 14.4c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0z'
                />
                <path
                  class='st40'
                  d='M13.4 14.8s-.1 0 0 0c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.3 16.9c0-.1.1-.1.1-.2 0 .1-.1.2-.1.2z'
                />
                <path
                  class='st40'
                  d='M13.4 15.6c-.1 0-.1-.1-.2-.1.1 0 .1 0 .2.1 0-.1 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.3 15.3c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0zM13.4 16.3s-.1 0 0 0c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0 0z'
                />
                <path
                  class='st40'
                  d='M13.3 16.2zM13.4 16.9s-.1 0 0 0c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0 0 .1 0 .2 0 .3-.1-.1.1-.2.1-.3.1z'
                />
                <path
                  class='st3'
                  d='M13.3 16.3s0-.1 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0zM13.3 14c.1 0 .2.1.3.1-.1 0-.2-.1-.3-.1zM13.2 14.7l.2-.2c0 .1-.1.2-.2.2.1 0 .1 0 0 0zM13.3 16.3z'
                />
                <path class='st40' d='M13.3 16.9z' />
                <path class='st3' d='M13.3 15.5h.1-.1z' />
                <path class='st40' d='M13.3 14v-.1z' />
                <path class='st3' d='M13.3 14zM13.2 15.5l.1-.1-.1.1z' />
                <path
                  class='st40'
                  d='M13.3 16.2s-.1-.1-.1-.2c.1.1.1.2.1.2zM13.2 13.9h.1v.1h-.1z'
                />
                <path
                  class='st3'
                  d='M13.2 14.7c.1 0 .1 0 0 0 .1 0 .1.1 0 0 .1.1.1.1 0 0 .1.1.1.1 0 0zM13.3 14.3c0-.1-.1-.2-.1-.3.1.1.1.2.1.3.1.1.1.1 0 0 0 .1 0 .1 0 0z'
                />
                <path
                  class='st40'
                  d='m13.3 13.9.1-.1.1-.1-.1.1-.1.1c0 .1 0 .1 0 0zM13.3 13.9c-.1 0-.1 0 0 0zM13.2 15.5c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0zM13.2 14c.1 0 0 0 0 0zM13.2 12.4s.1 0 0 0c.1 0 .1 0 0 0h.3-.3zM13.2 14v-.1.1z'
                />
                <path class='st3' d='M13.2 14.7h.1-.1z' />
                <path
                  class='st40'
                  d='M13.2 16c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M13.4 16.5c-.1 0-.1.1-.2.1.1-.1.2-.1.2-.1.1-.1.1-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0z'
                />
                <path class='st40' d='M13.2 15.5zM13.2 12.4c.1 0 0 0 0 0z' />
                <path
                  class='st3'
                  d='m13.2 15 .1.1-.1-.1zM13.2 15s0-.1.1-.1c0 0 0-.1.1-.1l-.2.2c.1-.1 0 0 0 0zM13.2 16.5c0-.1 0-.1.1-.2 0 .1 0 .1-.1.2z'
                />
                <path class='st40' d='M13.2 12.4z' />
                <path
                  class='st3'
                  d='M13.2 15zM13.2 12.4zM13.2 12.4c0 .1 0 .1 0 0 0 .1.1.2.2.2-.1 0-.2 0-.2-.2 0 .1 0 .1 0 0z'
                />
                <path
                  class='st40'
                  d='M13.2 12.4zM13.2 15.9s0 .1 0 0c.1.1.1.1 0 0 0 .1 0 .1 0 0v.1-.1z'
                />
                <path class='st3' d='M13.2 16.5z' />
                <path class='st40' d='M13.2 15.9z' />
                <path class='st3' d='M13.2 12.4zM13.2 12.4z' />
                <path class='st40' d='M13.4 12.9h-.2c0-.1.1-.1.2 0h.2-.2z' />
                <path
                  class='st3'
                  d='m13.3 12.7-.2.2.2-.2c.1-.1.1-.1 0 0 .1-.1.1 0 0 0 .1 0 0 0 0 0z'
                />
                <path class='st40' d='M13.2 12.8z' />
                <path
                  d='M13.2 11.5c-.1 0-.1 0-.3.1-.1.1-.2.1-.2.2 0 0-.1.1 0 .2s.2 0 .3-.1c0 0 .1-.1.1-.2.1 0 .2-.1.1-.2zm0 0c0 .1 0 .1 0 0l-.1.1.1-.1c-.1 0 0 0 0 0z'
                  fill='#c5c6a6'
                />
                <path class='st40' d='M13.2 12.9v-.1.1z' />
                <path class='st3' d='M13.2 12.8h-.1z' />
                <path
                  class='st40'
                  d='M13.2 12.9zM13.2 12.8s-.1 0 0 0c0 .1 0 .1 0 0z'
                />
                <path
                  class='st40'
                  d='M13.2 12.8c0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1-.1.1 0 0-.1.1-.1 0 0 0-.1 0 0 0 0 0zM13.1 12.8zM13.2 12.4s-.1 0 0 0c-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0-.1-.1 0 0 0 0zM13.1 12.3zM13.1 12.3zM13.1 12.3z'
                />
                <path d='M13.2 11.5c0 .1 0 .1 0 0l-.1.1.1-.1z' fill='#a3a395' />
                <path
                  class='st3'
                  d='M13.1 16.2c0-.1.1-.1.1-.2 0 .1 0 .2-.1.2 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0zM13.2 16.7h.1H13h.2zM13.2 14.7c0 .1-.1.1-.1.1s.1 0 .1-.1zM13.3 16.3h-.2.2zM13.1 12.3zM13.2 14.4h.1H13c.1-.1.2 0 .2 0zM13.1 12.3zM13.1 12.3c-.1 0 0 0 0 0zM13 16.7l.2-.2c0 .1-.1.2-.2.2.1 0 .1 0 0 0zM13.2 15c-.1 0-.1-.1-.1-.1l.1.1zM13 16.3c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0zM13.3 15.3c-.1 0-.2-.1-.3-.1.1 0 .2.1.3.1zM13 14.9c.1 0 .1 0 0 0 .1 0 .1 0 0 0zM13 12.6c0-.1 0-.1 0 0l.2-.2-.2.2c.1 0 0 0 0 0zM13 12.6c0 .1.1.1.1.2 0 0 0-.1-.1-.2z'
                />
                <path class='st40' d='M13.2 15.9a.22.22 0 0 1 0 0z' />
                <path
                  class='st3'
                  d='m13.2 14.7-.1-.1c0-.1-.1-.1-.1-.2 0 .1.1.1.1.2.1 0 .1.1.1.1zM13 12.6z'
                />
                <path class='st40' d='M13 14.3z' />
                <path class='st3' d='M13 14.3v.1-.1z' />
                <path
                  class='st40'
                  d='M13 15.6c0 .1 0 .1 0 0 0 .1 0 .1 0 0zM13 15.2z'
                />
                <path
                  class='st3'
                  d='M13 15.2zM13 15.2c0-.1.1-.1 0 0l.2-.2c0 .1-.1.1-.2.2.1-.1.1 0 0 0z'
                />
                <path class='st40' d='M13 15.2z' />
                <path
                  class='st3'
                  d='M13 16.8zM13 16.8c0-.1 0-.1 0 0 0-.1 0-.1 0 0 .1-.1.1-.1 0 0 .1-.1.1-.1 0 0z'
                />
                <path
                  class='st40'
                  d='M13 14.3c.1-.1.1-.3.2-.4 0 .2-.1.3-.2.4zM13 15.2zM13.2 15.5s.1 0 0 0c-.1-.1-.2-.2-.2-.3.1.1.1.2.2.3zM13 14.3z'
                />
                <path class='st3' d='M13 12.6v-.2.2z' />
                <path
                  class='st40'
                  d='M13 14.3zM13 14.4c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM13 15.2zM13 15.2zM13 16.8z'
                />
                <path
                  class='st3'
                  d='M13 14.9zM13 16.8c0-.1 0-.1 0 0 0-.1-.1-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM12.9 12.7s0-.1.1-.1c0 .1 0 .1-.1.1 0 .1 0 .1 0 0 0 .1 0 .1 0 0z'
                />
                <path
                  class='st40'
                  d='M12.9 14.9c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0z'
                />
                <path class='st3' d='M13 14.8c0 .1 0 .1 0 0l.1.1-.1-.1z' />
                <path
                  class='st40'
                  d='M12.9 14.9s.1 0 0 0c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1.1.1.1 0 0 0 .1.1.2.1.2l-.1-.2c.1.1 0 .1 0 0zM12.9 16.7s.1 0 0 0c.1 0 .1 0 0 0 .1.1.1.1 0 0 0 .1 0 .1 0 0zM12.9 12.7c0 .1 0 .1 0 0 0 .1 0 .1 0 0 .1.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0z'
                />
                <path
                  class='st40'
                  d='M13.3 16.9c-.1 0-.2-.1-.3-.1l-.1-.1c.2.2.3.2.4.2z'
                />
                <path
                  class='st3'
                  d='M12.9 15.8c0-.1.1-.2.1-.2 0 .1 0 .2-.1.2z'
                />
                <path
                  class='st40'
                  d='M13.1 12.8s-.1 0-.1-.1c0 .1 0 .1.1.1 0 0 0 .1 0 0 0 .1.1.1 0 0 0 .1 0 0 0 0zM12.9 14.9v-.2c.1.1.1.2 0 .2.1 0 .1 0 0 0zM13 14.8c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0z'
                />
                <path
                  class='st40'
                  d='M12.9 14.8c0-.1 0-.3.1-.4 0 .1 0 .2-.1.4z'
                />
                <path
                  class='st3'
                  d='M13.2 15.9c-.1 0-.2-.1-.3-.1.1.1.2.1.3.1zM12.9 16.5H13.2 12.9zM12.9 15.9c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0zM12.9 16.5c0-.1.1-.1.1-.2 0 .1 0 .1-.1.2zM12.9 16.5zM12.9 16.5z'
                />
                <path
                  class='st40'
                  d='M12.9 16.7c0-.1-.1-.1-.1-.2.1.1.1.2.1.2zM12.9 16.5z'
                />
                <path class='st3' d='M12.9 16.5z' />
                <path
                  class='st40'
                  d='M12.8 16.5c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0zM12.8 16.5s.1 0 0 0c.1 0 .1 0 0 0zM12.8 16.5c.1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M12.8 16.2c0-.1.1-.2.1-.3 0 .1-.1.2-.1.3zM12.8 16.2c.1 0 .2 0 .2.1 0 0-.1 0-.2-.1zM13.1 12.3s-.1 0 0 0l-.1.1c-.1 0-.1 0-.2.1 0 0 .1 0 .1-.1.1 0 .1-.1.2-.1z'
                />
                <path class='st40' d='M12.8 16.2z' />
                <path
                  class='st3'
                  d='M12.6 15.6c.1-.1.2-.1.2-.2h.1s.1 0 .1.1c-.1 0-.1.1-.2.1s-.1.1-.1.1l-.1-.1c-.1.1-.1.1 0 0-.1.1-.1.1 0 0z'
                />
                <path
                  class='st40'
                  d='M12.8 16.2zM12.8 16.3s0-.1 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM12.8 16.2s0 .1 0 0v.2-.2zM12.8 16.2zM12.8 12.5s0-.1 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0zM12.8 12.5c0 .1.1.2.2.3-.2-.1-.2-.2-.2-.3z'
                />
                <path
                  class='st40'
                  d='M13.7 12s-.1 0 0 0h-.1c-.1 0-.3.1-.4.1 0 0 .1-.1.1-.2.1-.1.2-.3.1-.4h.2v-.1h-.1s-.1 0-.1.1c0 0-.3 0-.5.3 0 0-.1.1-.1.2v.7-.7c0-.1.1-.1.1-.2.2-.3.5-.3.5-.3s.1-.1.1 0h.1-.2c.1.1 0 .3-.1.4 0 .1-.1.1-.1.2.1 0 .3 0 .4-.1h.1c-.1.1-.3.3-.5.3v.1c.1-.2.4-.4.5-.4z'
                />
                <path
                  class='st39'
                  d='M12.4 15.5c.1-.1.2-.1.2-.1l.1.1c-.1 0-.2.1-.2.2l-.1.1c.1-.1 0-.2 0-.3 0 .1 0 .1 0 0 0 .1 0 0 0 0z'
                />
                <path
                  class='st3'
                  d='M12.7 14.9c-.1 0-.1 0 0 0-.2-.1-.3-.1-.3-.1v-.1h.2c.1 0 .1 0 .2.1-.1 0-.1 0-.1.1zM12.6 15.9c0-.1 0-.1 0 0h.3-.3z'
                />
                <path
                  class='st40'
                  d='M12.6 15.8c0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0z'
                />
                <path
                  class='st3'
                  d='M12.5 14.3h.2v.2l-.1-.1h-.2v-.1c0 .1 0 .1.1 0z'
                />
                <path
                  class='st40'
                  d='m12.6 15.9.2.2v.2-.1c-.1-.2-.2-.3-.2-.3z'
                />
                <path class='st39' d='M12.4 15.1h.2v.1h-.2l-.1.1v-.2h.1z' />
                <path
                  class='st3'
                  d='M12.7 15.4c-.1 0-.2 0-.2.1 0 0 0 .1-.1.1v-.1s0-.1-.1-.1l.1-.1c.1-.1.2 0 .2 0l.1.1c0-.1 0 0 0 0z'
                />
                <path
                  class='st39'
                  d='M12.6 14.5c.1 0 .1 0 .1.1v.2s-.1-.1-.2-.1h-.2v-.1c.1-.1.2-.1.3-.1zM12.6 14.1c.1.1.1.2.1.2-.1 0-.2-.1-.2 0-.1 0-.1.1-.2.1v-.1c0-.1.1-.1.1-.2.1.1.1.1.2 0z'
                />
                <path
                  class='st3'
                  d='M12.6 15c0 .1 0 .1 0 0-.1.1-.1.1-.2.1h-.2v-.2h.2c.1.1.2.1.2.1zM12.3 14.4c-.1-.1-.1-.1 0 0 0-.2 0-.2.1-.3 0 0 .1 0 .1-.1l.1.1-.1.1-.2.2z'
                />
                <path
                  class='st39'
                  d='M12.6 15s-.1-.1-.2-.1h-.2v-.1H12.5l.1.2zM12.2 14.2s0-.1.1-.1l.1-.1h.1s-.1 0-.1.1c-.1.1-.1.1-.2.1 0 .1 0 .1 0 0 0 .1 0 .1 0 0z'
                />
                <path
                  class='st3'
                  d='M12.3 14.1c0 .1-.1.1-.1.1 0-.1-.1-.2 0-.2h.2c-.1 0-.1 0-.1.1z'
                />
                <path
                  class='st37'
                  d='M12.1 14.3c-.1 0-.1 0-.1-.1 0 0 0-.1-.1-.1V14l.1-.1H12.3v.1h-.1c-.1 0-.1.1-.1.3.1-.1.1-.1 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0z'
                />
                <path
                  class='st37'
                  d='M11.8 14.2s0-.1-.1-.1V14l.1-.1H12.1v.1H12s-.1 0-.1.1c0-.1 0 0-.1.1l.1-.1v.1h-.1c.1.1.1 0 0 0z'
                />
                <path
                  class='st37'
                  d='M11.6 13.9s.1-.1 0 0c.1 0 .1-.1.1-.1h.2v.1h-.1s-.1 0-.1.1v.1s0 .1.1.1c0 0 0 .1-.1.1s-.1-.1-.1-.1v-.1c-.1-.1-.1-.2 0-.2-.1 0 0 0 0 0z'
                />
                <path
                  class='st40'
                  d='m13 15.6-.1-.1h-.1l-.1-.1v-1.1c0-.1-.1-.2-.2-.3l-.1-.1h-.2v-.1H12v-.1h-.1s-.1 0-.1.1v-.1h-.1s-.1 0-.1.1h-.1s-.1 0-.1.1v.2c0 .1.1.1.1.1s.1 0 .1-.1l.1.1h.1c0 .1.1.1.1.1h.1s0 .1.1.1v1c0 .1 0 .1.1.1 0 0 0 .1.1.1 0 .1.1.1.1.1l.1.1-.1-.1-.1-.1s0-.1-.1-.1c0 0 0-.1-.1-.1V14.5s0-.1-.1-.1c0-.1-.1-.2 0-.2H12.3l.1.1c.1.1.1.2.1.2V15.5l.1.1h.1c.2-.1.2 0 .3 0-.1 0-.1 0 0 0zm-1.3-1.4c-.1 0-.1-.1-.1-.1s0-.1-.1-.1v-.1l.1-.1h.1l.1-.1h.1v.1h-.1s-.1 0-.1.1v.1c.1.1.1.1 0 .2.1 0 .1 0 0 0zm.2.1c-.1 0-.1 0-.1-.1 0 0 0-.1-.1-.1V14l.1-.1H12.1v.1H12s-.1 0-.1.1v.1c.1-.1.1 0 0 .1.1-.1.1-.1 0 0 .1-.1.1 0 0 0zm.3-.3c-.1.1-.1.2 0 .3h-.1c-.1 0-.1 0-.1-.1V14l.1-.1h.1c-.1 0-.1 0 0 .1-.1-.1 0-.1 0-.1v.1c.1-.1.1-.1 0 0 0-.1 0-.1 0 0z'
                />
              </g>
            </g>
            <g id='XMLID_6106_'>
              <path
                class='st15'
                d='M21.5 14c.1.3.3.8.2 1.4 0-.5-.2-1.1-.2-1.4-.2.1-.4 0-.4-.1h.2c0 .1.1.1.2.1z'
              />
              <path
                class='st45'
                d='M21.4 14.1c-.1 0-.3-.1-.4-.1-.1 0-.2-.1-.3-.2.1.2.3.7.3 1.1.2.2 0 .8 0 1 0 .1.1.3.1.5 0-.1.1-.2.1-.4 0 .3 0 .5.1.7 0-.3 0-.7.2-1.3.2-.5 0-1.1-.1-1.3zm0 1.7c0-.4 0-1-.1-1.1-.1-.2-.3-.2-.3-.4v-.1s.4 0 .5.6c0 .2-.1.6-.1 1z'
              />
              <path
                class='st46'
                d='M21.7 13.9c-.1 0-.1 0-.2.1h-.2c.1 0 .1-.1.2-.1-.1-.1-.4-.2-.5-.2 0-.2-.1-.4-.2-.6v-.2l.1-.1h-.1s-.1 0-.2-.1-.1-.2-.2-.3v-.2h-.2L20 12c0-.1 0-.1-.1-.2 0 0-.1-.1-.2-.1h-.1l-.1-.1c.1-.1.1-.2.1-.2 0-.1-.1-.1-.1-.1s0-.1.1-.1c.2.1.6.4.9.8.4.5.6.8.8 1.2 0 .4.4.7.4.7zM21.5 14.7c0 .3-.1.7-.1 1.1 0-.4 0-1-.1-1.1-.1-.2-.3-.2-.3-.4.1.2.3.2.3.2 0-.2-.3-.5-.3-.5s.5 0 .5.7z'
              />
              <path
                class='st15'
                d='M21.5 16.7v.2c0 .3-.2.4-.1.6-.1 0-.1-.5-.1-.8v-.4c0-.1.1-.2.1-.4-.1.4 0 .7.1.8z'
              />
              <path
                class='st47'
                d='M21.4 13.9s-.1.1-.2.1H21c-.1 0-.2-.1-.3-.2-.1 0-.1-.1-.2-.1h.3c.2 0 .5.1.6.2zM21.3 14.3c-.1 0-.2 0-.3-.2V14l.3.3z'
              />
              <path
                class='st48'
                d='M20.8 13.8c.1.2.3.7.3 1.1l-.1-.1c-.1-.3-.3-.8-.4-1v-.1c.1 0 .1 0 .1-.1 0 .1 0 .2.1.2z'
              />
              <path
                class='st15'
                d='M21 16.7c0 .2 0 .4-.1.6 0 0 0-.4.1-.6-.1-.1-.1-.2-.1-.2.1-.3 0-.8 0-1.2v.2c.1.3.1 1 .1 1.2z'
              />
              <path
                class='st47'
                d='M21 14.8s-.1 0 0 0c-.2-.2-.4-.4-.4-1 0 .2.3.7.4 1z'
              />
              <path
                class='st45'
                d='M20.9 15.3c0 .3.1.8 0 1.2v-.1c.1-.2 0-.6-.1-1.3-.2-.2-.3-.8-.4-1.1-.1 0-.3-.2-.4-.3v-.1c0 .1.1.1.2.2 0 .1.1.1.2.1 0 .3.1.9.4 1.1.1 0 .1.2.1.3z'
              />
              <path
                class='st15'
                d='M20.9 15.3c0-.2-.1-.3-.1-.3-.3-.3-.3-.9-.4-1.1-.1 0-.1-.1-.2-.1.1 0 .2.1.2 0v.1c0 .5.2.8.4.9.1.1.1.3.1.5z'
              />
              <path
                class='st45'
                d='M20.9 13.7h-.3c0-.1-.1-.3-.2-.6-.1-.1-.2-.3-.2-.4-.1-.2-.2-.5-.4-.7.2.2.6.7.9 1l.1.1c0 .1.1.4.1.6z'
              />
              <path
                class='st46'
                d='M20.8 15.1c.1.7.2 1 .1 1.3-.1-.2-.1-.3-.2-.5 0-.2-.1-.6-.1-.8 0-.1-.1-.2-.2-.3 0-.1-.1-.1-.1-.2v-.3c0-.3-.2-.5-.3-.6v-.1c.1.1.3.3.4.3.1.3.1.9.4 1.2z'
              />
              <path
                class='st47'
                d='M20.8 12.8c0 .1-.1.1 0 0l-.1.1c0-.1-.1-.2-.1-.2 0 .1.1.1.2.1-.1 0 0 0 0 0z'
              />
              <path
                class='st15'
                d='M20.7 13c-.2-.3-.7-.8-.9-1 .2.2.3.5.4.7-.1-.2-.2-.3-.3-.4-.3-.4-.7-.6-.8-.7v-.1c.1 0 .1 0 .2-.1.1 0 .2-.1.3-.2 0 0 0 .1-.1.1l-.2.2.2.2.1.1.3.3.2.2c.1.1.1.1.1.2.1.1.2.2.2.3.2 0 .2.1.3.2zM20.5 17.4c.1.1.1.2.1.2-.1.1-.4.2-.6.1-.1-.1-.3-.2-.4-.3-.4-.3-.9-.7-1.2-1.1l-.1-.1v-.1c.2.2.3.4.5.6l.2.2c.2.2.3.3.7.6 0 0 .1.1.3.2h.4c.1-.2.1-.3.1-.3z'
              />
              <path
                class='st47'
                d='M20.4 13c.1.3.2.5.2.6 0 .1 0 .1-.1.1h-.2c-.1 0-.2-.1-.2-.2.2.1.3.2.4.1 0-.1-.1-.3-.1-.6z'
              />
              <path
                class='st15'
                d='M20.5 15.7v1.1c0-.1 0-.3-.1-.4.1-.5-.1-1.4-.1-1.6-.3-.2-.4-.7-.4-.9-.1 0-.1-.1-.2-.1.1.1.2.1.2.1 0 .1 0 .4.2.6l.1.1s0 .1.1.1c0 .1.1.1.1.2 0 .2.1.6.1.8z'
              />
              <path
                class='st45'
                d='M20.5 17.4c0 .1-.1.1-.1.2h-.1v-.1c-.1 0-.2-.2-.4-.3h.2-.3c-.1-.1-.2-.2-.3-.2h.2-.2l-.3-.3h.1-.2c-.3-.3-.6-.5-.6-.5 0 .1.3.3.6.6 0 .1.1.2.1.2l-.2-.2c-.2-.2-.4-.4-.5-.6v-.4c.3.2 1.3 1.1 1.8 1.4 0-.1.1.1.2.2zM20.4 14.8c0 .2.1 1.1.1 1.6v-.2c.1-.4-.1-1.3-.1-1.3s-.1 0-.3-.5c-.1-.3-.3-.5-.4-.6v-.2s.1 0 .1.1c0 0 .1.1.2.1-.1.3.1.9.4 1z'
              />
              <path
                class='st46'
                d='M20.4 13v.5c0 .1-.2 0-.4-.1h-.1c0 .1 0 .2-.1.2s-.1 0-.2-.1v-.1h.1v-.1l.1-.1.1-.1c.1.1.2.1.3.1.1-.4-1-1.6-1.2-1.8v-.1c.1.1.5.3.8.7.1.1.2.3.3.4.2.4.2.5.3.6zM20.3 14.9s.2.8.1 1.3c-.1-.2-.2-.5-.2-.7 0-.4-.1-.6-.2-.7-.1-.3-.3-.7-.4-.9h.1c.1.1.2.3.4.6.1.4.2.4.2.4z'
              />
              <path class='st47' d='M20.3 12.2v.2c0-.1-.1-.1-.1-.2h.1z' />
              <path
                class='st48'
                d='M20 13.7c.1.1.3.3.3.6v.3s0-.1-.1-.1c.1-.2-.1-.6-.2-.8 0 .1 0 .1 0 0z'
              />
              <path
                class='st45'
                d='M19.1 11.6c.2.2 1.4 1.4 1.2 1.8-.1 0-.2-.1-.3-.1-.1-.1-.3-.3-.4-.5-.2-.3-.4-.6-.7-.7V12c.5.3 1 1 1.1 1.2-.2-.4-.9-1-1.1-1.2.2 0 .1-.3.2-.4-.1 0 0 0 0 0zM20.3 17.5c0 .1-.1.1-.2.1H20l-.3-.2c-.4-.4-.5-.4-.7-.6v-.1l.3.3v.2s0-.1.1-.1c.1.1.2.2.3.2v.2l.1-.1c.1.1.2.2.3.2.1-.1.1-.1.2-.1z'
              />
              <path
                class='st47'
                d='M20 13.8c.1.1.3.4.2.8 0-.1-.1-.1-.1-.1-.1-.3-.1-.6-.1-.7z'
              />
              <path
                class='st15'
                d='M20.1 15.9c0 .2 0 .4.1.4l-.2-.4v-.7s.1.5.1.7z'
              />
              <path
                class='st48'
                d='M20.3 17.5s-.1 0-.1-.1c-.1-.1-.2-.1-.3-.2l-.1.1v-.2c-.1-.1-.2-.2-.3-.2l-.1.1v-.2l-.3-.3v.1s0-.2-.1-.2l-.6-.6s.3.3.6.5h.2-.1l.3.3h.2-.2c.1.1.2.2.3.2h.3-.2c.2.6.4.7.5.7z'
              />
              <path
                class='st47'
                d='M20.1 13.5V13.8s-.1 0-.2-.1c0 0-.1 0-.1-.1v.2h-.2c-.1 0-.2 0-.3-.1v-.1c.1 0 .3.1.3.1v-.4c.1.1.1.1.2.1.1.1.2.1.2.1 0 .1 0 .1.1 0-.1 0-.1 0 0 0z'
              />
              <path
                class='st15'
                d='M20 17.7c-.1.1-.2.2-.3.2H19.4c-.1 0-.1-.1-.2-.2l-.5-.5c-.2-.2-.4-.4-.5-.6l.4.4.6.6c.2.2.3.3.4.3h.1c.1-.1.2-.2.3-.2z'
              />
              <path
                class='st46'
                d='M20 13.3h-.3c-.2-.1-.5-.4-.5-.4h.2c-.3 0-.6-.4-.7-.5 0 0 .1 0 .1-.1l.1-.1c.2.1.5.4.7.7l.4.4z'
              />
              <path class='st47' d='M20 11.8c0 .1.1.2.1.2l-.3-.3h.1l.1.1z' />
              <path class='st15' d='M20 15.6v.4-.4z' />
              <path
                class='st46'
                d='M20 15.6v.4c0-.1-.1-.2-.1-.2-.1-.4-.2-.7-.4-.8-.1-.3-.4-.7-.5-.8 0 0 .1 0 .1-.1.1.2.4.7.7.9 0 0 .1.1.2.3 0 .1 0 .2.1.3-.1-.1-.1-.1-.1 0z'
              />
              <path
                class='st45'
                d='M20 17.7c-.1.1-.2.1-.4.1h-.1c-.1 0-.2-.1-.4-.3l-.6-.6-.4-.4v-.2l.6.6c.1.1.3.3.7.6 0 0 .1.1.2.1s.1.1.2.1.1-.1.2 0c0-.1 0-.1 0 0zM20 15.2v.3-.1c0-.1-.1-.2 0-.2z'
              />
              <path
                class='st15'
                d='M20 15.2c-.1-.2-.1-.3-.1-.4-.3-.3-.4-.7-.4-.8-.1 0-.2-.1-.3-.2.2.1.3.1.3.1v.1c0 .1 0 .3.2.5l.1.1c.1 0 .1.1.1.1 0 .2 0 .4.1.5z'
              />
              <path
                class='st48'
                d='m19.9 14.8-.1-.1c0-.2-.3-.5-.3-.6V14h.1c0 .1.3.5.3.8z'
              />
              <path
                class='st45'
                d='M19.9 15.2c-.1-.2-.2-.3-.2-.3-.2-.2-.5-.7-.7-.9v-.2c.1 0 .2.1.3.2 0 .1.1.6.4.8.2.2.2.3.2.4z'
              />
              <path class='st47' d='m19.9 13.4-.1.1v.1l-.2-.3h.1l.2.1z' />
              <path
                class='st46'
                d='M20 17.6c-.1 0-.1.1 0 0h-.3c-.1-.1-.2-.1-.2-.1-.4-.3-.6-.5-.7-.6l-.6-.6v-.1l.1.1c.4.4.8.8 1.2 1.1.2 0 .3.2.5.2z'
              />
              <path
                class='st45'
                d='M19.8 13.6h-.1v.1c-.1 0-.1-.1-.2-.1-.3-.3-.7-.6-.8-.8v-.1c.3.1 1.1.8 1.1.9z'
              />
              <path
                class='st15'
                d='m19.6 13.3.2.3-1-1v-.2l-.1-.1h.1c.1.1.5.5.7.5h-.2s.3.4.5.4l-.2.1zM19.8 16l-.1-.1c0-.2-.1-.6-.1-.6-.1-.2-.1-.2-.2-.3-.2-.2-.4-.5-.5-.6-.1-.1-.1-.2-.1-.3h.1s0 .1.1.1c.1.1.2.3.3.4l.2.2c.1.1.1.3.1.5.1.3.2.5.2.7zM20.1 13.2c-.1-.1-.6-.8-1.1-1.2v.1s0 .1-.1.1v-.1c0-.1-.1-.3-.1-.3l-.1-.1s.2 0 .3-.1v.3c.3.3 1 .9 1.1 1.3z'
              />
              <path
                class='st47'
                d='m19.8 14.7-.1-.1c-.2-.2-.2-.4-.2-.5 0 0 .3.4.3.6z'
              />
              <path
                class='st46'
                d='M19.6 15.2s.1.4.1.6c-.1-.1-.2-.3-.2-.4 0-.2-.1-.4-.3-.6l-.1-.1-.5-.5v-.1c.1.1.2.3.3.4.1.1.3.3.4.3l.1.1c.1.1.1.2.2.3zM19.2 10.9c-.3-.1-.5-.3-.8-.5-.5-.2-1-.4-1.5-.4v.1c.1 0 .3 0 .4.1.1 0 .4.1.5.3.1.1.2.3.2.3s-.1-.1-.2-.3c-.2-.2-.3-.2-.4-.2h-.2l.1.1h.1s.1 0 .5.4c.1 0 .4.2.5.1 0 .1.3.2.4.2 0 0 .1 0 .1-.1l.2-.2c.1-.1.2-.1.2-.1s.1 0 .1.1c.1-.1.1-.2.1-.2s-.1.4-.3.3zm-.8.1c0 .1 0 .1 0 0-.2 0-.2-.2-.3-.4v-.1l-.1-.1s.2 0 .3.2l.2.2c-.1.1 0 .2-.1.2zm.4.3s0-.1-.1-.3c-.1-.2-.1-.2-.1-.3 0 0 .2.1.2.3.1.2 0 .3 0 .3z'
              />
              <path
                class='st47'
                d='M19.6 11.3c0 .1 0 .2-.1.2-.1-.1-.2-.1-.2-.2.1.1.1 0 .3 0-.1-.1-.1 0 0 0z'
              />
              <path
                class='st48'
                d='M19.6 14.9s-.1 0 0 0c-.1 0-.1 0 0 0-.1-.1-.2-.2-.3-.2-.1-.2-.2-.4-.3-.4 0 0 0-.1-.1-.1h.1c.2 0 .4.4.6.7z'
              />
              <path
                class='st47'
                d='M19.5 11.1c-.1.1-.1.1-.3.2-.1 0-.1 0-.2.1H18.7s.1 0 .1-.1l.2-.2c.1-.1.2-.1.2-.1.2 0 .3 0 .3.1z'
              />
              <path
                class='st46'
                d='M19.5 13.5v.3c0-.1-.1-.2-.1-.3-.2-.1-.5-.5-.6-.6v-.1h.1s.3.4.6.7z'
              />
              <path
                class='st15'
                d='M19.5 17.8c-.2.2-.3.1-.5 0-.1 0-.1-.1-.2-.2a5 5 0 0 1-.7-.8v-.2c.1.2.3.3.4.4l.5.5.1.1c.1.1.1.1.2.1 0 .1.1.1.2.1z'
              />
              <path
                class='st45'
                d='M19.5 13.7c-.1 0-.2-.1-.3-.2 0 0-.5-.5-.5-.6v-.2c.1.2.4.6.6.6.1.3.1.4.2.4z'
              />
              <path
                class='st46'
                d='M19.5 13.8c-.1.1-.2 0-.3 0-.1 0-.1 0-.1-.1 0 0 0-.1-.1-.3 0 0 .2.1.3.2l.2.2z'
              />
              <path
                class='st45'
                d='M19.4 17.7s0 .1 0 0c0 .1-.1.1-.2.1s-.2-.1-.2-.1l-.1-.1-.5-.5c-.1-.1-.2-.3-.4-.4v-.1c.1.1.1.2.2.3l.6.6.2.2h.4zM19.4 14.9l-.1-.1c-.1 0-.3-.3-.4-.3-.1-.1-.2-.3-.3-.4h.2c0 .1.1.2.1.3.1.1.4.3.5.5z'
              />
              <path
                class='st15'
                d='M19.3 15.7s-.1 0-.1-.1-.1-.2-.1-.2c0-.1 0-.3-.3-.6-.2-.2-.3-.3-.3-.4.1.1.2.3.4.4.1.2.2.4.2.5 0 .1.2.4.2.4zM19.2 13.6c-.1-.1-.5-.5-.6-.4l-.1.1c-.1.1-.2.1-.2.1s.1.2.2.3c.1.1.1.2.2.2v.1l-.4-.4.1.5c-.1-.1-.2-.3-.2-.4 0 0-.1 0-.1.1l.1-.1c0-.1.1-.2 0-.3v-.1s.1 0 .2-.1c.1 0 .1-.1.1-.2V12.7h.2V13s.5.5.5.6z'
              />
              <path
                class='st46'
                d='m19.2 17.6.2.2c-.1 0-.2 0-.3-.1-.1-.1-.2-.1-.2-.2l-.6-.6c-.1-.1-.2-.2-.2-.3v-.1s0 .1.1.1c.2.2.4.4.5.6.2 0 .3.2.5.4z'
              />
              <path
                class='st45'
                d='M19.2 13.5c-.1 0-.2-.1-.3-.1.1.2.1.3.1.3 0-.1-.1-.2-.3-.3-.1-.1-.1-.1-.1-.2l.1-.1.5.4z'
              />
              <path class='st46' d='m19.2 15.6-.2-.2c.1.1.2.1.2.2z' />
              <path class='st47' d='M19 14.2c.1.1.2.3.3.4-.1-.1-.2-.2-.3-.4z' />
              <path
                class='st47'
                d='M19.2 13.8c-.1 0-.1 0 0 0v.3s0 .1-.1.1H18.8s0-.1-.1-.1c.2 0 .4 0 .2-.1v-.1l-.2-.2c.1 0 .1 0 .3.1 0-.1-.1-.2-.1-.3.1.1.2.3.3.3-.2-.1-.1-.1 0 0z'
              />
              <path
                class='st46'
                d='m19.2 15.6-.2-.2-.7-.9h.2c0 .1.1.2.3.4.3.2.3.5.3.6 0-.2.1 0 .1.1z'
              />
              <path
                class='st47'
                d='M19.1 11.5v.1c-.1.1-.3 0-.3.1 0 0 .1 0 .1.1 0 0 .1.1.1.3v.1l-.1.1h-.2l-.1-.1v-.1H18.9v-.2c0-.1-.1-.2-.1-.2l-.1-.1H19l.1-.1v-.1h.1c-.1 0-.1 0-.1.1z'
              />
              <path
                class='st48'
                d='m19.1 14.7.1.1c-.1 0-.1-.1-.2-.1-.1-.1-.3-.4-.4-.5v-.1c.1.2.4.6.5.6z'
              />
              <path
                class='st46'
                d='m18.3 14.4.7.9-.3-.3c-.2-.1-.4-.4-.4-.6zM19 11.4v.1c0 .1-.1.1-.1.1H18.6l.1.1s.1.1.1.2.1.2 0 .2H18.5v.1l-.1-.1s-.1 0-.1-.1h.1v-.1l-.2-.2s-.1-.1 0-.2l.1-.1c.1 0 .1.1.2.1 0 0-.1 0-.1-.1.1.1.3.1.3 0 .2.1.2.1.3 0-.1 0 0 0 0 0z'
              />
              <path
                class='st47'
                d='M18.9 14.7c-.2-.1-.4-.3-.5-.4 0 0 .1 0 .1-.1.1.2.3.4.4.5z'
              />
              <path
                class='st45'
                d='M18.9 11.4v.1c-.1.1-.2.1-.3 0-.1-.1-.3-.3-.5-.4.1.1.7.3.8.3z'
              />
              <path
                class='st15'
                d='M18.9 18.4c.1.1.1.4-.2.4 0-.1.2-.2-.1-.3v-.1l.1-.1c.1 0 .2 0 .2.1z'
              />
              <path
                class='st45'
                d='M19 17.8s-.1 0-.1.1c-.1 0-.1 0-.2-.1l-.3-.3-.3-.3V17c.2.2.5.6.7.8.1-.1.1-.1.2 0z'
              />
              <path
                class='st15'
                d='M18.9 11.3s0 .1 0 0c0 0-.6-.2-.8-.3.1.2.3.3.5.4l.1.1c-.1 0-.1-.1-.2-.1-.2-.1-.3-.1-.4-.2.1.2-.1.1 0 .3.1.1.2.4.3.4-.1 0-.2-.1-.2-.1 0 .1.1.3.2.4l.1.1c0 .1-.1.1-.2.1h-.1v-.2c-.2.1-.3-.1-.4-.2.1.1.2.1.3.1v-.2c0-.1 0-.2-.1-.3v-.1c-.2.1-.3-.1-.3-.1.1.1.2.1.3.1.1-.1 0-.2 0-.2 0-.1-.1-.2-.1-.2 0 .1-.2.1-.2.1h-.1s.2 0 .3-.1V11c-.1-.2-.2-.3-.3-.3 0 0 .1 0 .5.4.1 0 .4.2.5.1-.2 0 .1.1.3.1z'
              />
              <path
                class='st46'
                d='M18.9 13.9c.1.1-.1.1-.3.1l.1.1h-.1v.1-.2l-.1-.5.4.4z'
              />
              <path
                class='st47'
                d='M18.9 11.1c0 .1 0 .2-.1.2 0 0 0-.1-.1-.3-.1-.2-.1-.2-.1-.3 0 0 .2.2.3.4z'
              />
              <path
                class='st46'
                d='M18.7 13.3c0 .1.1.3.1.3-.1 0-.2 0-.3-.1-.1-.2-.2-.3-.2-.3.1 0 .1-.1.2-.1l.2.2z'
              />
              <path
                class='st47'
                d='M18.8 12.4c0 .1.1.2 0 .2v.1H18.5v.1c0 .1.1.1 0 .2 0 .1-.1.2-.1.2-.1 0-.2 0-.2.1v.4l-.1.1s-.1.1-.2.1h-.1v.1c0 .1 0 .1-.1.2s-.1.1-.3.2h-.1s0-.1-.1-.1h.2s.2-.1.2-.2c0 0 0-.1-.1-.2v-.1s-.1-.1-.1-.2l.1.1v.1c.1.1.1.1.2.1s.2-.1.2-.1 0-.1-.1-.2c0-.1-.1-.2-.1-.2v-.1h.1s.1 0 .1.1.1.1.1.1h.1c.1 0 .1-.1.1-.1s0-.1-.1-.2v-.1h.1s.1 0 .2-.1v-.1s0-.1-.1-.2l.5-.3c-.1-.1-.1-.1 0 0z'
              />
              <path
                class='st15'
                d='M18.9 17.8h-.2c-.1-.1-.4-.4-.5-.6v-.1c.1.1.2.3.3.3.1.2.2.3.3.3 0 .1 0 .1.1.1z'
              />
              <path
                class='st5'
                d='M18.7 18.1h-.4l-.1-.1s0-.1-.1-.1c.1 0 .1.1.1.1l-.1.1s-.1 0-.1-.1V17.7 18l.1.1c0 .1.1.1.1.1 0 .1.1.1.1.1s0-.1.1-.1h.1c.1-.1.1-.1.2-.1v.2c.1-.1 0-.2 0-.2zm-.5 0c-.1 0-.1 0 0 0-.1 0-.1 0-.1-.1 0 0 0-.1.1-.1 0 0 .1 0 .1.1-.1 0-.1 0-.1.1zm.2.1c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.2 0-.2-.1s0-.1.1-.1h.1v.2c0-.1 0 0 0 0z'
              />
              <path
                class='st37'
                d='M18.7 18.1c0 .1.1.1.1.2l-.1.1v.1c-.1.1-.1.1-.3-.1-.2-.2-.4-.1-.6-.1-.1 0-.2.1-.2.2l-.1.1h-.2c0-.1-.1-.2-.1-.2 0-.1.1-.1.2-.2 0 .1.1.1.2.1 0 0 .1 0 .1-.1v-.1h.1s.1 0 .1-.1c0 0 .1 0 .1-.1v-.6c.1.1.2.2.2.3V17.9l.1.1c0 .1.1.1.1.1 0 .1.1.1.1.1h.1s0-.1.1-.1h.1-.1z'
              />
              <path
                class='st46'
                d='M18.7 12.5v.1c0 .1-.1.1-.2.1h-.1v.1l.1.2c0 .1-.1.1-.1.1h-.1s.1-.1.1-.2v-.4c.1.1.2 0 .3 0-.1.1-.1 0 0 0-.1-.1-.1-.2-.2-.3 0 0 0 .1.1.1.1.1.1.1.1.2z'
              />
              <path
                class='st45'
                d='M18.5 12.2c.1.1.1.2.1.3 0 0 0 .1-.1.1-.1.1-.2.1-.3 0 0 0 0-.1-.1-.1.1 0 .2 0 .2-.1 0 0 0-.1-.1-.1-.1-.1-.1-.2-.2-.4l.2.1c-.1 0-.2-.3-.3-.4-.1-.1 0-.1 0-.3l.4.2s-.1 0-.1.1v.2c0 .1.1.1.2.2 0 0 .1.1 0 .1h-.1c.1 0 .1 0 .2.1z'
              />
              <path
                class='st5'
                d='M18.6 18.5c-.2.1-.2.1-.4-.1s-.4-.1-.5-.1h-.1c-.1 0-.1.1-.2.2 0 .1-.1.1-.1.1h-.2.2l.1-.1c.1-.1.1-.2.2-.2s.4-.1.6.1c.3.1.3.1.4.1 0 0 0-.1 0 0z'
              />
              <path
                class='st47'
                d='M18.6 14V14.3s0 .1-.1.1h-.1c.1-.1 0-.2 0-.2l-.2-.2h.2c0-.1-.1-.3-.1-.4.1.1.2.3.3.4zM18.3 10.8c.1.1.1.2.1.3h-.1c-.1 0-.1-.2-.2-.4s-.3-.2-.3-.2.2 0 .3.2c.1-.1.2 0 .2.1z'
              />
              <path
                class='st37'
                d='M18.4 18s0 .1 0 0v.2h-.1s-.1 0-.1-.1c0 0 0-.1.2-.1-.1 0-.1 0 0 0z'
              />
              <path
                class='st46'
                d='M18.3 13.6c0 .1.1.3.1.4h-.2c-.1-.1-.1-.2-.1-.2s.1-.1.1-.2h.1zM18.3 14.2s.1.1 0 .2H18v-.2c0-.1 0-.2-.1-.2.2 0 .4.2.4.2z'
              />
              <path
                class='st45'
                d='M18.3 12.7c0 .1.1.2 0 .3 0 .1-.1.1-.1.2l-.1-.1.1.1v-.3c0-.1-.1-.2-.1-.2v-.1h.1c0-.1.1 0 .1.1 0-.1 0 0 0 0z'
              />
              <path
                class='st15'
                d='m18.2 14 .2.2s-.3-.2-.4-.2h-.1c-.1.1-.2.1-.1.2l.1.2c-.1-.1-.2-.3-.3-.2v-.1h.1c.1 0 .2-.1.3-.2.1-.1.1-.1.1-.2v-.1h.1c.1 0 .1 0 .2-.1-.1 0-.1.1-.1.2-.2.2-.2.2-.1.3z'
              />
              <path
                class='st45'
                d='M18.2 15.6v.8c0-.3 0-.6-.2-1 .1.3.1.9.1.9 0-.2-.2-.7-.3-.9 0-.3-.1-.5-.1-.6 0-.2.2.2.2.2v-.2c.2.2.3.5.3.8z'
              />
              <path
                class='st15'
                d='M18.2 15.6zM18.2 15.6c-.1-.3-.2-.6-.3-.8v.2s-.2-.5-.2-.2c0 .1 0 .4.1.6v.2c-.1-.2-.1-.4-.1-.5v-.6c0-.2-.1-.3-.1-.4.1.1.3.2.4.4v.1c.1.4.2.7.2 1z'
              />
              <path
                class='st37'
                d='M18.2 17.9c0 .1 0 .1 0 0 0 .1-.1.1-.1.1-.1 0-.1 0-.1-.1 0 0 0-.1.1-.1 0 .1 0 .1.1.1z'
              />
              <path
                class='st15'
                d='M18.2 12.9c0 .1.1.2 0 .3l-.1-.1-.1-.1h-.1v-.1c-.2 0-.2-.2-.2-.2 0 .1.1.1.2.1h.1s.1 0 .1-.1c0 0 0 .1.1.2z'
              />
              <path
                class='st46'
                d='M18.1 13.2c.1.1.1.2.1.2 0 .1-.1.1-.2.1l.1-.1v-.2s-.1 0-.1-.1c0 0 0-.1-.1-.1l.2.2zM18 15.4c.1.4.2.8.2 1-.1.1-.1 0-.1-.1v.2s-.1 0-.2-.1v.3h-.1s-.1 0-.1-.1c-.1-.1-.2-.3-.3-.4 0-.2-.1-.3-.2-.5.1.2.5.8.5 1 0-.1-.1-.4-.2-.7.1.1.2.3.3.4V15.8c.1.2.2.6.2.6v-.3c0-.1 0-.3-.1-.5v-.2c.1.2.3.7.3.9-.1 0-.1-.6-.2-.9z'
              />
              <path
                class='st46'
                d='M18.1 12.3V12.6s0 .1-.1.1h-.1c-.1 0-.1 0-.2-.1 0 0 0 .3.2.2v.2s0 .1-.1.1h-.2c-.1 0-.1-.1-.1-.1s0 .2.2.2c0 0 0 .1-.1.1l-.1.1c-.1 0-.2-.1-.2-.1s.1.2.2.2c0 .1-.1.1-.1.1 0-.1-.1-.1-.1-.2s-.1-.1-.1-.2 0-.2-.1-.3c.1-.1.3-.2.4-.4.1-.3.1-.6 0-.9 0-.1-.1-.3-.1-.4-.1-.1-.2-.2-.3-.4-.1-.1-.2-.1-.3-.2-.1-.1-.3-.1-.4-.3l-.1-.1v-.1c0-.2.3-.2.5-.2h.2v.1h-.2c-.1 0-.2.1-.2.2s.2.2.2.2c.2.1.3 0 .5 0s.4.2.4.2l-.2-.2-.1-.1h.1c.1.1.3.2.3.3v.1c-.1.1-.3.1-.3.1h.1c.1 0 .2 0 .2-.1.1.1.1.1.1.2v.2c-.1 0-.2 0-.3-.1 0 0 .1.2.3.1v.1c.1.1.1.2.1.3v.2c-.1 0-.2 0-.3-.1 0 .5.2.7.4.7 0-.1 0-.1 0 0z'
              />
              <path
                class='st15'
                d='M18.1 17.4v.2s-.1 0-.1-.1c0-.2-.2-.6-.2-.7v-.3c.1.1.1.1.2.1 0 .1-.1.2 0 .4.1.3.1.4.1.4z'
              />
              <path
                class='st45'
                d='M18.1 17.2v.2s-.1-.1-.1-.3c-.1-.2 0-.3 0-.4v.4c.1 0 .1 0 .1.1z'
              />
              <path
                class='st47'
                d='M18.1 14.4c0 .2 0 .3-.1.4v-.1s.1-.1.1-.2v-.3.2z'
              />
              <path
                class='st37'
                d='M18.1 17.8s0 .1 0 0c0 0-.1.1-.1.2 0 0-.1 0-.1-.1V17.6s.1 0 .1.1v-.1c0 .1.1.2.1.2z'
              />
              <path
                class='st45'
                d='M18.1 13.3v.2c0 .1 0 .1-.1.1 0 0-.1 0-.2-.1v-.1h.1v-.2c0-.1 0-.1-.1-.2l.1-.1s0 .1.1.1c.1.2.1.3.1.3z'
              />
              <path
                class='st48'
                d='M18 14.2v.3c0 .1 0 .1-.1.2v-.3c0-.1 0-.2-.1-.3h.1c.1 0 .1 0 .1.1z'
              />
              <path
                class='st45'
                d='M17.8 16.8c.1.1.2.5.2.7l-.1-.1c0-.2-.1-.4-.1-.6z'
              />
              <path
                class='st47'
                d='M17.9 10.6c.1.1.2.3.2.3s-.1-.1-.2-.3c-.2-.2-.4-.2-.4-.2h-.2l.1.1.1.1.2.2s-.1-.1-.4-.2c-.3-.1-.3.1-.5 0 0 0-.2-.1-.2-.2s.2-.1.2-.2h.2c.1 0 .3 0 .4.1.1 0 .3.1.5.3z'
              />
              <path
                class='st15'
                d='M18 17.7c-.1 0-.1 0 0 0-.1 0-.1 0 0 0 0-.1-.1-.2-.1-.3-.1-.2-.2-.5-.2-.6h.1c0 .1.1.4.1.6 0 .1.1.2.1.3zM17.9 13.3c0 .1.1.2 0 .2h-.1c-.1 0-.1-.1-.1-.1s0-.1.1-.1c-.2 0-.2-.2-.2-.2s0 .1.1.1h.2v.1z'
              />
              <path
                class='st45'
                d='M17.9 17.7c.1 0 .1 0 0 0l-.3-.3c0-.1-.1-.2-.1-.4v-.3s.1 0 .1.1.1.3.2.6c.1.1.1.2.1.3z'
              />
              <path
                class='st46'
                d='M17.9 14.3v.3c-.1-.2-.3-.3-.4-.4.1-.1.2.1.3.2 0-.1-.1-.2-.1-.2s.1-.1.1-.2c.1.1.1.2.1.3z'
              />
              <path
                class='st45'
                d='M17.9 16.2v.3s-.1-.4-.2-.6c0-.3-.1-.6-.1-.7h.1c0 .1.1.3.1.5.1.2.1.3.1.5z'
              />
              <path
                class='st46'
                d='M17.8 13.6c.1.1.1.2.1.2 0 .1-.2.2-.2.2h-.2l-.2-.2v-.1l.1.1c.1.1.2.1.2 0 .1 0 .1 0 .2-.2-.1.1 0 0 0 0z'
              />
              <path
                class='st5'
                d='M17.7 17.8c0 .1 0 .1 0 0v.2h-.1s0-.1-.1-.1h-.1v-.2l-.1.1c-.1 0-.1.1-.2.1h-.5l-.1.1s0-.1.1-.1h.2v.1c0 .1.1.1.1.1-.1 0-.1 0-.1.1h-.1s-.1 0-.1-.1l.1.1h.4v-.1h.1s.1 0 .1-.1c0 0 .1 0 .1-.1.4 0 .4-.1.3-.1zm-.4.1c.1-.1.1-.1.2-.1v.1c-.1 0-.1.1-.2 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 0 0 0zm0 .2c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zm-.2-.1s-.1 0 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0l.1-.1h-.1v.1zm.3.3c-.1 0-.1 0 0 0h-.1c-.1 0-.1 0-.1-.1v-.1c0-.1 0 0 0 0h.1v.1s.1 0 .1.1c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0zm.1-.2h-.2l.1-.1h.1c.1 0 .1 0 0 .1.1 0 .1 0 0 0z'
              />
              <path
                class='st45'
                d='M17.7 16.1c0 .2.1.3 0 .4 0-.1-.2-.3-.3-.4-.1-.2-.1-.4-.2-.5.1 0 .1.1.2.1 0 0 .1 0 .1-.1.1.1.2.3.2.5z'
              />
              <path
                class='st37'
                d='M17.7 17.9c0 .1-.1.1-.1.1s0-.1-.1-.1h-.1v-.2c.2.1.3.2.3.2z'
              />
              <path
                class='st45'
                d='M17.7 17.8c0 .1 0 .1 0 0-.1 0-.1 0-.2-.1 0-.1-.1-.1-.1-.2 0 .1 0 .2-.1.2-.1-.1-.3-.6-.3-.7l.1.1s.1-.1.1-.2c.1.1.3.3.3.4v-.2s.1.2.2.3v.4zM17.8 13.6v.2c0 .1-.1.1-.1.1h-.2l-.1-.1c.1 0 .1 0 .2-.1 0 0 0-.1-.1-.1l-.2-.2s.1.1.2.1.1 0 .1-.1l.2.2z'
              />
              <path
                class='st15'
                d='M17.7 15.9v.2c0-.2-.1-.4-.2-.5v-.4h.1c0 .1.1.4.1.7z'
              />
              <path
                class='st49'
                d='M17.7 14.7v.5h-.2v.4s0 .1-.1.1-.1 0-.2-.1l-.1-.1-.1-.1c-.1-.1-.1-.2-.2-.2-.1-.1-.1-.2-.2-.3 0-.1-.1-.2-.1-.2.2.2.4.6.6.8 0 0 0-.1-.1-.1 0-.1-.1-.2-.1-.4v-.1c.1.1.2.1.2.2.1.1.3.2.3.2.1 0 0-.5-.1-.7v-.1.1c.3-.2.3-.1.4.1 0-.1 0 0 0 0z'
              />
              <path
                class='st15'
                d='M17.7 17.4v.1c-.1-.2-.2-.3-.2-.3v.2c0-.2-.2-.4-.3-.4v-.1c.1.1.3.2.3.2.1.1.1.2.2.3z'
              />
              <path
                class='st37'
                d='M17.6 18v.2h-.2c0-.2 0-.2.2-.2-.1 0 0 0 0 0z'
              />
              <path
                class='st15'
                d='M17.5 16.1c.1.3.2.7.2.7-.1-.5-.4-.9-.5-1.1v-.3l.1.1c.1.2.1.4.2.6z'
              />
              <path
                class='st46'
                d='M17.5 14.4c.1.2.1.7.1.7s-.2-.1-.3-.2c-.1-.3-.3-.5-.4-.6v-.1s.1 0 .1.1c.1.2.4.6.5.7.1.1 0-.4 0-.6zM17.6 16.8v.3c-.1.1-.2 0-.3-.2v.1c0 .1 0 .2-.1.2 0 0-.1 0-.1-.1l-.3-.3c-.2-.2-.3-.5-.4-.7-.4-.2-.9-1-1.1-1.2 0 0 0-.1.1-.1.1.1.1.2.2.3.1.2.4.7.8.9 0 0 .1 0 .1.1.1.2.3.6.7.8-.1-.1-.4-.5-.4-.7v-.1c.1.1.3.5.4.6.1.1.1 0 .1-.2 0 .1.1.1.1.1-.1 0 0 .5.2.2z'
              />
              <path
                class='st45'
                d='M17.6 16.8c-.1.3-.3-.2-.3-.2v-.2c.1.1.2.3.3.4z'
              />
              <path
                class='st15'
                d='M17.7 14.6c0-.1-.1-.2-.1-.3v-.1c-.1-.1-.2-.2-.2-.3v-.1h.1l.2.2s0 .1.1.1v.1c-.2.1-.2.2-.1.4zM17.6 13.7c-.1.1-.1.1-.2.1 0-.1.1-.1.2-.1-.1-.1 0 0 0 0z'
              />
              <path
                class='st45'
                d='M17.5 14.4v.6c-.1-.1-.3-.5-.5-.7.1.1.3.2.4.5-.1-.2-.3-.6-.2-.8.1.1.1.2.2.3.1 0 .1 0 .1.1z'
              />
              <path
                class='st47'
                d='M17.5 11.8c.1.3.1.6 0 .9-.1.2-.3.4-.4.4V13s.1 0 .1-.1c.1-.1.2-.2.2-.3v-.8c0-.2-.1-.4-.2-.5h.1l.1.1c0 .1.1.3.1.4z'
              />
              <path
                class='st37'
                d='M17.5 17.8c0 .1 0 .1 0 0 0 .1 0 .1 0 0v.1c-.1 0-.1 0-.1.1 0-.2 0-.2.1-.2z'
              />
              <path class='st45' d='M17.2 15.7c.1.2.4.6.5 1.1l-.5-1v-.1z' />
              <path
                class='st37'
                d='M17.4 18.2c0 .1-.1.1-.1.1-.1 0-.1 0-.1-.1v-.1h.1l.1.1c-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0z'
              />
              <path
                class='st47'
                d='M17.4 12.2c0 .1 0 .1 0 0v.1c-.1-.2-.3-.4-.3-.4l.3.3z'
              />
              <path
                class='st48'
                d='M17.4 12.4v.2c-.1.1-.1 0-.2-.1v.4l-.1.1v-.2c0-.2 0-.3-.1-.3 0 0 .1 0 .1-.1s-.1-.2-.2-.4v-.2c0-.2-.1-.4-.2-.4-.1-.2-.4-.2-.5-.3-.2-.1-.2 0-.3-.1-.1-.1-.1-.3 0-.3 0-.2.2-.2.2-.3-.1.3-.1.4-.1.4.1.1.1 0 .4.1h.2c.1 0 .2.1.3.1 0 0 .1.1.2.1H17s.1 0 .2.1c0 0 .1.1.1.2 0 0 .1.1.2.3 0 0-.1-.1-.2-.1 0 0 .2.2.2.4 0 .1-.1.1-.2.1-.2-.1 0 .1.1.3z'
              />
              <path
                class='st45'
                d='M17.3 11.8v.4c-.1-.1-.2-.2-.3-.2.1 0 .2 0 .2-.1s0-.2-.2-.4c.1 0 .2.1.2.1.1.1.1.1.1.2z'
              />
              <path class='st37' d='M17.3 18c0 .1 0 .1 0 0z' />
              <path
                class='st47'
                d='M17.1 11.6c.2.2.3.3.2.4 0-.2-.2-.4-.2-.4z'
              />
              <path
                class='st15'
                d='M17.3 17.8c-.2 0-.3-.3-.3-.5v-.2c.1.1.2.5.3.7zM17.3 16.4v.2s-.1 0-.1-.1c-.2-.2-.4-.7-.5-.8.1.1.2.2.4.2.1.1.2.3.2.5z'
              />
              <path
                class='st47'
                d='M17.3 11.8v-.1c-.1-.2-.2-.3-.2-.3 0-.1-.1-.1-.1-.2 0 0 .1 0 .1.1.1.1.2.3.2.5z'
              />
              <path
                class='st45'
                d='M17.3 13.9s-.1 0 0 0h-.2c0-.2-.1-.4-.1-.5h.1l.2.2c-.1.1 0 .1 0 .3 0-.1 0-.1 0 0z'
              />
              <path
                class='st15'
                d='M17.4 14.8c-.1-.2-.3-.4-.4-.5 0 0-.1 0-.1-.1v-.1s.1 0 .1.1v-.3h.1v.1c0 .2.3.5.3.8z'
              />
              <path
                class='st45'
                d='M17.1 12.5c.1.1.2.2.2.1 0 .1-.1.2-.2.3.1-.2.1-.3 0-.4z'
              />
              <path
                class='st37'
                d='M17.3 18s-.1.1 0 0l-.1.1c-.1 0-.1 0-.1-.1v-.1h.1l.1.1c-.1-.1-.1-.1 0 0-.1-.1 0-.1 0 0 0-.1-.1 0 0 0z'
              />
              <path
                class='st45'
                d='M17.1 11c.1.1.2.2.3.4l-.1-.1h-.1c-.1-.2-.2-.3-.4-.4.1 0 .2 0 .3.1z'
              />
              <path
                class='st46'
                d='M17.2 15.2c0 .1 0 .1.1.1-.1-.2-.4-.5-.6-.8v-.1c.1.3.4.7.5.8z'
              />
              <path
                class='st45'
                d='M17.2 16.5c0 .1 0 .2-.1.2-.1-.1-.3-.5-.4-.6v-.4c.1.1.3.6.5.8z'
              />
              <path class='st47' d='M17.1 12.5c.1.1.1.3 0 .4v-.4z' />
              <path
                class='st49'
                d='M17.2 15.4v.4c-.1 0-.2-.1-.4-.2l-.1-.1c-.2-.2-.3-.5-.4-.6v-.1c.1.2.4.8.8.9 0 0 0-.1-.1-.1-.1-.1-.2-.4-.2-.5l.2.2c0 .1.1.1.2.1-.1 0 0 0 0 0z'
              />
              <path
                class='st45'
                d='M16.9 14.3c.1.1.3.4.4.6l-.2-.2c-.1-.1-.1-.3-.2-.4z'
              />
              <path
                class='st15'
                d='M17.1 18.6c-.1 0-.1.2-.1.3-.1-.1-.2-.2-.1-.3 0-.1.1-.1.1-.2.1 0 .1.1.1.2z'
              />
              <path
                class='st45'
                d='M17.1 14.9c0 .1.1.3.1.4-.2-.2-.4-.6-.5-.8v-.1c.1.1.3.3.4.5zM17.1 13.4c0 .1-.1.1 0 0-.2-.2-.3-.7-.4-.9.1.1.2.3.3.5.1.3.1.4.1.4z'
              />
              <path
                class='st15'
                d='M17.1 13.4s.1.1.1.2c-.1-.1-.2-.1-.1-.2-.1.1-.1.1 0 0-.1.1 0 .1 0 0zM17.1 14.7v.1c-.1-.2-.3-.3-.4-.4V14l.1.1v.2c.2.1.2.3.3.4z'
              />
              <path
                class='st48'
                d='M17.1 13.9v.3c0-.2-.1-.6-.1-.8 0 .1.1.3.1.5z'
              />
              <path
                class='st45'
                d='M16.9 13.4c0 .2.1.5.1.8 0 0-.1 0-.1-.1l-.1-.1v-.4h-.1v-.2s.2.3.2.5v-.5zM17.2 11.3c-.1 0-.1 0-.1-.1-.1 0-.2 0-.2-.1h.2s-.1-.2-.3-.2H16.5h.1s-.2-.1-.4-.1h-.3s.1-.1.2-.1h.1c-.1-.1-.2-.1-.2-.1H16.3c.1.1.3.2.4.3.2 0 .4.2.5.4z'
              />
              <path
                class='st37'
                d='M17.2 18.1c-.1 0-.2.1-.2.2h-.1s-.1 0-.1-.1V18s0-.1.1-.1h.2v.1c-.1.1 0 .1.1.1z'
              />
              <path
                class='st47'
                d='M16.7 11c.2 0 .3.2.3.2h-.1c-.1 0-.1-.1-.2-.1-.1-.1-.2-.1-.3-.1.2-.1.2-.1.3 0z'
              />
              <path
                class='st46'
                d='M17 13.5c-.1-.1-.1-.1 0 0h-.2c-.1 0-.1-.2-.2-.3 0 .1 0 .2-.1.3h-.1c.2-.3.1-.8 0-.9h.1v.1c.3.1.4.5.5.8z'
              />
              <path
                class='st15'
                d='M17 13v.1c-.2-.2-.3-.4-.3-.5v-.2c.1 0 .1.1.2 0 0 .1 0 .2.1.3v.3z'
              />
              <path class='st46' d='M17 17.1v.2c-.1-.1-.2-.1-.3-.2v-.3l.3.3z' />
              <path
                class='st49'
                d='M17 12.3c0 .1-.1.1-.1.1h-.2v.1H16.4c0 .2-.1.3-.1.4h-.1c-.1 0-.1-.1-.2-.2h-.1v-.5s.1-.1 0-.1h.1s.1.2 0 .3c.1-.1.1-.2.1-.2s0 .4.1.5c.1.1.1-.4.2-.4 0 .1.2.2.2.1l.1-.1h.3z'
              />
              <path
                class='st50'
                d='M17 12.3c-.1.1-.3 0-.3 0s0 .1-.1.1c-.1.1-.2 0-.2-.1 0-.2-.1-.4-.2-.5l-.1-.1c.1.1.2.2.3.4 0-.1-.2-.4-.2-.5l.4.4c0-.2-.1-.3-.1-.3s.3.5.5.6z'
              />
              <path
                class='st46'
                d='M17.1 15.4H17c0-.1 0-.2-.1-.2.1 0 .1.1.2.2zM16.9 15.6c0 .1.1.1.1.1-.3-.1-.6-.7-.8-.9v-.1c.1.3.4.8.7.9z'
              />
              <path
                class='st15'
                d='M16.9 13.4c0 .1.1.4.1.5 0-.3-.2-.5-.2-.5v.4s0-.1-.1-.3c0 .1.1.3.1.4s-.1 0-.1-.1l.1.2-.1-.1s0 .2.1.4c-.1-.1-.1-.2-.1-.4v-.4c.1 0 .1-.1.2-.2s.1-.2.1-.3c-.3.3-.3.4-.1.4z'
              />
              <path
                class='st45'
                d='M16.9 15.1c0 .1 0 .1.1.2-.2-.1-.2-.1-.2-.2 0 .1.1.3.2.5-.3-.1-.6-.7-.7-.8v-.1c0 .1.1.3.2.4 0 0-.2-.4-.1-.5.1.1.2.1.3.2.1.1.2.2.2.3z'
              />
              <path
                class='st15'
                d='M16.7 11.9c.1.2.2.3.2.4-.2-.1-.5-.5-.5-.6 0 0 .1.2.1.3 0-.1-.3-.4-.4-.4 0 0 .2.4.2.5-.1-.1-.2-.3-.3-.4v-.1c-.1 0-.1 0-.1-.1-.1-.1-.2-.1-.4-.2-.1 0-.2 0-.3-.1v-.1c.1 0 .2 0 .3.1.1 0 .1.1.3.1.2.1.2.1.3.1h.1s.1 0 .1-.1c.2.2.3.4.4.6zM16.7 16.1c.1.2.3.6.4.7-.5-.2-.7-.7-.7-.8.1.1.2.1.3.1zM16.8 18.1v.1h-.1l-.1.1c-.2-.2 0-.3.1-.4 0 .1 0 .1.1.2z'
              />
              <path
                class='st48'
                d='M16.8 14.1v.6c.1-.3.1-.9.1-.9-.1 0-.1.1-.1.3z'
              />
              <path
                class='st45'
                d='M16.7 13.7s0 .6-.1.9c0-.2-.1-.4-.1-.4l.1.1-.1-.2.1.1s-.1-.3-.1-.4c.1.1.1.3.1.3l.1-.4z'
              />
              <path
                class='st49'
                d='M16.7 15.6v.5c-.1 0-.2 0-.3-.1 0 0-.1 0-.1-.1l-.3-.3c-.2-.2-.3-.4-.4-.5V15c.1.1.5.7.6.7v-.4c0 .1.2.6.3.6.2 0 .2-.2.2-.3z'
              />
              <path
                class='st15'
                d='M16.7 16.8v.3l-.3-.3v-.1c-.1-.1-.1-.2-.2-.3v-.1c-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1l-.3-.3c-.1-.2-.3-.3-.3-.5-.1-.1-.1-.2-.2-.3l.1-.1c.1.2.6 1.1 1.1 1.2.2.1.3.4.5.7z'
              />
              <path
                class='st46'
                d='M16.7 14.6c-.1.1-.1.1 0 0-.2.1-.3-.1-.4-.1V14.2s.1-.1.2-.1c0 .2.1.3.1.4 0-.1 0 0 .1.1-.1-.1-.1-.1 0 0-.1-.1 0-.1 0 0 0-.1 0-.1 0 0z'
              />
              <path
                class='st15'
                d='M16.7 14.7s.1.1.1.2c-.1-.1-.2-.2-.3-.2 0 .1.1.4.1.5-.1-.1-.2-.3-.2-.4v-.3s.1.1.3.2c-.1 0-.1 0 0 0z'
              />
              <path
                class='st46'
                d='M16.6 15.6v.4c-.2 0-.3-.6-.3-.6s.1.3.2.4c.1-.1.1-.2.1-.2 0-.1 0 0 0 0z'
              />
              <path
                class='st45'
                d='M16.6 15.5c0 .1 0 .1-.1.2-.1-.1-.2-.3-.2-.4 0 0 0 .1-.1.2-.2-.1-.5-.6-.5-.7v-.2c0 .1.1.1.2.2s.2.4.3.5c0-.1-.1-.2-.1-.3.2.1.3.3.5.5z'
              />
              <path
                class='st46'
                d='M16.6 13.4c0 .1-.1.2-.1.2h-.1s-.1 0-.1-.1l-.1-.1c.1-.1.1-.3.1-.5.1 0 .1-.1.2-.3.1.2 0 .6-.1.8 0 0 .1 0 .2-.1v.3c-.1-.1-.1-.2 0-.2z'
              />
              <path
                class='st45'
                d='M16.6 12.5c0 .1.2.5 0 .9l-.1.1v-.3c-.1.1-.1.1-.2.1.3-.2.2-.7.3-.8-.1 0 0 0 0 0z'
              />
              <path
                class='st47'
                d='M16.2 10.8c.2 0 .4.1.4.1h-.2c-.3-.1-.4 0-.4-.1h.2z'
              />
              <path
                class='st15'
                d='M16.5 12.5c0 .1.1.6-.2.9.1-.2.2-.6.1-.8 0 .1-.1.2-.2.3v-.1c.1 0 .2-.2.3-.3-.1 0 0 0 0 0zM16.5 16.7c0 .2 0 .4-.1.5 0 0 0-.4.1-.5-.1-.1-.1 0 0 0z'
              />
              <path class='st45' d='M16.4 13.6v.4c-.1 0-.1.1-.2.1v-.5h.2z' />
              <path
                class='st46'
                d='M16.4 16.6v.6c-.1-.1-.2-.1-.2-.2 0-.3 0-.6.1-.7 0 .1 0 .2.1.3z'
              />
              <path
                class='st48'
                d='M16.2 11.8s-.1-.1-.2-.1c0 0-.1-.1-.3-.1-.1 0-.2-.1-.4-.1v.1h.2c.1 0 .2.1.2.1.1.1.2.1.2.2.1.1.1.2.1.2s.1.2 0 .3c.1-.1.1-.2.1-.2s0 .4.1.5c.1.1.1-.4.1-.4.1-.2 0-.4-.1-.5zm-.1.2c0-.1-.1-.2-.1-.2 0-.1.2 0 .2 0s0 .2-.1.2zM16.4 11.3v.1c-.2 0-.3 0-.4-.1-.1 0-.2-.1-.3-.1h-.2s.2 0 .5.1c.2.1.4 0 .4 0z'
              />
              <path
                class='st49'
                d='M16.3 11.4c.1 0 .1 0 0 0v.1h-.1c-.1 0-.1 0-.3-.1-.2-.1-.2-.1-.3-.1-.1 0-.2-.1-.3-.1v-.1h.2c.1 0 .2.1.3.1.3.1.4.2.5.2z'
              />
              <path
                class='st48'
                d='M16.3 14.4v.6c0-.1 0-.4-.1-.4v-.1c-.1-.1 0 0 .1-.1-.1 0-.3-.2-.3-.4.1.1.1.2.1.2s0-.5.1-.7c0 0 0 .1.1.1V14.4z'
              />
              <path
                class='st47'
                d='M16.3 10.4c0 .1 0 .1.1.1H16.1s.1 0 .2.1h-.1c-.1 0-.2.1-.2.1 0-.1-.1-.2.1-.4.1-.1.2-.1.3 0l-.1.1z'
              />
              <path
                class='st46'
                d='M16.3 15.4v.4c-.2-.1-.5-.6-.6-.7 0-.1 0-.1.1-.2.1.1.3.6.5.7v-.2z'
              />
              <path
                class='st49'
                d='M16.3 12.9c0 .2 0 .4-.1.5-.1.3-.3.4-.4.4 0 0-.1 0-.1-.1v-.4s.1-.3.1-.5c0 0 .1 0 .1-.1 0 .1 0 .1-.1.2l.2-.2s-.1.3-.2.3c.1 0 .1 0 .2-.1 0 .1 0 .4-.2.6.2.1.4-.2.5-.6-.1 0-.1 0 0 0-.1-.1 0-.1 0 0 0-.1 0 0 0 0z'
              />
              <path
                class='st46'
                d='M16.2 13.5c0 .1 0 .1 0 0-.1.2-.1.7-.1.7s-.1-.1-.1-.2c0 .1.2.4.2.4l-.2-.1v.1l-.1-.4s-.1 0-.1.1c0-.1 0-.2.1-.3v-.2c.2.2.3.1.3-.1z'
              />
              <path
                class='st49'
                d='M16.2 11.7c0 .1.1.1.1.1s-.1-.1-.2-.1c0 0-.1-.1-.3-.1-.1 0-.2-.1-.4-.1v-.2c.1 0 .2 0 .3.1.2.1.3.1.4.2 0 0 .1 0 .1.1-.1-.1 0-.1 0 0z'
              />
              <path
                class='st46'
                d='M16.2 12.9c0 .4-.3.7-.4.8.1-.2.1-.5.2-.6.1-.1.1-.2.2-.2z'
              />
              <path
                class='st15'
                d='M16.2 15c0 .1 0 .2.1.3-.1-.1-.3-.4-.3-.5 0 .1.1.2.2.2-.1 0 0 0 0 0z'
              />
              <path
                class='st48'
                d='M16.2 16.2c0 .2-.1.5 0 .8v-.1c0-.1-.1-.2-.1-.2v-.6s0 .1.1.1z'
              />
              <path
                class='st15'
                d='M16.2 13.4s0 .1.1.1l-.3.3h-.1l-.1-.1c.1.1.3 0 .4-.3zM16.1 16.9s.1 0 0 0c-.1.2-.5.2-.7.2 0 0 .6 0 .7-.2zM16.1 11.8v.2c0-.1-.1-.2-.1-.2 0-.1.1 0 .1 0z'
              />
              <path
                class='st49'
                d='M16.1 14.5c0 .1.1.3.1.4-.1 0-.2-.1-.2-.2l-.2-.2v.2V14.4c0-.1-.1-.4 0-.4V13.7s0 .1.1.1c0 .1.1.1.1.1h.1v.2c-.1.1-.1.2-.1.3 0-.1.1-.1.1-.1v.2z'
              />
              <path
                class='st45'
                d='M16 12.6c.1.1.1.2.2.2 0 .1-.1.2-.2.2 0 0-.1 0-.2.1.1-.1.2-.3.2-.3l-.2.2s.1-.1.1-.2c0 0 0-.1.1-.2 0 .1 0 0 0 0z'
              />
              <path
                class='st46'
                d='M16.1 16.7c0 .1 0 .2.1.2-.1.2-.7.2-.7.2.2 0 .5-.3.6-.4z'
              />
              <path
                class='st45'
                d='m16 15.6.3.3c-.4-.2-.7-.6-.8-.9l.1.1c.1.1.2.4.4.5z'
              />
              <path
                class='st48'
                d='M16 16.6s.1 0 0 0c-.1.2-.3.5-.6.6V17s0-.1.1-.1l.1-.1s.1 0 .1-.1c0 0 .1 0 .1-.1h.2z'
              />
              <path
                class='st49'
                d='M16 11.9c.1.1.1.2.1.2-.1 0-.1-.1-.1-.2-.2-.1-.5-.2-.5-.2h-.2c0-.1.1-.1.1-.2h.2c.1 0 .2.1.2.1 0 .2.1.2.2.3z'
              />
              <path
                class='st50'
                d='M16 12.1c-.1 0-.3-.1-.4-.2-.1-.1-.2-.1-.2-.1s0 .1.1.1c0 0 0 .1.1.1 0-.1-.2-.1-.2-.2v-.1c0-.1-.1-.1-.2-.1h.2c.1 0 .3.1.5.3.1.1.1.2.1.2z'
              />
              <path
                class='st46'
                d='M16 16.1v.5h-.1c0-.1-.1-.4-.1-.4l-.1.1c0-.1.1-.2.1-.3.1 0 .1 0 .2.1z'
              />
              <path
                class='st48'
                d='M16 12.1v.6c0 .1-.1.1-.2.2v-.5c.1.1.1.3.1.3s.1-.2.1-.3c0-.1-.1-.2-.1-.2V12c-.1 0 0 0 .1.1z'
              />
              <path
                class='st49'
                d='M15.9 12.6c.1 0 .1.1 0 0v.2c0 .1-.1.1-.1.1s-.1 0-.1-.1c0 0 0-.1-.1-.1-.1.1-.1.2-.2.2h-.2c-.1 0-.1-.1-.1-.1.1-.1.2-.3.2-.3l.2-.2c0 .1.1.2-.1.3.1 0 .3-.2.3-.2v-.1c.1 0 .1-.2.1-.3 0-.1 0-.1-.1-.1 0-.1-.1-.1-.1-.1s.1.1.2.1v.9s.1-.1.1-.2z'
              />
              <path
                class='st15'
                d='M15.9 12.2c0 .1-.1.3-.1.3s0-.2-.1-.3V12c.1.1.2.1.2.2z'
              />
              <path
                class='st49'
                d='M15.8 16.2s0 .3.1.4c0 0-.1 0-.1.1v-.1c0-.1-.1-.2-.1-.3v-.7l.3.3c0 .1-.1.2-.1.3h-.1z'
              />
              <path
                class='st15'
                d='M15.7 15.1c.1.1.2.3.4.5a.6.6 0 0 1-.4-.5c-.1 0-.1 0 0 0z'
              />
              <path
                class='st46'
                d='M15.8 12.9c0 .2-.1.4-.1.5v-.2l-.2.2.1-.3s-.1 0-.2.1c.1-.1.2-.3.2-.4.1.1.1.1.2.1z'
              />
              <path
                class='st48'
                d='M15.7 16.6s.1 0 0 0c0 .1 0 .1-.1.1-.1-.1-.2-.1-.3-.1v-.2c.2.2.3.2.4.2z'
              />
              <path
                class='st49'
                d='M15.7 14.8c0 .1 0 .1-.1.2v.2c-.1 0-.1-.1-.1-.1-.1-.1-.1-.2-.2-.3-.1-.2-.1-.4-.2-.6 0-.2.1-.4.2-.5v.1s.1 0 .2-.1c0 .1-.1.4-.2.5l.2-.1s-.1.4-.1.5l.1-.2.1.2c0 .1 0 .2.1.3-.1.1 0 0 0-.1z'
              />
              <path
                class='st48'
                d='M15.7 14.6v.2c0 .1-.1.2-.1.1-.1-.1-.1-.2-.1-.3v.1s.1-.3.1-.4v-.4l.1-.1v.8zM15.2 14.9c.2.2.7 1 1.1 1.2-.5-.1-1.1-1-1.1-1.2z'
              />
              <path
                class='st46'
                d='M15.7 13.7c0 .1-.1.1-.1.1v.4c-.1 0-.1.4-.1.4v-.1l-.1-.2-.1.2c0-.1.1-.5.1-.5s0 .1-.2.1c.1-.1.2-.4.2-.5l.1-.1.2-.2v.4z'
              />
              <path
                class='st49'
                d='M15.7 13.2V13.5s-.1.2-.4.3h-.1V13.5c.1-.2.2-.4.2-.5.1 0 .1-.1.2-.2 0 0 0 .1.1.1-.1.1-.2.3-.2.4 0-.2.1-.2.2-.1-.1-.1-.2.2-.2.2s.1-.3.2-.2z'
              />
              <path
                class='st48'
                d='m15.6 11.9.1.1c0 .1 0 .2-.1.3 0 0 0-.1-.1-.1 0-.1-.2-.2-.3-.2h.1c.1 0 .2.1.2.2.1-.2.1-.2.1-.3z'
              />
              <path
                class='st15'
                d='M15.7 13.5c-.1.1-.1.2-.2.2l-.1.1c-.1 0-.1.1-.2.1v-.1h.1c.3-.1.4-.3.4-.3z'
              />
              <path
                class='st49'
                d='M15.6 16.3c0 .1.1.2.1.3-.1 0-.2 0-.4-.1v-.1c.1 0 .1-.1.2-.1h.1z'
              />
              <path
                class='st46'
                d='M15.6 16.2s0 .1 0 0c0 .1 0 .1 0 0-.2 0-.4-.1-.4-.3.1.1.3.3.4.3z'
              />
              <path
                class='st49'
                d='M15.6 16v.2c-.1-.1-.3-.2-.4-.3h.2c.1.1.2.1.2.1z'
              />
              <path
                class='st50'
                d='M15.6 12.5c0 .1-.1.2-.3.2.2-.1.1-.3.1-.3s0-.1.1-.1l.1.1v.1z'
              />
              <path
                class='st46'
                d='M15.6 16h-.1c-.3-.3-.3-.6-.2-.7.1.1.2.3.3.5v.2z'
              />
              <path
                class='st49'
                d='m15.7 16.7-.1.1c-.3 0-.6-.1-.6-.1h.1s.1 0 .1.1h.1c.1 0 .2 0 .2-.1h.2z'
              />
              <path
                class='st50'
                d='M15.3 12c.1-.1.1-.2.1-.2h-.3c.1 0 .1-.1.1-.1.1-.1.1-.1.1-.2V11c0-.1 0-.2-.1-.2v.5s0 .1-.1.1c-.1.1-.2.2-.3.2-.1.1-.2.1-.3.1h-.1v.1c0 .1-.1.2-.3.2.1 0 .2.1.2.1.1 0 .6 0 .6.1v.1c.2-.2.2.2.2.2l.2-.2s0-.1.1-.1c.1-.1-.1-.1-.1-.2zm-.3.1h-.1s.2-.1.1 0c.1-.1-.1-.1-.2-.1 0 0 .2-.1.3-.1h.1l-.2.2zm.3.2c0-.1-.3-.1-.3-.1.1-.1.2-.1.3.1.1 0 .1-.1 0 0 .1-.1.1.1 0 0z'
              />
              <path
                class='st46'
                d='m15.5 16.8-.1.1c-.3 0-.5-.2-.6-.2.1.1.4.2.7.1z'
              />
              <path
                class='st15'
                d='M15.5 16.9c0 .1 0 .1 0 0-.2 0-.5-.1-.6-.2 0 .1.3.2.6.2z'
              />
              <path
                class='st49'
                d='M15.4 11.9c0 .1-.1.1-.1.1 0-.1 0-.2.1-.1-.1-.1-.1-.1-.4-.1.1 0 .1-.1.1-.1.2 0 .3 0 .3.2 0-.1 0 0 0 0z'
              />
              <path
                class='st48'
                d='M15.4 16.6c0 .1 0 .1 0 0 0 0-.1 0 0 0-.1 0-.1 0 0 0h-.1c0-.1 0-.1.1 0-.1-.1-.1-.1 0 0 0-.1 0 0 0 0z'
              />
              <path
                class='st49'
                d='M15.4 16.7c0 .1-.1.1-.2.1h-.1s-.1 0-.1-.1h.1c.1.1.2 0 .3 0z'
              />
              <path
                class='st15'
                d='M15.3 12.2c.1.1.1 0 .1 0s0 .2-.1 0h-.2c0-.1.1-.1.2 0z'
              />
              <path
                class='st49'
                d='M15.4 16.4s0 .1 0 0c0 .1 0 .1 0 0-.1.1-.1.1 0 0-.1.1-.1.1 0 0-.1.1-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0z'
              />
              <path
                class='st48'
                d='M15.4 16.6c0 .1 0 .1 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0 0z'
              />
              <path
                class='st49'
                d='M15.4 16.7c0 .1-.2.1-.2.1h-.1c0-.1.1-.1.3-.1z'
              />
              <path
                class='st37'
                d='M15.4 16.7c-.1 0-.2 0-.3.1 0 0 .1 0 .1-.1h.2c-.1-.1-.1-.1 0 0-.1-.1-.1-.1 0 0z'
              />
              <path
                class='st46'
                d='M15.3 13c.1 0 .1 0 0 0 0 .1-.1.3-.1.4 0-.1.1-.3.1-.4z'
              />
              <path
                class='st49'
                d='M15.3 16.4s0 .1 0 0c0 .1 0 .1 0 0-.1.1-.1 0-.1 0h.1z'
              />
              <path
                class='st5'
                d='M15.2 16.3c.1 0 .1 0 .1.1 0-.1 0-.1-.1-.1v-.2c-.1 0-.1.1-.1.1l-.1.1h-.2v-.1l-.1-.1h-.1v.1s-.1 0-.1.1h-.1v-.2l.1-.1h.1-.1s-.1 0-.1.1v.1-.2c.1-.1.2-.1.2-.1h.1-.1s-.2 0-.2.1v.1s-.1 0-.1.1v.1l-.1.1V16.7s0 .1.1.1c0 0-.1 0-.1-.1v-.1s0 .1.1.1c0 0-.1 0-.1-.1v-.1l.1-.1h.1c0 .1 0 .1.1.2v.1s-.1-.1 0-.1c0 0 0-.1.1-.1 0 .1.1.1.1.1s0 .1.1.1c.1.1.1.1.2.1s.1-.1.1-.2h.2v.1s.1 0 .1-.1h.2-.1c-.3-.1-.3-.1-.3-.3 0 .1 0 .1 0 0zm-1.4-.2.1-.1-.1.1c.1 0 .1 0 .1.1v.1s-.1 0-.1.1v.1-.1c-.1-.1-.1-.2 0-.3-.1.1-.1 0 0 0zm-.1.3v-.2.2zm0 .1v-.1.1s.1 0 0 0zm.2-.1s-.1 0 0 0c-.1.1-.1.1 0 0l-.1.1s0-.1.1-.1c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1-.1 0 0v-.1.1c0-.1 0-.1 0 0zm.4.1c-.1 0-.1-.1-.2-.1v-.1l.1-.1c.1 0 .1.1.1.1v.2c.2-.1.1 0 0 0zm.4 0c0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .2-.1.2-.1.2s-.1 0-.2-.1l-.1-.1c.1 0 .2-.1.2-.1v-.1h.1c.1.1.1.1.1.2zm.1.1-.1-.1h.2v.1c0-.1 0 0-.1 0zm.3 0c0 .1-.1 0-.2 0h-.1l.1-.1h.1c.1 0 .1 0 .1.1zm0-.2H15v-.1h-.1s0-.1.1-.1l.1-.1v.2c.1 0 .1 0 0 .1.1-.1 0 0 0 0z'
              />
              <path
                class='st49'
                d='M15.3 10.9v.4s0 .1-.1.1l-.1.1s.1 0 .2-.1c0 0 0 .1-.1.1-.1.1-.2.2-.3.2h-.1s-.1 0-.2.1h-.1v.1-.1c0-.1-.1-.1-.1-.1.1-.1.4-.2.5-.4.1-.1.1-.2.1-.5v.1c0 .1 0 .4-.1.6-.1.1-.1.2-.2.2.3-.1.4-.2.5-.5 0-.1.1-.2.1-.3zM15.2 13h.1c0 .1-.1.3-.1.4v.1c-.1 0-.2.1-.2 0s.2-.4.2-.5z'
              />
              <path
                class='st37'
                d='M15.2 16.3c.1 0 .1 0 .1.1 0 0-.1 0-.1.1 0 0 0 .1.1.1h-.1v-.3c0 .1 0 .1 0 0z'
              />
              <path class='st15' d='m15.2 11.4.1-.1s0 .1-.1.1l-.1.1.1-.1z' />
              <path
                class='st48'
                d='M15.3 16.5h-.1.1zM15.2 14.8s0 .1 0 0l-.1.1-.1.1s-.1 0-.1.1c0-.3-.1-.7-.1-.7-.1-.4.1-.7.2-.9v.1c-.1.1-.2.4-.2.7.1.4.2.5.2.5v-.2.1-.5c.1.2.1.4.2.6z'
              />
              <path
                class='st15'
                d='M15.1 11.9h.1l-.2.2h-.1l.2-.2c0 .1-.2.1-.3.1.1 0 .2-.1.3-.1z'
              />
              <path
                class='st46'
                d='M15.2 13c0 .1-.2.4-.3.6h-.1c.1-.1.3-.5.4-.6z'
              />
              <path
                class='st37'
                d='M15.2 16.1v.2s0 .1-.1.1H15v-.1h-.1s0-.1.1-.1c0 0 .1-.1.2-.1z'
              />
              <path
                class='st46'
                d='M15.2 13.6c0 .1 0 .1 0 0-.1.2-.1.5-.1.6v.5-.1l-.1-.3s0 .1-.1 0c0-.1 0-.3.1-.3l-.1.1c0-.1.1-.3.2-.4l.1-.1z'
              />
              <path class='st15' d='M15.2 13.5v.1H15v-.1c0 .1.1 0 .2 0z' />
              <path
                class='st37'
                d='M15.1 16.4v.1c0 .1-.1 0-.2 0h-.1l.1-.1c.1.1.2.1.2 0z'
              />
              <path
                class='st50'
                d='M15.1 11.2c-.1.3-.2.4-.5.5.1-.1.2-.1.2-.2.1-.1.2-.3.2-.4.1 0 .1.1.1.1z'
              />
              <path
                class='st49'
                d='m15 14.3.1.3v.2s-.1-.1-.2-.5c0-.3.1-.6.2-.7h.1c-.1.1-.2.4-.2.4s-.1.3 0 .3c0 .1 0 0 0 0z'
              />
              <path
                class='st3'
                d='M15.1 12.4c-.1 0-.1 0-.2-.1h-.2c-.1 0-.2.1-.3 0h-.2c-.1 0-.2.1-.3.2l-.2.2c0 .2.1.3.1.3s-.2-.1-.2-.2 0-.3.1-.4v-.1h.1c0-.1.2-.1.2-.1H14.3c.1 0 .2.1.2.1.1-.1.5-.1.6.1z'
              />
              <path
                class='st15'
                d='M15.1 11.1c0 .1-.1.3-.2.4.1-.2.1-.5.1-.6 0 0 0 .1.1.2z'
              />
              <path
                class='st37'
                d='M15 16.3v.1c0 .1-.1.1-.1.1V16.2c-.1.1-.1.1.1.1-.1 0-.1 0 0 0-.1 0 0 0 0 0zM14.9 12.3c.1 0 .1.1.1.1-.1.1-.3.2-.5.3l-.1-.1c.1 0 .2-.1.2-.1l.1-.1-.1-.1c-.1 0-.1.1-.3.1h-.2s-.1 0-.2.1-.1.1-.1.2 0 .2.1.3h-.2c-.1 0-.2-.2-.3-.3v-.2s0-.1.1-.1l.1-.1v.1c-.1.1-.2.2-.1.4 0 .1.2.2.2.2s-.1-.1-.1-.3c0-.1.1-.2.2-.2.1-.1.2-.2.3-.2h.8z'
              />
              <path
                class='st15'
                d='M14.8 11.8h.1c-.1.1-.2.1-.3.1.1-.1.2-.1.2-.1z'
              />
              <path
                class='st48'
                d='m14.7 11.4.1-.1c-.1.2-.4.4-.5.4.1-.1.1-.3 0-.3 0 .1-.2.4-.4.5.1.1.1.2.1.2h-.1V12l.1-.1.2-.2c.1-.1.1-.1.1-.2 0 0 0-.1.1-.2.1.1.1.2.1.4.1-.1.2-.4.2-.6 0-.1.1.3 0 .3 0 .1 0 0 0 0z'
              />
              <path
                d='m14.7 12.5-.1.1c-.1 0-.1.1-.2.1-.2-.1-.3-.2-.5-.1s0 .4.1.5h-.1c-.1 0-.3-.1-.3-.2v-.3s0-.1.1-.1c0 0 0 .1-.1.1v.2c0 .1.1.2.3.3h.2c-.1-.1-.1-.2-.1-.3 0-.1.1-.2.1-.2.1 0 .1-.1.2-.1h.2c.1 0 .2-.1.3-.1-.1 0-.1.1-.1.1z'
                fill='#bc7e2f'
              />
              <path
                class='st37'
                d='M14.7 16.4V16.7c0 .1 0 .1-.1.1 0 0-.1 0-.2-.2l-.1-.1c.1 0 .2-.1.2-.1v-.1c.1 0 .2 0 .2.1 0-.1 0-.1 0 0 0-.1 0 0 0 0z'
              />
              <path
                class='st15'
                d='M14.7 11c.1.1.1.3.1.4l-.1.1c.1-.1 0-.5 0-.5z'
              />
              <path
                class='st37'
                d='M14.6 16.1v.2c0 .1-.1.1-.2.1l-.1-.1v-.1l.1-.1h.2z'
              />
              <path
                d='M14.5 11.8c0 .1 0 .1 0 0v.1l-.1.1c0 .1 0 0 .1-.2 0 .1 0 .1 0 0z'
                fill='#e6e7e8'
              />
              <path
                d='M14.5 11.8c0 .1 0 .1 0 0 0 .2-.1.2-.1.3h-.1l.1-.1c-.1 0 0 0 .1-.2-.1.1-.1 0-.1 0v-.1s0 .1.1.1z'
                fill='#f06d19'
              />
              <path class='st5' d='M14.4 16.7z' />
              <path
                class='st49'
                d='M14.4 11.4v.4c0 .1-.1.1-.1.2 0 0 0 .1-.1.1h.1l.1-.1c0 .1-.1.2-.3.2H13.8c0-.1 0-.1.1-.2.3-.2.5-.5.5-.6z'
              />
              <path
                class='st15'
                d='M14.4 16.7c-.2 0-.3-.1-.3-.1h.1c.1 0 .2.1.2.1zM14.5 15.8c0 .1-.1.1-.1.3V16c0-.1 0-.1.1-.2-.2 0-.2 0 0 0zM14.3 11.8l.1.1c0 .1 0 .1-.1.1v-.2zM14.4 11.2c0 .1 0 .2-.1.2 0 .1-.1.2-.1.2l-.2.2c0 .1 0 .2-.1.2v.1c0-.1 0-.1.1-.2 0 0 .4-.3.4-.7z'
              />
              <path class='st37' d='M14.3 16.5c-.1 0-.1 0 0 0-.1 0 0 0 0 0z' />
              <path
                class='st5'
                d='M14.3 16.5c0 .1-.1.1 0 0 0 .1-.1.1-.1.1l.1-.1c-.1 0-.1 0 0 0z'
              />
              <path class='st37' d='M14.2 16.5c0 .1-.1.1-.1.1l.1-.1z' />
              <path
                class='st15'
                d='M14.3 16.7c-.2 0-.3 0-.3-.1 0 0 .1 0 .1-.1.1.2.1.2.2.2z'
              />
              <path
                class='st37'
                d='M14.1 16.3c0 .1.1.1.1.1-.1 0-.1.1-.1.1-.1 0-.1 0 0-.2-.1.1-.1.1 0 0 0 .1 0 0 0 0z'
              />
              <path
                class='st37'
                d='M14.2 16.2s0 .1-.1.1c0 0-.1 0-.1.1h-.1v-.2l.1-.1c.1 0 .1 0 .2.1 0-.1 0 0 0 0z'
              />
              <path
                class='st37'
                d='M14.2 15.9c0 .1-.1.1-.1.2H14s-.1 0-.1.1v.1-.2c.1-.1.2-.1.2-.1l.1-.1z'
              />
              <path
                class='st5'
                d='M14.1 16.5c0 .1 0 .1 0 0 0 .1 0 .1-.1.1 0 0 .1 0 .1-.1 0 .1 0 .1 0 0 0 .1 0 .1 0 0 0 .1 0 .1 0 0z'
              />
              <path
                class='st37'
                d='M14.1 16.6s-.1 0 0 0c-.1 0-.1 0 0 0-.1 0-.1 0 0 0z'
              />
              <path
                class='st15'
                d='M14.2 16.8c-.1.1-.2 0-.3 0 0 0 .1 0 .1-.1 0 0 .1.1.2.1z'
              />
              <path
                class='st37'
                d='M14.1 16.6c-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0l-.1.1s-.1 0-.1-.1v-.1l.1-.1h.1c-.1.1-.1.1 0 .2z'
              />
              <path
                class='st48'
                d='M13.9 12.1s.1 0 0 0c.1 0-.1 0-.1.1 0 0 0 .1-.1 0 .1 0 .1 0 .2-.1z'
              />
              <path
                class='st5'
                d='M14 16.7s-.1 0 0 0c-.1 0-.1 0 0 0-.1 0-.1-.1 0 0-.1-.1-.1-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0zM14 16.7c-.1 0-.1 0 0 0-.1.1-.1.1-.1 0h.1c-.1 0-.1 0 0 0-.1 0 0 0 0 0z'
              />
              <path class='st37' d='M13.9 16.7zM13.9 16.7h-.1.1z' />
              <path class='st5' d='M13.9 16.6s0 .1 0 0c0 .1 0 .1 0 0z' />
              <path
                class='st37'
                d='M13.9 16.6zM13.9 16.3s0 .1 0 0c0 .1 0 .1 0 0 0 .1-.1.1-.1.2v-.1l.1-.1c-.1.1-.1 0 0 0-.1 0 0 0 0 0z'
              />
              <path
                class='st37'
                d='M13.9 16.2c0 .1 0 .1-.1.1v.1V16.1l.1-.1c-.1.1 0 .1 0 .2zM13.8 16.6v.1l-.1-.1h.1s0-.1 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0zM13.7 16.5s.1 0 0 0c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0 0 0 0zM13.7 16.3v.1c-.1 0 0-.1 0-.1z'
              />
            </g>
          </g>
        </svg>
      </i>
    );
  }
}
