export default {
  app: {
    search: {
      button: 'Znajdź',
      noResults: 'Nie znaleziono wyników',
      title: 'Szukaj',
      widgetTitle: 'Znajdź co robić w kraju',
    },
  },
};
