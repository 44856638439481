export default {
  app: {
    gridList: {
      label: {
        incentive: '인센티브로 필터링',
        itinerary: '태그로 필터링',
        region: '지역별 필터링',
      },
    },
  },
};
