import { convertToTimeZone, formatToTimeZone } from 'date-fns-timezone';
import format from 'date-fns/format';
import { dateFnsLocalizations, defaultLocale } from '~/app/localization';
import { defaultDateFormat, defaultDateTimeFormat } from '~/utils/dateTime';

const defaultTimezone = 'Europe/Prague';

interface TimeZoneOptions {
  locale: any;
  timeZone: string;
  convertTimeZone: boolean;
}

export default function(
  date: Date,
  dateFormat: string = defaultDateFormat,
  locale: string = defaultLocale
) {
  return format(date, dateFormat, {
    locale: dateFnsLocalizations[locale],
  });
}

export function timeFormat(
  date: Date,
  dateFormat: string = defaultDateTimeFormat,
  locale: string = defaultLocale
) {
  return format(date, dateFormat, {
    locale: dateFnsLocalizations[locale],
  });
}

export function formatToCET(
  date: Date,
  dateFormat: string = defaultDateTimeFormat,
  locale: string = defaultLocale
) {
  const options: TimeZoneOptions = {
    timeZone: defaultTimezone,
    locale: dateFnsLocalizations[locale],
    convertTimeZone: true,
  };
  return formatToTimeZone(date, dateFormat, options);
}

export function convertToCET(date: Date) {
  return convertToTimeZone(date, { timeZone: defaultTimezone });
}
