export default {
  app: {
    url: {
      controller: 'https://www.visitczechia.com/zh-TW/Contacts',
      facebook: 'https://www.facebook.com/czechrepublic',
      instagram: 'https://www.instagram.com/visitcz/',
      privacy: '~/Privacy-Policy',
      twitter: 'https://twitter.com/VisitCZ',
      web: 'https://www.visitczechia.com/',
      youtube: 'https://www.youtube.com/visitczech',
    },
  },
};
