import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class SummerSportsIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--summer-sports'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M46.4 73.6h.2l.4.1c6.1.5 11.7-3.8 12.6-10l5.9-42.5-11.6-1.6a11.7 11.7 0 0 0-12.4 9.1l-.1.8-4.3 31c-1 6.2 3.3 12 9.3 13.1M83.4 24.4a11.7 11.7 0 1 0 0-23.4 11.7 11.7 0 0 0 0 23.4M72.6 110.5l2-13.4-10.4-10.3-3 21.6-22.9 30.3 9.4 7L70 116.3l1-1.4c.8-1.3 1.4-2.7 1.6-4.3'
          />
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M104.1 105.4c-.5-1.4-1.3-2.8-2.4-4L98 97.9l6.3-13.9 6.7-7-2.4-2.3 4.3-9.3-4.3-2-3.6 7.9-14-13.3-1.9 14.3L100 82.4l-5.3 11.8L83 82.5l7-51.2-17.7-2.5-3.1-.4-6.4 44.2c-.4 3 .4 5.9 2 8.2l24.5 24.5L72.2 143l4.2 2 16.5-36 .3.2 10.1 36.8 11.3-3.2-10.5-37.3Z'
          />
        </svg>
      </i>
    );
  }
}
