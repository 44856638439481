export default {
  app: {
    safeTravels: {
      search: 'Найти заведение',
      categories: 'Выбрать категорию',
      button: 'Поиск',
      table: {
        name: 'Название заведения',
        link: 'Веб страницы',
      },
      categoryTranslations: {
        eventy: 'Мероприятия',
        kongresy_a_veletrhy: 'Конгрессы и выставки',
        restaurace: 'Рестораны',
        ubytovací_zařízení: 'Отели',
        sauny: 'Сауны',
        přírodní_a_umělá_koupaliště: 'Бассейны',
        lanová_doprava: 'Подъемники и канатные дороги',
        maloobchod: 'Магазины',
        fitness_centra: 'Фитнес-центры',
        kina: 'Кино',
        golfové_areály: 'Поля для гольфа',
      },
    },
  },
};
