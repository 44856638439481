export default {
  app: {
    safeTravels: {
      search: 'Meklēt objektus',
      categories: 'Izvēlieties kategoriju',
      button: 'Meklēt',
      table: {
        name: 'Objekta nosaukums',
        link: 'Interneta vietne',
      },
      categoryTranslations: {
        eventy: 'Pasākumi',
        kongresy_a_veletrhy: 'Kongresi un izstādes',
        restaurace: 'Restorāns',
        ubytovací_zařízení: 'Izmitināšanas vieta',
        sauny: 'Saunas',
        přírodní_a_umělá_koupaliště: 'Pludmales',
        lanová_doprava: 'Gaisa tramvajiņi',
        maloobchod: 'Mazumtirdzniecība',
        fitness_centra: 'Fitnesa klubs',
        kina: 'Kinoteātri',
        golfové_areály: 'Golfa laukumi',
      },
    },
  },
};
