export default {
  app: {
    cookies: {
      title: 'We respect your privacy',
      text:
        'We use cookies to personalise content, analyse traffic and provide social media features. We share information about how you use our website with our social media and analytics partners. Partners may combine this information with other information you provide to them or that they obtain as a result of your use of their services. You can find more details {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'here',
      },
      buttons: {
        accept: 'Accept all cookies',
        save: 'Save selected',
      },
      settings: {
        button: 'Detailed settings',
        required: 'Required (for the proper functioning of the site)',
        analytics:
          'Analytics (to understand how visitors behave on the website or how they move around the website)',
        statistics: 'Statistics (to understand how visitors use the site)',
        marketing: 'Marketing (to track visitors to show relevant ads)',
        reject: 'Reject all',
        accept: 'Accept all',
      },
    },
  },
};
