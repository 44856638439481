import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class SpFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--spain'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g fill-rule='nonzero'>
            <path
              d='M16.284.69c-5.233 0-9.88 2.512-12.799 6.397h25.599C26.164 3.202 21.519.69 16.284.69zM16.284 32.688c5.235 0 9.88-2.515 12.8-6.4H3.485c2.918 3.885 7.564 6.4 12.8 6.4z'
              fill='#c62026'
            />
            <path
              d='M29.084 7.087H3.485a15.93 15.93 0 00-3.2 9.602c0 3.6 1.19 6.924 3.2 9.6h25.6c2.008-2.676 3.2-6 3.2-9.6a15.93 15.93 0 00-3.201-9.602z'
              fill='#fec40b'
            />
          </g>
        </svg>
      </i>
    );
  }
}
