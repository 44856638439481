import { Action, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  ImageIntersectionObserverOptions,
  lazyLoadedImageClass,
} from '~/utils/atoms/image';

const handleImageObserve: (image: HTMLImageElement) => boolean = (image) => {
  if (!image.hasAttribute('data-src')) {
    return false;
  }
  if (
    image.classList.contains(lazyLoadedImageClass) &&
    image.src === image.dataset[`src`]
  ) {
    return false;
  }
  const newSrc = image.dataset[`src`];
  if (newSrc?.trim()) {
    image.src = newSrc;
    image.setAttribute('src', newSrc);
    image.srcset = image.dataset[`srcset`] || '';
    image.setAttribute('srcset', image.srcset);
    image.classList.add(lazyLoadedImageClass);
    return true;
  }
  return false;
};

@Module({
  name: 'ImageObserverModule',
  stateFactory: true,
  namespaced: true,
})
export default class ImageObserverModule extends AbstractModule {
  public observer: IntersectionObserver | null = null;

  @Action({ rawError: true })
  public initImageObserver(): Promise<IntersectionObserver> {
    return new Promise((resolve, reject) => {
      if (!this.observer) {
        try {
          const observer = new IntersectionObserver((observed, obs) => {
            observed.map((entry) => {
              const element = entry.target;
              if (entry.isIntersecting && element instanceof HTMLImageElement) {
                const call = () => {
                  const handled = handleImageObserve(element);
                  if (handled) {
                    obs.unobserve(element);
                  }
                };
                if (element.hasAttribute('data-lazy-timeout')) {
                  const timeout = Number(element.dataset[`lazyTimeout`]);
                  if (!isNaN(timeout)) {
                    new Promise((res) => setTimeout(res, timeout)).then(call);
                  }
                } else {
                  call();
                }
              }
            });
          }, ImageIntersectionObserverOptions);
          this.setObserver(observer);
          resolve(observer);
        } catch {
          this.setObserver(null);
          reject(null);
        }
      } else {
        resolve(this.observer);
      }
    });
  }

  @Action({ rawError: true })
  public observeImage(image: HTMLImageElement) {
    this.initImageObserver().then((observer) => observer.observe(image));
  }

  @Mutation
  public unobserveImage(image: HTMLImageElement) {
    if (this.observer) {
      this.observer.unobserve(image);
    }
  }

  @Mutation
  protected setObserver(observer: IntersectionObserver | null) {
    this.observer = observer;
  }
}
