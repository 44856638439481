export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Jeg er enig i {0}',
          link: 'behandling af personoplysninger.',
          error: 'Du skal acceptere behandling af personoplysninger.',
        },
      },
      send: 'Sende',
      sent: 'Formularen blev sendt.',
    },
  },
};
