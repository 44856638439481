import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Headline.scss';

export enum Align {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum LineColor {
  RED = 'red',
  WHITE = 'white',
}

export interface HeadlineInterface {
  level?: number;
  align?: Align;
  underscore?: boolean;
  light?: boolean;
  lineColor?: LineColor;
}

@Component({
  style,
})
export default class Headline extends VueComponent<HeadlineInterface>
  implements HeadlineInterface {
  @Prop({
    default: 3,
    type: Number,
    validator: (value: number) => {
      return value > 0 && value <= 5;
    },
  })
  public level!: number;

  @Prop({ default: false, type: Boolean })
  public underscore!: boolean;

  @Prop({ default: Align.CENTER, type: String })
  public align!: Align;

  @Prop({ default: LineColor.RED, type: String })
  public lineColor!: LineColor;

  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  public render(createElement: typeof Vue.prototype.$createElement) {
    if (!this.$slots.default) {
      return;
    }
    const className: string = 'czt-headline';
    const classes: string[] = [className, `${className}--${this.align}`];
    if (this.underscore) {
      classes.push(`${className}--underscored`);
    }
    if (this.lineColor === LineColor.WHITE) {
      classes.push(`${className}--underscored--white`);
    }
    if (this.light) {
      classes.push(`${className}--light`);
    }
    return createElement(
      'h' + this.level,
      {
        class: classes,
      },
      this.$slots.default
    );
  }
}
