export default {
  app: {
    newsletter: {
      bottomText:
        'Ved å abonnere på nyhetsbrevet godtar du vår <a href="~/Privacy-Policy" target="_blank">personvernpolicy</a>.',
      button: 'Bestill',
      ctaDialogParagraph1: 'Do you want to know the upcoming events in Prague?',
      ctaDialogParagraph2:
        'Not sure which castle to visit on your Czech Republic tour?',
      ctaDialogParagraph3:
        'Subscribe to our newsletter and you will receive many useful tips for your next trip!',
      ctaDialogTitle: 'KNOCK KNOCK!',
      ctaDialogBtn: 'CLICK HERE',
      dialog: {
        headline: 'Velg landet/regionen av ditt nyhetsbrev',
        notice:
          'Din e-postadresse som er oppgitt ovenfor, vil bare bli brukt i forbindelse til din abonnement på vårt nyhetsbrev, og den vil ikke bli gitt ut til andre subjekter. Vi registrerer e-postadressen din for dette formålet til den dagen du avslutter abonnementet ditt. Du kan avslutte abonnementet når som helst ved å klikke på lenken i meldingen som leverer nyhetsbrevet. Din e-postadresse kan bli behandlet av IT-selskapet som administrerer driften og utviklingen av våre nettsider. {0}',
        company: 'Datakontrolleren er {0}, {1}',
        link: {
          privacy: 'Våre personvernregler er tilgjengelig her.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'E-postadressen din',
      newsletterError:
        'There seem to be no newsletters for you to subscribe to right now',
      serverError:
        'Det oppsto et problem under behandling av forespørselen. Vær så snill, prøv på nytt.',
      success:
        'For å fullføre påmeldingsprosessen, vennligst bekreft registreringen i e-posten vi har sendt deg.',
      title: 'Bestill vårt nyhetsbrev',
      topText:
        'Registrer deg for å motta den siste informasjonen, tipsene, kommende arrangementer og nye historier.',
      validityError: 'E-postadressen din ser ut til å være feil',
    },
  },
};
