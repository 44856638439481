export default {
  app: {
    panorama: {
      loadingText: 'Загрузка изображения...',
      autorotate: 'Автоповорот',
      zoom: 'Масштаб',
      zoomOut: 'Отдалить',
      zoomIn: 'Приблизить',
      move: 'Переместить',
      download: 'Загрузка',
      fullscreen: 'Полный экран',
      menu: 'Меню',
      twoFingers: 'Навигация двумя пальцами',
      ctrlZoom: 'ctrl + scroll чтобы приблизить',
      loadError: 'Не удалось загрузить панораму',
    },
  },
};
