import { Component, Prop } from 'vue-property-decorator';
import { ImageInterface } from '~/components/atoms/image/Image';
import { MediaBackground, Link } from '~/components/molecules';
import { ImageFilterMixin, ImageFilterMixinInterface } from '~/mixins';
import { ThemeRatios } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';

import style from './ImageTile.scss';
import { Sizes } from '../imageWrapper/ImageWrapper';
import { LinkTarget } from '~/utils/molecules';

export interface ImageTileInterface extends ImageFilterMixinInterface {
  image: ImageInterface;
  link?: string;
  ratio?: ThemeRatios;
  forcedLocale?: string;
  target?: LinkTarget;
}

/*
 *  min width is taken from defined breakpoints
 *  width is calculated from max container width for the given breakpoint and from the number of columns it takes up in it (12 column grid)
 *  follows the grid list item column width set in GridList.scss
 */
const sizes: Sizes[] = [
  {
    minWidth: 1264,
    size: (1150 / 12) * 4,
    unit: 'px',
  },
  {
    minWidth: 1008,
    size: (1000 / 12) * 4,
    unit: 'px',
  },
  {
    minWidth: 768,
    size: (710 / 12) * 4,
    unit: 'px',
  },
  {
    minWidth: 640,
    size: (640 / 12) * 6,
    unit: 'px',
  },
  {
    size: 50,
    unit: 'vw',
  },
];

@Component({
  style,
})
export default class ImageTile
  extends VueComponentMixin<ImageTileInterface, ImageFilterMixinInterface>(
    ImageFilterMixin
  )
  implements ImageTileInterface {
  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop({ default: ThemeRatios['1x1'] })
  public ratio!: ThemeRatios;

  @Prop({ type: String })
  public link?: string;

  @Prop({ type: String })
  public forcedLocale?: string;

  @Prop({ type: String })
  public target?: LinkTarget;

  public render() {
    if (this.link) {
      return (
        <Link
          url={this.link}
          forcedLocale={this.forcedLocale}
          target={this.target}
        >
          {this.renderTile()}
        </Link>
      );
    }
    return this.renderTile();
  }

  protected renderTile() {
    return (
      <div class='czt-image-tile'>
        <MediaBackground
          image={this.image}
          imageFilter={this.imageFilter}
          ratio={this.ratio}
          sizes={sizes}
        >
          <div class='czt-image-tile__content'>{this.$slots.default}</div>
        </MediaBackground>
      </div>
    );
  }
}
