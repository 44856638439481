export default {
  app: {
    search: {
      button: 'Vind',
      noResults: 'Geen resultaten gevonden',
      title: 'Zoeken',
      widgetTitle: 'Zoek wat u in het land kunt doen',
    },
  },
};
