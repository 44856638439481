export default {
  app: {
    safeTravels: {
      search: '施設を検索',
      categories: 'カテゴリー',
      button: '検索',
      table: {
        name: '施設名',
        link: 'ウェブサイト',
      },
      categoryTranslations: {
        eventy: 'イベント',
        kongresy_a_veletrhy: 'MICE',
        restaurace: 'レストラン',
        ubytovací_zařízení: '宿泊施設',
        sauny: 'サウナ',
        přírodní_a_umělá_koupaliště: 'プール',
        lanová_doprava: 'ケーブルカー',
        maloobchod: 'ショップ',
        fitness_centra: 'フィットネス施設',
        kina: '映画館',
        golfové_areály: 'ゴルフ',
      },
    },
  },
};
