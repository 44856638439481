import { Action, Module, Mutation } from 'vuex-module-decorators';
import { ItemData } from '~/components/atoms/multiselect/Multiselect';
import {
  isMeatspaceListLocationsResponse,
  isMeatspaceLocation,
  isMeatspaceSearchResponse,
  isMeatspaceSearchResponsePayload,
  isMeatspaceVenueSearchItem,
  VenueFinderLanguage,
} from '~/utils/venueFinder';
import {
  isMeatspaceListTypesResponse,
  isMeatspaceType,
} from '~/utils/venueFinder/typeGuards';
import {
  MeatspaceListRequestBody,
  MeatspaceVenueSearch,
} from '~/utils/venueFinder/types';

import AbstractModule from './AbstractModule';

export interface VenueFilter {
  location: string;
  numberOfHostsMin: number | null;
  numberOfHostsMax: number | null;
  tags: string[];
  search: string;
}

interface SearchResultsCommit {
  results: MeatspaceVenueSearch[];
}

interface OptionsCommit {
  locations: ItemData[];
  types: ItemData[];
}

const defaultFilter: VenueFilter = {
  location: '',
  numberOfHostsMax: null,
  numberOfHostsMin: null,
  tags: [],
  search: '',
};

function createVenueFilter(data: Partial<VenueFilter>): VenueFilter {
  return {
    location: data.location || defaultFilter.location,
    numberOfHostsMax: data.numberOfHostsMax || defaultFilter.numberOfHostsMax,
    numberOfHostsMin: data.numberOfHostsMin || defaultFilter.numberOfHostsMin,
    search: data.search || defaultFilter.search,
    tags: data.tags || defaultFilter.tags,
  };
}

@Module({
  name: 'VenueFinderModule',
  stateFactory: true,
  namespaced: true,
})
export default class VenueFinderModule extends AbstractModule {
  public loading: boolean = false;

  public venues: MeatspaceVenueSearch[] = [];

  public hasNextPage: boolean = false;

  public types: ItemData[] = [];

  public locations: ItemData[] = [];

  public limit: number = 6;

  protected filter: VenueFilter = { ...defaultFilter };

  protected offset: number = 0;

  protected get requestBody(): MeatspaceListRequestBody {
    return {
      limit: this.limit,
      offset: this.offset,
      search: {
        parent: -1,
        location: this.filter.location || undefined,
        tags: this.filter.tags.length > 0 ? this.filter.tags : undefined,
        capacity:
          this.filter.numberOfHostsMin !== null
            ? this.filter.numberOfHostsMin
            : 1,
        max_capacity:
          this.filter.numberOfHostsMax !== null
            ? this.filter.numberOfHostsMax
            : undefined,
        keyword: this.filter.search.trim() || undefined,
      },
      options: {
        imagesCount: 1,
      },
    };
  }

  @Action({ rawError: true })
  public search(data?: Partial<VenueFilter>) {
    const filter = data ? createVenueFilter(data) : defaultFilter;
    if (
      this.venues.length > 0 &&
      this.filter.location === filter.location &&
      this.filter.numberOfHostsMax === filter.numberOfHostsMax &&
      this.filter.numberOfHostsMin === filter.numberOfHostsMin &&
      this.filter.search === filter.search &&
      this.filter.tags.sort().join(',') === filter.tags.sort().join(',')
    ) {
      return Promise.resolve();
    }
    this.initializeSearchData(filter);
    return this.loadMore();
  }

  @Action({ commit: 'addVenues', rawError: true })
  public loadMore(): Promise<SearchResultsCommit> {
    this.setLoading(true);
    const promise = this.$api
      .venueFinder()
      .meatSpaceWrapperPost(
        'venues/list',
        JSON.stringify(this.requestBody),
        VenueFinderLanguage.english
      )
      .then((response: any) => {
        const hasMorePages: boolean = !!(
          response.itemsCountNextPage && response.itemsCountNextPage > 0
        );
        this.setHasNextPage(hasMorePages);
        if (
          isMeatspaceSearchResponse(response) &&
          isMeatspaceSearchResponsePayload(response.payload)
        ) {
          this.setOffset(this.offset + this.limit);
          this.setHasNextPage(
            (response.payload.offset || 0) + response.payload.showing <
              response.payload.total
          );
          return {
            results: response.payload.venues.filter(isMeatspaceVenueSearchItem),
          };
        }
        this.setHasNextPage(false);
        return { results: [] };
      })
      .catch((err: any) => {
        this.setHasNextPage(false);
        return { results: [] };
      })
      .finally(() => {
        this.setLoading(false);
      });
    return promise;
  }

  @Action({ commit: 'setOptions', rawError: true })
  public getOptions(): Promise<OptionsCommit> {
    if (this.locations.length > 0 && this.locations.length > 0) {
      return Promise.resolve({
        locations: this.locations,
        types: this.types,
      });
    }
    return Promise.allSettled([
      this.$api
        .venueFinder()
        .meatSpaceWrapperGet(
          'system/locations',
          0,
          VenueFinderLanguage.english
        ),
      this.$api
        .venueFinder()
        .meatSpaceWrapperGet('system/types', 0, VenueFinderLanguage.english),
    ])
      .then(([locationsResponse, tagsResponse]) => {
        const options: OptionsCommit = {
          locations: [],
          types: [],
        };
        if (
          locationsResponse.status === 'fulfilled' &&
          isMeatspaceListLocationsResponse(locationsResponse.value)
        ) {
          options.locations = locationsResponse.value.payload
            .filter(isMeatspaceLocation)
            .map((location) => ({
              text: location.title,
              value: location.name,
            }));
        }
        if (
          tagsResponse.status === 'fulfilled' &&
          isMeatspaceListTypesResponse(tagsResponse.value)
        ) {
          options.types = tagsResponse.value.payload
            .filter(isMeatspaceType)
            .map((type) => ({ text: type.title, value: type.tag }));
        }
        return options;
      })
      .catch(() => {
        return {
          locations: [],
          types: [],
        };
      });
  }

  @Mutation
  protected initializeSearchData(data: VenueFilter) {
    this.filter = data;
    this.offset = 0;
    this.venues = [];
    this.hasNextPage = true;
  }

  @Mutation
  protected setOffset(offset: number) {
    this.offset = offset;
  }

  @Mutation
  protected setHasNextPage(state: boolean) {
    this.hasNextPage = state;
  }

  @Mutation
  protected addVenues(data: SearchResultsCommit) {
    this.venues = this.venues.concat(data.results);
  }

  @Mutation
  protected setLoading(state: boolean): void {
    this.loading = state;
  }

  @Mutation
  protected setOptions(options: OptionsCommit) {
    this.locations = options.locations;
    this.types = options.types;
  }
}
