export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: '我同意 {0}',
          link: '个人资料处理。',
          error: '您必须同意个人数据处理。',
        },
      },
      send: '发送',
      sent: '表格已成功发送。',
    },
  },
};
