export default {
  app: {
    common: {
      backToMenu: 'Retour au menu',
      blog: 'Blog',
      exploreButton: 'Explorer',
      language: 'Langue',
      loadMore: 'Voir autres',
      moreInfo: "Plus d'informations",
      opensInNewWindow: 'Ouvre dans une nouvelle fenêtre',
      pageTypes: {
        article: 'Article',
        category: 'Catégorie',
        event: 'Événement',
        page: 'Page',
        place: 'Endroit',
        region: 'Région',
      },
      selectLanguage: 'Choisir la langue',
      homepageBreadcrumb: 'Accueil',
      translationVoting: 'Comment avez-vous apprécié cette traduction ?',
      translationVotingSuccess: 'Merci pour votre avis !',
    },
  },
};
