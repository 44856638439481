export default {
  app: {
    gridList: {
      label: {
        incentive: 'Suodata kannusteen mukaan',
        itinerary: 'Suodata tagin mukaan',
        region: 'Suodata alueen mukaan',
      },
    },
  },
};
