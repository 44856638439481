export default {
  app: {
    footer: {
      aboutUs: 'チェコ政府観光局について',
      contactUs: 'お問い合わせ',
      pressAndMedia: 'プレス＆メディア',
      travelProfessionals: 'トレード',
      brochures: 'パンフレット',
      news: '最新情報',
      covid19: '新型コロナウイルス',
      destinations: '行き先',
      thingsToDo: '体験したいこと',
      travelInfo: '旅行情報',
      czechTourism: 'チェコ政府観光局',
      menu: 'メニュー',
      mobileApp: 'モバイルアプリ',
      visitCzechAndroid: 'VisitCzech Android向けモバイルアプリケーション',
      followUs: 'SNS',
      privacyPolicy: '個人情報保護方針',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/ja-jp/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookieの設定',
    },
  },
};
