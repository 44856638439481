import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { getLocaleFromRouter } from '~/app/core/router';
import MapPinsModule, {
  MapPinsRequest,
} from '~/app/core/store/modules/MapPinsModule';
import MapsModule from '~/app/core/store/modules/MapsModule';
import { Button, Headline } from '~/components/atoms';
import Dialog from '~/components/organisms/dialog/Dialog';
import Map, {
  MapPin,
  MapStyle,
  MapVariant,
} from '~/components/organisms/map/Map';
import { ThemeColors } from '~/utils/theme';
import { Ratios } from '~/utils/theme/ratios';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';
import { PinCategory } from './MapCategoryPin';
import MapPinFilter from './MapPinFilter';
import MapStyleSwitch from './MapStyleSwitch';

import style from './MapWidget.scss';
import { MapWidgetProps } from './types';
import { Align } from '~/components/atoms/headline/Headline';

const rootClass = 'czt-map-widget';

@Component({ style })
export default class MapWidget extends VueComponent<MapWidgetProps>
  implements MapWidgetProps {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true })
  public id!: string;

  @Prop({ required: true })
  public latitude!: number;

  @Prop({ required: true })
  public longitude!: number;

  @Prop({ default: () => [] })
  public preselectedPoi!: MapPin[];

  @Prop({ default: MapStyle.BASIC })
  public defaultStyle!: MapStyle;

  @Prop({ default: false, type: Boolean })
  public detail!: boolean;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: PinCategory.DEFAULT })
  public detailCategory!: PinCategory;

  @Prop({ type: String })
  public title!: string;

  @Prop({ required: true })
  public zoom!: number;

  @Ref('dialogMap')
  protected dialogMap?: Map;

  public className = CztWidgets.MAP;

  protected dialogActive: boolean = false;

  protected mapStyle: MapStyle = this.defaultStyle;

  protected currentZoom: number = this.zoom;

  protected currentCenter: [number, number] = [this.longitude, this.latitude];

  protected loadedPoi: MapPin[] = [];

  protected showLoaderButton: boolean = this.preselectedPoi.length > 0;

  protected selectedCategories: string[] = [];

  protected mapPinsLoading: boolean = false;

  protected get language() {
    const lang = getLocaleFromRouter(this.$router);
    switch (lang) {
      case 'de-DE':
        return 'de';
      case 'pl-PL':
        return 'pl';
      case 'sk-SK':
        return 'sk';
      case 'en-US':
      default:
        return 'en';
    }
  }

  protected get mapsModule() {
    return getModule(MapsModule, this.$store);
  }

  protected get mapPinsModule() {
    return getModule(MapPinsModule, this.$store);
  }

  protected get range(): number {
    switch (this.currentZoom) {
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return 70;
      case 11:
        return 35;
      case 12:
        return 16;
      case 13:
        return 8;
      case 14:
        return 4;
      case 15:
        return 2;
      case 16:
      case 17:
      case 18:
      default:
        return 1;
    }
  }

  protected get poiRequestObject(): MapPinsRequest {
    return {
      categories: this.selectedCategories.join(','),
      latitude: this.currentCenter[1],
      locale: getLocaleFromRouter(this.$router),
      longitude: this.currentCenter[0],
      range: this.range,
    };
  }

  public mounted() {
    this.mapsModule.loadApi(this.language);
    if (this.preselectedPoi.length < 1) {
      this.loadPoi();
    }
  }

  public render() {
    let component: JSX.Element = (
      <v-row class='pa-0 ma-0 fill-height' justify='center' align='center'>
        Could not load Mapy API
      </v-row>
    );
    if (this.mapsModule.loadingApi || this.mapsModule.loadingScripts) {
      component = (
        <v-row class='pa-0 ma-0 fill-height' justify='center' align='center'>
          <v-progress-circular color={ThemeColors.ACCENT} indeterminate />
        </v-row>
      );
    } else if (this.mapsModule.apiLoaded) {
      component = (
        <Map
          id={this.id}
          center={[this.longitude, this.latitude]}
          places={this.preselectedPoi}
          zoom={this.zoom}
          variant={this.detail ? MapVariant.SIMPLE : MapVariant.MOVABLE}
          detail={this.detail}
          detailCategory={this.detailCategory}
          mapStyle={this.defaultStyle}
        />
      );
    }

    const classes = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      classes.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      classes.push('czt-spacer--collapse-bottom');
    }

    return (
      <div class={classes.join(' ')}>
        <v-container id={this.anchorId} class={{ 'px-0': this.detail }}>
          {this.title && (
            <Headline level={this.detail ? 4 : 2} align={Align.LEFT} underscore>
              {this.title}
            </Headline>
          )}
          <v-responsive
            class={rootClass}
            aspect-ratio={this.detail ? Ratios['1x1'] : Ratios['16x9']}
          >
            {component}
            {this.mapsModule.apiLoaded && [
              <Button
                class={`${rootClass}__button`}
                onClick={() => {
                  this.dialogActive = true;
                }}
                small
              >
                {this.$t('app.maps.more')}
              </Button>,
              <Dialog
                active={this.dialogActive}
                onCloseDialog={() => {
                  this.dialogActive = false;
                }}
                fullScreen
              >
                <Map
                  ref='dialogMap'
                  detail={this.detail}
                  detailCategory={this.detailCategory}
                  id={`${this.id}`}
                  center={this.currentCenter}
                  places={this.preselectedPoi}
                  loadedPlaces={this.loadedPoi}
                  syncTimeout={301}
                  zoom={this.currentZoom}
                  variant={MapVariant.FULL}
                  onRedraw={({ center, zoom }) => {
                    if (
                      this.currentCenter[0] !== center[0] ||
                      this.currentCenter[1] !== center[1]
                    ) {
                      this.currentCenter = center;
                    }
                    if (this.currentZoom !== zoom) {
                      this.currentZoom = zoom;
                    }
                  }}
                  mapStyle={this.mapStyle}
                />
                <MapPinFilter
                  language={this.language}
                  loading={this.mapPinsLoading}
                  onCategoryChange={(categories) => {
                    this.selectedCategories = categories;
                    this.loadPoi();
                  }}
                  onLocationChange={(location) => {
                    if (
                      this.currentCenter[0] !== location[0] ||
                      this.currentCenter[1] !== location[1]
                    ) {
                      this.currentCenter = location;
                      this.loadPoi();
                    }
                  }}
                />
                <MapStyleSwitch
                  currentStyle={this.mapStyle}
                  onStyleChange={(selectedStyle) => {
                    this.mapStyle = selectedStyle;
                  }}
                />
                {this.showLoaderButton && (
                  <Button
                    class={`${rootClass}__load-poi`}
                    onClick={this.loadPoi}
                  >
                    {this.$t('app.maps.loadPoI')}
                  </Button>
                )}
                {this.mapPinsLoading && (
                  <v-overlay absolute style={{ zIndex: 500 }}>
                    <v-row
                      class='pa-0 ma-0 fill-height'
                      justify='center'
                      align='center'
                    >
                      <v-progress-circular
                        color={ThemeColors.ACCENT}
                        indeterminate
                      />
                    </v-row>
                  </v-overlay>
                )}
              </Dialog>,
            ]}
          </v-responsive>
        </v-container>
      </div>
    );
  }

  protected loadPoi() {
    this.mapPinsLoading = true;
    return this.mapPinsModule
      .getMapPins(this.poiRequestObject)
      .then((poi) => {
        this.loadedPoi = poi;
      })
      .finally(() => {
        this.showLoaderButton = false;
        this.mapPinsLoading = false;
      });
  }

  @Watch('currentCenter', { deep: true })
  @Watch('currentZoom')
  protected showFinderButton() {
    this.showLoaderButton = true;
  }
}
