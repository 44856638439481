export default {
  app: {
    cookies: {
      title: '我们尊重您的隐私',
      text:
        '我们使用cookies来个性化内容、分析流量并提供社交媒体功能。我们与我们的社交媒体和分析合作伙伴分享有关您如何使用我们网站的信息。合作伙伴可以将此信息与您提供给他们的其他信息或您使用其服务后获得的其他信息相结合。{0}。 {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: '你可以在这里找到更多的细节',
      },
      buttons: {
        accept: '接受所有cookies',
        save: '保存选定',
      },
      settings: {
        button: '详细设置',
        required: '强制要求（为了网站正常运行）',
        analytics: '分析（了解网站访问者的行为或移动方式）',
        statistics: '数据（以便了解访客如何使用网站)',
        marketing: '市场推广（跟踪访客展示相关广告）',
        reject: '全部拒绝',
        accept: '全部接受',
      },
    },
  },
};
