export default {
  app: {
    safeTravels: {
      search: '找尋設施',
      categories: '選擇種類',
      button: '搜尋',
      table: {
        name: '設施名稱',
        link: '網站',
      },
      categoryTranslations: {
        eventy: '活動',
        kongresy_a_veletrhy: 'MICE會展',
        restaurace: '餐廳',
        ubytovací_zařízení: '住宿',
        sauny: '桑拿',
        přírodní_a_umělá_koupaliště: '泳池',
        lanová_doprava: '纜車',
        maloobchod: '零售商店',
        fitness_centra: '健身房',
        kina: '電影院',
        golfové_areály: '高爾夫',
      },
    },
  },
};
