import { CztWidgets } from '~/utils/views/widgets';
import { TradeOffersListInterface } from './types';

export default function(data: any): data is TradeOffersListInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.TRADE_OFFFERS &&
    typeof data.guid === 'string'
  );
}
