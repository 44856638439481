export default {
  app: {
    safeTravels: {
      search: 'Zoek locatie',
      categories: 'Selecteer een categorie',
      button: 'Zoeken',
      table: {
        name: 'Naam vestiging',
        link: 'Website',
      },
      categoryTranslations: {
        eventy: 'Evenement',
        kongresy_a_veletrhy: 'Beurzen en congressen',
        restaurace: 'Restaurant',
        ubytovací_zařízení: 'Accommodatie',
        sauny: 'Sauna',
        přírodní_a_umělá_koupaliště: 'Natuur- en buitenzwembaden',
        lanová_doprava: 'Kabelbaan',
        maloobchod: 'Winkel',
        fitness_centra: 'Fitness',
        kina: 'Bioscoop',
        golfové_areály: 'Golfbaan',
      },
    },
  },
};
