export default {
  app: {
    search: {
      button: 'Rasti',
      noResults: 'Nieko nerasta',
      title: 'Ieškoti',
      widgetTitle: 'Raskite ką veikti šalyje',
    },
  },
};
