export default {
  app: {
    search: {
      button: 'Nájsť',
      noResults: 'Neboli nájdené žiadne výsledky',
      title: 'Vyhľadávanie',
      widgetTitle: 'Zistite, čo môžete robiť v krajine',
    },
  },
};
