export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Homepage',
      destinations: 'Destinos',
      thingsToDo: 'Atividades',
      new: 'Novidades',
      covid: 'Covid-19',
      travelInfo: 'Informação de viagem',
    },
  },
};
