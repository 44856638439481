export default {
  app: {
    gridList: {
      label: {
        incentive: 'Szűrés ösztönző alapján',
        itinerary: 'Szűrés címke alapján',
        region: 'Szűrés régió alapján',
      },
    },
  },
};
