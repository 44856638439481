import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Map.scss';
import { link } from '~/utils/molecules';
import createCard from '~/utils/maps/createCard';
import MapCategoryPin, {
  PinCategory,
} from '~/components/templates/mapWidget/MapCategoryPin';

export enum MapVariant {
  SIMPLE,
  MOVABLE,
  FULL,
}

export enum MapStyle {
  BASIC = 'basic',
  OUTDOOR = 'tourist',
  AERIAL = 'ophoto',
  HYBRID = 'hybrid',
  WINTER = 'winter',
}

export function isMapStyle(data: any): data is MapStyle {
  for (const value in MapStyle) {
    if (MapStyle.hasOwnProperty(value)) {
      if (data === (MapStyle as any)[value]) {
        return true;
      }
    }
  }
  return false;
}

interface OnRedraw {
  center: [number, number];
  zoom: number;
}
export interface MapProps {
  id: string;
  detail?: boolean;
  detailCategory: PinCategory;
  mapStyle?: MapStyle;
  center: [number, number];
  places?: MapPin[];
  loadedPlaces?: MapPin[];
  syncTimeout?: number;
  variant?: MapVariant;
  zoom: number;
  onRedraw?: (data: OnRedraw) => void;
}

export interface MapPin {
  id: string;
  latitude: number;
  longitude: number;
  url: string;
  image: string;
  title: string;
  category: PinCategory;
}

const rootClass = 'czt-map';

@Component({
  style,
})
export default class Map extends VueComponent<MapProps> implements MapProps {
  @Prop({ required: true })
  public id!: string;

  @Prop({ default: false, type: Boolean })
  public detail!: boolean;

  @Prop({ required: true })
  public detailCategory!: PinCategory;

  @Prop({ default: MapStyle.BASIC })
  public mapStyle!: MapStyle;

  @Prop({ required: true })
  public center!: [number, number];

  @Prop({ default: () => [] })
  public places!: MapPin[];

  @Prop({ default: () => [] })
  public loadedPlaces!: MapPin[];

  @Prop({ default: 0 })
  public syncTimeout!: number;

  @Prop({ default: MapVariant.SIMPLE })
  public variant!: MapVariant;

  @Prop({ required: true })
  public zoom!: number;

  @Ref('map')
  protected mapWrapper!: HTMLDivElement;

  @Ref('defaultPin')
  protected defaultPin!: MapCategoryPin;

  @Ref(PinCategory.ACCOMMODATION)
  protected accomodationPin!: MapCategoryPin;

  @Ref(PinCategory.CULTURE)
  protected culturePin!: MapCategoryPin;

  @Ref(PinCategory.EVENT)
  protected eventPin!: MapCategoryPin;

  @Ref(PinCategory.EXPERIENCES)
  protected experiencesPin!: MapCategoryPin;

  @Ref(PinCategory.LANDMARKS)
  protected landmarkPin!: MapCategoryPin;

  @Ref(PinCategory.LIFESTYLE)
  protected lifestylePin!: MapCategoryPin;

  @Ref(PinCategory.NATURE)
  protected naturePin!: MapCategoryPin;

  @Ref(PinCategory.RESTAURANTS)
  protected restaurantPin!: MapCategoryPin;

  @Ref(PinCategory.SPA)
  protected spaPin!: MapCategoryPin;

  @Ref(PinCategory.SUMMER_SPORTS)
  protected summerPin!: MapCategoryPin;

  @Ref(PinCategory.WINTER_SPORTS)
  protected winterPin!: MapCategoryPin;

  public map: any = null;

  protected coords: any = null;

  protected detailMarker: any = null;

  protected placesMarkers: any[] = [];

  protected placesCards: any[] = [];

  protected loadedMarkers: any[] = [];

  protected loadedCards: any[] = [];

  protected poiLayer: any = null;

  protected poiClusterer: any = null;

  protected styles: { [key: string]: any } = {};

  protected currentLatitude: number = this.center[1];
  protected currentLongitude: number = this.center[0];

  protected clustering: boolean = false;

  protected get placeIDs() {
    return this.places.map((place) => place.id);
  }

  public mounted() {
    this.coords = window.SMap.Coords.fromWGS84(
      this.currentLongitude,
      this.currentLatitude
    );
    this.map = new window.SMap(this.mapWrapper, this.coords, this.zoom);
    this.addStyles();
    this.addControls();
    this.addPoiLayer();
    this.toggleClusterers();
    if (this.syncTimeout) {
      setTimeout(() => {
        this.map.addControl(new window.SMap.Control.Sync());
      }, this.syncTimeout);
    } else {
      this.map.addControl(new window.SMap.Control.Sync());
    }
    this.map._dom.container.classList.add('smap-defaults');
    if (this.variant === MapVariant.FULL) {
      const signals = this.map.getSignals();
      signals.addListener(window, 'map-redraw', this.redraw);
    }
  }

  public render() {
    const classes = [rootClass, `${rootClass}--${MapVariant[this.variant]}`];
    return (
      <div class={classes.join(' ')}>
        <div class='d-none'>
          <MapCategoryPin categoryId='' ref='defaultPin' />
          <MapCategoryPin
            categoryId={PinCategory.ACCOMMODATION}
            ref={PinCategory.ACCOMMODATION}
          />
          <MapCategoryPin
            categoryId={PinCategory.CULTURE}
            ref={PinCategory.CULTURE}
          />
          <MapCategoryPin
            categoryId={PinCategory.EVENT}
            ref={PinCategory.EVENT}
          />
          <MapCategoryPin
            categoryId={PinCategory.EXPERIENCES}
            ref={PinCategory.EXPERIENCES}
          />
          <MapCategoryPin
            categoryId={PinCategory.LANDMARKS}
            ref={PinCategory.LANDMARKS}
          />
          <MapCategoryPin
            categoryId={PinCategory.LIFESTYLE}
            ref={PinCategory.LIFESTYLE}
          />
          <MapCategoryPin
            categoryId={PinCategory.NATURE}
            ref={PinCategory.NATURE}
          />
          <MapCategoryPin
            categoryId={PinCategory.RESTAURANTS}
            ref={PinCategory.RESTAURANTS}
          />
          <MapCategoryPin categoryId={PinCategory.SPA} ref={PinCategory.SPA} />
          <MapCategoryPin
            categoryId={PinCategory.SUMMER_SPORTS}
            ref={PinCategory.SUMMER_SPORTS}
          />
          <MapCategoryPin
            categoryId={PinCategory.WINTER_SPORTS}
            ref={PinCategory.WINTER_SPORTS}
          />
        </div>
        <div
          class={`${rootClass}__wrapper`}
          ref='map'
          id={`${this.id}--${this.variant}`}
        />
      </div>
    );
  }

  public beforeDestroy() {
    if (this.map && typeof this.map.$destructor === 'function') {
      this.map.$destructor();
    }
  }

  protected addStyles() {
    this.styles[MapStyle.AERIAL] = this.map.addDefaultLayer(
      window.SMap.DEF_OPHOTO
    );
    this.styles[MapStyle.BASIC] = this.map.addDefaultLayer(
      window.SMap.DEF_BASE
    );
    this.styles[MapStyle.HYBRID] = this.map.addDefaultLayer(
      window.SMap.DEF_HYBRID
    );
    this.styles[MapStyle.OUTDOOR] = this.map.addDefaultLayer(
      window.SMap.DEF_TURIST
    );
    this.styles[MapStyle.WINTER] = this.map.addDefaultLayer(
      window.SMap.DEF_TURIST_WINTER
    );
    this.changeMapStyle();
  }

  protected addControls() {
    if (this.variant === MapVariant.SIMPLE) {
      return;
    }
    if (this.variant === MapVariant.FULL) {
      this.map.addControl(
        new window.SMap.Control.Compass({ title: window.SMap._('map.move') })
      );
      this.map.addControl(
        new window.SMap.Control.Zoom(
          {
            2: window.SMap._('map.zooms.world'),
            5: window.SMap._('map.zooms.states'),
            8: window.SMap._('map.zooms.districts'),
            11: window.SMap._('map.zooms.cities'),
            14: window.SMap._('map.zooms.municipalities'),
            17: window.SMap._('map.zooms.streets'),
            20: window.SMap._('map.zooms.houses'),
            21: window.SMap._('map.zooms.birdEye'),
          },
          {
            titles: [window.SMap._('map.zoomIn'), window.SMap._('map.zoomOut')],
          }
        )
      );
    }
    this.map.setCursor('move');
    this.map.addControl(
      /* tslint:disable:no-bitwise */
      new window.SMap.Control.Mouse(
        window.SMap.MOUSE_PAN | window.SMap.MOUSE_WHEEL | window.SMap.MOUSE_ZOOM
      )
      /* tslint:enable:no-bitwise */
    );
    this.map.addControl(new window.SMap.Control.Scale());
  }

  protected getCard() {
    const card = new window.SMap.Card();
    card.setSize(250, 350);
    return card;
  }

  protected addPoiLayer() {
    this.poiLayer = new window.SMap.Layer.Marker(`${this.id}--poi`);
    this.map.addLayer(this.poiLayer).enable();
    this.addMarkers();
  }

  @Watch('loadedPlaces', { deep: true })
  protected addMarkers() {
    if (this.poiLayer) {
      this.map.removeCard();
      this.poiLayer.removeAll();
      this.loadedMarkers.forEach((marker) => {
        if (marker && typeof marker.$destructor === 'function') {
          marker.$destructor();
        }
      });
      this.loadedCards.forEach((card) => {
        if (card && typeof card.$destructor === 'function') {
          card.$destructor();
        }
      });
      this.loadedCards = [];
      if (this.detail) {
        if (!this.detailMarker) {
          const detailClass = `${rootClass}__detail-pin`;
          const pin = this.getCategoryPin(this.detailCategory);
          pin.$el.classList.add(detailClass);
          const clone = pin.$el.cloneNode(true);
          pin.$el.classList.remove(detailClass);
          this.detailMarker = new window.SMap.Marker(this.coords, this.id, {
            url: clone,
            anchor: { left: 29, bottom: 1 },
          });
        }
        this.poiLayer.addMarker(this.detailMarker);
      }
      if (this.places.length > 0 || this.loadedPlaces.length > 0) {
        this.loadedMarkers = [
          ...this.places,
          ...this.loadedPlaces.filter(
            (place) => !this.placeIDs.includes(place.id) && this.id !== place.id
          ),
        ]
          .map((place) => {
            if (place.latitude === 0 && place.longitude === 0) {
              return;
            }
            const pin = this.getCategoryPin(place.category);
            const placeMarker = new window.SMap.Marker(
              window.SMap.Coords.fromWGS84(place.longitude, place.latitude),
              place.id,
              {
                url: pin.$el.cloneNode(true),
                title: place.title,
                anchor: { left: 29, bottom: 1 },
              }
            );
            const cardData = createCard(
              place,
              place.url,
              this.$t('app.common.moreInfo').toString(),
              this.$i18n.locale
            );
            const card = this.getCard();
            card.setOwner(this.map);
            card.getHeader().innerHTML = cardData.header;
            card.getBody().innerHTML = cardData.body;
            card.getFooter().innerHTML = cardData.footer;
            placeMarker.decorate(window.SMap.Marker.Feature.Card, card);
            this.loadedCards.push(card);
            return placeMarker;
          })
          .filter((place) => !!place);
        this.poiLayer.addMarker(this.loadedMarkers);
      }
    }
  }

  @Watch('mapStyle')
  protected changeMapStyle() {
    Object.values(this.styles).forEach((styleLayer: any) => {
      styleLayer.disable();
    });
    switch (this.mapStyle) {
      case MapStyle.AERIAL:
        this.styles[MapStyle.AERIAL].enable();
        this.styles[MapStyle.HYBRID].enable();
        break;
      case MapStyle.OUTDOOR:
        this.styles[MapStyle.OUTDOOR].enable();
        break;
      case MapStyle.WINTER:
        this.styles[MapStyle.WINTER].enable();
        break;
      case MapStyle.BASIC:
      default:
        this.styles[MapStyle.BASIC].enable();
    }
  }

  @Watch('zoom')
  protected toggleClusterers() {
    if (this.clustering && this.zoom > 16) {
      if (this.poiClusterer) {
        this.poiClusterer = null;
      }
      if (this.poiLayer) {
        this.poiLayer.setClusterer(null);
      }
      this.clustering = false;
    } else if (!this.clustering && this.zoom <= 16) {
      if (this.poiLayer) {
        this.poiClusterer =
          this.poiClusterer || new window.SMap.Marker.Clusterer(this.map);
        this.poiLayer.setClusterer(this.poiClusterer);
      }
      this.clustering = true;
    }
  }

  @Watch('center', { deep: true })
  protected updateCenter() {
    if (this.map) {
      if (
        this.center[0] !== this.currentLongitude ||
        this.center[1] !== this.currentLatitude
      ) {
        this.map.setCenter(
          window.SMap.Coords.fromWGS84(this.center[0], this.center[1]),
          true
        );
        this.currentLongitude = this.center[0];
        this.currentLatitude = this.center[1];
      }
    }
  }

  @Emit('redraw')
  protected redraw() {
    if (this.map) {
      const center = this.map.getCenter();
      const [lng, lat] = center.toWGS84();
      this.currentLatitude = lat;
      this.currentLongitude = lng;
      const zoom = this.map.getZoom();
      return { center: [lng, lat], zoom };
    }
  }

  protected getCategoryPin(categoryId: string) {
    switch (categoryId) {
      case PinCategory.ACCOMMODATION:
        return this.accomodationPin;
      case PinCategory.CULTURE:
        return this.culturePin;
      case PinCategory.EVENT:
        return this.eventPin;
      case PinCategory.EXPERIENCES:
        return this.experiencesPin;
      case PinCategory.LANDMARKS:
        return this.landmarkPin;
      case PinCategory.LIFESTYLE:
        return this.lifestylePin;
      case PinCategory.NATURE:
        return this.naturePin;
      case PinCategory.RESTAURANTS:
        return this.restaurantPin;
      case PinCategory.SPA:
        return this.spaPin;
      case PinCategory.SUMMER_SPORTS:
        return this.summerPin;
      case PinCategory.WINTER_SPORTS:
        return this.winterPin;
      default:
        return this.defaultPin;
    }
  }
}
