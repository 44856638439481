import { CztWidgets } from '~/utils/views/widgets';
import { SmartGuideInterface } from './types';

export default function(data: any): data is SmartGuideInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.SMART_GUIDE
  );
}
