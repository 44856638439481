export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtrera efter incitament',
        itinerary: 'Filtrera efter tagg',
        region: 'Filtrera efter region',
      },
    },
  },
};
