import { Component, Prop } from 'vue-property-decorator';
import { Image } from '~/components/atoms';
import { VueComponent } from '~/utils/vue-component';

import style from './WinterDataGallery.scss';

export interface Webcam {
  title: string;
  url: string;
  imgUrl: string;
}

interface WinterDataGalleryProps {
  items: Webcam[];
}

const rootClass = 'czt-winter-data-gallery';

@Component({ style })
export default class WinterDataGallery
  extends VueComponent<WinterDataGalleryProps>
  implements WinterDataGalleryProps {
  @Prop({ required: true })
  public items!: Webcam[];

  protected slide: number = 0;

  public render() {
    if (this.items.length < 1) {
      return;
    }
    const classes = [rootClass];

    if (this.items.length === 1) {
      let component: JSX.Element | undefined;
      if (this.items[0].url) {
        component = (
          <video controls poster={this.items[0].imgUrl ?? undefined}>
            <source src={this.items[0].url} />
          </video>
        );
      } else if (this.items[0].imgUrl) {
        component = (
          <Image alt={this.items[0].title} src={this.items[0].imgUrl} />
        );
      }
      return (
        component && (
          <div class={classes.join(' ')}>
            <div class={`${rootClass}__item__title`}>{this.items[0].title}</div>
            {component}
          </div>
        )
      );
    }

    return (
      <v-carousel
        class={classes.join(' ')}
        height='auto'
        show-arrows-on-hover
        hide-delimiters
        v-model={this.slide}
      >
        {this.items.map((item, key) => {
          let component: JSX.Element | undefined;
          if (item.url) {
            component = (
              <video
                controls
                poster={item.imgUrl ?? undefined}
                preload='metadata'
              >
                <source src={item.url} />
              </video>
            );
          } else if (item.imgUrl) {
            component = <Image alt={item.title} src={item.imgUrl} />;
          }
          return (
            component && (
              <v-carousel-item
                content-class={`${rootClass}__item`}
                key={item.title + key}
                eager
              >
                <div class={`${rootClass}__item__title`}>{item.title}</div>
                {component}
                <div
                  class={`${rootClass}__item__number`}
                  onClick={() => {
                    this.slide =
                      this.slide + 1 === this.items.length ? 0 : this.slide + 1;
                  }}
                >{`${key + 1} / ${this.items.length}`}</div>
              </v-carousel-item>
            )
          );
        })}
      </v-carousel>
    );
  }
}
