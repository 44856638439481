export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Homepagina',
      destinations: 'Bestemmingen',
      regions: "Regio's",
      cities: 'Steden',
      thingsToDo: 'Wat te doen',
      alwaysVisit: 'Bezoek altijd',
      culturalHeritage: 'Cultureel erfgoed',
      activeHoliday: 'Actieve vakantie',
      healthAndSpa: 'Gezondheid & Spa',
      new: 'Nieuw',
      covid: 'Covid-19',
      travelInfo: 'Reisinformatie',
    },
  },
};
