export default {
  app: {
    newsletter: {
      bottomText:
        'Prihlásením sa na odber noviniek súhlasíte s našimi <a href="~/Privacy-Policy" target="_blank">zásadami ochrany údajov</a>.',
      button: 'Prihlásiť sa na odber',
      ctaDialogParagraph1: 'Do you want to know the upcoming events in Prague?',
      ctaDialogParagraph2:
        'Not sure which castle to visit on your Czech Republic tour?',
      ctaDialogParagraph3:
        'Subscribe to our newsletter and you will receive many useful tips for your next trip!',
      ctaDialogTitle: 'KNOCK KNOCK!',
      ctaDialogBtn: 'CLICK HERE',
      dialog: {
        headline: 'Vyberte krajinu/región pre váš newsletter',
        notice:
          'Vaša e-mailová adresa uvedená vyššie bude použitá iba na účely odberu noviniek a nebude poskytnutá žiadnym iným subjektom. Vašu e-mailovú adresu budeme na tento účel registrovať až do dňa, keď sa odhlásite z odberu noviniek. Z odberu noviniek sa môžete kedykoľvek odhlásiť kliknutím na odkaz v správe doručujúcej newsletter. Vaša e-mailová adresa môže byť spracovaná IT spoločnosťou, ktorá spravuje prevádzku a vývoj našich webových stránok. {0}',
        company: 'Prevádzkovateľom údajov je {0}, {1}',
        link: {
          privacy: 'Naše zásady ochrany osobných údajov sú k dispozícii tu.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Vaša e-mailová adresa',
      newsletterError:
        'Zdá sa, že práve teraz nie sú k dispozícii žiadne newslettery, na ktorých odber by ste sa mohli prihlásiť',
      serverError:
        'Pri vykonávaní požiadavky sa vyskytla chyba. Prosím skúste znova',
      success:
        'Pre dokončenie registrácie prosím potvrďte svoju registráciu v e-maile, ktorý sme vám poslali.',
      title: 'Odoberajte náš newsletter',
      topText:
        'Prihláste sa a získajte najnovšie informácie, tipy, pripravované podujatia a nové príbehy.',
      validityError: 'Zdá sa, že vaša e-mailová adresa je neplatná',
    },
  },
};
