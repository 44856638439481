export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Homepage',
      destinations: '旅行地点',
      thingsToDo: '推荐体验',
      new: '时事关注',
      covid: '新型冠状病毒肺炎',
      travelInfo: '旅游信息',
    },
  },
};
