export default {
  app: {
    footer: {
      aboutUs: '关于我们',
      contactUs: '联系我们',
      pressAndMedia: '新闻&媒体',
      travelProfessionals: '旅游专业',
      brochures: '电子小册子',
      news: '时事关注',
      covid19: '新型冠状病毒肺炎',
      destinations: '旅行地点',
      thingsToDo: '推荐体验',
      travelInfo: '旅游信息',
      czechTourism: '捷克旅游局',
      menu: '菜单',
      mobileApp: '移动应用',
      visitCzechAndroid: 'VisitCzech Android移動應用',
      followUs: '跟着我们',
      privacyPolicy: '隐私政策',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/zh-cn/privacy-policy',
      cookies: 'Cookies和分析',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookies设置',
    },
  },
};
