export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Kezdőlap',
      destinations: 'Úticélok',
      regions: 'Régiók',
      cities: 'Városok',
      thingsToDo: 'Tennivalók',
      alwaysVisit: 'Látogatás bármikor',
      culturalHeritage: 'Kulturális örökség',
      activeHoliday: 'Aktív vakáció',
      healthAndSpa: 'Gyógyászati- és fürdőközpont',
      new: 'Új',
      covid: 'Covid-19',
      travelInfo: 'Utazási információk',
    },
  },
};
