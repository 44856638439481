import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';
import Vuetify from 'vuetify/es5/locale/hu';
import GeneralLocalization from './general';

const localizations: LocaleMessages[] = [
  GeneralLocalization,
  {
    $vuetify: Vuetify,
  },
];

const mergedLocalizations: LocaleMessages = deepmerge.all(
  localizations
) as LocaleMessages;

export default mergedLocalizations;
