import friendlyUrl from './friendlyUrl';
import guid from './guid';
import homepage from './homepage';
import login from './login';
import search from './search';
import venue from './venue';
// DO NOT IMPORT redirect routes here, these routes are localized

const routes = {
  homepage,
  search,
  login,
  venue,
  guid,
  friendlyUrl,
};

export { routes };

export default [homepage, search, login, venue, guid, friendlyUrl];
