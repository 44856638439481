export default {
  app: {
    newsletter: {
      bottomText:
        'By subscribing to the newsletter, you agree to our <a href="~/Privacy-Policy" target="_blank">data protection policy</a>.',
      button: 'Subscribe',
      ctaDialogParagraph1: 'Do you want to know the upcoming events in Prague?',
      ctaDialogParagraph2:
        'Not sure which castle to visit on your Czech Republic tour?',
      ctaDialogParagraph3:
        'Subscribe to our newsletter and you will receive many useful tips for your next trip!',
      ctaDialogTitle: 'KNOCK KNOCK!',
      ctaDialogBtn: 'CLICK HERE',
      dialog: {
        headline: 'Select Your Newsletter Country/Region',
        notice:
          'Your email address provided above will only be used for the purpose of our newsletter subscription and it will not be disclosed to any other subjects. We will register your email address for this purpose until the day you unsubscribe from our newsletter. You can unsubscribe at any time by clicking on the link in the message delivering the newsletter. Your email address may be processed by the IT company that manages the operation and development of our websites. {0}',
        company: 'The Data Controller is {0}, {1}',
        link: {
          privacy: 'Our Privacy Policy is available here.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Your e-mail address',
      newsletterError:
        'There seem to be no newsletters for you to subscribe to right now',
      serverError:
        'There was an error while executing the request. Please, try again',
      success:
        'In order to complete your sign up process, please confirm your registration in the email we have sent to you.',
      title: 'Receive our newsletter',
      topText:
        'Sign up to receive the latest information, tips, upcoming events and new stories to tell.',
      validityError: 'Your email address seems to be invalid',
    },
  },
};
