import Vue from 'vue';
import { Dictionary } from 'vue-router/types/router';
import { Action, Module, Mutation } from 'vuex-module-decorators';

import {
  BaseNavigationItem,
  NavigationFolder,
  NavigationItem,
} from '~/app/core/apiClient/api';
import toBoolean from '~/utils/toBoolean';
import { NavigationElement } from '~/utils/navigation';

import AbstractModule from './AbstractModule';
import { LinkTarget } from '~/utils/molecules';
import { isTargetEnum } from '~/utils/molecules/link';
import { ImageInterface } from '~/components/atoms/image/Image';
import { defaultLocale } from '~/app/localization';

interface LoadNavigationInput {
  force?: boolean;
  locale: string;
  query?: Dictionary<string | (string | null)[]>;
  path?: string;
  resource?: string;
}
interface NavigationCommit {
  path: string;
  footer: NavigationElement[];
  header: NavigationElement[];
  main: NavigationElement[];
}

const NAVIGATION_BASE_NAME = '/NAVIGATION';

enum NavigationLocation {
  FOOTER = '/FOOTER',
  HEADER = '/HEADER',
  MAIN = '/MAIN',
}

function isNavigationFolder(
  item: BaseNavigationItem
): item is NavigationFolder {
  return item.className === 'VCR.NavigationFolder';
}

function isNavigationItem(item: BaseNavigationItem): item is NavigationItem {
  return item.className === 'VCR.NavigationItem';
}

function isNavigationFolderOrItem(
  item: BaseNavigationItem
): item is NavigationFolder | NavigationItem {
  return isNavigationFolder(item) || isNavigationItem(item);
}

function recursivelyCreateNavigation(
  item: NavigationItem | NavigationFolder,
  parent?: NavigationFolder
): NavigationElement {
  if (isNavigationFolder(item)) {
    return {
      inline: item.isHorizontalMenu,
      items:
        item.items
          .filter(isNavigationFolderOrItem)
          .map((subItem) => recursivelyCreateNavigation(subItem, item)) || [],
      title: item.title,
    };
  }

  let target: LinkTarget | undefined;

  if (isTargetEnum(item.target)) {
    target = item.target;
  }

  let image: ImageInterface | undefined;
  if (item.image && (!parent || parent.isImageMenu)) {
    image = {
      src: item.image,
      alt: item.title,
    };
  }

  return {
    image,
    title: item.title,
    target,
    url: item.alternativeUrls.length > 0 ? item.alternativeUrls[0] : item.url,
    forcedLocale: item.forcedCulture,
  };
}

export function createNavigationCommit(
  data: NavigationFolder,
  path: string,
  key: string
): NavigationCommit {
  const navigationCommit: NavigationCommit = {
    path: key,
    footer: [],
    header: [],
    main: [],
  };
  if (data && data.items && data.items.length > 0) {
    data.items.filter(isNavigationFolder).forEach((navigation) => {
      const menu = navigation.items
        .filter(isNavigationFolderOrItem)
        .map((item) => recursivelyCreateNavigation(item, navigation));

      switch (navigation.nodeAliasPath.toUpperCase()) {
        case (path + NavigationLocation.MAIN).toUpperCase():
          navigationCommit.main = menu;
          break;
        case (path + NavigationLocation.FOOTER).toUpperCase():
          navigationCommit.footer = menu;
          break;
        case (path + NavigationLocation.HEADER).toUpperCase():
          navigationCommit.header = menu;
          break;
      }
    });
  }
  return navigationCommit;
}

@Module({
  name: 'NavigationModule',
  stateFactory: true,
  namespaced: true,
})
export default class NavigationModule extends AbstractModule {
  public loading: boolean = false;

  protected loadingPromiseCache: { [key: string]: Promise<void> | null } = {};

  protected navigationCache: {
    [key: string]: {
      header: NavigationElement[];
      footer: NavigationElement[];
      main: NavigationElement[];
    };
  } = {};

  protected currentNavigationKey: string = `${defaultLocale}|${NAVIGATION_BASE_NAME}`;

  public get footer(): NavigationElement[] {
    if (
      this.navigationCache.hasOwnProperty(this.currentNavigationKey) &&
      this.navigationCache[this.currentNavigationKey].footer.length > 0
    ) {
      return this.navigationCache[this.currentNavigationKey].footer;
    }

    return [];
  }

  public get header(): NavigationElement[] {
    if (
      this.navigationCache.hasOwnProperty(this.currentNavigationKey) &&
      this.navigationCache[this.currentNavigationKey].header.length > 0
    ) {
      return this.navigationCache[this.currentNavigationKey].header;
    }

    return [];
  }

  public get main(): NavigationElement[] {
    if (
      this.navigationCache.hasOwnProperty(this.currentNavigationKey) &&
      this.navigationCache[this.currentNavigationKey].main.length > 0
    ) {
      return this.navigationCache[this.currentNavigationKey].main;
    } else if (this.navigationCache.hasOwnProperty(NAVIGATION_BASE_NAME)) {
      return this.navigationCache[NAVIGATION_BASE_NAME].main;
    }

    return [];
  }

  @Action({ rawError: true })
  public load(input: LoadNavigationInput): Promise<void> {
    let endpoint: () => Promise<NavigationFolder>;
    let key: string = '';
    let path: string = '';
    const { locale, query } = input;
    const isPreview =
      query &&
      query.hasOwnProperty('isPreview') &&
      typeof query.isPreview === 'string'
        ? toBoolean(query.isPreview)
        : undefined;
    if (input.resource?.trim()) {
      const resource = input.resource;
      endpoint = () =>
        this.$api
          .navigations()
          .navigationsGetNearestParentNavigation(resource, locale, isPreview)
          .then((response) => {
            path = response.nodeAliasPath;
            key = `${locale}|${path}`;
            return response;
          });
    } else {
      path =
        input.path && input.path !== '/' ? input.path : NAVIGATION_BASE_NAME;
      key = `${locale}|${path}`;
      if (input.force !== true && this.navigationCache.hasOwnProperty(key)) {
        this.setCurrentNavigationKey(key);
        return Promise.resolve();
      }
      if (this.loadingPromiseCache.hasOwnProperty(key)) {
        const cachedPromise = this.loadingPromiseCache[key];
        if (cachedPromise) {
          return cachedPromise;
        }
      }

      endpoint = () =>
        this.$api
          .navigations()
          .navigationsGetNavigation(path, locale, isPreview);
    }

    this.setLoading(true);

    const promise = endpoint()
      .then((result) => {
        const navigationCommit = createNavigationCommit(result, path, key);
        this.setNavigationCache(navigationCommit);
      })
      .catch(() => {
        this.setNavigationCache({
          path: key,
          footer: [],
          header: [],
          main: [],
        });
      })
      .finally(() => {
        this.setCurrentNavigationKey(key);
        this.setLoading(false);
        this.setLoadingPromiseCache({ id: key, promise: null });
      });

    this.setLoadingPromiseCache({ id: key, promise });
    return promise;
  }

  @Mutation
  protected setLoading(state: boolean): void {
    this.loading = state;
  }

  @Mutation
  protected setLoadingPromiseCache({
    id,
    promise,
  }: {
    id: string;
    promise: Promise<void> | null;
  }): void {
    this.loadingPromiseCache[id] = promise;
    Vue.set(this.loadingPromiseCache, id, promise);
  }

  @Mutation
  public setNavigationCache(commit: NavigationCommit): void {
    const { footer, header, main } = commit;
    this.navigationCache[commit.path] = { footer, header, main };
    Vue.set(this.navigationCache, commit.path, { footer, header, main });
  }

  @Mutation
  public setCurrentNavigationKey(id: string): void {
    this.currentNavigationKey = id;
  }
}
