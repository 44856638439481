export default {
  app: {
    weather: {
      title: 'Väderprognos',
      now: 'Idag',
      weatherTranslations: {
        clearsky_day: 'Klar himmel',
        clearsky_night: 'Klar himmel',
        clearsky_polartwilight: 'Klar himmel',
        cloudy: 'Molnigt',
        fair_day: 'Ljus',
        fair_night: 'Ljus',
        fair_polartwilight: 'Ljus',
        fog: 'Dimma',
        heavyrain: 'Kraftigt regn',
        heavyrainandthunder: 'Kraftigt regn och åska',
        heavyrainshowers_day: 'Kraftiga regnskurar',
        heavyrainshowers_night: 'Kraftiga regnskurar',
        heavyrainshowers_polartwilight: 'Kraftiga regnskurar',
        heavyrainshowersandthunder_day: 'Kraftiga regnskurar och åska',
        heavyrainshowersandthunder_night: 'Kraftiga regnskurar och åska',
        heavyrainshowersandthunder_polartwilight:
          'Kraftiga regnskurar och åska',
        heavysleet: 'Snöblandat regn',
        heavysleetandthunder: 'Snöblandat regn och åska',
        heavysleetshowers_day: 'Snöblandade regnskurar',
        heavysleetshowers_night: 'Snöblandade regnskurar',
        heavysleetshowers_polartwilight: 'Snöblandade regnskurar',
        heavysleetshowersandthunder_day: 'Snöblandade regnskurar och åska',
        heavysleetshowersandthunder_night: 'Snöblandade regnskurar och åska',
        heavysleetshowersandthunder_polartwilight:
          'Snöblandade regnskurar och åska',
        heavysnow: 'Rikligt med snö',
        heavysnowandthunder: 'Rikligt med snö och åska',
        heavysnowshowers_day: 'Kraftiga snöskurar',
        heavysnowshowers_night: 'Kraftiga snöskurar',
        heavysnowshowers_polartwilight: 'Kraftiga snöskurar',
        heavysnowshowersandthunder_day: 'Kraftiga snöskurar och åska',
        heavysnowshowersandthunder_night: 'Kraftiga snöskurar och åska',
        heavysnowshowersandthunder_polartwilight: 'Kraftiga snöskurar och åska',
        lightrain: 'Lätt regn',
        lightrainandthunder: 'Lätt regn och åska',
        lightrainshowers_day: 'Regnskur',
        lightrainshowers_night: 'Regnskur',
        lightrainshowers_polartwilight: 'Regnskur',
        lightrainshowersandthunder_day: 'Regnskur och åska',
        lightrainshowersandthunder_night: 'Regnskur och åska',
        lightrainshowersandthunder_polartwilight: 'Regnskur och åska',
        lightsleet: 'Lätta snöskurar',
        lightsleetandthunder: 'Lätta snöskurar och åska',
        lightsleetshowers_day: 'Lätta snöskurar',
        lightsleetshowers_night: 'Lätta snöskurar',
        lightsleetshowers_polartwilight: 'Lätta snöskurar',
        lightssleetshowersandthunder_day: 'Lätta snöskurar och åska',
        lightssleetshowersandthunder_night: 'Lätta snöskurar och åska',
        lightssleetshowersandthunder_polartwilight: 'Lätta snöskurar och åska',
        lightsnow: 'Lätt snöfall',
        lightsnowandthunder: 'Lätt snöfall och åska',
        lightsnowshowers_day: 'Lätta snöbyar',
        lightsnowshowers_night: 'Lätta snöbyar',
        lightsnowshowers_polartwilight: 'Lätta snöbyar',
        lightssnowshowersandthunder_day: 'Lätta snöbyar och åska',
        lightssnowshowersandthunder_night: 'Lätta snöbyar och åska',
        lightssnowshowersandthunder_polartwilight: 'Lätta snöbyar och åska',
        rain: 'Regn',
        rainandthunder: 'Regn och åska',
        rainshowers_day: 'Regnbyar',
        rainshowers_night: 'Regnbyar',
        rainshowers_polartwilight: 'Regnbyar',
        rainshowersandthunder_day: 'Regnbyar och åska',
        rainshowersandthunder_night: 'Regnbyar och åska',
        rainshowersandthunder_polartwilight: 'Regnbyar och åska',
        sleet: 'Snö med inslag av regn',
        sleetandthunder: 'Snöblandat regn och åska',
        sleetshowers_day: 'Snöbyar',
        sleetshowers_night: 'Snöbyar',
        sleetshowers_polartwilight: 'Snöbyar',
        sleetshowersandthunder_day: 'Snöbyar och åska',
        sleetshowersandthunder_night: 'Snöbyar och åska',
        sleetshowersandthunder_polartwilight: 'Snöbyar och åska',
        snow: 'Snö',
        snowandthunder: 'Snö och åska',
        snowshowers_day: 'Snöbyar',
        snowshowers_night: 'Snöbyar',
        snowshowers_polartwilight: 'Snöbyar',
        snowshowersandthunder_day: 'Snöbyar och åska',
        snowshowersandthunder_night: 'Snöbyar och åska',
        snowshowersandthunder_polartwilight: 'Snöbyar och åska',
        partlycloudy_day: 'Lätt molnighet',
        partlycloudy_night: 'Lätt molnighet',
        partlycloudy_polartwilight: 'Lätt molnighet',
      },
    },
  },
};
