export default {
  app: {
    user: {
      button: {
        logIn: 'Log in',
        logOut: 'Log out',
      },
      error: {
        username: 'Username cannot be empty',
        password: 'Password cannot be empty',
        undefined: 'Could not log in',
      },
      title: 'Log in',
      placeholder: {
        user: 'Username',
        pass: 'Password',
      },
      loggedIn: 'You are logged in as {0}',
      admin: 'Administration',
    },
  },
};
