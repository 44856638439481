export default {
  app: {
    search: {
      button: 'Hitta',
      noResults: 'Inga resultat funna',
      title: 'Sök',
      widgetTitle: 'Hitta vad du ska göra i landet',
    },
  },
};
