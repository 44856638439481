export default {
  app: {
    weather: {
      title: '天気予報',
      now: '今日',
      weatherTranslations: {
        clearsky_day: '快晴',
        clearsky_night: '快晴',
        clearsky_polartwilight: '快晴',
        cloudy: '曇り',
        fair_day: '晴れ',
        fair_night: '晴れ',
        fair_polartwilight: '晴れ',
        fog: '霧',
        heavyrain: '大雨',
        heavyrainandthunder: '雷を伴う大雨',
        heavyrainshowers_day: '激しいにわか雨',
        heavyrainshowers_night: '激しいにわか雨',
        heavyrainshowers_polartwilight: '激しいにわか雨',
        heavyrainshowersandthunder_day: '雷を伴う激しいにわか雨',
        heavyrainshowersandthunder_night: '雷を伴う激しいにわか雨',
        heavyrainshowersandthunder_polartwilight: '雷を伴う激しいにわか雨',
        heavysleet: '激しいみぞれ',
        heavysleetandthunder: '雷を伴う激しいみぞれ',
        heavysleetshowers_day: '急な激しいみぞれ',
        heavysleetshowers_night: '急な激しいみぞれ',
        heavysleetshowers_polartwilight: '急な激しいみぞれ',
        heavysleetshowersandthunder_day: '雷を伴う急な激しいみぞれ',
        heavysleetshowersandthunder_night: '雷を伴う急な激しいみぞれ',
        heavysleetshowersandthunder_polartwilight: '雷を伴う急な激しいみぞれ',
        heavysnow: '大雪',
        heavysnowandthunder: '雷を伴う大雪',
        heavysnowshowers_day: '急な大雪',
        heavysnowshowers_night: '急な大雪',
        heavysnowshowers_polartwilight: '急な大雪',
        heavysnowshowersandthunder_day: '雷を伴う急な激しい大雪',
        heavysnowshowersandthunder_night: '雷を伴う急な激しい大雪',
        heavysnowshowersandthunder_polartwilight: '雷を伴う急な激しい大雪',
        lightrain: '小雨',
        lightrainandthunder: '雷を伴う小雨',
        lightrainshowers_day: '霧雨',
        lightrainshowers_night: '霧雨',
        lightrainshowers_polartwilight: '霧雨',
        lightrainshowersandthunder_day: '雷を伴う急な小雨',
        lightrainshowersandthunder_night: '雷を伴う急な小雨',
        lightrainshowersandthunder_polartwilight: '雷を伴う急な小雨',
        lightsleet: '弱いみぞれ',
        lightsleetandthunder: '雷を伴う弱いみぞれ',
        lightsleetshowers_day: '急な弱いみぞれ',
        lightsleetshowers_night: '急な弱いみぞれ',
        lightsleetshowers_polartwilight: '急な弱いみぞれ',
        lightssleetshowersandthunder_day: '雷を伴う急な弱いみぞれ',
        lightssleetshowersandthunder_night: '雷を伴う急な弱いみぞれ',
        lightssleetshowersandthunder_polartwilight: '雷を伴う急な弱いみぞれ',
        lightsnow: '泡雪',
        lightsnowandthunder: '雷を伴う泡雪',
        lightsnowshowers_day: 'にわか雪',
        lightsnowshowers_night: 'にわか雪',
        lightsnowshowers_polartwilight: 'にわか雪',
        lightssnowshowersandthunder_day: '雷を伴うにわか雪',
        lightssnowshowersandthunder_night: '雷を伴うにわか雪',
        lightssnowshowersandthunder_polartwilight: '雷を伴うにわか雪',
        rain: '雨',
        rainandthunder: '雷を伴う雨',
        rainshowers_day: 'にわか雨',
        rainshowers_night: 'にわか雨',
        rainshowers_polartwilight: 'にわか雨',
        rainshowersandthunder_day: '雷を伴うにわか雨',
        rainshowersandthunder_night: '雷を伴うにわか雨',
        rainshowersandthunder_polartwilight: '雷を伴うにわか雨',
        sleet: 'みぞれ',
        sleetandthunder: '雷を伴うみぞれ',
        sleetshowers_day: '急なみぞれ',
        sleetshowers_night: '急なみぞれ',
        sleetshowers_polartwilight: '急なみぞれ',
        sleetshowersandthunder_day: '雷を伴う急なみぞれ',
        sleetshowersandthunder_night: '雷を伴う急なみぞれ',
        sleetshowersandthunder_polartwilight: '雷を伴う急なみぞれ',
        snow: '雪',
        snowandthunder: '雷を伴う雪',
        snowshowers_day: 'しゅう雪',
        snowshowers_night: 'しゅう雪',
        snowshowers_polartwilight: 'しゅう雪',
        snowshowersandthunder_day: '雷を伴うしゅう雪',
        snowshowersandthunder_night: '雷を伴うしゅう雪',
        snowshowersandthunder_polartwilight: '雷を伴うしゅう雪',
        partlycloudy_day: '晴れときどき曇り',
        partlycloudy_night: '晴れときどき曇り',
        partlycloudy_polartwilight: '晴れときどき曇り',
      },
    },
  },
};
