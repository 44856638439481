export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtrar por incentivo',
        itinerary: 'Filtrar por etiqueta',
        region: 'Filtrar por região',
      },
    },
  },
};
