import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import MapPinsModule from '~/app/core/store/modules/MapPinsModule';
import { Button, InputField, Multiselect } from '~/components/atoms';
import { ItemData } from '~/components/atoms/multiselect/Multiselect';
import { IconText } from '~/components/molecules';
import {
  mdiImageFilterCenterFocus,
  mdiImageSearchOutline,
} from '~/utils/iconPaths';
import { ThemeColors } from '~/utils/theme';
import { VueComponent } from '~/utils/vue-component';
import { getCategoryColor, getCategoryIcon } from './MapCategoryPin';

import style from './MapPinFilter.scss';

export const MAP_CATEGORY_DELIMITER = ',';

interface MapPinFilterProps {
  language: string;
  loading: boolean;
  onCategoryChange?: (categories: string[]) => void;
  onLocationChange?: (location: [number, number]) => void;
}

const rootClass = 'czt-map-filter';

@Component({ style })
export default class MapPinFilter extends VueComponent<MapPinFilterProps>
  implements MapPinFilterProps {
  @Prop({ required: true })
  public language!: string;

  @Prop({ required: true })
  public loading!: boolean;

  protected open: boolean = true;

  protected selectedLocation: [number, number] | null = null;

  protected categories: string[] = [];
  protected location: string = '';

  protected showLocationButton = false;

  protected locationLoading = false;
  protected locationError = '';
  protected geocoder: any = null;
  protected suggestions: ItemData[] = [];
  protected suggestionsOpen: boolean = false;

  protected get mapPinsModule() {
    return getModule(MapPinsModule, this.$store);
  }

  public mounted() {
    if (navigator !== undefined && 'geolocation' in navigator) {
      this.showLocationButton = true;
    }
    this.mapPinsModule.getMapCategories(this.$i18n.locale);
  }

  public render() {
    return (
      <div class={rootClass}>
        <v-btn
          color={ThemeColors.PRIMARY}
          dark
          fab
          absolute
          top
          left
          style={{
            left: '24px',
            top: '24px',
          }}
          onClick={() => {
            this.open = !this.open;
          }}
        >
          <v-icon>{mdiImageSearchOutline}</v-icon>
        </v-btn>
        {this.open && (
          <v-sheet
            class={`${rootClass}__fields`}
            color='transparent'
            onClick={(e?: Event) => {
              if (e) {
                e.stopImmediatePropagation();
              }
            }}
          >
            <v-container fluid class='pa-0'>
              <v-row no-gutters>
                <v-col cols={12} class={`${rootClass}__field`}>
                  <form
                    class='d-flex'
                    style={{ position: 'relative' }}
                    onSubmit={(e: Event) => {
                      e.preventDefault();
                      if (this.selectedLocation) {
                        this.changeLocation(this.selectedLocation);
                        this.suggestionsOpen = false;
                      }
                    }}
                  >
                    <InputField
                      label={this.$t('app.maps.location')}
                      v-model={this.location}
                      errorMessage={this.locationError}
                      disabled={this.locationLoading}
                      onFocus={() => {
                        if (!this.suggestionsOpen) {
                          this.suggestionsOpen = true;
                        }
                      }}
                      onBlur={() => {
                        if (this.suggestionsOpen) {
                          setTimeout(() => {
                            this.suggestionsOpen = false;
                          }, 100);
                        }
                      }}
                    />
                    {this.showLocationButton && (
                      <Button
                        style='height: 56px !important; min-width: 0 !important; width: 56px; padding: 0'
                        onClick={this.getCurrentLocation}
                        loading={this.locationLoading}
                      >
                        <v-icon>{mdiImageFilterCenterFocus}</v-icon>
                      </Button>
                    )}
                    {this.suggestionsOpen && (
                      <v-sheet
                        style={{
                          position: 'absolute',
                          top: '56px',
                          left: 0,
                          zIndex: 1,
                          maxHeight: '200px',
                          overflowY: 'auto',
                        }}
                      >
                        <v-list>
                          {this.suggestions.map((suggestion) => {
                            return (
                              <v-list-item
                                onClick={() => {
                                  this.selectedLocation = (((suggestion.value as unknown) as string).split(
                                    '|'
                                  ) as unknown) as [number, number];
                                  this.changeLocation(
                                    (((suggestion.value as unknown) as string).split(
                                      '|'
                                    ) as unknown) as [number, number]
                                  );
                                  this.location = suggestion.text;
                                }}
                              >
                                {suggestion.text}
                              </v-list-item>
                            );
                          })}
                        </v-list>
                      </v-sheet>
                    )}
                  </form>
                </v-col>
                <v-col
                  cols={12}
                  class={`${rootClass}__field`}
                  id={`${rootClass}_categories`}
                  style='position: relative'
                >
                  <Multiselect
                    attach={`#${rootClass}_categories`}
                    label={this.$t('app.maps.categories')}
                    items={this.mapPinsModule.categoryOptions}
                    disabled={this.loading}
                    loading={this.loading}
                    v-model={this.categories}
                    itemSlot={(data) => {
                      const iconName = getCategoryIcon(
                        data.item.value.toString()
                      );
                      const element = iconName ? (
                        <IconText
                          iconMargin='0.6em'
                          iconSize={0.8}
                          iconColor={getCategoryColor(
                            data.item.value.toString()
                          )}
                          icon={iconName}
                        >
                          {data.item.text}
                        </IconText>
                      ) : (
                        data.item.text
                      );
                      return (
                        <v-list-item-content>
                          <v-list-item-title>{element}</v-list-item-title>
                        </v-list-item-content>
                      );
                    }}
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        )}
      </div>
    );
  }

  protected getCurrentLocation() {
    this.locationError = '';
    this.locationLoading = true;
    this.suggestions = [];
    navigator.geolocation.getCurrentPosition(
      (position) => {
        try {
          const coords = window.SMap.Coords.fromWGS84(
            position.coords.longitude,
            position.coords.latitude
          );
          // tslint:disable
          new window.SMap.Geocoder.Reverse(
            coords,
            (geocoder: any) => {
              const result = geocoder.getResults();
              this.location = result.label;
              this.selectedLocation = [
                position.coords.longitude,
                position.coords.latitude,
              ];
              this.changeLocation([
                position.coords.longitude,
                position.coords.latitude,
              ]);
              this.locationLoading = false;
            },
            {
              lang: this.language,
            }
          );
          // tslint:enable
        } catch {
          this.location = 'N/A';
          this.selectedLocation = [
            position.coords.longitude,
            position.coords.latitude,
          ];
          this.changeLocation([
            position.coords.longitude,
            position.coords.latitude,
          ]);
          this.locationLoading = false;
        }
      },
      (error) => {
        this.locationLoading = false;
        this.locationError = error.message;
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 10000,
      }
    );
  }

  @Watch('location')
  protected suggest(value: string) {
    this.locationError = '';
    if (this.geocoder) {
      this.geocoder.abort();
    }
    if (!value) {
      this.suggestions = [];
      this.selectedLocation = null;
      return;
    }
    this.geocoder = new window.SMap.Geocoder(
      value,
      (geocoder: any) => {
        this.geocoder = null;
        const results = geocoder.getResults()[0].results;
        this.suggestions = results.map((result: any) => {
          return {
            text: result.label,
            value: result.coords.toWGS84().join('|'),
          };
        });
      },
      { lang: this.language }
    );
  }

  @Watch('categories', { deep: true })
  protected changeCategories() {
    this.$emit('categoryChange', this.categories);
  }

  @Emit('locationChange')
  protected changeLocation(location: [number, number]) {
    return location;
  }
}
