export default {
  app: {
    cookies: {
      title: 'Nous respectons votre vie privée',
      text:
        "Nous utilisons des cookies pour personnaliser le contenu, analyser les performences et proposer des fonctions relatives aux résaux sociaux. Nous partageons l'information sur votre comportement sur notre site avec nos partenaires en réseaux sociaux et en analyse. Nos partenaires peuvent combiner ces informations avec d'autres infromations que vous leur fournissez suite à l'uitilisation de leurs services. Vous pouvez trouver plus de détails {0}. {1}",
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'ici',
      },
      buttons: {
        accept: 'Accepter tous les cookies',
        save: 'Sauvegarder les donnés sélectionnés',
      },
      settings: {
        button: 'Paramètres détaillés',
        required: 'Indispensable (pour le bon fonctionnement du site web)',
        analytics:
          'Analytics (pour comprendre le comportement des visiteurs sur le site ou comment les visiteurs naviguent sur le site)',
        statistics:
          "Statistiques (pour la compréhension de l'utilisation du site web)",
        marketing:
          "Marketing (suivi des visiteurs afin d'afficher la publicité)",
        reject: 'Rejeter tout',
        accept: 'Accepter tout',
      },
    },
  },
};
