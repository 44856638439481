import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './Tag.scss';
import { link } from '~/utils/molecules';

const rootClass = 'czt-tag';

interface TagProps {
  name: string;
  link?: string;
}

@Component({ style })
export default class Tag extends VueComponent<TagProps> implements TagProps {
  @Prop({ required: true })
  public name!: string;

  @Prop()
  public link?: string;

  protected get to(): string | undefined {
    return this.link && link.isInternal(this.link)
      ? link.createInternal(this.link, this.$i18n.locale)
      : undefined;
  }

  public render() {
    return (
      <v-chip
        class={rootClass}
        label
        color='secondary'
        link={!!this.link}
        to={this.to}
      >
        {this.name}
      </v-chip>
    );
  }
}
