export default {
  app: {
    url: {
      blog: 'https://czechrepublic.jp/',
      controller: 'https://www.visitczechia.com/ja-JP/Contacts',
      facebook: 'https://www.facebook.com/czechrepublic.jp',
      instagram: 'https://www.instagram.com/visitczechrepublic_jp/',
      privacy: '~/Privacy-Policy',
      twitter: 'https://twitter.com/JpCzechRepublic',
      web: 'https://www.visitczechia.com/',
      youtube: 'https://www.youtube.com/CzechRepublicJP',
    },
  },
};
