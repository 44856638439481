import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class SeFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--sweden'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33 33'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M32.5 16.5c0 1.1-.1 2.2-.3 3.2-1.5 7.3-8 12.8-15.7 12.8-.5 0-1 0-1.4-.1-2.3-.2-4.5-.9-6.4-2A15.65 15.65 0 0 1 .6 16.5 15.65 15.65 0 0 1 8.7 2.6c1.9-1.1 4.1-1.8 6.4-2 .5 0 1-.1 1.4-.1C24.2.5 30.7 6 32.2 13.3c.2 1 .3 2.1.3 3.2z'
            fill='#006aa7'
          />
          <path
            d='M32.5 16.5c0 1.1-.1 2.2-.3 3.2H15.1v12.7c-2.3-.2-4.5-.9-6.4-2V19.7H.8a16.55 16.55 0 0 1 0-6.4h7.8V2.6c1.9-1.1 4.1-1.8 6.4-2v12.7h17.1c.3 1 .4 2.1.4 3.2z'
            fill='#fecc00'
          />
        </svg>
      </i>
    );
  }
}
