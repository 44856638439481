import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class PtFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--portugal'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g fill-rule='nonzero'>
            <path
              d='M2.056 9.84c-.183.437-.347.89-.49 1.342H4.07L2.056 9.839zM31.498 22.116c.185-.445.353-.904.498-1.364h-2.545l2.047 1.364zM5.464 27.2a15.952 15.952 0 006.533 3.983v-8.337L5.464 27.2zM11.997.75a16.006 16.006 0 00-6.549 3.998l6.549 4.37V.75zM31.996 11.182a16.21 16.21 0 00-.49-1.343l-2.013 1.343h2.503zM21.565 31.183a15.947 15.947 0 006.532-3.983l-6.532-4.354v8.337zM1.566 20.752c.145.46.312.92.498 1.364l2.047-1.364H1.566zM28.114 4.748A16.008 16.008 0 0021.565.75v8.367l6.55-4.37z'
              fill='#273376'
            />
            <path
              d='M32.478 18.76H19.572v12.905a16.463 16.463 0 001.993-.482v-8.337l6.532 4.354a15.753 15.753 0 001.985-2.443l-6.026-4.005h3.787l3.284 2.181c.13-.27.254-.54.371-.817l-2.047-1.364h2.545c.204-.65.363-1.313.482-1.991zM11.997.75v8.367l-6.549-4.37a16.13 16.13 0 00-1.936 2.379l6.101 4.056H5.826L2.463 8.949c-.145.292-.28.591-.407.89l2.014 1.343H1.566c-.204.649-.362 1.313-.483 1.998h12.908V.27c-.68.117-1.347.277-1.994.481zM1.083 18.76c.121.679.279 1.343.483 1.992h2.545l-2.047 1.364a15.88 15.88 0 001.416 2.648l6.031-4.012h2.486v.86l-7.242 4.823c.23.262.464.517.71.765l6.532-4.354v8.337c.647.205 1.313.358 1.994.482V18.76H1.083zM31.996 11.182h-2.503l2.013-1.343c-.4-.955-.891-1.874-1.464-2.728l-6.12 4.07h-2.357v-.948l7.195-4.785c-.21-.24-.423-.474-.646-.7l-6.549 4.37V.75A15.2 15.2 0 0019.572.27V13.18h12.906c-.12-.685-.278-1.35-.482-1.998z'
              fill='#fff'
            />
            <path
              d='M16.89.005c-1.003 0-1.983.097-2.934.274v31.454c.95.177 1.93.272 2.935.272 8.835 0 15.998-7.163 15.998-16 0-8.836-7.163-16-15.998-16z'
              fill='#cf2028'
            />
            <path
              d='M.89 16.005c0 7.833 5.631 14.349 13.066 15.728V.28C6.52 1.656.889 8.172.889 16.005z'
              fill='#3c8e42'
            />
            <path
              d='M20.291 21.047c-2.443-.074-13.643-7.063-13.722-8.175l.621-1.033c1.108 1.615 12.557 8.412 13.682 8.173l-.58 1.035z'
              fill='#fcd955'
            />
            <path
              d='M6.581 12.869c.067 1.093 11.258 8.087 13.702 8.166l.59-1.01a.497.497 0 01-.068.004c-1.462-.003-12.527-6.588-13.605-8.183L6.58 12.87zm-.023-.004l.64-1.033c1.083 1.58 12.17 8.177 13.607 8.173.024 0 .045-.003.077.013l-.58 1.035-.01-.006-.001.014c-2.455-.082-13.644-7.057-13.733-8.196z'
              fill='#030404'
            />
            <path
              d='M20.348 21.08c-.04.075-.117.077-.266.063-.913-.103-3.686-1.451-6.965-3.415-3.817-2.284-6.97-4.366-6.628-4.91l.092-.166.02.007c-.307.924 6.223 4.653 6.605 4.894 3.777 2.34 6.962 3.706 7.243 3.352l-.101.176v-.001zM7.06 11.699c-.22.588 2.92 2.532 6.702 4.83 3.78 2.3 7.04 3.721 7.279 3.518.016-.026.121-.207.112-.205-.048.068-.156.09-.327.039-1.025-.294-3.687-1.516-6.986-3.518-3.297-2-6.167-3.842-6.613-4.624a.49.49 0 01-.048-.231h-.01l-.096.164-.013.027z'
              fill='#fcd955'
            />
            <path
              d='M7.18 11.52l-.002-.007-.003.006.004.001zm-.122.24c-.007.636 3.053 2.538 6.71 4.758 3.536 2.154 6.625 3.536 7.19 3.535.039 0 .062-.006.073-.014l.083-.147a.246.246 0 01-.124.029.574.574 0 01-.169-.029c-1.027-.295-3.686-1.516-6.987-3.52-3.3-1.999-6.167-3.838-6.618-4.628a.493.493 0 01-.052-.21l-.082.143-.011.027H7.07a.147.147 0 00-.012.057zm6.696 4.779c-3.658-2.226-6.712-4.106-6.72-4.778 0-.024.005-.046.013-.067l.011.004-.01-.006.013-.025.106-.172.024.016v.016c0 .071.02.158.046.205.44.776 3.313 2.623 6.61 4.62 3.297 2.002 5.959 3.223 6.98 3.517a.594.594 0 00.163.027.281.281 0 00.14-.034l.01-.02h.011v-.013c.002 0 .01-.002.013.013a1.685 1.685 0 01-.115.214.154.154 0 01-.09.022c-.584-.001-3.663-1.384-7.205-3.54zm6.594 4.541l-.007-.004.02.004h-.013zm-13.85-8.253a.138.138 0 00-.023.084c-.008.64 3.024 2.638 6.648 4.807 3.277 1.961 6.054 3.312 6.959 3.412.042.004.078.007.11.007.078-.001.118-.015.145-.065v.002l.12-.162c-.035.04-.1.06-.191.06-.785 0-3.688-1.315-7.065-3.41-.378-.24-6.617-3.79-6.624-4.834 0-.027.003-.05.017-.057l-.003-.012-.093.168zm13.694 8.334c-.032 0-.07-.004-.112-.006-.92-.103-3.69-1.454-6.971-3.415-3.627-2.178-6.652-4.152-6.659-4.829a.16.16 0 01.026-.098l.109-.172.026.022a.232.232 0 00-.01.065c-.01 1.003 6.236 4.582 6.61 4.814 3.376 2.092 6.287 3.405 7.055 3.405a.482.482 0 00.193-.037l-.12.165h.002-.002l-.003.005h.012l.01.006c-.03.056-.084.075-.159.075h-.007z'
              fill='#030404'
            />
            <path
              d='M13.844 21.52c2.949.015 5.52-.415 6.695-1.027l.427-.748c-1.02.557-3.795 1.135-7.118 1.048-2.707-.018-5.599-.3-6.987-1.084l.628.87c1.129.623 3.821.938 6.355.942z'
              fill='#fcd955'
            />
            <path
              d='M13.844 21.532v-.024h.148c2.885 0 5.387-.427 6.536-1.02l.444-.733c-.956.52-3.433 1.059-6.458 1.059-.22 0-.443-.002-.666-.008-2.708-.018-5.6-.302-6.976-1.105l.622.868c1.124.62 3.817.936 6.35.939v.024c-2.536-.003-5.227-.316-6.365-.946l-.612-.888c1.384.78 4.275 1.065 6.981 1.084.224.006.446.008.666.008 3.022 0 5.5-.538 6.464-1.04l-.435.755c-1.16.6-3.665 1.027-6.55 1.027h-.15z'
              fill='#030404'
            />
            <path
              d='M13.884 21.588c-1.7-.006-3.268-.109-4.489-.333-.885-.174-1.47-.386-1.91-.618l-.164-.248c.32.203.948.466 2.103.688 1.211.225 2.77.328 4.46.333 1.608-.013 3.13-.098 4.358-.313 1.051-.187 2.243-.524 2.556-.879l-.185.297c-.352.267-1.319.567-2.296.76-1.237.225-2.825.315-4.433.313zm0-.686c-1.874-.013-3.364-.084-4.672-.326a8.847 8.847 0 01-2.493-.86l-.077-.165c.23.17 1.266.596 2.601.848 1.266.223 2.741.326 4.637.318 1.671.004 3.293-.075 4.574-.318 1.275-.244 2.268-.538 2.668-.802l-.127.228c-.164.183-1.311.523-2.514.75-1.292.234-2.917.33-4.597.327z'
              fill='#fcd955'
            />
            <path
              d='M13.963 20.889c1.65 0 3.243-.097 4.517-.326 1.198-.227 2.351-.57 2.504-.745l.145-.213c-.405.268-1.396.562-2.673.805-1.256.238-2.837.319-4.468.319h-.282c-1.814 0-3.236-.102-4.467-.32-1.336-.252-2.371-.676-2.587-.864l.073.16a8.76 8.76 0 002.489.859c1.306.241 2.796.314 4.67.325h.08zm-.079.024v-.012.012c-1.875-.012-3.364-.084-4.675-.325a8.816 8.816 0 01-2.502-.867l-.06-.181c.228.168 1.265.595 2.599.848 1.225.215 2.646.317 4.46.317h.282c1.631 0 3.21-.081 4.464-.319 1.274-.243 2.266-.538 2.68-.785l-.128.232c-.173.19-1.316.524-2.52.753-1.275.232-2.87.327-4.52.327h-.08zm0 .675v.01c-1.702-.005-3.27-.106-4.492-.331-.885-.172-1.473-.387-1.917-.624l-.147-.265c.317.202.944.466 2.098.688 1.21.223 2.769.326 4.457.332 1.607-.013 3.13-.1 4.355-.312 1.048-.188 2.24-.526 2.569-.862-.322.363-1.511.697-2.564.887-1.23.213-2.75.297-4.36.312-1.688-.006-3.25-.109-4.462-.333-1.156-.223-1.786-.486-2.09-.709l.16.246c.436.23 1.022.442 1.907.616 1.22.223 2.786.325 4.486.33h.092c1.575 0 3.126-.09 4.34-.31.975-.192 1.94-.495 2.286-.754l.205-.285h.002l-.19.3c-.355.272-1.32.57-2.3.763-1.215.22-2.768.31-4.343.31l-.092.001v-.01z'
              fill='#030404'
            />
            <path
              d='M13.935 17.67c2.728-.023 6.113-.385 8.048-1.174v-.691c-1.143.647-4.965 1.075-8.07 1.139-3.67-.037-6.657-.538-7.956-1.43l-.018.89c2.387 1.034 4.85 1.252 7.996 1.266z'
              fill='#fcd955'
            />
            <path
              d='M5.944 16.393c2.383 1.032 4.846 1.251 7.99 1.263 2.726-.022 6.113-.384 8.036-1.16l.019-.681c-1.149.65-4.97 1.076-8.08 1.14-3.668-.036-6.655-.536-7.94-1.44l-.025.878zm-.018.012l.038-.9c1.293.888 4.279 1.39 7.946 1.425 3.105-.062 6.929-.494 8.085-1.125l-.008.703c-1.938.79-5.322 1.15-8.052 1.173v-.013.013c-3.146-.011-5.612-.231-8.009-1.276zm8.009 1.276'
              fill='#030404'
            />
            <path
              d='M22.076 16.514c-.065.109-1.332.552-3.19.883-1.26.196-2.906.365-4.959.367a34.623 34.623 0 01-4.748-.308c-1.952-.313-2.96-.749-3.333-.903.037-.071-.033-.123 0-.191 1.073.439 2.178.703 3.363.885 1.2.167 2.773.312 4.711.31 2.044-.002 3.67-.185 4.924-.373 1.908-.312 3.087-.721 3.232-.909v.239z'
              fill='#fcd955'
            />
            <path
              d='M5.847 16.4c0 .031.02.066.022.109 0 .014-.004.031-.018.033.371.154 1.379.588 3.33.901 1.197.166 2.778.308 4.713.308h.033a32.01 32.01 0 004.957-.368c1.85-.324 3.12-.774 3.18-.874l.02-.226c-.153.196-1.329.598-3.238.912-1.255.19-2.882.372-4.927.372h-.072c-1.908 0-3.455-.143-4.641-.308-1.185-.183-2.292-.447-3.35-.89a.058.058 0 00-.009.031zm8.047 1.376c-1.936 0-3.518-.142-4.717-.308-1.953-.314-2.963-.75-3.343-.921a.088.088 0 00.01-.038c0-.034-.022-.068-.022-.109 0-.013.003-.03.029-.05 1.07.44 2.176.703 3.358.884 1.185.166 2.733.31 4.638.31.025 0 .047-.002.072 0a32.46 32.46 0 004.923-.373c1.905-.312 3.087-.724 3.245-.896v.239h-.011l.008.005c-.076.122-1.333.557-3.196.89a32.245 32.245 0 01-4.96.367h-.034z'
              fill='#030404'
            />
            <path
              d='M22.076 15.81c-.206.17-1.606.543-3.415.84a31.86 31.86 0 01-4.713.338c-1.865.002-3.392-.125-4.55-.3-1.842-.247-3.552-1.074-3.552-1.074l-.002-.267s1.707.872 3.574 1.142c1.146.165 2.674.29 4.53.287a31.299 31.299 0 004.678-.342c1.821-.252 3.303-.73 3.45-.849v.225zm0 .704c-.065.108-1.332.552-3.19.883-1.26.196-2.906.365-4.959.367a34.623 34.623 0 01-4.748-.308c-1.952-.313-2.96-.749-3.333-.903.037-.073-.033-.123 0-.191 1.073.439 2.18.703 3.363.885 1.2.167 2.773.312 4.711.31 2.044-.002 3.67-.185 4.924-.373 1.908-.312 3.087-.721 3.232-.909v.239z'
              fill='#fcd955'
            />
            <path
              d='M5.85 15.605c.005 0 1.71.825 3.548 1.07 1.145.172 2.647.3 4.483.3l.067.001c1.964 0 3.5-.161 4.71-.338 1.8-.295 3.2-.669 3.405-.836l.018-.207c-.152.123-1.63.597-3.454.852-1.202.179-2.724.34-4.68.342h-.073c-1.826 0-3.327-.125-4.459-.289-1.852-.266-3.546-1.126-3.56-1.154l-.004.259zM13.882 17c-1.837 0-3.341-.127-4.486-.299-1.844-.247-3.555-1.074-3.563-1.087l.017-.278c.004 0 1.706.872 3.57 1.14 1.131.163 2.631.287 4.455.287h.074a31.157 31.157 0 004.675-.341c1.82-.253 3.305-.732 3.464-.837v.225h-.011l.007.009c-.214.176-1.609.544-3.421.843-1.212.178-2.749.338-4.714.338h-.067zm-8.034-.6c0 .031.02.064.022.107 0 .015-.004.033-.018.035.371.154 1.379.588 3.33.901 1.197.166 2.778.308 4.713.308h.033a32.01 32.01 0 004.957-.368c1.85-.324 3.12-.777 3.18-.874l.02-.226c-.153.196-1.329.598-3.238.912-1.255.19-2.882.372-4.927.372h-.072c-1.908 0-3.455-.143-4.641-.308-1.184-.183-2.292-.447-3.35-.89a.053.053 0 00-.009.031zm8.047 1.376c-1.936 0-3.518-.142-4.717-.308-1.953-.314-2.963-.75-3.343-.921a.09.09 0 00.01-.04c0-.032-.022-.066-.022-.107 0-.013.003-.029.029-.05 1.07.44 2.176.703 3.358.884 1.185.166 2.733.31 4.638.31.025 0 .047-.002.072 0a32.46 32.46 0 004.923-.373c1.905-.312 3.087-.724 3.245-.896v.239h-.011l.008.005c-.076.12-1.333.557-3.196.89a32.245 32.245 0 01-4.96.367h-.034z'
              fill='#030404'
            />
            <path
              d='M13.977 13.544c2.444-.018 5.463-.334 7.198-1.026l-.373-.605c-1.027.564-4.061.94-6.845.996-3.293-.03-5.62-.339-6.782-1.119l-.355.645c2.142.908 4.337 1.1 7.157 1.109z'
              fill='#fcd955'
            />
            <path
              d='M6.826 12.425c2.138.905 4.33 1.098 7.15 1.107 2.443-.019 5.462-.334 7.188-1.008l-.357-.601c-1.032.567-4.065.942-6.85.998-3.293-.03-5.622-.337-6.772-1.126l-.359.63zm7.15 1.119v.012c-2.82-.009-5.017-.202-7.167-1.125l.373-.652c1.156.777 3.482 1.086 6.775 1.117 2.782-.056 5.816-.433 6.854-.99l.368.624c-1.738.692-4.757 1.007-7.202 1.026v-.012z'
              fill='#030404'
            />
            <path
              d='M20.949 11.917c-.189.148-1.11.476-2.736.733-1.084.16-2.462.299-4.226.3-1.674.002-3.04-.112-4.08-.265-1.09-.142-1.993-.414-2.846-.716.032-.054.066-.11.1-.163.244.125 1.092.466 2.762.705 1.03.144 2.398.253 4.064.252 1.755 0 3.12-.144 4.194-.299 1.634-.224 2.514-.64 2.647-.747l.12.2zm.325.616c-.058.094-1.19.484-2.856.774a29.7 29.7 0 01-4.45.322 31.545 31.545 0 01-4.257-.27c-1.751-.276-2.655-.656-2.99-.792.031-.063.053-.108.081-.169.964.385 1.872.614 2.934.778 1.074.145 2.488.272 4.227.27a29.589 29.589 0 004.417-.328c1.71-.274 2.644-.63 2.776-.794l.118.209z'
              fill='#fcd955'
            />
            <path
              d='M7.065 11.957c.315.115 1.194.502 2.843.717 1.032.15 2.386.262 4.044.262.012 0 .023.003.034.003a29.112 29.112 0 004.224-.3c1.601-.256 2.523-.578 2.722-.724l-.096-.19c-.137.113-1.017.526-2.654.752-1.075.155-2.44.299-4.196.299-.013-.002-.024-.002-.035-.002-1.651 0-3.008-.106-4.03-.25-1.672-.24-2.52-.58-2.751-.71-.034.052-.067.105-.105.143zm6.887 1.003c-1.66 0-3.013-.112-4.048-.262-1.651-.214-2.532-.605-2.855-.734.032-.056.066-.11.115-.168.243.123 1.091.464 2.761.702 1.021.145 2.376.25 4.026.25.011 0 .022 0 .035.003 1.754 0 3.118-.144 4.194-.3 1.63-.222 2.512-.64 2.658-.74l.12.2-.01.006.006.01c-.19.152-1.112.476-2.74.737a28.876 28.876 0 01-4.228.296h-.034zm-7.227-.404c.336.136 1.237.516 2.987.79a31.3 31.3 0 004.223.271h.033c1.84-.001 3.315-.15 4.446-.324 1.631-.28 2.76-.668 2.844-.763l-.094-.2c-.14.174-1.07.526-2.784.8a29.871 29.871 0 01-4.418.33h-.03c-1.73 0-3.13-.126-4.199-.271-1.062-.166-1.971-.395-2.92-.786-.028.06-.049.106-.088.153zm7.21 1.084c-1.735 0-3.153-.125-4.226-.27-1.751-.275-2.658-.656-3-.808.032-.064.052-.107.098-.175.963.384 1.87.613 2.93.778 1.068.144 2.468.269 4.195.269l.031.001a29.622 29.622 0 004.414-.33c1.709-.271 2.645-.63 2.788-.788l.12.21-.011.005.01.007c-.069.107-1.195.487-2.866.78a29.315 29.315 0 01-4.45.321h-.033z'
              fill='#030404'
            />
            <path
              d='M13.955 23.13a7.124 7.124 0 010-14.248 7.125 7.125 0 110 14.248zm0-15.006a7.883 7.883 0 00-7.879 7.881 7.879 7.879 0 007.88 7.88 7.88 7.88 0 007.88-7.88 7.883 7.883 0 00-7.88-7.881z'
              fill='#fcd955'
            />
            <path
              d='M13.955 8.205a7.804 7.804 0 00-7.801 7.8 7.802 7.802 0 107.801-7.8zm7.962 7.8a7.961 7.961 0 11-15.922.002 7.961 7.961 0 0115.922-.002z'
              fill='#fcd955'
            />
            <path
              d='M6.14 16.005a7.816 7.816 0 017.815-7.811v.024a7.8 7.8 0 00-7.79 7.787 7.802 7.802 0 007.79 7.79 7.8 7.8 0 007.79-7.79 7.798 7.798 0 00-7.79-7.787v-.024a7.814 7.814 0 11-7.814 7.811zm-.133 0a7.961 7.961 0 007.948 7.95 7.961 7.961 0 007.95-7.95 7.96 7.96 0 00-7.95-7.948 7.961 7.961 0 00-7.948 7.948zm-.025 0a7.974 7.974 0 1115.948.001 7.974 7.974 0 01-15.948 0z'
              fill='#030404'
            />
            <path
              d='M13.955 8.96a7.046 7.046 0 00-7.044 7.045 7.043 7.043 0 007.044 7.044 7.044 7.044 0 100-14.09zm7.207 7.045a7.204 7.204 0 01-7.207 7.204 7.203 7.203 0 110-14.406 7.203 7.203 0 017.207 7.202z'
              fill='#fcd955'
            />
            <path
              d='M6.897 16.005a7.06 7.06 0 017.058-7.058v.024a7.047 7.047 0 00-7.034 7.034 7.043 7.043 0 007.034 7.032 7.042 7.042 0 007.034-7.032 7.046 7.046 0 00-7.034-7.034v-.024a7.056 7.056 0 110 14.113 7.056 7.056 0 01-7.058-7.055zm-.133 0a7.203 7.203 0 007.191 7.193 7.203 7.203 0 007.193-7.193 7.201 7.201 0 00-7.193-7.19 7.201 7.201 0 00-7.19 7.19zm-.026 0a7.216 7.216 0 017.217-7.216 7.215 7.215 0 017.218 7.216 7.216 7.216 0 01-7.218 7.218 7.216 7.216 0 01-7.217-7.218z'
              fill='#030404'
            />
            <path
              d='M13.874 14.325c3.321-.019 6.785.475 8.11 1.17v.853c-1.153-.633-4.358-1.293-8.105-1.195-3.051.02-6.305.344-7.94 1.235v-.986c1.343-.712 5.079-1.071 7.935-1.077z'
              fill='#fcd955'
            />
            <path
              d='M5.95 15.401l-.017.976c1.64-.893 4.894-1.216 7.944-1.236.25-.007.502-.01.75-.01 3.421 0 6.283.613 7.343 1.217l.006-.843c-1.297-.683-4.66-1.17-7.921-1.17-.061 0-.121.003-.18.003-2.856.004-6.594.366-7.925 1.063zm-.017-.01c1.348-.714 5.081-1.071 7.94-1.078l.182-.001c3.265 0 6.627.486 7.94 1.182l-.019.865c-1.07-.589-3.932-1.204-7.348-1.204-.248 0-.499.004-.749.01-3.05.02-6.304.344-7.953 1.223l.007-.997z'
              fill='#030404'
            />
            <path
              d='M13.917 14.249c1.813-.005 3.603.1 4.998.355 1.102.218 2.763.561 3.16.866v.338c-.356-.402-2.06-.786-3.244-1.002-1.385-.243-3.101-.34-4.914-.355-1.901.005-3.66.125-5.025.377-1.302.256-2.69.554-3.046.786v-.282c.493-.264 2.016-.508 3.015-.705 1.375-.257 3.144-.373 5.056-.378zm0 .781c1.894-.003 3.725.104 5.181.376 1.358.254 2.794.643 2.978.851v.258c-.45-.299-1.569-.633-3.005-.913-1.442-.275-3.274-.367-5.158-.362-2.136-.008-3.798.109-5.223.36-1.507.288-2.585.702-2.844.896v-.27c.502-.263 1.468-.58 2.806-.824 1.475-.277 3.154-.36 5.265-.372z'
              fill='#fcd955'
            />
            <path
              d='M18.833 14.792c1.186.218 2.887.598 3.23 1.016l.005-.329c-.39-.3-2.055-.644-3.155-.861-1.365-.252-3.106-.357-4.875-.357h-.12c-1.913.006-3.682.122-5.056.377-.997.197-2.522.443-3.005.693l-.019.275c.364-.238 1.748-.534 3.05-.79 1.367-.252 3.128-.373 5.03-.377 1.81.014 3.528.11 4.915.353zm-.005.025c-1.384-.243-3.1-.34-4.91-.353-1.902.004-3.661.124-5.023.377-1.303.252-2.692.553-3.063.773l.007-.294c.497-.264 2.02-.508 3.02-.705 1.374-.257 3.145-.374 5.058-.38v.026-.026h.12c1.772 0 3.514.106 4.88.358 1.104.218 2.761.557 3.17.877l-.022.347c-.347-.397-2.054-.786-3.237-1zm.24.797c-1.403-.268-3.174-.361-5.005-.361-.05 0-.1-.002-.15 0l-.211-.002c-2.037 0-3.637.117-5.012.361-1.503.289-2.583.701-2.858.884l.007-.28c.504-.266 1.473-.58 2.81-.826 1.476-.276 3.156-.36 5.268-.372v.024c-2.112.014-3.79.096-5.263.373-1.337.244-2.303.56-2.797.811l-.019.262c.263-.199 1.341-.61 2.849-.9 1.376-.244 2.978-.362 5.015-.362l.21.002c.05-.002.1 0 .15 0 1.832 0 3.606.094 5.01.361 1.438.282 2.556.614 2.991.926l.002-.25c-.173-.201-1.614-.594-2.969-.848-1.427-.267-3.212-.375-5.063-.375h-.116v-.024h.116c1.853 0 3.64.108 5.068.375 1.358.257 2.79.64 2.986.864l-.02.268c-.444-.297-1.564-.632-3-.911z'
              fill='#030404'
            />
            <path
              d='M13.921 10.612c2.98-.016 5.58.418 6.768 1.027l.433.748c-1.033-.555-3.837-1.135-7.196-1.048-2.737.019-5.663.3-7.128 1.084l.516-.867c1.205-.622 4.047-.941 6.607-.944z'
              fill='#fcd955'
            />
            <path
              d='M7.323 11.563l-.533.846c1.472-.782 4.399-1.066 7.134-1.082.226-.007.45-.01.674-.01 3.058 0 5.56.538 6.513 1.076l-.429-.745c-1.166-.596-3.696-1.025-6.61-1.025l-.15.001c-2.561.002-5.403.323-6.6.94zm-.014-.017c1.209-.625 4.049-.944 6.612-.945v-.003h.151c2.917 0 5.449.428 6.627 1.034l.417.766c-.96-.516-3.463-1.058-6.518-1.057-.223 0-.448.002-.672.01-2.736.017-5.661.3-7.14 1.066l.523-.871z'
              fill='#030404'
            />
            <path
              d='M13.96 10.545c1.626-.003 3.232.087 4.483.311.988.193 1.963.493 2.322.76l.187.301c-.318-.357-1.521-.694-2.585-.884-1.243-.213-2.781-.298-4.407-.31-1.707.003-3.285.107-4.506.333-1.169.222-1.922.483-2.24.69l.163-.25c.443-.231 1.152-.444 2.05-.619 1.23-.226 2.816-.327 4.533-.332zm0 .687c1.7-.005 3.341.092 4.65.326 1.213.228 2.375.567 2.541.75l.127.226c-.403-.264-1.406-.553-2.695-.799-1.295-.246-2.935-.325-4.626-.32-1.916-.01-3.407.093-4.688.319-1.348.254-2.288.612-2.521.784l.128-.241c.447-.226 1.165-.506 2.363-.721 1.32-.24 2.83-.313 4.721-.324z'
              fill='#fcd955'
            />
            <path
              d='M20.94 11.923h.003-.003zm.003 0l-.185-.298c-.355-.264-1.33-.565-2.318-.756-1.23-.222-2.802-.312-4.4-.312l-.08.001c-1.717.005-3.301.106-4.532.33-.898.175-1.604.388-2.04.613l-.18.233c.32-.207 1.073-.468 2.244-.692 1.221-.224 2.8-.328 4.508-.332 1.626.012 3.164.097 4.409.31 1.063.191 2.263.524 2.571.903-.316-.351-1.517-.69-2.577-.877-1.24-.213-2.779-.298-4.403-.312-1.705.004-3.284.108-4.504.332-1.168.224-1.92.485-2.253.672l.169-.255c.444-.232 1.155-.444 2.05-.62 1.234-.224 2.82-.325 4.538-.327v-.003h.08c1.599 0 3.17.089 4.404.31.99.194 1.967.492 2.332.766l.168.317-.001-.003zm-2.357-.2c1.29.246 2.29.536 2.679.818l-.124-.227c-.157-.175-1.323-.518-2.534-.743-1.276-.23-2.867-.327-4.52-.327h-.127v-.012.013c-1.893.01-3.4.082-4.72.323-1.197.216-1.913.493-2.354.715l-.146.224c.239-.174 1.176-.531 2.529-.786 1.23-.22 2.657-.32 4.472-.32h.355c1.642 0 3.227.082 4.49.321zm-.005.024c-1.258-.239-2.844-.321-4.485-.321l-.14.001-.215-.001c-1.814 0-3.24.1-4.47.32-1.347.253-2.287.612-2.535.766l.133-.245c.45-.227 1.167-.508 2.367-.723 1.322-.24 2.83-.314 4.724-.324h.128c1.654 0 3.246.095 4.524.327 1.215.226 2.372.562 2.55.754l.108.242c-.399-.26-1.402-.55-2.69-.796z'
              fill='#030404'
            />
            <path
              d='M13.96 10.545c1.626-.003 3.232.087 4.483.311.988.193 1.963.493 2.322.76l.187.301c-.318-.357-1.521-.694-2.585-.884-1.241-.213-2.781-.298-4.407-.31-1.707.003-3.285.107-4.506.333-1.169.22-1.922.482-2.24.69l.163-.25c.443-.231 1.152-.444 2.05-.619 1.23-.226 2.816-.327 4.533-.332zm0 .687c1.7-.005 3.341.092 4.648.326 1.215.228 2.377.567 2.543.75l.127.226c-.403-.264-1.406-.553-2.695-.8-1.295-.245-2.935-.325-4.626-.32-1.916-.008-3.407.094-4.685.32-1.351.254-2.29.612-2.524.784l.128-.241c.447-.226 1.165-.506 2.363-.721 1.32-.24 2.83-.313 4.721-.324z'
              fill='#fcd955'
            />
            <path
              d='M20.94 11.923h.003-.003zm.003 0l-.185-.298c-.355-.264-1.33-.565-2.318-.756-1.23-.222-2.802-.312-4.4-.312l-.08.001v-.013.013c-1.717.005-3.301.106-4.532.33-.898.175-1.604.388-2.04.613l-.18.233c.32-.209 1.073-.47 2.244-.692 1.221-.224 2.8-.328 4.508-.332 1.626.012 3.166.097 4.409.31 1.063.191 2.263.524 2.571.903-.316-.351-1.517-.69-2.577-.877-1.239-.213-2.779-.298-4.403-.312-1.705.004-3.284.108-4.504.332-1.168.22-1.92.484-2.253.672l.169-.255c.444-.232 1.155-.444 2.05-.62 1.234-.224 2.82-.325 4.538-.33h.08c1.599 0 3.17.089 4.404.31.99.194 1.967.492 2.332.766l.168.317-.001-.003zm-2.357-.202c1.29.248 2.29.538 2.679.82l-.124-.227c-.157-.175-1.323-.518-2.536-.743-1.274-.23-2.865-.327-4.517-.327h-.128v-.012.013c-1.893.01-3.4.082-4.72.323-1.197.216-1.913.493-2.354.715l-.146.224c.239-.174 1.176-.531 2.529-.786 1.23-.22 2.657-.32 4.472-.32h.394c1.629 0 3.2.084 4.45.32zm-.005.024c-1.248-.235-2.819-.32-4.446-.32-.06 0-.12 0-.18.002l-.214-.001c-1.814 0-3.24.1-4.468.32-1.349.253-2.289.612-2.537.766l.133-.245c.45-.227 1.167-.508 2.367-.723 1.322-.24 2.83-.314 4.724-.324h.128c1.654 0 3.246.095 4.522.327 1.217.226 2.374.562 2.552.754l.108.242c-.399-.26-1.402-.55-2.69-.798z'
              fill='#030404'
            />
            <path
              d='M13.898 18.573c2.445.018 5.463.331 7.197 1.024l-.373.61c-1.026-.569-4.059-.943-6.845-.998-3.295.03-5.575.368-6.715 1.01l-.318-.54c2.038-.906 4.233-1.098 7.054-1.106z'
              fill='#fcd955'
            />
            <path
              d='M6.854 19.671l.302.537c1.144-.645 3.425-.982 6.72-1.012 2.788.057 5.819.429 6.834 1.005l.38-.593c-1.732-.691-4.749-1.006-7.193-1.022-2.821.007-5.012.199-7.043 1.085zm-.016-.003c2.041-.906 4.238-1.1 7.06-1.107v.025-.025c2.446.017 5.463.332 7.208 1.04l-.392.617c-1.02-.567-4.054-.942-6.837-.997-3.294.03-5.574.367-6.727 1.004l-.312-.557z'
              fill='#030404'
            />
            <path
              d='M20.523 20.315c-.33-.166-1.155-.48-2.487-.664a29.038 29.038 0 00-4.17-.296c-1.656-.002-3.018.106-4.04.25-1.662.238-2.507.584-2.75.706l-.097-.163c.315-.113 1.189-.502 2.83-.717 1.035-.151 2.392-.263 4.057-.262 1.752.002 3.124.14 4.2.296 1.34.214 2.198.475 2.559.645l-.102.205zm.59-.73l-.12.208c-.133-.167-1.061-.52-2.761-.796a29.093 29.093 0 00-4.39-.325 30.415 30.415 0 00-4.2.267c-1.06.165-1.96.396-2.919.778l-.081-.166c.334-.136 1.23-.52 2.97-.793a31.672 31.672 0 014.235-.269c1.83.001 3.296.149 4.421.32 1.659.288 2.785.68 2.846.775z'
              fill='#fcd955'
            />
            <path
              d='M13.842 18.658c1.823 0 3.272.157 4.391.326 1.703.277 2.627.628 2.747.8l.118-.2c-.088-.093-1.21-.482-2.833-.762a29.145 29.145 0 00-4.418-.32h-.033c-1.722 0-3.13.124-4.2.267-1.74.275-2.633.656-2.962.774a.701.701 0 01.066.16c.96-.38 1.862-.611 2.921-.774a29.863 29.863 0 014.14-.27h.063zm4.388.351a29.081 29.081 0 00-4.388-.326h-.063c-1.7 0-3.083.123-4.137.27a13.584 13.584 0 00-2.93.769 1.02 1.02 0 01-.075-.184c.334-.137 1.232-.517 2.974-.791a31.155 31.155 0 014.236-.27c1.83 0 3.296.148 4.422.32 1.663.29 2.783.675 2.854.778l-.01.008.01.006-.142.211c-.123-.159-1.053-.517-2.75-.791zm-8.42.434c-1.64.215-2.512.605-2.821.7.031.054.063.108.081.157.246-.123 1.09-.47 2.753-.707 1.015-.145 2.364-.252 4-.252h.043c1.744.002 3.1.144 4.172.299 1.319.18 2.141.491 2.479.66l.102-.18c-.358-.168-1.217-.429-2.556-.643a29.018 29.018 0 00-4.198-.297h-.034c-1.649 0-2.995.112-4.02.263zm8.224.22a28.888 28.888 0 00-4.169-.297h-.042c-1.635 0-2.984.108-3.997.252-1.661.237-2.505.583-2.762.7-.033-.055-.065-.107-.09-.183.315-.112 1.191-.502 2.832-.717 1.028-.15 2.375-.262 4.025-.262h.035c1.752 0 3.124.14 4.202.296 1.34.214 2.198.476 2.569.663l-.103.205-.012-.005-.005.011c-.328-.165-1.152-.48-2.483-.663z'
              fill='#030404'
            />
            <path fill='#fcd955' d='M14.274 8.007h.17v15.998h-.17z' />
            <path
              d='M14.274 23.993h.156l.013-15.974-.157-.012-.012 15.986zm-.013.012l.013-16.012.181.014v15.998h-.012v.012l-.182-.012z'
              fill='#030404'
            />
            <path fill='#fcd955' d='M13.47 8.007h.168v15.998h-.169z' />
            <path
              d='M13.468 23.993h.159l.01-15.974-.156-.012-.013 15.986zm-.011.012l.011-16.012.182.014v15.998h-.013v.012l-.18-.012z'
              fill='#030404'
            />
            <path fill='#fcd955' d='M13.638 8.085h.636v15.842h-.636z' />
            <path
              d='M13.637 23.914h.624l.013-15.816-.624-.013-.013 15.83zm-.012.013l.012-15.855.649.013v15.842h-.012v.012l-.649-.012z'
              fill='#030404'
            />
            <path
              d='M7.245 11.547c2.462-.034 13.818 6.942 13.852 8.222l-.704 1.08c-.318-1.49-11.842-8.276-13.743-8.148l.595-1.154z'
              fill='#fcd955'
            />
            <path
              d='M7.245 11.558h.001-.001zM20.38 20.84l.705-1.071C21.06 18.51 9.745 11.55 7.26 11.556l-.009.002-.604 1.13c.017 0 .036-.003.057-.003 2.069.007 13.373 6.669 13.675 8.155zM6.705 12.712c-.02 0-.04 0-.067-.017l.596-1.155.011.007-.001-.014h.017c2.508.006 13.8 6.936 13.846 8.242l-.727 1.075c-.303-1.46-11.63-8.144-13.675-8.138z'
              fill='#030404'
            />
            <path
              d='M21.168 19.848c.286-.568-2.824-2.91-6.682-5.196-3.946-2.235-6.79-3.551-7.308-3.16l-.117.21c0 .012.005-.014.03-.033.094-.084.25-.078.319-.078.896.015 3.45 1.19 7.038 3.244 1.566.916 6.632 4.163 6.613 5.077 0 .08.008.094-.022.133l.13-.197zM6.578 12.66c.458-.311 3.813 1.184 7.324 3.3 3.498 2.123 6.842 4.523 6.542 4.971l-.1.157-.045.037c.01-.007.061-.069-.005-.236-.148-.49-2.522-2.384-6.46-4.762-3.838-2.289-7.036-3.668-7.358-3.272l.102-.195z'
              fill='#fcd955'
            />
            <path
              d='M21.072 19.912v.056l.084-.126a.197.197 0 00.017-.08c.005-.669-3.006-2.917-6.692-5.1-3.481-1.969-6.107-3.227-7.026-3.227-.125 0-.214.024-.267.063l-.08.142a.497.497 0 01.275-.062l.027.001c.903.015 3.454 1.19 7.043 3.245 1.565.918 6.612 4.143 6.62 5.082h-.004l.003.006zm-.044.125l.011-.018c.008-.015.01-.026.01-.05 0-.015 0-.033.002-.051-.007-.008-.003-.012-.003-.012.008-.9-5.046-4.151-6.608-5.06-3.585-2.054-6.143-3.23-7.03-3.242l-.027-.002c-.077 0-.206.007-.285.078-.016.01-.017.016-.024.02l-.001.002s0 .002-.002 0a.072.072 0 01-.024.003l.002-.008.12-.214c.064-.05.158-.071.286-.071.934.001 3.555 1.26 7.038 3.23 3.689 2.189 6.699 4.417 6.704 5.12a.204.204 0 01-.019.092l-.01-.007.01.008-.106.126c0 .028-.009.045-.044.056zm-7.189-3.92c3.941 2.38 6.312 4.268 6.467 4.768a.45.45 0 01.033.15.197.197 0 01-.006.047l.002-.001.096-.157a.105.105 0 00.017-.06c.008-.562-3.204-2.864-6.554-4.893-3.198-1.927-6.272-3.34-7.128-3.34a.337.337 0 00-.179.038l-.12.178c.04-.052.119-.072.229-.072.848.001 3.744 1.313 7.143 3.343zm6.452 4.998l.002-.002-.002.001c.002 0 .023-.023.023-.078a.405.405 0 00-.033-.144c-.141-.48-2.517-2.378-6.455-4.753-3.395-2.029-6.298-3.34-7.13-3.34a.495.495 0 00-.231.05l.101-.194.011.005-.006-.01a.352.352 0 01.195-.043c.872.001 3.938 1.414 7.14 3.343 3.353 2.037 6.56 4.312 6.567 4.914a.16.16 0 01-.02.074l-.101.16-.03.007c-.005.008-.012.013-.019.013l-.012-.003z'
              fill='#030404'
            />
            <path
              d='M9.23 16.8a4.68 4.68 0 001.39 3.323c.862.858 2.032 1.4 3.328 1.4 1.305 0 2.488-.53 3.345-1.385a4.696 4.696 0 001.39-3.335v-6.314H9.231V16.8z'
              fill='#fff'
            />
            <path
              d='M10.63 20.114c.858.856 2.025 1.396 3.318 1.396 1.301 0 2.48-.53 3.335-1.38a4.686 4.686 0 001.388-3.327l.012-6.301-9.441-.013V16.8c0 1.29.53 2.46 1.387 3.314zm-.018.018A4.69 4.69 0 019.217 16.8l.013-6.323 9.465.012v6.314a4.712 4.712 0 01-1.394 3.345 4.741 4.741 0 01-3.353 1.386c-1.3 0-2.475-.542-3.336-1.402z'
              fill='#030404'
            />
            <path
              d='M9.422 16.8v.003a4.49 4.49 0 001.335 3.187 4.538 4.538 0 003.202 1.328c1.25 0 2.38-.506 3.201-1.322a4.494 4.494 0 001.33-3.19v-6.114H9.427l-.006 6.109z'
              fill='#cf2028'
            />
            <path
              d='M9.434 16.803c0 1.235.51 2.361 1.331 3.177a4.531 4.531 0 003.194 1.325 4.511 4.511 0 003.192-1.32 4.473 4.473 0 001.327-3.178l.011-6.104-9.05-.01-.005 6.107v.003zm1.315 3.195a4.494 4.494 0 01-1.339-3.195V16.8l.017-6.12 9.075.012v6.115a4.5 4.5 0 01-1.335 3.197 4.532 4.532 0 01-3.208 1.327 4.545 4.545 0 01-3.21-1.333z'
              fill='#030404'
            />
            <path
              d='M11.274 16.521v.002c0 .807.303 1.539.79 2.072.487.534 1.158.866 1.895.866.738 0 1.41-.33 1.893-.862a3.067 3.067 0 00.787-2.073v-3.975h-5.361l-.004 3.97z'
              fill='#fff'
            />
            <path
              d='M11.287 16.523c0 .803.3 1.532.786 2.063.484.532 1.154.862 1.886.862.734 0 1.4-.326 1.885-.857.483-.53.783-1.26.783-2.065l.012-3.962-5.35-.012-.002 3.97zm.767 2.079a3.075 3.075 0 01-.792-2.08h.025l-.025-.002.016-3.981 5.373.013v3.974a3.08 3.08 0 01-.79 2.081c-.487.536-1.162.867-1.902.867s-1.417-.335-1.905-.872z'
              fill='#030404'
            />
            <path
              d='M13.367 16.12a.68.68 0 00.172.455c.108.117.254.19.416.19a.558.558 0 00.417-.19.662.662 0 00.173-.456v-.874h-1.178v.874z'
              fill='#1972b9'
            />
            <path
              d='M14.28 15.437a.118.118 0 100 .235.117.117 0 100-.235zM13.948 15.753a.118.118 0 000 .237.12.12 0 00.12-.119.119.119 0 00-.12-.118zM14.279 16.086a.12.12 0 00-.119.119.119.119 0 00.237 0c0-.066-.05-.12-.118-.12zM13.635 16.086a.121.121 0 00-.121.12.12.12 0 00.12.118.119.119 0 000-.238zM13.632 15.437a.118.118 0 00-.118.117c0 .065.054.116.118.116.065 0 .12-.051.12-.116a.118.118 0 00-.12-.117z'
              fill='#fff'
            />
            <path
              d='M11.707 16.12c0 .177.066.337.174.455.106.117.255.19.417.19a.564.564 0 00.417-.19.673.673 0 00.174-.456v-.874h-1.182v.874z'
              fill='#1972b9'
            />
            <path
              d='M12.622 15.437a.119.119 0 00-.12.117.12.12 0 00.12.117.117.117 0 00.117-.117.117.117 0 00-.117-.117zM12.29 15.753a.119.119 0 00-.12.118.12.12 0 00.239 0 .119.119 0 00-.12-.118zM12.622 16.086a.12.12 0 00-.12.119.118.118 0 10.12-.12zM11.975 16.086a.121.121 0 00-.119.12.12.12 0 00.119.118c.066 0 .12-.053.12-.118a.12.12 0 00-.12-.12zM11.975 15.437a.118.118 0 00-.119.117c0 .065.053.116.119.116a.117.117 0 00.118-.116.118.118 0 00-.118-.117z'
              fill='#fff'
            />
            <path
              d='M15.026 16.12c0 .177.067.337.173.455a.57.57 0 00.415.19.567.567 0 00.419-.19.677.677 0 00.174-.456v-.874h-1.181v.874z'
              fill='#1972b9'
            />
            <path
              d='M15.94 15.437a.119.119 0 00-.118.117.118.118 0 00.236 0 .117.117 0 00-.119-.117zM15.607 15.753a.118.118 0 10.003.237.118.118 0 00-.003-.237zM15.938 16.086a.118.118 0 00-.116.119c0 .064.051.117.116.117a.12.12 0 00.12-.117.12.12 0 00-.12-.12zM15.295 16.086a.12.12 0 00-.121.12.119.119 0 10.12-.12zM15.29 15.437a.119.119 0 00-.118.117c0 .065.055.116.119.116.066 0 .12-.051.12-.116a.119.119 0 00-.12-.117z'
              fill='#fff'
            />
            <path
              d='M13.367 17.243v.876c0 .176.066.338.172.455.108.119.254.19.416.19a.558.558 0 00.417-.188.665.665 0 00.173-.457v-.874l-1.178-.002z'
              fill='#1972b9'
            />
            <path
              d='M14.28 17.435a.119.119 0 100 .237.119.119 0 000-.237zM13.948 17.753a.118.118 0 000 .236c.065 0 .12-.053.12-.116a.12.12 0 00-.12-.12zM14.279 18.083a.12.12 0 00-.119.119c0 .065.053.118.119.118a.117.117 0 00.118-.118.118.118 0 00-.118-.119zM13.635 18.085a.12.12 0 00-.121.118c0 .065.054.12.12.12a.12.12 0 00.12-.12.12.12 0 00-.12-.118zM13.632 17.435a.12.12 0 00-.118.118.12.12 0 00.118.119.12.12 0 00.12-.119.12.12 0 00-.12-.118z'
              fill='#fff'
            />
            <path
              d='M13.367 14.12c0 .178.066.34.172.457a.569.569 0 00.416.188.569.569 0 00.417-.186.673.673 0 00.173-.457v-.876h-1.178v.875z'
              fill='#1972b9'
            />
            <path
              d='M14.28 13.438a.118.118 0 00-.118.118c0 .065.051.118.119.118a.117.117 0 00.118-.118.117.117 0 00-.118-.118zM13.948 13.756a.118.118 0 000 .236c.065 0 .12-.052.12-.12a.119.119 0 00-.12-.116zM14.279 14.087a.119.119 0 100 .237.117.117 0 00.118-.119.117.117 0 00-.118-.118zM13.635 14.088a.12.12 0 00-.121.119c0 .065.054.117.12.117a.118.118 0 00.12-.117.12.12 0 00-.12-.119zM13.632 13.438a.119.119 0 00-.118.118.12.12 0 00.118.118.12.12 0 00.12-.118.119.119 0 00-.12-.118z'
              fill='#fff'
            />
            <path
              d='M16.913 12.277h1.308l-.06-.504h-1.195l-.053.504z'
              fill='#fcd955'
            />
            <path
              d='M18.208 12.28l-.047-.494-1.183-.013-.054.493 1.284.013zm-1.295.01v-.012l-.012-.003.065-.514 1.208.01.047.518h-1.308z'
              fill='#030404'
            />
            <path
              d='M17.56 11.816c.164.002.33.265.331.46h-.651c.001-.204.16-.46.32-.46z'
              fill='#fcd955'
            />
            <path
              d='M17.228 12.276c.002-.21.16-.47.332-.473v.024c-.148 0-.308.25-.32.437l.638.012c0-.187-.167-.45-.318-.45v-.024c.177.005.34.274.331.487l-.663-.013z'
              fill='#030404'
            />
            <path
              d='M17.562 11.926s.19.092.19.35h-.37c0-.258.18-.35.18-.35z'
              fill='#fcd955'
            />
            <path
              d='M17.381 12.264c.18 0 .181 0 .359.013a.371.371 0 00-.178-.337.391.391 0 00-.18.324zm-.012.013c0-.266.186-.362.187-.362l.006.011.005-.011c.002 0 .198.095.185.374-.191 0-.19 0-.383-.012z'
              fill='#030404'
            />
            <path
              d='M16.92 11.549h1.293c.026 0 .05.022.05.054 0 .033-.024.058-.05.058H16.92c-.028 0-.05-.025-.05-.058 0-.032.022-.054.05-.054z'
              fill='#fcd955'
            />
            <path
              d='M16.92 11.672c-.035 0-.06-.033-.06-.07 0-.037.026-.067.06-.067v.025c-.021 0-.035.016-.037.043.002.028.018.044.037.044h1.293c.018 0 .038-.018.038-.044-.002-.026-.019-.043-.038-.043H16.92v-.025h1.293c.033 0 .063.028.063.068 0 .039-.029.069-.063.069H16.92z'
              fill='#030404'
            />
            <path
              d='M16.888 12.28h1.358c.03 0 .052.025.052.058 0 .035-.022.059-.052.059h-1.358c-.03 0-.054-.024-.054-.06 0-.032.024-.058.054-.058z'
              fill='#fcd955'
            />
            <path
              d='M16.887 12.408c-.037 0-.065-.032-.065-.07 0-.038.027-.071.065-.071v.025c-.022 0-.04.019-.042.046.002.029.02.046.042.046h1.36c.018 0 .037-.016.039-.046-.002-.027-.02-.046-.04-.046h-1.359v-.025h1.36c.035 0 .064.033.064.07 0 .04-.029.07-.065.07h-1.359z'
              fill='#030404'
            />
            <path
              d='M16.948 11.66h1.237c.025 0 .047.025.047.055 0 .032-.022.056-.047.056h-1.237c-.025 0-.047-.024-.047-.056 0-.03.022-.054.047-.054z'
              fill='#fcd955'
            />
            <path
              d='M16.948 11.784c-.033 0-.059-.032-.059-.069 0-.036.026-.068.059-.068v.025c-.017 0-.034.018-.034.043 0 .028.017.044.034.044h1.236c.018 0 .034-.016.035-.044-.001-.026-.017-.043-.035-.043h-1.236v-.025h1.236c.035 0 .06.032.06.068 0 .037-.025.069-.06.069h-1.236z'
              fill='#030404'
            />
            <path
              d='M17.724 11.02l.021.529-.343-.002.025-.526h.297z'
              fill='#fcd955'
            />
            <path
              d='M17.402 11.533l.33.016-.02-.517-.273-.011-.037.512zm-.013.013l.038-.54.297.002v.013l.012-.002.009.54-.356-.013z'
              fill='#030404'
            />
            <path
              d='M17.36 10.818h.095v.07h.07v-.07h.098v.07h.07v-.07h.1v.16c0 .025-.021.043-.045.043h-.345c-.022 0-.044-.02-.044-.043v-.16z'
              fill='#fcd955'
            />
            <path
              d='M17.372 10.978c0 .014.017.03.03.03h.346c.017-.002.03-.011.032-.03l.013-.148-.088-.012-.011.083-.084-.013.013-.058-.087-.012-.01.083-.084-.013.012-.058h-.082v.148zm.03.055a.057.057 0 01-.055-.055v-.16h.012v-.012l.108.012-.013.058.059.012.012-.082.109.012-.011.058.058.012.013-.082.11.012v.16a.055.055 0 01-.056.055h-.346zM17.539 11.243c-.003-.05.07-.052.07 0v.12h-.07v-.12z'
              fill='#030404'
            />
            <path
              d='M17.778 11.07h.099v.07h.07v-.072h.096v.072h.068v-.072h.1v.163c0 .025-.021.04-.042.04h-.345c-.023 0-.046-.019-.046-.04v-.16z'
              fill='#fcd955'
            />
            <path
              d='M17.791 11.23c0 .014.016.027.033.027h.344c.016 0 .03-.01.03-.027l.012-.15-.087-.012-.012.085-.081-.014.013-.058-.086-.013-.01.085-.083-.014.013-.057h-.086v.148zm.033.052c-.03 0-.058-.021-.058-.052v-.16h.012v-.011l.111.012-.012.056.055.012.014-.083.107.012-.01.059.055.012.013-.083.113.012v.162c0 .034-.03.052-.056.052h-.344z'
              fill='#030404'
            />
            <path
              d='M18.15 11.272l.008.277h-.327l.01-.277h.31z'
              fill='#fcd955'
            />
            <path
              d='M17.83 11.535l.315.014-.007-.267-.285-.01-.022.263zm-.012.013l.022-.29h.31v.012h.011l-.004.29-.339-.012z'
              fill='#030404'
            />
            <path
              d='M16.935 11.07h.094v.07h.073v-.072l.095.003v.07h.07v-.073h.097v.163a.04.04 0 01-.041.04h-.344c-.022 0-.044-.019-.044-.04v-.16z'
              fill='#fcd955'
            />
            <path
              d='M16.946 11.231c0 .012.018.026.033.026h.344c.015 0 .028-.01.029-.026l.012-.15-.085-.013-.013.085-.082-.012.011-.059-.082-.014-.012.085-.085-.012.013-.059h-.083v.149zm.033.053c-.029-.002-.056-.023-.057-.053v-.162h.013v-.012l.107.012-.013.058.059.014.013-.084.107.012-.011.058.057.014.012-.084.11.011v.163c0 .033-.026.053-.053.053h-.344z'
              fill='#030404'
            />
            <path
              d='M17.302 11.272l.011.277h-.327l.01-.277h.306z'
              fill='#fcd955'
            />
            <path
              d='M17.29 11.273v-.001zm-.304.262l.314.014-.009-.267-.283-.01-.022.263zm-.011.013l.021-.29h.306v.014h.013l-.002.288-.338-.012zM17.96 11.39c0-.05.07-.05.07 0v.074h-.07v-.074z'
              fill='#030404'
            />
            <path
              d='M17.116 11.39c-.002-.05.07-.05.068 0v.074h-.068v-.074z'
              fill='#030404'
            />
            <path
              d='M16.913 16.069h1.308l-.06-.506h-1.195l-.053.506z'
              fill='#fcd955'
            />
            <path
              d='M18.208 16.071l-.047-.495-1.183-.012-.054.493 1.284.014zm-1.295.01v-.011l-.012-.003.065-.516 1.208.01.047.52h-1.308z'
              fill='#030404'
            />
            <path
              d='M17.56 15.606c.164 0 .33.266.331.463h-.651c.001-.207.16-.463.32-.463z'
              fill='#fcd955'
            />
            <path
              d='M17.228 16.069c.002-.213.16-.472.332-.476v.025c-.148-.001-.308.249-.32.438l.638.013c0-.191-.167-.453-.318-.451v-.026c.177.006.34.274.331.489l-.663-.012z'
              fill='#030404'
            />
            <path
              d='M17.562 15.717s.19.09.19.352h-.37c0-.263.18-.352.18-.352z'
              fill='#fcd955'
            />
            <path
              d='M17.381 16.056c.18 0 .181 0 .359.013a.375.375 0 00-.178-.339.393.393 0 00-.18.326zm-.012.013c0-.268.186-.364.187-.364l.006.012.005-.012c.002 0 .198.095.185.376-.191 0-.19 0-.383-.012z'
              fill='#030404'
            />
            <path
              d='M16.92 15.34h1.293c.026 0 .05.023.05.054 0 .032-.024.058-.05.058H16.92c-.028 0-.05-.026-.05-.058 0-.03.022-.054.05-.054z'
              fill='#fcd955'
            />
            <path
              d='M16.92 15.464c-.035 0-.06-.033-.06-.07 0-.037.026-.066.06-.066v.025c-.021 0-.035.015-.037.041.002.029.018.045.037.045h1.293c.018 0 .038-.018.038-.045-.002-.025-.019-.04-.038-.04H16.92v-.026h1.293c.033 0 .063.029.063.066 0 .038-.029.069-.063.07H16.92z'
              fill='#030404'
            />
            <path
              d='M16.888 16.071h1.358c.03 0 .052.026.052.059 0 .034-.022.059-.052.059h-1.358c-.03 0-.054-.025-.054-.06 0-.032.024-.058.054-.058z'
              fill='#fcd955'
            />
            <path
              d='M16.887 16.201c-.037 0-.065-.032-.065-.071 0-.04.027-.07.065-.07v.024c-.022 0-.04.019-.042.046.002.029.02.046.042.046h1.36c.018 0 .037-.016.039-.046-.002-.027-.02-.046-.04-.046h-1.359v-.025h1.36c.035 0 .064.032.064.07 0 .04-.029.072-.065.072h-1.359z'
              fill='#030404'
            />
            <path
              d='M16.948 15.452h1.237c.025 0 .047.023.047.053 0 .033-.022.057-.047.057h-1.237c-.025 0-.047-.024-.047-.057 0-.03.022-.053.047-.053z'
              fill='#fcd955'
            />
            <path
              d='M16.948 15.573c-.033 0-.059-.03-.059-.068 0-.033.026-.066.059-.066v.025c-.017 0-.034.017-.034.041 0 .026.017.044.034.044h1.236c.018 0 .034-.018.035-.044-.001-.025-.017-.04-.035-.04h-1.236v-.026h1.236c.035 0 .06.033.06.066 0 .038-.025.068-.06.068h-1.236z'
              fill='#030404'
            />
            <path
              d='M17.724 14.812l.021.528-.343-.002.025-.526h.297z'
              fill='#fcd955'
            />
            <path
              d='M17.402 15.327l.33.013-.02-.518-.273-.01-.037.515zm-.013.01l.038-.54.297.002v.012l.012-.001.009.543-.356-.017z'
              fill='#030404'
            />
            <path
              d='M17.36 14.609h.095v.071h.07v-.071h.098v.071h.07v-.071h.1v.16c0 .026-.021.043-.045.043h-.345c-.022 0-.044-.022-.044-.042v-.161z'
              fill='#fcd955'
            />
            <path
              d='M17.372 14.768c0 .013.017.031.03.031h.346c.017-.002.03-.012.032-.03l.013-.147-.088-.013-.011.082-.084-.01.013-.06-.087-.012-.01.082-.084-.01.012-.06h-.082v.147zm.03.054a.057.057 0 01-.055-.054v-.16h.012v-.01l.108.01-.013.058.059.014.012-.082.109.01-.011.058.058.014.013-.082.11.01v.16c0 .033-.027.054-.056.054h-.346zM17.539 15.032c-.003-.05.07-.051.07 0v.123h-.07v-.123z'
              fill='#030404'
            />
            <path
              d='M17.778 14.86h.099v.07h.07v-.071h.096v.071h.068v-.071h.1v.163c0 .024-.021.04-.042.04h-.345c-.023 0-.046-.02-.046-.04v-.161z'
              fill='#fcd955'
            />
            <path
              d='M17.791 15.02c0 .014.016.029.033.029h.344c.016 0 .03-.01.03-.028l.012-.15-.087-.011-.012.082-.081-.012.013-.06-.086-.01-.01.082-.083-.012.013-.058h-.086v.149zm.033.053c-.03 0-.058-.022-.058-.052v-.16h.012v-.014l.111.014-.012.057.055.012.014-.084.107.014-.01.058.055.012.013-.084.113.014v.16c0 .034-.03.053-.056.053h-.344z'
              fill='#030404'
            />
            <path
              d='M18.15 15.061l.008.279h-.327l.01-.279h.31z'
              fill='#fcd955'
            />
            <path
              d='M17.83 15.328l.315.013-.007-.268-.285-.012-.022.267zm-.012.011l.022-.29h.31v.011h.011l-.004.292-.339-.013z'
              fill='#030404'
            />
            <path
              d='M16.935 14.86h.094v.07h.073v-.071l.095.002v.069h.07v-.071h.097v.163c0 .024-.02.04-.041.04h-.344c-.022 0-.044-.02-.044-.04v-.161z'
              fill='#fcd955'
            />
            <path
              d='M16.946 15.022c0 .012.018.026.033.026h.344c.015 0 .028-.01.029-.026l.012-.15-.085-.013-.013.084-.082-.013.011-.058-.082-.013-.012.084-.085-.013.013-.058h-.083v.15zm.033.051c-.029-.001-.056-.02-.057-.05v-.163h.013v-.013l.107.013-.013.057.059.013.013-.084.107.014-.011.057.057.013.012-.084.11.013v.163c0 .032-.026.051-.053.051h-.344z'
              fill='#030404'
            />
            <path
              d='M17.302 15.061l.011.279h-.327l.01-.279h.306z'
              fill='#fcd955'
            />
            <path
              d='M17.29 15.062zm-.304.266l.314.012-.009-.268-.283-.01-.022.266zm-.011.011l.021-.29h.306v.013h.013l-.002.291-.338-.014zM17.96 15.18c0-.05.07-.05.07 0v.076h-.07v-.076z'
              fill='#030404'
            />
            <path
              d='M17.116 15.18c-.002-.05.07-.05.068 0v.076h-.068v-.076z'
              fill='#030404'
            />
            <path
              d='M9.695 16.069h1.308l-.06-.506H9.746l-.051.506z'
              fill='#fcd955'
            />
            <path
              d='M10.989 16.071l-.046-.495-1.186-.012-.05.493 1.282.014zm-1.294.01v-.011l-.014-.003.065-.516 1.209.01.048.52H9.695z'
              fill='#030404'
            />
            <path
              d='M10.34 15.606c.165 0 .328.266.331.463h-.65c.002-.207.16-.463.32-.463z'
              fill='#fcd955'
            />
            <path
              d='M10.009 16.069c.001-.213.16-.472.331-.476v.025c-.146-.001-.306.249-.319.438l.638.013c-.002-.191-.168-.453-.319-.451v-.026c.178.006.338.274.331.489l-.662-.012z'
              fill='#030404'
            />
            <path
              d='M10.343 15.717s.188.09.188.352h-.368c0-.263.18-.352.18-.352z'
              fill='#fcd955'
            />
            <path
              d='M10.163 16.056c.178 0 .18 0 .357.013a.378.378 0 00-.177-.339.387.387 0 00-.18.326zm-.012.013c0-.268.186-.364.186-.364l.006.012.005-.012c.001 0 .196.095.183.376-.19 0-.188 0-.38-.012z'
              fill='#030404'
            />
            <path
              d='M9.703 15.34h1.292c.025 0 .049.023.049.054 0 .032-.024.058-.05.058H9.704c-.03 0-.05-.026-.05-.058 0-.03.02-.054.05-.054z'
              fill='#fcd955'
            />
            <path
              d='M9.701 15.464c-.036 0-.061-.033-.061-.07 0-.037.026-.066.061-.066v.025c-.021 0-.036.015-.036.041 0 .029.016.045.036.045h1.294c.016 0 .036-.018.036-.045 0-.025-.018-.04-.036-.04H9.7v-.026h1.294c.032 0 .06.029.06.066 0 .038-.028.069-.06.07H9.7z'
              fill='#030404'
            />
            <path
              d='M9.67 16.071h1.357c.028 0 .051.026.051.059 0 .034-.023.059-.051.059H9.669c-.03 0-.053-.025-.053-.06 0-.032.023-.058.053-.058z'
              fill='#fcd955'
            />
            <path
              d='M9.668 16.201c-.036 0-.065-.032-.065-.071 0-.04.028-.07.065-.07v.024c-.02 0-.04.019-.04.046 0 .029.018.046.04.046h1.359c.02 0 .039-.016.04-.046-.001-.027-.02-.046-.04-.046H9.668v-.025h1.359c.036 0 .063.032.063.07 0 .04-.027.072-.063.072H9.668z'
              fill='#030404'
            />
            <path
              d='M9.728 15.452h1.237c.028 0 .046.023.046.053 0 .033-.018.057-.046.057H9.728c-.024 0-.047-.024-.047-.057 0-.03.023-.053.047-.053z'
              fill='#fcd955'
            />
            <path
              d='M9.728 15.573c-.032 0-.059-.03-.059-.068 0-.033.027-.066.059-.066v.025c-.016 0-.033.017-.033.041 0 .026.017.044.033.044h1.237c.018 0 .033-.018.035-.044-.002-.025-.017-.04-.035-.04H9.728v-.026h1.237c.036 0 .059.033.059.066 0 .038-.023.068-.059.068H9.728z'
              fill='#030404'
            />
            <path
              d='M10.505 14.812l.021.528-.343-.002.025-.526h.297z'
              fill='#fcd955'
            />
            <path
              d='M10.183 15.327l.33.013-.02-.518-.274-.01-.036.515zm-.013.01l.037-.54.298.002v.012l.012-.001.01.543-.357-.017z'
              fill='#030404'
            />
            <path
              d='M10.141 14.609h.095v.071h.07v-.071h.097v.071h.072v-.071h.097v.16c0 .026-.02.043-.043.043h-.345c-.02 0-.043-.022-.043-.042v-.161z'
              fill='#fcd955'
            />
            <path
              d='M10.153 14.768c0 .013.017.031.03.031h.346c.017-.002.03-.012.03-.03l.013-.147-.085-.013-.012.082-.084-.01.012-.06-.085-.012-.012.082-.083-.01.013-.06h-.083v.147zm.03.054a.058.058 0 01-.056-.054v-.16h.013v-.01l.107.01-.011.058.058.014.012-.082.109.01-.012.058.059.014.013-.082.11.01v.16c0 .033-.029.054-.056.054h-.345zM10.32 15.032c-.002-.05.07-.051.07 0v.123h-.07v-.123z'
              fill='#030404'
            />
            <path
              d='M10.558 14.86h.098v.07h.07v-.071h.097v.071h.07v-.071h.1v.163c0 .024-.021.04-.045.04h-.343c-.025 0-.047-.02-.047-.04v-.161z'
              fill='#fcd955'
            />
            <path
              d='M10.572 15.02c0 .014.014.029.032.029h.344c.017 0 .032-.01.032-.028l.013-.15-.09-.011-.01.082-.083-.012.013-.06-.085-.01-.012.082-.084-.012.014-.058h-.084v.149zm.032.053c-.03 0-.057-.022-.057-.052v-.16h.011v-.014l.11.014-.012.057.057.012.013-.084.108.014-.011.058.057.012.012-.084.114.014v.16c0 .034-.03.053-.058.053h-.344z'
              fill='#030404'
            />
            <path
              d='M10.93 15.061l.009.279h-.328l.01-.279h.31z'
              fill='#fcd955'
            />
            <path
              d='M10.611 15.328l.315.013-.008-.268-.286-.012-.021.267zm-.013.011l.021-.29h.312v.011h.012l-.004.292-.341-.013z'
              fill='#030404'
            />
            <path
              d='M9.715 14.86h.094v.07h.072v-.071l.096.002v.069h.07v-.071h.1v.163c0 .024-.021.04-.042.04H9.76c-.023 0-.045-.02-.045-.04v-.161z'
              fill='#fcd955'
            />
            <path
              d='M9.726 15.022c0 .012.017.026.034.026h.345c.014 0 .027-.01.029-.026l.012-.15-.085-.013-.014.084-.082-.013.011-.058-.083-.013-.013.084-.084-.013.013-.058h-.083v.15zm.034.051c-.03-.001-.056-.02-.057-.05v-.163h.012v-.013l.106.013-.012.057.06.013.01-.084.11.014-.012.057.058.013.012-.084.11.013v.163c0 .032-.026.051-.052.051H9.76z'
              fill='#030404'
            />
            <path
              d='M10.085 15.061l.009.279h-.33l.013-.279h.308z'
              fill='#fcd955'
            />
            <path
              d='M10.072 15.062zm-.307.266l.315.012-.008-.268-.284-.01-.023.266zm-.01.011l.022-.29h.308v.013h.01l-.001.291-.34-.014zM10.74 15.18c-.002-.05.07-.05.07 0v.076h-.07v-.076z'
              fill='#030404'
            />
            <path
              d='M9.896 15.18c-.002-.05.07-.05.068 0v.076h-.068v-.076z'
              fill='#030404'
            />
            <path
              d='M13.302 12.278h1.31l-.059-.505h-1.197l-.054.505z'
              fill='#fcd955'
            />
            <path
              d='M14.6 12.28l-.047-.494-1.186-.013-.052.493 1.285.014zm-1.298.01v-.012l-.011-.003.065-.514 1.209.012.047.516h-1.31z'
              fill='#030404'
            />
            <path
              d='M13.95 11.816c.164.002.328.267.33.462h-.65c.002-.205.16-.462.32-.462z'
              fill='#fcd955'
            />
            <path
              d='M13.62 12.277c0-.21.159-.471.33-.473v.024c-.147-.001-.307.25-.32.436l.638.013c0-.188-.168-.451-.319-.449v-.026c.177.006.34.274.332.487l-.662-.012z'
              fill='#030404'
            />
            <path
              d='M13.952 11.926s.19.092.19.352h-.37c0-.26.18-.352.18-.352z'
              fill='#fcd955'
            />
            <path
              d='M13.773 12.264c.178 0 .18 0 .356.014a.375.375 0 00-.177-.338.39.39 0 00-.18.324zm-.012.014c0-.267.182-.363.185-.363l.006.011.005-.011c.002 0 .197.095.185.374-.191 0-.19 0-.38-.01z'
              fill='#030404'
            />
            <path
              d='M13.31 11.549h1.294c.027 0 .05.023.05.055s-.023.057-.05.057H13.31c-.028 0-.049-.025-.049-.057s.02-.055.05-.055z'
              fill='#fcd955'
            />
            <path
              d='M13.31 11.672c-.037 0-.062-.032-.062-.068 0-.04.026-.067.062-.067v.023c-.02 0-.037.017-.038.044.001.027.019.043.038.043h1.294c.018 0 .038-.018.038-.043-.002-.026-.019-.044-.038-.044H13.31v-.023h1.294c.033 0 .062.026.062.067 0 .038-.027.068-.062.068H13.31z'
              fill='#030404'
            />
            <path
              d='M13.277 12.28h1.36c.029 0 .05.025.05.058 0 .035-.021.06-.05.06h-1.36c-.03 0-.054-.025-.054-.06 0-.033.024-.058.054-.058z'
              fill='#fcd955'
            />
            <path
              d='M13.277 12.409c-.038 0-.066-.031-.066-.07 0-.04.028-.072.066-.072v.025c-.022 0-.041.02-.042.046 0 .029.02.046.042.046h1.36c.02 0 .036-.016.038-.046-.002-.026-.019-.046-.038-.046h-1.36v-.025h1.36c.035 0 .062.033.062.071 0 .04-.027.07-.062.07h-1.36z'
              fill='#030404'
            />
            <path
              d='M13.338 11.66h1.239c.027 0 .045.025.045.055 0 .032-.018.058-.045.058h-1.239c-.025 0-.047-.026-.047-.058 0-.03.022-.054.047-.054z'
              fill='#fcd955'
            />
            <path
              d='M13.338 11.784c-.034 0-.06-.032-.06-.069 0-.035.026-.068.06-.068v.025c-.016 0-.036.018-.036.043 0 .028.02.044.036.044h1.237c.018 0 .033-.016.034-.044-.001-.025-.016-.043-.034-.043h-1.237v-.025h1.237c.036 0 .06.033.06.068 0 .037-.024.07-.06.07h-1.237z'
              fill='#030404'
            />
            <path
              d='M14.114 11.022l.021.527-.343-.002.025-.525h.297z'
              fill='#fcd955'
            />
            <path
              d='M13.792 11.534l.33.015-.02-.516-.274-.011-.036.512zm-.011.012l.036-.54.297.002v.013h.01l.01.54-.353-.015z'
              fill='#030404'
            />
            <path
              d='M13.752 10.818h.093v.07h.07v-.07h.096v.07h.073v-.07h.097v.161c0 .026-.02.043-.043.043h-.345c-.02 0-.041-.02-.041-.043v-.16z'
              fill='#fcd955'
            />
            <path
              d='M13.763 10.978c0 .014.018.03.03.03h.345c.016-.002.03-.01.032-.03l.011-.147-.087-.013-.01.084-.084-.014.011-.057-.084-.013-.013.084-.082-.014.012-.057h-.081v.147zm.03.055a.058.058 0 01-.055-.055v-.16h.012v-.01l.106.01-.012.058.06.012.01-.08.11.01-.013.058.058.012.015-.08.11.01v.16a.056.056 0 01-.056.055h-.345zM13.929 11.243a.035.035 0 01.069 0v.12h-.07v-.12z'
              fill='#030404'
            />
            <path
              d='M14.169 11.07h.096v.07h.07v-.072h.097v.073h.071v-.073h.1v.163c0 .025-.021.04-.043.04h-.347c-.023 0-.044-.019-.044-.04v-.16z'
              fill='#fcd955'
            />
            <path
              d='M14.18 11.231c0 .014.017.028.033.028h.346c.016 0 .03-.011.03-.028l.015-.15-.09-.012-.011.084-.082-.013.011-.06-.085-.01-.011.083-.083-.013.012-.058h-.085v.149zm.033.051c-.03 0-.058-.021-.058-.051v-.16h.014v-.012l.109.012-.013.056.058.013.013-.084.109.013-.013.058.057.013.014-.084.112.013v.162c0 .034-.029.051-.056.051h-.346z'
              fill='#030404'
            />
            <path
              d='M14.541 11.272l.008.277h-.329l.011-.277h.31z'
              fill='#fcd955'
            />
            <path
              d='M14.22 11.535l.316.015-.007-.268-.287-.01-.023.263zm-.013.014l.023-.29h.311v.013l.012-.002-.004.29-.342-.011z'
              fill='#030404'
            />
            <path
              d='M13.324 11.07h.095v.07h.072v-.072l.097.003v.07h.07v-.073h.098v.163a.04.04 0 01-.042.04h-.345c-.021 0-.045-.019-.045-.04v-.16z'
              fill='#fcd955'
            />
            <path
              d='M13.335 11.231c0 .013.018.026.034.026h.345c.016 0 .029-.01.03-.026l.012-.15-.086-.013-.013.085-.083-.012.012-.059-.083-.014-.013.085-.085-.012.012-.059h-.082v.149zm.034.053c-.03-.002-.057-.022-.058-.053v-.16h.013v-.012l.106.012-.013.056.06.014.013-.084.11.014-.012.056.057.014.012-.084.11.011v.163a.052.052 0 01-.053.053h-.345z'
              fill='#030404'
            />
            <path
              d='M13.694 11.272l.01.277h-.328l.01-.277h.308z'
              fill='#fcd955'
            />
            <path
              d='M13.682 11.273v-.001.001zm-.306.262l.315.014-.008-.267-.286-.01-.02.263zm-.012.013l.022-.29h.308v.015l.012-.001-.002.29-.34-.014zM14.351 11.39c-.002-.05.07-.05.07 0v.074h-.07v-.074z'
              fill='#030404'
            />
            <path
              d='M13.506 11.39c-.002-.05.07-.05.068 0v.074h-.068v-.074z'
              fill='#030404'
            />
            <path
              d='M9.695 12.277h1.308l-.06-.504H9.746l-.051.504z'
              fill='#fcd955'
            />
            <path
              d='M10.989 12.28l-.046-.494-1.186-.013-.05.493 1.282.013zm-1.294.01v-.012l-.014-.003.065-.514 1.209.01.048.518H9.695z'
              fill='#030404'
            />
            <path
              d='M10.34 11.816c.165.002.328.265.331.46h-.65c.002-.204.16-.46.32-.46z'
              fill='#fcd955'
            />
            <path
              d='M10.009 12.276c.001-.21.16-.47.331-.473v.024c-.146 0-.306.25-.319.437l.638.012c-.002-.187-.168-.45-.319-.45v-.024c.178.005.338.274.331.487l-.662-.013z'
              fill='#030404'
            />
            <path
              d='M10.343 11.926s.188.092.188.35h-.368c0-.258.18-.35.18-.35z'
              fill='#fcd955'
            />
            <path
              d='M10.163 12.264c.178 0 .18 0 .357.013a.374.374 0 00-.177-.337.386.386 0 00-.18.324zm-.012.013c0-.266.186-.362.186-.362l.006.011.005-.011c.001 0 .196.095.183.374-.19 0-.188 0-.38-.012z'
              fill='#030404'
            />
            <path
              d='M9.703 11.549h1.292c.025 0 .049.022.049.054 0 .033-.024.058-.05.058H9.704c-.03 0-.05-.025-.05-.058 0-.032.02-.054.05-.054z'
              fill='#fcd955'
            />
            <path
              d='M9.701 11.672c-.036 0-.061-.033-.061-.07 0-.037.026-.067.061-.067v.025c-.021 0-.036.016-.036.043 0 .028.016.044.036.044h1.294c.016 0 .036-.018.036-.044s-.018-.043-.036-.043H9.7v-.025h1.294c.032 0 .06.028.06.068 0 .039-.028.069-.06.069H9.7z'
              fill='#030404'
            />
            <path
              d='M9.67 12.28h1.357c.028 0 .051.025.051.058 0 .035-.023.059-.051.059H9.669c-.03 0-.053-.024-.053-.06 0-.032.023-.058.053-.058z'
              fill='#fcd955'
            />
            <path
              d='M9.668 12.408c-.036 0-.065-.032-.065-.07 0-.038.028-.071.065-.071v.025c-.02 0-.04.019-.04.046 0 .029.018.046.04.046h1.359c.02 0 .039-.016.04-.046-.001-.027-.02-.046-.04-.046H9.668v-.025h1.359c.036 0 .063.033.063.07 0 .04-.027.07-.063.07H9.668z'
              fill='#030404'
            />
            <path
              d='M9.728 11.66h1.237c.028 0 .046.025.046.055 0 .032-.018.056-.046.056H9.728c-.024 0-.047-.024-.047-.056 0-.03.023-.054.047-.054z'
              fill='#fcd955'
            />
            <path
              d='M9.728 11.784c-.032 0-.059-.032-.059-.069 0-.036.027-.068.059-.068v.025c-.016 0-.033.018-.033.043 0 .028.017.044.033.044h1.237c.018 0 .033-.016.035-.044-.002-.026-.017-.043-.035-.043H9.728v-.025h1.237c.036 0 .059.032.059.068 0 .037-.023.069-.059.069H9.728z'
              fill='#030404'
            />
            <path
              d='M10.505 11.02l.021.529-.343-.002.025-.526h.297z'
              fill='#fcd955'
            />
            <path
              d='M10.183 11.533l.33.016-.02-.517-.274-.011-.036.512zm-.013.013l.037-.54.298.002v.013l.012-.002.01.54-.357-.013z'
              fill='#030404'
            />
            <path
              d='M10.141 10.818h.095v.07h.07v-.07h.097v.07h.072v-.07h.097v.16c0 .025-.02.043-.043.043h-.345c-.02 0-.043-.02-.043-.043v-.16z'
              fill='#fcd955'
            />
            <path
              d='M10.153 10.978c0 .014.017.03.03.03h.346c.017-.002.03-.011.03-.03l.013-.148-.085-.012-.012.083-.084-.013.012-.058-.085-.012-.012.083-.083-.013.013-.058h-.083v.148zm.03.055a.059.059 0 01-.056-.055v-.16h.013v-.012l.107.012-.011.058.058.012.012-.082.109.012-.012.058.059.012.013-.082.11.012v.16c0 .032-.029.055-.056.055h-.345zM10.32 11.243c-.002-.05.07-.052.07 0v.12h-.07v-.12z'
              fill='#030404'
            />
            <path
              d='M10.558 11.07h.098v.07h.07v-.072h.097v.072h.07v-.072h.1v.163c0 .025-.021.04-.045.04h-.343c-.025 0-.047-.019-.047-.04v-.16z'
              fill='#fcd955'
            />
            <path
              d='M10.572 11.23c0 .014.014.027.032.027h.344c.017 0 .032-.01.032-.027l.013-.15-.09-.012-.01.085-.083-.014.013-.058-.085-.013-.012.085-.084-.014.014-.057h-.084v.148zm.032.052c-.03 0-.057-.021-.057-.052v-.16h.011v-.011l.11.012-.012.056.057.012.013-.083.108.012-.011.059.057.012.012-.083.114.012v.162c0 .034-.03.052-.058.052h-.344z'
              fill='#030404'
            />
            <path
              d='M10.93 11.272l.009.277h-.328l.01-.277h.31z'
              fill='#fcd955'
            />
            <path
              d='M10.611 11.535l.315.014-.008-.267-.286-.01-.021.263zm-.013.013l.021-.29h.312v.012h.012l-.004.29-.341-.012z'
              fill='#030404'
            />
            <path
              d='M9.715 11.07h.094v.07h.072v-.072l.096.003v.07h.07v-.073h.1v.163c0 .025-.021.04-.042.04H9.76c-.023 0-.045-.019-.045-.04v-.16z'
              fill='#fcd955'
            />
            <path
              d='M9.726 11.231c0 .012.017.026.034.026h.345c.014 0 .027-.01.029-.026l.012-.15-.085-.013-.014.085-.082-.012.011-.059-.083-.014-.013.085-.084-.012.013-.059h-.083v.149zm.034.053c-.03-.002-.056-.023-.057-.053v-.162h.012v-.012l.106.012-.012.058.06.014.01-.084.11.012-.012.058.058.014.012-.084.11.011v.163a.052.052 0 01-.052.053H9.76z'
              fill='#030404'
            />
            <path
              d='M10.085 11.272l.009.277h-.33l.013-.277h.308z'
              fill='#fcd955'
            />
            <path
              d='M10.072 11.273v-.001zm-.307.262l.315.014-.008-.267-.284-.01-.023.263zm-.01.013l.022-.29h.308v.014h.01l-.001.288-.34-.012zM10.74 11.39c-.002-.05.07-.05.07 0v.074h-.07v-.074z'
              fill='#030404'
            />
            <path
              d='M9.896 11.39c-.002-.05.07-.05.068 0v.074h-.068v-.074z'
              fill='#030404'
            />
            <path
              d='M11.227 19.97l.925-.927-.4-.313-.845.844.32.395z'
              fill='#fcd955'
            />
            <path
              d='M12.144 19.054l-.382-.315-.846.828.311.385.917-.898zm-.908.925l-.009-.01-.01.008-.32-.413.864-.846.4.334-.925.927z'
              fill='#030404'
            />
            <path
              d='M11.358 19.184c.115-.115.419-.043.561.094l-.46.458c-.145-.147-.214-.439-.101-.552z'
              fill='#fcd955'
            />
            <path
              d='M11.448 19.745c-.146-.152-.22-.446-.1-.57l.018.02c-.104.102-.042.39.082.533l.461-.442c-.136-.134-.44-.2-.543-.092l-.018-.018c.128-.122.433-.047.579.11l-.479.46z'
              fill='#030404'
            />
            <path
              d='M11.435 19.26s.2-.068.384.116l-.26.261c-.186-.185-.124-.376-.124-.376z'
              fill='#fcd955'
            />
            <path
              d='M11.55 19.627c.127-.124.127-.125.261-.241-.16-.162-.33-.125-.366-.115a.39.39 0 00.105.356zm0 .018c-.19-.189-.127-.386-.126-.387l.011.003-.003-.012c.001 0 .207-.072.397.137-.135.134-.135.133-.279.259z'
              fill='#030404'
            />
            <path
              d='M10.716 19.448l.914-.914c.018-.018.051-.02.075.004.022.022.023.057.004.075l-.915.915c-.018.018-.051.015-.075-.006-.022-.023-.023-.054-.003-.074z'
              fill='#fcd955'
            />
            <path
              d='M10.804 19.535c-.027.026-.068.021-.093-.003-.027-.027-.03-.067-.005-.092l.009.008.009.01c-.014.016-.014.036.003.056.02.018.045.018.059.005l.915-.915c.012-.011.012-.04-.006-.058-.018-.016-.043-.016-.056-.003l-.915.915-.009-.01-.009-.008.915-.914a.064.064 0 01.092.004.07.07 0 01.005.093l-.914.912z'
              fill='#030404'
            />
            <path
              d='M11.21 19.989l.961-.962c.02-.02.056-.018.077.005.025.025.025.058.007.079l-.96.961c-.022.02-.056.02-.08-.005-.025-.022-.026-.058-.005-.078z'
              fill='#fcd955'
            />
            <path
              d='M11.302 20.081c-.026.027-.07.024-.097-.004-.027-.027-.031-.07-.004-.097l.018.017c-.016.016-.016.043.002.062.023.02.048.02.063.004l.96-.961c.014-.015.015-.039-.005-.062-.02-.019-.045-.019-.06-.005l-.96.962-.018-.017.96-.962c.027-.025.069-.021.096.005.028.03.03.07.004.096l-.959.962z'
              fill='#030404'
            />
            <path
              d='M10.814 19.507l.874-.875c.02-.018.05-.014.074.007.022.022.025.054.006.073l-.875.874c-.017.017-.05.015-.073-.006-.022-.02-.024-.054-.006-.073z'
              fill='#fcd955'
            />
            <path
              d='M10.902 19.594c-.024.024-.066.02-.091-.006-.025-.025-.03-.065-.006-.09l.009.01.01.009c-.013.012-.013.037.004.054.02.018.044.017.057.006l.874-.874c.012-.012.01-.035-.007-.055-.017-.017-.042-.019-.054-.005l-.875.874-.01-.008-.008-.01.875-.874c.026-.026.064-.02.09.006.025.025.031.064.006.089l-.874.874z'
              fill='#030404'
            />
            <path
              d='M10.912 18.507l.388.358-.245.24-.354-.388.21-.21z'
              fill='#fcd955'
            />
            <path
              d='M11.047 19.097l.244-.224-.38-.35-.202.187.338.387zm0 .017l-.355-.406.21-.21.01.008.006-.009.39.376-.261.24z'
              fill='#030404'
            />
            <path
              d='M10.51 18.622l.068-.068.05.049.048-.049-.048-.05.068-.068.05.05.05-.05-.049-.051.069-.07.114.114c.016.018.014.043-.002.06l-.244.246c-.015.013-.044.014-.06 0l-.113-.113z'
              fill='#fcd955'
            />
            <path
              d='M10.632 18.726c.009.01.034.009.043 0l.244-.245a.03.03 0 000-.044l-.094-.112-.07.052.05.066-.067.05-.034-.05-.067.053.049.067-.068.05-.033-.05-.057.06.104.103zm.062.017a.057.057 0 01-.08 0l-.112-.113.009-.007-.009-.01.083-.067.033.049.05-.031-.049-.068.085-.069.031.048.053-.032-.05-.066.087-.07.114.114c.022.021.017.057-.002.077l-.243.245zM10.936 18.793c-.035-.032.015-.083.05-.049l.086.086-.05.049-.086-.086z'
              fill='#030404'
            />
            <path
              d='M10.984 18.503l.068-.069.05.05.05-.05-.05-.05.067-.069.052.05.049-.048-.05-.05.07-.072.114.115c.018.017.013.042-.002.059l-.242.244c-.018.016-.047.018-.062.004l-.114-.114z'
              fill='#fcd955'
            />
            <path
              d='M11.107 18.607c.008.01.03.009.042-.003l.244-.244c.011-.012.014-.03.001-.041l-.096-.116-.07.056.05.066-.066.05-.034-.052-.068.053.05.066-.066.05-.033-.049-.058.06.104.104zm.059.016c-.02.02-.056.024-.077.002l-.113-.112.008-.01-.008-.01.086-.067.032.049.05-.033-.051-.067.086-.069.033.05.049-.03-.05-.07.088-.069.112.113c.024.025.018.058-.001.077l-.244.246z'
              fill='#030404'
            />
            <path
              d='M11.388 18.381l.204.192-.231.231-.192-.203.22-.22z'
              fill='#fcd955'
            />
            <path
              d='M11.351 18.795l.231-.213-.194-.183-.21.194.173.202zm0 .019l-.19-.22.219-.221.008.008.008-.009.202.21-.247.232z'
              fill='#030404'
            />
            <path
              d='M10.39 19.1l.066-.067.047.05.052-.053-.05-.05.069-.067.048.05.05-.05-.05-.05.069-.07.114.115c.019.018.015.042 0 .058l-.244.244c-.014.016-.044.02-.06.003l-.112-.113z'
              fill='#fcd955'
            />
            <path
              d='M10.51 19.205c.008.009.03.007.042-.004l.243-.245a.029.029 0 00.002-.04l-.097-.115-.07.053.05.069-.067.049-.032-.05-.067.05.05.068-.069.052-.033-.05-.057.058.104.105zm.06.014c-.021.02-.056.025-.078.004l-.114-.114.011-.01-.01-.007.085-.068.03.05.053-.034-.052-.068.087-.068.031.05.05-.032-.051-.068.088-.069.114.115c.022.023.018.056 0 .075l-.245.244z'
              fill='#030404'
            />
            <path
              d='M10.79 18.98l.205.19-.232.233-.19-.204.217-.219z'
              fill='#fcd955'
            />
            <path
              d='M10.782 18.99v-.002.001zm-.03.403l.234-.213-.196-.183-.21.193.172.203zm0 .018l-.189-.222.218-.218.01.01.007-.01.205.208-.25.232zM11.34 18.601c-.037-.034.013-.084.048-.05l.053.054-.048.05-.053-.054z'
              fill='#030404'
            />
            <path
              d='M10.742 19.199c-.037-.035.014-.086.048-.05l.054.054-.05.049-.052-.053z'
              fill='#030404'
            />
            <path
              d='M15.805 19.039l.926.927.316-.4-.846-.845-.396.318z'
              fill='#fcd955'
            />
            <path
              d='M16.722 19.957l.317-.383-.831-.844-.385.31.899.917zm-.925-.908l.008-.009-.007-.01.412-.317.846.86-.334.401-.925-.925z'
              fill='#030404'
            />
            <path
              d='M16.59 19.171c.116.117.045.42-.094.559l-.457-.458c.147-.145.439-.214.551-.1z'
              fill='#fcd955'
            />
            <path
              d='M16.03 19.264c.15-.15.446-.222.569-.101l-.017.016c-.103-.103-.392-.04-.536.085l.442.458c.134-.136.202-.437.094-.543l.018-.019c.122.13.046.433-.112.579l-.458-.475z'
              fill='#030404'
            />
            <path
              d='M16.514 19.251s.07.199-.115.382l-.26-.26c.183-.185.375-.122.375-.122z'
              fill='#fcd955'
            />
            <path
              d='M16.148 19.365c.125.125.127.126.242.26a.374.374 0 00.114-.364.387.387 0 00-.356.104zm-.018 0c.188-.191.387-.13.388-.127l-.004.013.013-.005s.071.206-.137.395c-.134-.134-.132-.132-.26-.276z'
              fill='#030404'
            />
            <path
              d='M16.327 18.531l.913.913a.052.052 0 01-.002.074c-.023.022-.057.022-.075.005l-.913-.914c-.02-.02-.018-.052.003-.075.024-.023.054-.023.074-.003z'
              fill='#fcd955'
            />
            <path
              d='M16.24 18.618c-.025-.025-.02-.067.005-.093.028-.028.066-.03.091-.004l-.01.01-.008.007c-.015-.014-.035-.014-.057.004-.016.02-.017.044-.003.058l.912.914c.014.014.04.014.058-.004.019-.02.018-.044.003-.058l-.913-.914.009-.008.009-.01.912.916c.025.023.026.063-.002.091-.027.025-.067.028-.093.005l-.914-.914z'
              fill='#030404'
            />
            <path
              d='M15.787 19.022l.961.962c.021.02.018.056-.004.078-.025.026-.059.027-.08.006l-.961-.96c-.021-.023-.02-.056.004-.081.024-.022.058-.025.08-.005z'
              fill='#fcd955'
            />
            <path
              d='M15.694 19.114c-.025-.025-.024-.068.005-.097.027-.027.07-.03.096-.004l-.009.01-.008.009c-.014-.015-.042-.015-.063.003-.019.02-.019.046-.003.062l.961.962c.015.013.04.015.062-.006.018-.02.018-.046.004-.059l-.961-.962.008-.01.01-.009.96.962c.026.025.023.069-.004.096-.028.028-.071.03-.096.006l-.962-.963z'
              fill='#030404'
            />
            <path
              d='M16.269 18.627l.873.877c.019.019.015.048-.006.07-.022.023-.053.026-.071.007l-.875-.874c-.018-.018-.016-.05.006-.073.022-.021.055-.023.073-.007z'
              fill='#fcd955'
            />
            <path
              d='M16.18 18.715c-.023-.023-.019-.063.007-.09.025-.025.066-.029.09-.005l-.018.018c-.01-.012-.037-.013-.053.005-.02.018-.02.043-.008.055l.876.873c.01.013.035.013.055-.007.015-.018.017-.04.005-.053l-.875-.873.018-.018.874.874c.025.025.018.064-.008.089-.023.025-.062.031-.087.006l-.875-.874z'
              fill='#030404'
            />
            <path
              d='M17.268 18.726l-.357.387-.241-.245.39-.353.208.21z'
              fill='#fcd955'
            />
            <path
              d='M16.678 18.86l.225.244.348-.378-.183-.203-.39.337zm-.017-.002l.408-.353.208.211-.01.008.01.009-.375.389-.241-.264z'
              fill='#030404'
            />
            <path
              d='M17.154 18.325l.067.067-.05.05.05.048.05-.048.068.069-.05.049.05.05.05-.049.07.07-.113.113a.043.043 0 01-.06-.001l-.242-.244c-.016-.015-.016-.045-.002-.06l.112-.114z'
              fill='#fcd955'
            />
            <path
              d='M17.05 18.446c-.008.01-.008.035.002.044l.241.245c.013.009.03.012.044 0l.114-.096-.054-.07-.066.05-.05-.068.049-.032-.051-.068-.067.05-.049-.069.05-.031-.059-.059-.104.104zm-.015.06a.057.057 0 01-.002-.077l.111-.114.009.008.01-.008.065.086-.047.031.031.05.067-.048.068.085-.049.032.032.05.068-.049.07.087-.113.113a.057.057 0 01-.078-.001l-.242-.244zM16.983 18.75c.033-.035.085.014.05.05l-.087.085-.05-.049.087-.085z'
              fill='#030404'
            />
            <path
              d='M17.271 18.797l.07.07-.05.049.05.048.05-.05.07.07-.051.05.049.049.05-.05.07.071-.114.114c-.018.017-.044.013-.058-.003l-.244-.243c-.017-.016-.02-.045-.004-.062l.112-.113z'
              fill='#fcd955'
            />
            <path
              d='M17.167 18.919c-.008.01-.008.031.003.042l.244.245c.013.012.03.016.043.003l.115-.098-.055-.07-.067.05-.05-.067.051-.033-.052-.068-.068.05-.048-.067.048-.032-.06-.059-.104.104zm-.013.06c-.02-.02-.024-.056-.004-.078l.113-.112.008.008.008-.008.07.085-.049.032.03.05.07-.052.068.087-.05.033.03.05.07-.052.07.09-.115.114c-.023.025-.057.016-.076-.002l-.243-.244z'
              fill='#030404'
            />
            <path
              d='M17.393 19.203l-.191.203-.23-.232.201-.19.22.219z'
              fill='#fcd955'
            />
            <path
              d='M16.98 19.164l.214.234.183-.195-.195-.212-.201.173zm-.017 0l.22-.19.219.22-.01.009.01.007-.208.205-.231-.25z'
              fill='#030404'
            />
            <path
              d='M16.676 18.2l.066.068-.049.05.051.05.05-.05.068.069-.05.05.05.05.05-.051.07.07-.115.114c-.017.018-.042.014-.058 0l-.245-.245c-.014-.017-.017-.046-.003-.061l.115-.114z'
              fill='#fcd955'
            />
            <path
              d='M16.569 18.322c-.008.008-.006.032.005.043l.245.244c.012.013.026.014.04.002l.115-.097-.053-.07-.068.05-.05-.066.05-.032-.05-.07-.067.051-.052-.068.049-.034-.057-.058-.107.105zm-.013.061c-.02-.021-.025-.056-.003-.078l.113-.113.01.008.008-.008.067.083-.049.031.034.053.067-.05.067.087-.048.031.031.05.068-.05.07.087-.115.114a.05.05 0 01-.074-.001l-.246-.244z'
              fill='#030404'
            />
            <path
              d='M16.796 18.604l-.19.204-.233-.232.204-.19.219.218z'
              fill='#fcd955'
            />
            <path
              d='M16.381 18.568l.213.23.185-.194-.193-.21-.205.174zm-.016 0l.221-.19.22.218-.011.009.009.008-.208.203-.23-.248zM17.175 19.152c.033-.035.083.016.048.05l-.054.054-.048-.05.054-.054z'
              fill='#030404'
            />
            <path
              d='M16.577 18.556c.034-.037.085.015.049.048l-.054.053-.049-.048.054-.053z'
              fill='#030404'
            />
            <path
              d='M24.056 20.752l6.026 4.005c.387-.584.736-1.19 1.045-1.824l-3.284-2.181h-3.787zM21.565 11.182h2.356l6.121-4.07a15.955 15.955 0 00-1.282-1.664l-7.195 4.785v.949zM11.997 20.752H9.51l-6.03 4.012c.387.584.813 1.138 1.274 1.67l7.242-4.821v-.861zM9.613 11.182L3.512 7.126a15.754 15.754 0 00-1.05 1.823l3.364 2.233h3.787z'
              fill='#d1202d'
            />
          </g>
        </svg>
      </i>
    );
  }
}
