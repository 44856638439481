export default {
  app: {
    panorama: {
      loadingText: '正在加载图像。。。',
      autorotate: '自动旋转',
      zoom: '快速移动',
      zoomOut: '缩小',
      zoomIn: '放大',
      move: '移动',
      download: '下载',
      fullscreen: '全屏',
      menu: '菜单目录',
      twoFingers: '用两个手指导航',
      ctrlZoom: '使用ctrl+滚动缩放图像',
      loadError: '全景无法加载',
    },
  },
};
