export const defaultDateFormat = 'DD.MM.YYYY';
export const defaultDateTimeFormat = 'DD.MM.YYYY HH:mm:ss';
export const RFC3339Format = 'YYYY-MM-DDTHH:mm:ssZ';

export const defaultVCRFormat = 'MMMM D YYYY';
export const shortMonthDateFormat = 'MMM D YYYY';
export const chineseDateFormat = 'M月 D日 YYYY年';
export const koreanDateFormat = 'M월 D일 YYYY년';

export const weatherFormat = 'dddd';

export function pickDateFormat(locale: string, format?: string) {
  switch (locale) {
    case 'zh-CN':
      return chineseDateFormat;
    case 'ko-KR':
      return koreanDateFormat;
    default:
      return format || defaultVCRFormat;
  }
}
