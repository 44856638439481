export default {
  app: {
    winter: {
      snowReport: 'Schneebericht',
      snow: '{snow} cm of {type} snow',
      snowTypeTranslation: {
        1: '',
        2: 'Pulverschnee',
        3: 'Nasser Schnee',
        4: 'Eis',
        5: 'Firnschnee',
        6: 'Künstlicher Schnee',
        7: 'Feuchter Schnee',
      },
      lifts: 'Skilift',
      open: 'geöffnet',
      slopes: 'Skipisten',
      webcam: 'Webcam',
    },
  },
};
