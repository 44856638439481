export default {
  app: {
    gridList: {
      label: {
        incentive: '按激励筛选',
        itinerary: '按标签筛选',
        region: '按地区筛选',
      },
    },
  },
};
