export default {
  app: {
    panorama: {
      loadingText: 'Notiek attēla ielāde...',
      autorotate: 'Automātiska rotācija',
      zoom: 'Tālummaiņa',
      zoomOut: 'Attālināt',
      zoomIn: 'Pietuvināt',
      move: 'Stumt',
      download: 'Lejupielādēt',
      fullscreen: 'Pilnekrāna režīms',
      menu: 'Izvēlne',
      twoFingers: 'Lai pārlūkotu, pagrieziet attēlu ar diviem pirkstiem',
      ctrlZoom: 'Lai mainītu mērogu, izmantojiet CTRL + peles ritenīti',
      loadError: 'Neizdevās ielādēt panorāmu',
    },
  },
};
