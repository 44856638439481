export default {
  app: {
    weather: {
      title: 'Weerbericht',
      now: 'Vandaag',
      weatherTranslations: {
        clearsky_day: 'Heldere lucht',
        clearsky_night: 'Heldere lucht',
        clearsky_polartwilight: 'Heldere lucht',
        cloudy: 'Bewolkt',
        fair_day: 'Helder',
        fair_night: 'Helder',
        fair_polartwilight: 'Helder',
        fog: 'Mist',
        heavyrain: 'Zware regen',
        heavyrainandthunder: 'Zware regen en storm',
        heavyrainshowers_day: 'Zware regenbuien',
        heavyrainshowers_night: 'Zware regenbuien',
        heavyrainshowers_polartwilight: 'Zware regenbuien',
        heavyrainshowersandthunder_day: 'Zware regenbuien en onweer',
        heavyrainshowersandthunder_night: 'Zware regenbuien en onweer',
        heavyrainshowersandthunder_polartwilight: 'Zware regenbuien en onweer',
        heavysleet: 'Zware sneeuw',
        heavysleetandthunder: 'Zware ijzel en storm',
        heavysleetshowers_day: 'Zware sneeuwbuien',
        heavysleetshowers_night: 'Zware sneeuwbuien',
        heavysleetshowers_polartwilight: 'Zware sneeuwbuien',
        heavysleetshowersandthunder_day: 'Zware sneeuwbuien en storm',
        heavysleetshowersandthunder_night: 'Zware sneeuwbuien en storm',
        heavysleetshowersandthunder_polartwilight: 'Zware sneeuwbuien en storm',
        heavysnow: 'Zware sneeuwval',
        heavysnowandthunder: 'Zware sneeuwstorm',
        heavysnowshowers_day: 'Zware sneeuwbuien',
        heavysnowshowers_night: 'Zware sneeuwbuien',
        heavysnowshowers_polartwilight: 'Zware sneeuwbuien',
        heavysnowshowersandthunder_day: 'Zware sneeuwbuien en onweer',
        heavysnowshowersandthunder_night: 'Zware sneeuwbuien en onweer',
        heavysnowshowersandthunder_polartwilight: 'Zware sneeuwbuien en onweer',
        lightrain: 'Lichte regen',
        lightrainandthunder: 'Lichte regen en storm',
        lightrainshowers_day: 'Lichte regenbuien',
        lightrainshowers_night: 'Lichte regenbuien',
        lightrainshowers_polartwilight: 'Lichte regenbuien',
        lightrainshowersandthunder_day: 'Lichte regenbuien en onweer',
        lightrainshowersandthunder_night: 'Lichte regenbuien en onweer',
        lightrainshowersandthunder_polartwilight: 'Lichte regenbuien en onweer',
        lightsleet: 'Lichte ijzel',
        lightsleetandthunder: 'Lichte ijzel en storm',
        lightsleetshowers_day: 'Lichte ijzelbuien',
        lightsleetshowers_night: 'Lichte ijzelbuien',
        lightsleetshowers_polartwilight: 'Lichte ijzelbuien',
        lightssleetshowersandthunder_day: 'Lichte ijzelbuien en onweer',
        lightssleetshowersandthunder_night: 'Lichte ijzelbuien en onweer',
        lightssleetshowersandthunder_polartwilight:
          'Lichte ijzelbuien en onweer',
        lightsnow: 'Lichte sneeuw',
        lightsnowandthunder: 'Lichte sneeuw en storm',
        lightsnowshowers_day: 'Lichte sneeuwbuien',
        lightsnowshowers_night: 'Lichte sneeuwbuien',
        lightsnowshowers_polartwilight: 'Lichte sneeuwbuien',
        lightssnowshowersandthunder_day: 'Lichte sneeuwbuien en onweer',
        lightssnowshowersandthunder_night: 'Lichte sneeuwbuien en onweer',
        lightssnowshowersandthunder_polartwilight:
          'Lichte sneeuwbuien en onweer',
        rain: 'Regenen',
        rainandthunder: 'Regen en onweer',
        rainshowers_day: 'Regenbuien',
        rainshowers_night: 'Regenbuien',
        rainshowers_polartwilight: 'Regenbuien',
        rainshowersandthunder_day: 'Regenbuien en onweer',
        rainshowersandthunder_night: 'Regenbuien en onweer',
        rainshowersandthunder_polartwilight: 'Regenbuien en onweer',
        sleet: 'Natte sneeuw',
        sleetandthunder: 'Ijzel en storm',
        sleetshowers_day: 'Natte buien',
        sleetshowers_night: 'Natte buien',
        sleetshowers_polartwilight: 'Natte buien',
        sleetshowersandthunder_day: 'Sneeuwbuien en onweer',
        sleetshowersandthunder_night: 'Sneeuwbuien en onweer',
        sleetshowersandthunder_polartwilight: 'Sneeuwbuien en onweer',
        snow: 'Sneeuw',
        snowandthunder: 'Sneeuw en onweer',
        snowshowers_day: 'Sneeuwbui',
        snowshowers_night: 'Sneeuwbui',
        snowshowers_polartwilight: 'Sneeuwbui',
        snowshowersandthunder_day: 'Sneeuwbui en storm',
        snowshowersandthunder_night: 'Sneeuwbui en storm',
        snowshowersandthunder_polartwilight: 'Sneeuwbui en storm',
        partlycloudy_day: 'Half bewolkt',
        partlycloudy_night: 'Half bewolkt',
        partlycloudy_polartwilight: 'Half bewolkt',
      },
    },
  },
};
