import { CztWidgets } from '~/utils/views/widgets';
import { TabsWidgetInterface } from './types';

export default function(data: any): data is TabsWidgetInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.TABS &&
    typeof data.items !== 'undefined'
  );
}
