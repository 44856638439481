export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtrer efter incitament',
        itinerary: 'Filtrer efter tag',
        region: 'Filtrer efter region',
      },
    },
  },
};
