export default {
  app: {
    common: {
      homepageBreadcrumb: 'Sākums',
      language: 'Valoda',
      loadMore: 'Vairāk',
      moreInfo: 'Vairāk informācijas',
      translationVoting: 'Kā Jums patīk šis tulkojums?',
      translationVotingSuccess: 'Paldies par Jūsu atsauksmi!',
    },
  },
};
