import { RouteConfig } from 'vue-router';
import Login from '~/views/Login';

const config: RouteConfig = {
  component: Login,
  path: 'login',
  alias: 'Login',
};

export default config;
