export default {
  app: {
    cookies: {
      title: 'Gerbiame jūsų privatumą',
      text:
        'Naudojame slapukus, kad galėtume suasmeninti turinį, analizuoti srautą ir teikti visuomeninės medijos funkcijas. Svetainės naudojimo informaciją bendriname su visuomeninės medijos ir analizės partneriais, kurie gali ją pridėti prie kitos jūsų pateiktos arba naudojant paslaugas surinktos informacijos. Daugiau informacijos rasite {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'čia',
      },
      buttons: {
        accept: 'Priimti visus slapukus',
        save: 'Išsaugoti pasirinkimą',
      },
      settings: {
        button: 'Išsamūs nustatymai',
        required: 'Būtini (kad svetainė veiktų)',
        analytics:
          'Analitika (skirta suprasti, kaip lankytojai elgiasi svetainėje arba kaip jie juda svetainėje)',
        statistics: 'Statistiniai (leidžia analizuoti svetainės lankomumą)',
        marketing:
          'Reklaminiai (pagal lankytojų elgesį parenka reklaminį turinį)',
        reject: 'Pašalinti viską',
        accept: 'Priimti viską',
      },
    },
  },
};
