export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Startseite',
      destinations: 'Reiseziele',
      thingsToDo: 'Aktivitäten',
      new: 'Neu',
      covid: 'Covid-19',
      travelInfo: 'Reiseinfos',
    },
  },
};
