export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Homepage',
      destinations: 'Destinations',
      regions: 'Regions',
      cities: 'Cities',
      thingsToDo: 'Things to Do',
      alwaysVisit: 'Always Visit',
      culturalHeritage: 'Cultural Heritage',
      activeHoliday: 'Active Holiday',
      healthAndSpa: 'Health & Spa',
      new: 'New',
      covid: 'Covid-19',
      travelInfo: 'Travel Info',
    },
  },
};
