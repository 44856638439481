export default {
  app: {
    footer: {
      aboutUs: '有關我們',
      contactUs: '聯繫我們',
      pressAndMedia: '媒體聯繫',
      travelProfessionals: '企業合作',
      brochures: '旅遊手冊',
      news: '最新活動',
      covid19: '新冠病毒資訊',
      destinations: '旅行目的地',
      thingsToDo: '做什麼好？',
      travelInfo: '旅行資訊',
      czechTourism: 'CzechTourism',
      menu: '選單',
      mobileApp: '移動應用',
      visitCzechAndroid: 'VisitCzech 適用於Android的移動應用程序',
      followUs: '跟著我們',
      privacyPolicy: '隱私政策',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookies 設定',
    },
  },
};
