import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AutomatedTranslationVoting } from '~/app/core/apiClient/api';
import TranslationModule from '~/app/core/store/modules/TranslationModule';
import Icon from '~/components/atoms/icons/Icon';
import { VueComponent } from '~/utils/vue-component';

import style from './TranslationVoting.scss';

interface TranslationVotingProps {
  message: string;
  pageId: string;
  locale: string;
}

const rootClass = '.czt-translation-voting';

@Component({ style })
export default class TranslationVoting
  extends VueComponent<TranslationVotingProps>
  implements TranslationVotingProps {
  @Prop({ type: String, required: true })
  public message!: string;

  @Prop({ type: String, required: true })
  public pageId!: string;

  @Prop({ type: String, required: true })
  public locale!: string;

  protected success: boolean = false;

  protected get translationStore() {
    return getModule(TranslationModule, this.$store);
  }

  protected get showButtons() {
    return !this.translationStore.votedPageIds.includes(this.pageId);
  }

  public render() {
    return (
      <div class={rootClass}>
        <div class='czt-description'>{this.message}</div>
        <v-expand-transition>
          {this.showButtons && (
            <v-row align='center'>
              <v-col cols='auto'>
                <div class='czt-description'>
                  {this.$t('app.common.translationVoting')}
                </div>
              </v-col>
              <v-col cols='auto'>
                <v-btn
                  fab
                  small
                  color='primary'
                  onClick={() =>
                    this.handleVote(AutomatedTranslationVoting.StatusEnum.Like)
                  }
                  disabled={
                    this.translationStore.loading ||
                    this.translationStore.voting
                  }
                >
                  <Icon
                    size={1.5}
                    name={['common', 'ThumbUp']}
                    style={{ position: 'relative', top: '-3px' }}
                  />
                </v-btn>
              </v-col>
              <v-col cols='auto'>
                <v-btn
                  fab
                  small
                  color='secondary'
                  onClick={() =>
                    this.handleVote(
                      AutomatedTranslationVoting.StatusEnum.Dislike
                    )
                  }
                  disabled={
                    this.translationStore.loading ||
                    this.translationStore.voting
                  }
                >
                  <Icon
                    size={1.5}
                    name={['common', 'ThumbDown']}
                    style={{ position: 'relative', top: '3px' }}
                  />
                </v-btn>
              </v-col>
            </v-row>
          )}
        </v-expand-transition>
        <v-fade-transition>
          {this.success && (
            <div class='czt-description py-2'>
              {this.$t('app.common.translationVotingSuccess')}
            </div>
          )}
        </v-fade-transition>
      </div>
    );
  }

  protected handleVote(status: AutomatedTranslationVoting.StatusEnum) {
    this.translationStore
      .vote({
        documentCulture: this.locale,
        nodeGUID: this.pageId,
        status,
      })
      .then((response) => {
        this.success = response.success || false;
      });
  }
}
