import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class WinterSportsIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--winter-sports'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M127.9 29.5a12.3 12.3 0 1 0 5.7-23.8 12.3 12.3 0 0 0-5.7 23.8M147.5 136.2a9.8 9.8 0 0 1-13.6 2.9l-57.3-37.3 23.7-12.5a12.2 12.2 0 0 0 2.5-20.7l-35-23.1a12.2 12.2 0 0 0 3.4 16.9l24 15.8-30.4 16L2.5 53.6l-2.6 4 131.4 85.5a14.4 14.4 0 0 0 11 2c3.8-.8 7.1-3 9.3-6.3l-4.1-2.7ZM100 47.4l-.8 2.8-.2.4c-1 4.3.2 8.6 3 11.6l20 13.2 6.7-10.1-17.8-11.6 5.7-18 .7-2a12.3 12.3 0 0 0-17.8-13.6L66 38.3l23 15.2 11-6.1Z'
          />
        </svg>
      </i>
    );
  }
}
