import { Component, Prop } from 'vue-property-decorator';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { Banner, Breadcrumbs } from '~/components/organisms';
import { Button, Headline } from '~/components/atoms';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import TopLayerImage from '~/components/templates/common/TopLayerImage';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { ItineraryPage } from '~/utils/views';
import { Widget } from '~/utils/views/widgets';
import createSeoTags, { createJsonLd, TYPE } from '~/utils/views/createSeoTags';
import { getUrlFromPath } from '~/app/core/router';
import { sidebarColumns } from '~/utils/sidebarColumns';
import { Style } from '~/components/atoms/button/Button';
import TranslationVoting from '~/components/templates/common/TranslationVoting';
import Advertisement from '~/components/organisms/advertisement/Advertisement';
import TransportInfo from '~/components/templates/common/itinerary/TransportInfo';
import ItineraryPlan from '~/components/templates/common/itinerary/ItineraryPlan';
import Tag from '~/components/atoms/tag/Tag';
import { anchorPreposition, createAnchorId } from '~/utils/anchor';

interface ItineraryView {
  data: ItineraryPage;
}

@Component
export default class Itinerary
  extends VueComponentMixin<ItineraryView, {}>(HeadManagement)
  implements ItineraryView {
  @Prop({ required: true })
  public data!: ItineraryPage;

  protected get firstWidget(): Widget | null {
    const widgets = [...this.data.widgets.main];
    return this.data.firstWidgetAsHero && widgets.length > 0
      ? widgets.shift() || null
      : null;
  }

  protected get mainWidgets(): Widget[] {
    const widgets = [...this.data.widgets.main];
    if (this.data.firstWidgetAsHero && widgets.length > 0) {
      widgets.shift();
    }
    return widgets;
  }

  protected get title() {
    return this.data.metadata.title;
  }

  public headTags(): string {
    return (
      createSeoTags({
        title: this.data.metadata.title,
        description: this.data.metadata.description,
        canonicalUrl: getUrlFromPath(this.data.canonicalPath, this.$router),
        type: TYPE.ARTICLE,
        image: this.data.image,
        imagesToPreload: this.data.imagesToPreload,
      }) +
      createJsonLd({
        image: this.data.imagesToPreload,
        title: this.data.metadata.title,
        created: this.data.created,
        modified: this.data.modified,
      })
    );
  }

  public render() {
    // Destructure the array so we get a new clone, because we cannot modify the prop
    // TODO: JSON serialization Quickfix... referenced get screwed up when we touch the items inside
    const widgets: Widget[] = JSON.parse(JSON.stringify(this.mainWidgets));

    return (
      <v-container fluid class='pa-0' key={this.data.guid}>
        {this.renderHero(this.data.image)}

        {!this.data.hideBreadcrumbs && (
          <v-container class='czt-breadcrumbs__wrapper'>
            <v-row>
              <v-col cols={12} class='py-0'>
                <Breadcrumbs breadcrumbs={this.data.breadcrumbs} />
              </v-col>
            </v-row>
          </v-container>
        )}

        {this.renderContent()}
        {this.data.widgets.before.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.before} />
        )}
        {widgets.length > 0 && <WidgetRenderer widgets={widgets} />}
        {this.data.itineraryFolders &&
          this.data.itineraryFolders.length > 0 && (
            <ItineraryPlan data={this.data.itineraryFolders} />
          )}
        {this.data.widgets.after.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.after} />
        )}
      </v-container>
    );
  }

  protected renderContent() {
    if (this.data.content || this.data.perex) {
      return (
        <v-container id={`${anchorPreposition}content`}>
          <v-row justify='center'>
            <v-col cols={12} md={7} lg={8} class='py-0'>
              {(() => {
                const content: JSX.Element[] = [];
                if (this.data.perex) {
                  content.push(
                    <div class='czt-perex'>
                      <HtmlRenderer content={this.data.perex} />
                    </div>
                  );
                }
                if (this.data.pageTags && this.data.pageTags.length > 0) {
                  content.push(
                    <div class='mb-3'>
                      {this.data.pageTags.map((tag) => (
                        <Tag
                          name={tag.name}
                          key={tag.id}
                          link={`~/itineraries?taxonomy=${tag.id}`}
                        />
                      ))}
                    </div>
                  );
                }
                if (this.data.content) {
                  content.push(
                    <div class='czt-richtext'>
                      <HtmlRenderer content={this.data.content} />
                    </div>
                  );
                }
                if (this.data.showAutomatedTranslationVoting) {
                  content.push(
                    <TranslationVoting
                      locale={this.$i18n.locale}
                      message={this.data.automatedTranslationMessage}
                      pageId={this.data.guid}
                    />
                  );
                }
                return content;
              })()}
            </v-col>
            {this.renderSideContent()}
          </v-row>
        </v-container>
      );
    }
  }

  protected renderSideContent() {
    return (
      <v-col {...{ attrs: sidebarColumns }} class='py-0 px-0 px-md-3'>
        <div class='czt-side-content'>
          <v-container>
            <v-row>
              <v-col cols={12} class='py-0'>
                <TransportInfo
                  carDistance={this.data.carDistance}
                  carTime={this.data.carTime}
                  publicTransportTime={this.data.publicTransportTime}
                />
              </v-col>
            </v-row>
          </v-container>
          {this.data.showRightColumn &&
            Array.isArray(this.data.widgets.right) &&
            this.data.widgets.right.length > 0 && (
              <WidgetRenderer widgets={this.data.widgets.right} side />
            )}
          {this.data.banners.length > 0 && (
            <v-container>
              <v-row>
                <v-col col={12} class='py-0'>
                  {this.data.banners.map((banner) => (
                    <Advertisement data={banner} />
                  ))}
                </v-col>
              </v-row>
            </v-container>
          )}
        </div>
      </v-col>
    );
  }

  protected renderHero(image?: string): JSX.Element | undefined {
    let events = {};
    if (!this.data.anchorTitle) {
      events = {
        on: {
          click: this.anchorClick,
        },
      };
    }
    return this.firstWidget ? (
      <WidgetRenderer widgets={[this.firstWidget]} isFirst />
    ) : (
      <Banner
        backgroundImage={{
          src: image ? image : imageHeroDefault,
          alt: this.data.title + '',
        }}
        isFirst
        imageFilter={this.data.imageFilter}
        imageSource={this.data.imageSource}
        clickable={!this.data.anchorTitle}
        showArrow={!this.data.anchorTitle}
        {...events}
        title={this.data.title + ''}
        titleLevel={1}
        titlePosition={`${this.data.titlePosition}`}
      >
        {(() => {
          if (this.data.subtitle) {
            return (
              <Headline level={3} light>
                <HtmlRenderer content={this.data.subtitle} />
              </Headline>
            );
          }
        })()}
        {(() => {
          if (this.data.anchorTitle) {
            return (
              <Button
                class='my-3'
                buttonStyle={Style.PRIMARY_LIGHT}
                onClick={this.anchorClick}
              >
                {this.data.anchorTitle}
              </Button>
            );
          }
        })()}
        <TopLayerImage pageData={this.data} />
      </Banner>
    );
  }

  protected anchorClick() {
    const anchorId = this.data.anchorCustom || this.data.anchor || 'content';
    const anchor = document.querySelector(
      createAnchorId(anchorId, !this.data.anchorCustom)
    );
    if (anchor && anchor instanceof HTMLElement) {
      this.$vuetify.goTo(anchor);
    } else {
      this.$vuetify.goTo(createAnchorId('content'));
    }
  }
}
