export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Zgadzam się {0}',
          link: 'przetwarzanie danych osobowych.',
          error: 'Musisz wyrazić zgodę na przetwarzanie danych osobowych.',
        },
      },
      send: 'Wysłać',
      sent: 'Formularz został pomyślnie wysłany.',
    },
  },
};
