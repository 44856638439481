export default {
  app: {
    newsletter: {
      bottomText:
        'Al suscribirte a la newsletter aceptas nuestra <a href="~/Privacy-Policy" target="_blank">política de protección de datos</a>.',
      button: 'Suscríbete',
      ctaDialogParagraph1:
        '¿Quieres conocer las últimas novedades turísticas en Chequia?',
      ctaDialogParagraph2:
        '¿Quieres tenerlo todo bien organizado para no perderte lo más interesante?',
      ctaDialogParagraph3:
        '¡Inscríbete en nuestro newsletter y recibe todas las sugerencias para tu viaje!',
      ctaDialogTitle: '¡Toc, toc!',
      ctaDialogBtn: 'Haz click aquí',
      dialog: {
        headline: 'Elige tu newsletter',
        notice:
          'La dirección de correo electrónico antes indicada se utilizará solo para remitir nuestro boletín y no será transferida a otro sujeto. Su dirección de correo electrónico quedará registrada con este fin hasta el día en que Ud. cancele la remisión del boletín. Puede cancelar la remisión en cualquier momento, haciendo clic en el enlace incluido en el mensaje del boletín. Su dirección de correo electrónico podrá ser procesada por la empresa IT que gestiona la operación de nuestros sitios web y aplicaciones web. {0}',
        company:
          '{0} (Agencia Checa de Promoción del Turismo), {1} es administrador de los datos personales.',
        link: {
          privacy: 'Nuestra Política de Privacidad véase aquí.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Tu dirección de correo electrónico',
      newsletterError:
        'Parece que no hay boletines informativos a los que suscribirse en este momento.',
      serverError:
        'Se ha producido un error con tu petición. Por favor, prueba más tarde.',
      success:
        'Para la inscripción exitosa a newsletter, por favor, confirmar su registración en el email recibido.',
      title: 'Recibe nuestra newsletter',
      topText:
        'Inscríbete para recibir novedades, recomendaciones, próximos eventos y nuevas historias.',
      validityError: 'Tu dirección de correo electrónico no es válida',
    },
  },
};
