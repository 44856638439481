import { CztWidgets } from '~/utils/views/widgets';
import { CarouselInterface } from './types';

export default function(data: any): data is CarouselInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.CAROUSEL &&
    typeof data.title === 'string'
  );
}
