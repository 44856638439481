export default {
  app: {
    tradeOffers: {
      country: 'Land',
      loginPrompt: 'For å se private handelstilbud må du {0}.',
      logIn: 'logg inn',
      info: 'Information',
      address: 'Address',
      email: 'E-mail',
      social: 'Social media',
      phone: 'Phone',
      web: 'Web',
    },
  },
};
