import { Component, Prop, Ref, Watch } from 'vue-property-decorator';

import style from './SearchForm.scss';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';
import { Button, Icons } from '~/components/atoms';
import { getLocaleFromRouter, getPathWithoutLocale } from '~/app/core/router';
import searchRoute from '~/app/core/router/routes/search';
import { InputFieldState } from '~/components/atoms/inputField/InputField';
import { SearchFormInterface } from './types';

const rootClass = 'czt-search-form';

@Component({
  style,
})
export default class SearchForm extends VueComponent<SearchFormInterface>
  implements SearchFormInterface {
  @Prop({ required: true })
  public className!: CztWidgets;

  @Prop({ type: String })
  public term?: string;

  @Ref('submitButton')
  private submitButton!: Vue;

  protected searchTerm: string = '';

  protected valid: boolean = true;

  protected loading: boolean = false;

  protected get state() {
    if (this.valid) {
      return InputFieldState.DEFAULT;
    }

    return InputFieldState.ERROR;
  }

  public render() {
    return (
      <v-form onSubmit={this.submitForm}>
        <v-row justify='center' no-gutters>
          <v-col cols='12' sm='7' md='8'>
            <v-text-field
              class={`${rootClass}__input`}
              placeholder={this.$t('app.search.title')}
              v-model={this.searchTerm}
              solo
              clearable
              onInput={() => (this.valid = true)}
              autofocus
              error={this.state === InputFieldState.ERROR}
            >
              <Icons.common.Search
                size={2}
                slot='prepend-inner'
                onClick={() => {
                  if (this.submitButton) {
                    (this.submitButton.$el as HTMLElement).click();
                  }
                }}
              />
            </v-text-field>
          </v-col>
          <v-col cols='12' sm='5' md='4' class={`${rootClass}__button-wrapper`}>
            <Button
              ref='submitButton'
              class={`${rootClass}__button`}
              disabled={!this.valid}
              loading={this.loading}
              submit
            >
              {this.$t('app.search.button')}
            </Button>
          </v-col>
        </v-row>
      </v-form>
    );
  }

  protected submitForm(e: Event) {
    e.preventDefault();

    if (this.searchTerm.trim() === '') {
      this.valid = false;
      return;
    }

    this.loading = true;

    const locale = getLocaleFromRouter(this.$router).toLowerCase();
    this.$route.meta.scrollY = window.scrollY || window.pageYOffset;

    this.$router
      .push({
        path: `/${locale}/${searchRoute.path.replace(
          ':term',
          this.searchTerm
        )}`,
        query: {
          path: this.$route.query.path || getPathWithoutLocale(this.$router),
        },
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Watch('term', { immediate: true })
  protected setTerm() {
    if (this.term) {
      this.searchTerm = this.term;
    }
  }
}
