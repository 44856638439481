import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class AccomodationIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--accomodation'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M149.1 76.4h-138V43.5H1.9v63.3h9.2V85.5H140v21.6h9.1V76.4Z'
          />
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M16.7 43.5A27.2 27.2 0 0 1 44 70.7H16.7V43.5ZM135 51.4H63.8a14 14 0 0 0-14 14v5.3H149v-5.2a14 14 0 0 0-14-14'
          />
        </svg>
      </i>
    );
  }
}
