import { VueComponent } from '~/utils/vue-component';
import { Component } from 'vue-property-decorator';
import { mdiChevronUp } from '~/utils/iconPaths';

@Component
export default class ScrollToTop extends VueComponent<{}> {
  public render() {
    return (
      <v-btn
        fab
        dark
        small
        fixed
        bottom
        right
        color='secondary'
        class='d-none d-sm-flex'
        aria-label='Scroll to Top'
        onClick={this.scrollUp}
      >
        <v-icon>{mdiChevronUp}</v-icon>
      </v-btn>
    );
  }

  protected scrollUp(): void {
    this.$vuetify.goTo(0);
  }
}
