import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class ChFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--china'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g fill-rule='nonzero'>
            <path
              d='M32.085 16.199c0 8.836-7.163 16-15.998 16C7.25 32.2.085 25.035.085 16.2c0-8.837 7.164-16 16.002-16 8.835 0 15.998 7.163 15.998 16z'
              fill='#de2c26'
            />
            <path
              d='M7.223 6.52l1.033 3.188h3.354L8.9 11.681l1.032 3.19L7.223 12.9l-2.715 1.97 1.036-3.19-2.713-1.972h3.355L7.223 6.52zM15.961 4.855l-.07 1.102 1.023.409-1.07.27-.07 1.103-.59-.932-1.07.273.705-.851-.588-.931 1.025.407.705-.85zM19.727 8.428l-.504.983.778.781-1.088-.175-.505.983-.168-1.09-1.09-.175.982-.498-.167-1.09.778.78.984-.5zM18.595 12.901l.336 1.05 1.106.002-.894.646.341 1.052-.892-.65-.894.648.342-1.05-.89-.648 1.102.002.343-1.052zM15.953 16.27l-.072 1.1 1.027.41-1.072.27-.072 1.103-.59-.932-1.068.272.703-.85-.586-.932 1.025.409.705-.85z'
              fill='#fcde04'
            />
          </g>
        </svg>
      </i>
    );
  }
}
