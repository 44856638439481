import { Action, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';

export interface UserData {
  username: string;
  userfullname: string;
  useremail: string;
  userguid: string;
  userid: string;
  userroles: string[];
  iat: number;
  nbf: number;
  exp: number;
}

function isUserData(data: any): data is UserData {
  return (
    data &&
    typeof data.username === 'string' &&
    typeof data.userfullname === 'string' &&
    typeof data.useremail === 'string' &&
    typeof data.userguid === 'string' &&
    typeof data.userid === 'number' &&
    typeof data.iat === 'number' &&
    typeof data.nbf === 'number' &&
    typeof data.exp === 'number'
  );
}

function getUserData(token: string): UserData | null {
  const tokenData = token.split('.');
  const userData =
    tokenData.length === 3
      ? JSON.parse(Buffer.from(tokenData[1], 'base64').toString('utf8'))
      : null;
  return isUserData(userData) ? userData : null;
}

export const tokenKey = 'czt_user_token';

@Module({
  name: 'UserModule',
  stateFactory: true,
  namespaced: true,
})
export default class UserModule extends AbstractModule {
  public user: UserData | null = null;
  public token: string = '';
  public loading: boolean = true;

  @Action({ rawError: true })
  public getUser({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<boolean> {
    if (this.user && this.user.username === username && this.token !== '') {
      return Promise.resolve(true);
    }
    this.setLoading(true);
    return this.$api
      .auth()
      .authLogin({
        password,
        username,
      })
      .then((response) => {
        let user: UserData | null = null;
        let token = response.token;
        if (token) {
          user = getUserData(token);
          if (document && user) {
            document.cookie = `${tokenKey}=${token}; path=/; expires=${new Date(
              user.exp * 1000
            ).toUTCString()}`;
          }
        }
        token = user ? token : '';
        this.setToken(token || '');
        this.setUser(user);
        return (user && token && response.success) || false;
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public loadUser(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const user = getUserData(token);
      token = user ? token : '';
      this.setToken(token);
      this.setUser(user);
      resolve(!!(token && user));
    });
  }

  @Action
  public logOut() {
    if (document) {
      document.cookie = `${tokenKey}=;path=/; expires=${new Date().toUTCString()}`;
    }
    this.setUser(null);
    this.setToken('');
  }

  @Mutation
  public setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  protected setToken(token: string) {
    this.token = token;
  }

  @Mutation
  protected setUser(user: UserData | null) {
    this.user = user;
  }
}
