import { CztWidgets } from '~/utils/views/widgets';
import { RepresentationMapInterface } from './types';

export function isRepresentationMap(
  data: any
): data is RepresentationMapInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.REPRESENTATION_MAP &&
    typeof data.guid === 'string'
  );
}
