export default {
  app: {
    weather: {
      title: 'Météo',
      now: "Aujourd'hui",
      weatherTranslations: {
        clearsky_day: 'Ciel clair',
        clearsky_night: 'Ciel clair',
        clearsky_polartwilight: 'Ciel clair',
        cloudy: 'Nuageux',
        fair_day: 'Soleil',
        fair_night: 'Soleil',
        fair_polartwilight: 'Soleil',
        fog: 'Brouillard',
        heavyrain: 'Forte pluie',
        heavyrainandthunder: 'Forte pluie et tempête',
        heavyrainshowers_day: 'Fortes averses de pluie',
        heavyrainshowers_night: 'Fortes averses de pluie',
        heavyrainshowers_polartwilight: 'Fortes averses de pluie',
        heavyrainshowersandthunder_day: 'Fortes averses de pluie et tonnerre',
        heavyrainshowersandthunder_night: 'Fortes averses de pluie et tonnerre',
        heavyrainshowersandthunder_polartwilight:
          'Fortes averses de pluie et tonnerre',
        heavysleet: 'Pluie et neige',
        heavysleetandthunder: 'Pluie, neige et tempête',
        heavysleetshowers_day: 'Fortes averses de neige',
        heavysleetshowers_night: 'Fortes averses de neige',
        heavysleetshowers_polartwilight: 'Fortes averses de neige',
        heavysleetshowersandthunder_day: 'Fortes averses de neige et tempête',
        heavysleetshowersandthunder_night: 'Fortes averses de neige et tempête',
        heavysleetshowersandthunder_polartwilight:
          'Fortes averses de neige et tempête',
        heavysnow: 'Neige abondante',
        heavysnowandthunder: 'Forte tempête de neige',
        heavysnowshowers_day: 'Fortes averses de neige',
        heavysnowshowers_night: 'Fortes averses de neige',
        heavysnowshowers_polartwilight: 'Fortes averses de neige',
        heavysnowshowersandthunder_day: 'Fortes averses de neige et tonnerre',
        heavysnowshowersandthunder_night: 'Fortes averses de neige et tonnerre',
        heavysnowshowersandthunder_polartwilight:
          'Fortes averses de neige et tonnerre',
        lightrain: 'Pluie légère',
        lightrainandthunder: 'Pluie légère et tonnerre',
        lightrainshowers_day: 'Légères averses de pluie',
        lightrainshowers_night: 'Légères averses de pluie',
        lightrainshowers_polartwilight: 'Légères averses de pluie',
        lightrainshowersandthunder_day: 'Légères averses de pluie et tonnerre',
        lightrainshowersandthunder_night:
          'Légères averses de pluie et tonnerre',
        lightrainshowersandthunder_polartwilight:
          'Légères averses de pluie et tonnerre',
        lightsleet: 'Légère pluie et neige',
        lightsleetandthunder: 'Légère pluie, neige et tempête',
        lightsleetshowers_day: 'Légères averses de pluie et neige',
        lightsleetshowers_night: 'Légères averses de pluie et neige',
        lightsleetshowers_polartwilight: 'Légères averses de pluie et neige',
        lightssleetshowersandthunder_day: 'Légères averses de grésil et orage',
        lightssleetshowersandthunder_night:
          'Légères averses de grésil et orage',
        lightssleetshowersandthunder_polartwilight:
          'Légères averses de grésil et orage',
        lightsnow: 'Neige légère',
        lightsnowandthunder: 'Neige légère et tempête',
        lightsnowshowers_day: 'Légères averses de neige',
        lightsnowshowers_night: 'Légères averses de neige',
        lightsnowshowers_polartwilight: 'Légères averses de neige',
        lightssnowshowersandthunder_day: 'Légères averses de neige et tempête',
        lightssnowshowersandthunder_night:
          'Légères averses de neige et tempête',
        lightssnowshowersandthunder_polartwilight:
          'Légères averses de neige et tempête',
        rain: 'Pluie',
        rainandthunder: 'Pluie et tempête',
        rainshowers_day: 'Averses de pluie',
        rainshowers_night: 'Averses de pluie',
        rainshowers_polartwilight: 'Averses de pluie',
        rainshowersandthunder_day: 'Averses et tempêtes',
        rainshowersandthunder_night: 'Averses et tempêtes',
        rainshowersandthunder_polartwilight: 'Averses et tempêtes',
        sleet: 'Pluie et neige',
        sleetandthunder: 'Pluie, neige et tempête',
        sleetshowers_day: 'Averses de pluie et neige',
        sleetshowers_night: 'Averses de pluie et neige',
        sleetshowers_polartwilight: 'Averses de pluie et neige',
        sleetshowersandthunder_day: 'Averses de grésil et tempête',
        sleetshowersandthunder_night: 'Averses de grésil et tempête',
        sleetshowersandthunder_polartwilight: 'Averses de grésil et tempête',
        snow: 'Neige',
        snowandthunder: 'Neige et tempête',
        snowshowers_day: 'Averses de neige',
        snowshowers_night: 'Averses de neige',
        snowshowers_polartwilight: 'Averses de neige',
        snowshowersandthunder_day: 'Averses de neige et tempête',
        snowshowersandthunder_night: 'Averses de neige et tempête',
        snowshowersandthunder_polartwilight: 'Averses de neige et tempête',
        partlycloudy_day: 'Partiellement nuageux',
        partlycloudy_night: 'Partiellement nuageux',
        partlycloudy_polartwilight: 'Partiellement nuageux',
      },
    },
  },
};
