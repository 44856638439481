export default {
  app: {
    footer: {
      aboutUs: 'Tietoa meistä',
      contactUs: 'Yhteystiedot',
      pressAndMedia: 'Lehdistö ja media',
      travelProfessionals: 'Matkailun ammattilaiset',
      brochures: 'Matkaoppaat',
      news: 'Uutta',
      covid19: 'Covid-19',
      destinations: 'Matkakohteet',
      thingsToDo: 'Aktiviteetit',
      travelInfo: 'Matkatiedot',
      czechTourism: 'CzechTourism',
      menu: 'Valikko',
      mobileApp: 'Mobiilisovellus',
      visitCzechAndroid: 'VisitCzech Mobile Application for Android',
      followUs: 'Seuraa meitä',
      privacyPolicy: 'Tietosuojakäytäntö',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Evästeet ja analyysi',
      czechConventionBureau: 'Tšekin kongressivirasto',
      cookieSettings: 'Evästeasetukset',
    },
  },
};
