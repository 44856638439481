import { Component, Prop } from 'vue-property-decorator';
import StatusCode from '~/utils/http/StatusCode';
import { VueComponent } from '~/utils/vue-component';

interface RedirectionInterface {
  location: string;
  statusCode: number;
}

@Component
export default class Redirect extends VueComponent<RedirectionInterface>
  implements RedirectionInterface {
  @Prop({ required: true, type: String })
  public location!: string;

  @Prop({ required: true, type: Number })
  public statusCode!: number;

  public render() {
    return (
      <div>
        <StatusCode code={this.statusCode} />
        <abbr id='ssr-redirect-location' data-location={this.location} />
      </div>
    );
  }
}
