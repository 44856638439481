export default {
  app: {
    weather: {
      title: 'Wetterbericht',
      now: 'Heute',
      weatherTranslations: {
        clearsky_day: 'Klarer Himmel',
        clearsky_night: 'Klarer Himmel',
        clearsky_polartwilight: 'Klarer Himmel',
        cloudy: 'Bewölkt',
        fair_day: 'Heiter',
        fair_night: 'Heiter',
        fair_polartwilight: 'Heiter',
        fog: 'Nebel',
        heavyrain: 'Starker Regen',
        heavyrainandthunder: 'Starker Regen und Sturm',
        heavyrainshowers_day: 'Starke Regenschauer',
        heavyrainshowers_night: 'Starke Regenschauer',
        heavyrainshowers_polartwilight: 'Starke Regenschauer',
        heavyrainshowersandthunder_day: 'Starke Regenschauer und Sturm',
        heavyrainshowersandthunder_night: 'Starke Regenschauer und Sturm',
        heavyrainshowersandthunder_polartwilight:
          'Starke Regenschauer und Sturm',
        heavysleet: 'Starker Schneeregen',
        heavysleetandthunder: 'Starker Schneeregen und Sturm',
        heavysleetshowers_day: 'Starke Schneeregenschauer',
        heavysleetshowers_night: 'Starke Schneeregenschauer',
        heavysleetshowers_polartwilight: 'Starke Schneeregenschauer',
        heavysleetshowersandthunder_day: 'Starke Schneeregenschauer und Sturm',
        heavysleetshowersandthunder_night:
          'Starke Schneeregenschauer und Sturm',
        heavysleetshowersandthunder_polartwilight:
          'Starke Schneeregenschauer und Sturm',
        heavysnow: 'Heftiges Schneien',
        heavysnowandthunder: 'Heftiger Schneesturm',
        heavysnowshowers_day: 'Heftige Schneeschauer',
        heavysnowshowers_night: 'Heftige Schneeschauer',
        heavysnowshowers_polartwilight: 'Heftige Schneeschauer',
        heavysnowshowersandthunder_day: 'Heftige Schneeschauer und Sturm',
        heavysnowshowersandthunder_night: 'Heftige Schneeschauer und Sturm',
        heavysnowshowersandthunder_polartwilight:
          'Heftige Schneeschauer und Sturm',
        lightrain: 'Leichter Regen',
        lightrainandthunder: 'Leichter Regen und Sturm',
        lightrainshowers_day: 'Leichte Regenschauer',
        lightrainshowers_night: 'Leichte Regenschauer',
        lightrainshowers_polartwilight: 'Leichte Regenschauer',
        lightrainshowersandthunder_day: 'Leichte Regenschauer und Sturm',
        lightrainshowersandthunder_night: 'Leichte Regenschauer und Sturm',
        lightrainshowersandthunder_polartwilight:
          'Leichte Regenschauer und Sturm',
        lightsleet: 'Leichter Schneeregen',
        lightsleetandthunder: 'Leichter Schneeregen und Sturm',
        lightsleetshowers_day: 'Leichte Schneeregenschauer',
        lightsleetshowers_night: 'Leichte Schneeregenschauer',
        lightsleetshowers_polartwilight: 'Leichte Schneeregenschauer',
        lightssleetshowersandthunder_day:
          'Leichte Schneeregenschauer und Sturm',
        lightssleetshowersandthunder_night:
          'Leichte Schneeregenschauer und Sturm',
        lightssleetshowersandthunder_polartwilight:
          'Leichte Schneeregenschauer und Sturm',
        lightsnow: 'Leichter Schnee',
        lightsnowandthunder: 'Leichter Schnee und Sturm',
        lightsnowshowers_day: 'Leichte Schneeschauer',
        lightsnowshowers_night: 'Leichte Schneeschauer',
        lightsnowshowers_polartwilight: 'Leichte Schneeschauer',
        lightssnowshowersandthunder_day: 'Leichte Schneeschauer und Sturm',
        lightssnowshowersandthunder_night: 'Leichte Schneeschauer und Sturm',
        lightssnowshowersandthunder_polartwilight:
          'Leichte Schneeschauer und Sturm',
        rain: 'Regen',
        rainandthunder: 'Regen und Sturm',
        rainshowers_day: 'Regenschauer',
        rainshowers_night: 'Regenschauer',
        rainshowers_polartwilight: 'Regenschauer',
        rainshowersandthunder_day: 'Regenschauer und Sturm',
        rainshowersandthunder_night: 'Regenschauer und Sturm',
        rainshowersandthunder_polartwilight: 'Regenschauer und Sturm',
        sleet: 'Schneeregen',
        sleetandthunder: 'Schneeregen und Sturm',
        sleetshowers_day: 'Schneeregenschauer',
        sleetshowers_night: 'Schneeregenschauer',
        sleetshowers_polartwilight: 'Schneeregenschauer',
        sleetshowersandthunder_day: 'Schneeregenschauer und Sturm',
        sleetshowersandthunder_night: 'Schneeregenschauer und Sturm',
        sleetshowersandthunder_polartwilight: 'Schneeregenschauer und Sturm',
        snow: 'Schnee',
        snowandthunder: 'Schnee und Sturm',
        snowshowers_day: 'Schneeschauer',
        snowshowers_night: 'Schneeschauer',
        snowshowers_polartwilight: 'Schneeschauer',
        snowshowersandthunder_day: 'Schneeschauer und Sturm',
        snowshowersandthunder_night: 'Schneeschauer und Sturm',
        snowshowersandthunder_polartwilight: 'Schneeschauer und Sturm',
        partlycloudy_day: 'Teilweise bewölkt',
        partlycloudy_night: 'Teilweise bewölkt',
        partlycloudy_polartwilight: 'Teilweise bewölkt',
      },
    },
  },
};
