export default {
  app: {
    winter: {
      snowReport: 'Bulletin neige',
      snow: '{snow} cm de {type}',
      snowTypeTranslation: {
        1: '',
        2: 'neige poudreuse',
        3: 'neige mouillée',
        4: 'neige glacée',
        5: 'neige firn',
        6: 'neige artificielle',
        7: 'neige humide',
      },
      lifts: 'Remontées mécaniques',
      open: 'ouvert',
      slopes: 'Pistes de ski',
      webcam: 'Webcam',
    },
  },
};
