export default {
  app: {
    user: {
      button: {
        logIn: 'Kirjaudu sisään',
        logOut: 'Kirjaudu ulos',
      },
      error: {
        username: 'Käyttäjätunnus ei voi olla tyhjä',
        password: 'Salasana ei voi olla tyhjä',
        undefined: 'Sisäänkirjautuminen epäonnistui',
      },
      title: 'Kirjaudu sisään',
      placeholder: {
        user: 'Käyttäjätunnus',
        pass: 'Salasana',
      },
      loggedIn: 'Olet kirjautunut sisään käyttäjänä {0}',
      admin: 'Tilin hallinta',
    },
  },
};
