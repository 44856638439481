export default {
  app: {
    maps: {
      more: '詳細地図を開く',
      style: {
        tooltip: 'マップスタイル',
        basic: 'ベーシック',
        outdoor: '観光客',
        aerial: 'エアリアル',
        winter: '冬',
      },
      loadPoI: 'ローカル検索',
      currentPosition: '現在の位置',
      categories: 'カテゴリー',
      location: '所在地',
    },
  },
};
