export default {
  app: {
    weather: {
      title: 'Weather forecast',
      now: 'Today',
      weatherTranslations: {
        clearsky_day: 'Clear sky',
        clearsky_night: 'Clear sky',
        clearsky_polartwilight: 'Clear sky',
        cloudy: 'Cloudy',
        fair_day: 'Fair',
        fair_night: 'Fair',
        fair_polartwilight: 'Fair',
        fog: 'Fog',
        heavyrain: 'Heavy rain',
        heavyrainandthunder: 'Heavy rain and thunder',
        heavyrainshowers_day: 'Heavy rain showers',
        heavyrainshowers_night: 'Heavy rain showers',
        heavyrainshowers_polartwilight: 'Heavy rain showers',
        heavyrainshowersandthunder_day: 'Heavy rain showers and thunder',
        heavyrainshowersandthunder_night: 'Heavy rain showers and thunder',
        heavyrainshowersandthunder_polartwilight:
          'Heavy rain showers and thunder',
        heavysleet: 'Heavy sleet',
        heavysleetandthunder: 'Heavy sleet and thunder',
        heavysleetshowers_day: 'Heavy sleet showers',
        heavysleetshowers_night: 'Heavy sleet showers',
        heavysleetshowers_polartwilight: 'Heavy sleet showers',
        heavysleetshowersandthunder_day: 'Heavy sleet showers and thunder',
        heavysleetshowersandthunder_night: 'Heavy sleet showers and thunder',
        heavysleetshowersandthunder_polartwilight:
          'Heavy sleet showers and thunder',
        heavysnow: 'Heavy snow',
        heavysnowandthunder: 'Heavy snow and thunder',
        heavysnowshowers_day: 'Heavy snow showers',
        heavysnowshowers_night: 'Heavy snow showers',
        heavysnowshowers_polartwilight: 'Heavy snow showers',
        heavysnowshowersandthunder_day: 'Heavy snow showers and thunder',
        heavysnowshowersandthunder_night: 'Heavy snow showers and thunder',
        heavysnowshowersandthunder_polartwilight:
          'Heavy snow showers and thunder',
        lightrain: 'Light rain',
        lightrainandthunder: 'Light rain and thunder',
        lightrainshowers_day: 'Light rain showers',
        lightrainshowers_night: 'Light rain showers',
        lightrainshowers_polartwilight: 'Light rain showers',
        lightrainshowersandthunder_day: 'Light rain showers and thunder',
        lightrainshowersandthunder_night: 'Light rain showers and thunder',
        lightrainshowersandthunder_polartwilight:
          'Light rain showers and thunder',
        lightsleet: 'Light sleet',
        lightsleetandthunder: 'Light sleet and thunder',
        lightsleetshowers_day: 'Light sleet showers',
        lightsleetshowers_night: 'Light sleet showers',
        lightsleetshowers_polartwilight: 'Light sleet showers',
        lightssleetshowersandthunder_day: 'Light sleet showers and thunder',
        lightssleetshowersandthunder_night: 'Light sleet showers and thunder',
        lightssleetshowersandthunder_polartwilight:
          'Light sleet showers and thunder',
        lightsnow: 'Light snow',
        lightsnowandthunder: 'Light snow and thunder',
        lightsnowshowers_day: 'Light snow showers',
        lightsnowshowers_night: 'Light snow showers',
        lightsnowshowers_polartwilight: 'Light snow showers',
        lightssnowshowersandthunder_day: 'Light snow showers and thunder',
        lightssnowshowersandthunder_night: 'Light snow showers and thunder',
        lightssnowshowersandthunder_polartwilight:
          'Light snow showers and thunder',
        rain: 'Rain',
        rainandthunder: 'Rain and thunder',
        rainshowers_day: 'Rain showers',
        rainshowers_night: 'Rain showers',
        rainshowers_polartwilight: 'Rain showers',
        rainshowersandthunder_day: 'Rain showers and thunder',
        rainshowersandthunder_night: 'Rain showers and thunder',
        rainshowersandthunder_polartwilight: 'Rain showers and thunder',
        sleet: 'Sleet',
        sleetandthunder: 'Sleet and thunder',
        sleetshowers_day: 'Sleet showers',
        sleetshowers_night: 'Sleet showers',
        sleetshowers_polartwilight: 'Sleet showers',
        sleetshowersandthunder_day: 'Sleet showers and thunder',
        sleetshowersandthunder_night: 'Sleet showers and thunder',
        sleetshowersandthunder_polartwilight: 'Sleet showers and thunder',
        snow: 'Snow',
        snowandthunder: 'Snow and thunder',
        snowshowers_day: 'Snow showers',
        snowshowers_night: 'Snow showers',
        snowshowers_polartwilight: 'Snow showers',
        snowshowersandthunder_day: 'Snow showers and thunder',
        snowshowersandthunder_night: 'Snow showers and thunder',
        snowshowersandthunder_polartwilight: 'Snow showers and thunder',
        partlycloudy_day: 'Partly cloudy',
        partlycloudy_night: 'Partly cloudy',
        partlycloudy_polartwilight: 'Partly cloudy',
      },
    },
  },
};
