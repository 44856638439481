export default {
  app: {
    search: {
      button: '探す',
      noResults: '結果が見つかりません',
      title: '検索',
      widgetTitle: '国で何をすべきかを見つける',
    },
  },
};
