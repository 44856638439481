export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Homepage',
      destinations: 'Cele podróży',
      thingsToDo: 'Atrakcje',
      new: 'Nowości',
      covid: 'Covid-19',
      travelInfo: 'Info dla podróżujących',
    },
  },
};
