import { Component, Prop } from 'vue-property-decorator';
import { Newsletter } from '~/app/core/store/modules/NewsletterModule';
import Checkbox from '~/components/atoms/checkbox/Checkbox';
import { VueComponent } from '~/utils/vue-component';

import style from './NewsletterButton.scss';

const rootClass = 'czt-newsletter-button';

interface NewsletterButtonInterface {
  newsletter: Newsletter;
  light?: boolean;
}

@Component({ style })
export default class NewsletterButton
  extends VueComponent<NewsletterButtonInterface>
  implements NewsletterButtonInterface {
  @Prop({ required: true })
  public newsletter!: Newsletter;

  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  public render() {
    return (
      <v-item
        value={this.newsletter.name}
        {...{
          scopedSlots: {
            default: ({
              active,
              toggle,
            }: {
              active: boolean;
              toggle: () => void;
            }) => {
              const itemClasses: string[] = [
                `${rootClass}`,
                'd-flex',
                'align-center',
              ];
              if (active) {
                itemClasses.push(`${rootClass}--active`);
              }
              return (
                <div
                  class={itemClasses.join(' ')}
                  onClick={() => {
                    toggle();
                  }}
                >
                  <Checkbox
                    readOnly
                    value={active}
                    label={this.newsletter.title}
                    light={this.light}
                  />
                </div>
              );
            },
          },
        }}
      />
    );
  }
}
