import { ItineraryFolder, ItineraryItem } from '~/app/core/apiClient/api';
import { PlanDay } from '~/components/templates/common/itinerary/ItineraryPlanDay';
import getItineraryPlanStops from './getItineraryPlanStop';

export default function(data: ItineraryFolder[]): PlanDay[] {
  return data.map((folder, i) => {
    let possibleNextStop: ItineraryItem | undefined;
    if (data.length - 1 >= i + 1) {
      const nextDay = data[i + 1];
      possibleNextStop =
        nextDay.itineraryItems && nextDay.itineraryItems.length > 0
          ? nextDay.itineraryItems[0]
          : undefined;
    }
    return {
      title: data.length > 1 ? folder.title : undefined,
      stops:
        (folder.itineraryItems &&
          folder.itineraryItems.map((item, n) =>
            getItineraryPlanStops(
              item,
              folder.itineraryItems && folder.itineraryItems.length - 1 >= n + 1
                ? folder.itineraryItems[n + 1]
                : possibleNextStop
            )
          )) ||
        [],
    };
  });
}
