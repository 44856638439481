export default {
  app: {
    newsletter: {
      bottomText:
        '뉴스레터 구독으로 당사의 <a href="~/Privacy-Policy" target="_blank">데이터 보호 정책에</a> 동의하게 됩니다.',
      button: '구독',
      ctaDialogParagraph1: 'Do you want to know the upcoming events in Prague?',
      ctaDialogParagraph2:
        'Not sure which castle to visit on your Czech Republic tour?',
      ctaDialogParagraph3:
        'Subscribe to our newsletter and you will receive many useful tips for your next trip!',
      ctaDialogTitle: 'KNOCK KNOCK!',
      ctaDialogBtn: 'CLICK HERE',
      dialog: {
        headline: '뉴스레터 국가/지역 선택',
        notice:
          '제공된 귀하의 이메일 주소는 뉴스레터 구독 목적으로만 사용되며 그 외의 다른 목적으로 사용되지 않습니다. 연락처는 구독을 취소하는 날까지만 사용됩니다. 뉴스레터 내 링크로 언제든지 구독을 취소할 수 있습니다. 등록된 이메일 주소는 당사 웹사이트 운영 및 개발을 관리하는 IT 회사에서 접근할 수 있습니다. {0}',
        company: '데이터 관리자는 {0}, {1} 입니다. ',
        link: {
          privacy:
            '체코관광청의 개인정보 보호정책은 여기에서 확인할 수 있습니다.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: '이메일 주소',
      newsletterError: '현재 구독 가능한 뉴스레터가 없습니다.',
      serverError:
        '요청을 실행하는 동안 오류가 발생했습니다. 다시 시도해 주세요.',
      success:
        "가입 절차를 완료하기 위해 발송된 이메일에서 '확인' 버튼을 눌러주세요.",
      title: '뉴스레터 신청',
      topText: '최신 정보, 팁, 다가올 이벤트, 새로운 소식을 확인해보세요.',
      validityError: '이메일 주소가 유효하지 않습니다.',
    },
  },
};
