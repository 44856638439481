import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { isGridWidget } from '~/app/core/apiClient';
import { Button, Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { MediaBackground } from '~/components/molecules';
import Brochure from '~/components/molecules/gridItem/Brochure';
import Classic from '~/components/molecules/gridItem/Classic';
import Extended from '~/components/molecules/gridItem/Extended';
import Simple from '~/components/molecules/gridItem/Simple';
import IconBlock from '~/components/molecules/gridItem/IconBlock';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { CztImage } from '~/utils/atoms/image';
import { ThemeColors, ThemeRatios } from '~/utils/theme';
import { createGridItem } from '~/utils/views/components';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponentMixin } from '~/utils/vue-component';
import { LocaleMessage } from 'vue-i18n';

import RouterModule from '~/app/core/store/modules/RouterModule';

import style from './GridList.scss';
import {
  GridItem,
  GridListInterface,
  GridListStyleEnum,
  TaxonomyList,
} from './types';
import GridListFilter from './GridListFilter';

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

const rootClass = 'czt-grid-list';

@Component({
  style,
})
export default class GridList
  extends VueComponentMixin<GridListInterface, PrefetchComponent>(Prefetch)
  implements GridListInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true, type: Array })
  public items!: GridItem[];

  @Prop({ required: true })
  public title!: string | LocaleMessage;

  @Prop({ type: String, required: true })
  public guid!: string;

  @Prop()
  public image?: CztImage;

  @Prop()
  public imageAspectRatio?: ThemeRatios;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ type: String })
  public link?: string;

  @Prop()
  public overlayColor?: ThemeColors;

  @Prop({ default: 0, type: Number })
  public maxItems!: number;

  /**
   * This is the number of items in the next page that came with initial request
   */
  @Prop({ default: 0, type: Number })
  public nextPageCount!: number;

  @Prop({ default: 0, type: Number })
  public pageSize!: number;

  @Prop({ type: String })
  public buttonText?: string;

  @Prop({ type: Boolean, default: false })
  public showFlags!: boolean;

  @Prop()
  public taxonomies?: string[];

  @Prop()
  public taxonomyLists?: TaxonomyList[];

  @Prop({ type: String })
  public type!: GridListStyleEnum;

  public className = CztWidgets.GRID;

  protected rightColumn: boolean = false;

  /**
   * Extra property for storage of items loaded through load more button
   * as we cannot update the parent 'items' prop
   *
   * These items are concatenated in itemsCollection getter
   */
  protected loadedItems: GridItem[] = [];

  /**
   * We are starting on page 1
   */
  protected pageNumber = 1;

  /**
   * Flag indicating we are downloading items from the backend
   */
  protected fetchingItems: boolean = false;

  /**
   * Whether it is possible to load more items in the grid
   */
  protected nextPage: boolean = true;

  protected filterValue: Map<string, string[]> = new Map();

  protected routerModule!: RouterModule;

  protected get taxonomy(): string[] {
    return [...this.filterValue.values()].flat();
  }

  /**
   * Collection of items passed on the page load and loaded through load more button
   */
  protected get itemsCollection(): GridItem[] {
    return this.taxonomy.length > 0
      ? this.loadedItems
      : this.items.concat(this.loadedItems);
  }

  public created() {
    if (this.nextPageCount === 0) {
      this.nextPage = false;
    }

    this.routerModule = getModule(RouterModule, this.$store);
  }

  public prefetch() {
    const queryTaxonomy = this.$route.query.taxonomy;
    if (queryTaxonomy && typeof queryTaxonomy === 'string') {
      const taxList = this.taxonomyLists?.find((tl) =>
        tl.tags.map((tag) => tag.id).includes(queryTaxonomy)
      );
      if (!taxList) {
        return Promise.resolve();
      }
      this.filterValue = new Map([[taxList.taxonomy, [queryTaxonomy]]]);
      this.$router.replace({});
      return this.getItems(true);
    } else {
      return Promise.resolve();
    }
  }

  public render() {
    if (this.image) {
      return (
        <MediaBackground
          id={this.anchorId}
          class={rootClass}
          image={this.image}
          sizes={sizes}
          overlayColor={
            this.image && this.image.src
              ? this.overlayColor
                ? this.overlayColor
                : ThemeColors.PRIMARY
              : undefined
          }
        >
          {this.renderContent()}
        </MediaBackground>
      );
    } else {
      return (
        <v-sheet id={this.anchorId} class={rootClass}>
          {this.renderContent()}
        </v-sheet>
      );
    }
  }

  protected renderContent() {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')}>
        <div class='py-3'>
          {!this.rightColumn && <v-row>{this.getHeadline()}</v-row>}
          {this.taxonomyLists && this.taxonomyLists.length > 0 && (
            <GridListFilter
              filterOptions={this.taxonomyLists}
              onFilter={(data) => (this.filterValue = data)}
              value={this.filterValue}
            />
          )}
          <v-row class='ma-0 pa-0'>
            <v-col class='py-0'>
              <v-row class={`${rootClass}__container`}>
                {this.itemsCollection.length < 1 ? (
                  this.fetchingItems ? (
                    this.renderPlaceholder()
                  ) : (
                    <v-col cols='12' class='text-center py-5'>
                      {this.$t('app.search.noResults')}
                    </v-col>
                  )
                ) : (
                  this.getGridItems()
                )}
              </v-row>
              {this.renderLoadMoreButton()}
            </v-col>
            {this.getSideColumn()}
          </v-row>
          {this.getButton()}
        </div>
      </v-container>
    );
  }

  protected getHeadline() {
    return (
      <v-col class='py-0'>
        <Headline
          underscore
          align={this.rightColumn ? Align.RIGHT : Align.LEFT}
          level={2}
          light={!!this.image?.src}
        >
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected getButton() {
    if (!this.link) {
      return;
    }
    return (
      <v-col cols='auto' class='py-0 text-right'>
        <Button url={this.link}>
          {this.buttonText
            ? this.buttonText
            : this.$t('app.common.exploreButton')}
        </Button>
      </v-col>
    );
  }

  protected getSideColumn() {
    if (this.rightColumn)
      return (
        <v-col class='py-0' cols='3'>
          <v-row
            class='flex-column fill-height'
            justify-content='space-between'
          >
            {[this.getHeadline(), this.getButton()]}
            <v-col cols='auto' class='py-0 text-right'>
              <Button>
                {this.buttonText
                  ? this.buttonText
                  : this.$t('app.common.exploreButton')}
              </Button>
            </v-col>
          </v-row>
        </v-col>
      );
  }

  protected getGridItems() {
    const items = this.itemsCollection.map((item) => {
      return {
        ...item,
        showFlag: this.showFlags,
      };
    });
    if (
      this.type === GridListStyleEnum.Classic ||
      this.type === GridListStyleEnum.Compact ||
      this.type === GridListStyleEnum.Classicsubt
    ) {
      return items.map((item, index) => (
        <Classic
          item={item}
          key={index + item.title}
          compact={this.type === GridListStyleEnum.Compact}
          perex={
            this.type === GridListStyleEnum.Classicsubt
              ? item.subtitle !== '<!notitle>'
                ? item.subtitle
                : undefined
              : item.perex
          }
        />
      ));
    } else if (this.type === GridListStyleEnum.Simplelist) {
      return items.map((item, index) => (
        <Simple item={item} key={index + item.title} />
      ));
    } else if (this.type === GridListStyleEnum.Extended) {
      return items.map((item, index) => (
        <Extended item={item} key={index + item.title} />
      ));
    } else if (this.type === GridListStyleEnum.Brochures) {
      return items.map((item, index) => (
        <Brochure
          item={item}
          key={index + item.title}
          imageRatio={this.imageAspectRatio}
        />
      ));
    } else if (this.type === GridListStyleEnum.Iconbox) {
      return items.map((item, index) => (
        <IconBlock item={item} key={index + item.title} />
      ));
    }
  }

  protected getItems(initial?: boolean) {
    if (this.fetchingItems) {
      return Promise.resolve();
    }

    this.fetchingItems = true;
    return this.$api
      .widgets()
      .widgetsGetWidgetByGuid(
        this.guid,
        this.pageNumber,
        this.routerModule.resource
          ? this.routerModule.resource.guid
          : undefined,
        this.$i18n.locale,
        CztWidgets.GRID,
        this.taxonomy.length > 0 ? this.taxonomy.join('|') : undefined
      )
      .then((widget) => {
        if (isGridWidget(widget)) {
          if (widget.items && widget.items.length > 0) {
            const items = widget.items.map(createGridItem);

            if (initial) {
              this.loadedItems = items;
            } else {
              this.loadedItems.push(...items);
            }

            if (!widget.itemsCountNextPage || widget.itemsCountNextPage < 1) {
              this.nextPage = false;
            } else {
              this.nextPage = true;
            }
          } else {
            this.nextPage = false;
          }
        }
      })
      .finally(() => {
        this.fetchingItems = false;
      });
  }

  protected renderLoadMoreButton() {
    if (this.pageSize < 1 || !this.nextPage) {
      // Do not render the load more button if paging is disabled
      return;
    }

    return (
      <v-row no-gutters justify='center' class='pt-3'>
        <Button
          disabled={this.fetchingItems}
          loading={this.fetchingItems}
          onClick={() => {
            this.pageNumber++;
            this.getItems().catch(() => {
              this.pageNumber--;
            });
          }}
        >
          {this.$t('app.common.loadMore')}
        </Button>
      </v-row>
    );
  }

  @Watch('taxonomy')
  protected onTaxonomyChange() {
    this.loadedItems = [];
    this.pageNumber = 1;
    if (this.taxonomy.length > 0) {
      this.getItems(true);
    } else if (this.nextPageCount > 0) {
      this.nextPage = true;
    }
  }

  protected renderPlaceholder(): JSX.Element[] {
    return [...Array(this.pageSize || 6)].map(() => (
      <v-col cols={6} md={4} class={`${rootClass}__item`}>
        <v-responsive aspect-ratio={1} height='100%'>
          <v-skeleton-loader type='image' />
        </v-responsive>
      </v-col>
    ));
  }
}
