import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class PinIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--pin'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 244 395'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M101.3 1.3a116.6 116.6 0 0 0-76 47c-21 28.2-31 67.3-22 102C9.9 174.7 23 197.4 34 220.1c10.3 22 19.7 44.3 30 66.3 12.7 27.8 26.6 55.2 38.4 83.4 3.4 8 9.8 25.8 21 24.7 10-.9 15.4-17 19.2-24.7 13.2-28 25-56.5 37.5-84.8 12-27.5 25-54.5 37.3-82 5.6-12.5 12.8-24.5 17.5-37.4 13.6-37.4 12.8-77.2-10.5-110.6a128.6 128.6 0 0 0-123-53.7'
          />
        </svg>
      </i>
    );
  }
}
