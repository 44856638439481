export default {
  app: {
    common: {
      backToMenu: 'Terug naar menu',
      blog: 'Blog',
      exploreButton: 'Ontdek',
      language: 'Taal',
      loadMore: 'Ontdek meer',
      moreInfo: 'Meer informatie',
      opensInNewWindow: 'Opent in een nieuw venster',
      pageTypes: {
        article: 'Artikel',
        category: 'Categorie',
        event: 'Evenement',
        page: 'Bladzijde',
        place: 'Plaats',
        region: 'Regio',
      },
      selectLanguage: 'Selecteer taal',
      homepageBreadcrumb: 'Home',
      translationVoting: 'Wat vind je van deze vertalingen?',
      translationVotingSuccess: 'Bedankt voor je mening!',
    },
  },
};
