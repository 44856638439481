export default {
  app: {
    maps: {
      more: '상세 지도 열기',
      style: {
        tooltip: '지도 스타일',
        basic: '기초적인',
        outdoor: '관광객',
        aerial: '공중선',
        winter: '겨울',
      },
      loadPoI: '지역 검색',
      currentPosition: '현재 위치',
      categories: '카테고리',
      location: '위치',
    },
  },
};
