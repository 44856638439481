import { Component, Prop } from 'vue-property-decorator';
import { icons } from '~/components/atoms/icons';
import { VueComponent } from '~/utils/vue-component';
import { BaseIconInterface } from './BaseIcon';
import style from './Icon.scss';

export type IconName =
  | ['common', keyof typeof icons.common]
  | ['flags', keyof typeof icons.flags]
  | ['social', keyof typeof icons.social]
  | ['map', keyof typeof icons.map];

export interface IconInterface extends BaseIconInterface {
  name: IconName | string;
}

export function isIconName(data: any): data is IconName {
  return (
    (data &&
      Array.isArray(data) &&
      data.length === 2 &&
      typeof data[0] === 'string' &&
      ['common', 'flags', 'social', 'map'].indexOf(data[0]) > -1 &&
      typeof data[1] === 'string' &&
      ((data[0] === 'common' && data[1] in icons.common) ||
        (data[0] === 'flags' && data[1] in icons.flags) ||
        (data[0] === 'social' && data[1] in icons.social))) ||
    (data[0] === 'map' && data[1] in icons.map)
  );
}

const rootClass = 'czt-icon';

@Component({
  style,
})
export default class Icon extends VueComponent<IconInterface>
  implements IconInterface {
  @Prop({ default: false })
  public bordered!: boolean;

  @Prop({ required: true })
  public name!: IconName | string;

  @Prop({ default: 1, type: Number })
  public size!: number;

  public render() {
    let iconName: IconName | string = this.name;
    if (this.name instanceof Array) {
      iconName = this.name.join('-');
    }

    const classes = [rootClass];

    if (this.bordered) {
      classes.push(`${rootClass}--bordered`);
    }

    return (
      <v-icon
        class={classes.join(' ')}
        size={`${this.size}em`}
        nativeOnClick={() => this.$emit('click')}
      >
        {typeof this.name === 'string'
          ? this.name
          : `$vuetify.icons.${iconName}`}
      </v-icon>
    );
  }
}
