export default {
  app: {
    user: {
      button: {
        logIn: 'ログイン',
        logOut: 'ログアウト',
      },
      error: {
        username: 'ユーザー名を記入してください',
        password: 'パスワードを記入してください',
        undefined: 'ログインできません',
      },
      title: 'ログイン',
      placeholder: {
        user: 'ユーザー名',
        pass: 'パスワード',
      },
      loggedIn: '{0}としてログイン中',
      admin: '管理',
    },
  },
};
