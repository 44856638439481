import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class RestaurantIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--restaurant'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M100.6 1.2c-5.6 0-10.1 4.5-10.1 10.1v135.5h10V89h10.2V11.3c0-5.6-4.5-10.1-10.1-10.1M74.1 1.4v39.8h-6.2V1.4H57.7v39.8h-6.2V1.4H41.4v40c.1 10 7 18.3 16.3 20.6v84.8H68V62a21.4 21.4 0 0 0 16.3-20.6v-40H74.1Z'
          />
        </svg>
      </i>
    );
  }
}
