import { createApp } from '~/app/main';
import { getModule } from 'vuex-module-decorators';
import DomainModule from '~/app/core/store/modules/DomainModule';
import CookieSettingsModule from './app/core/store/modules/CookieSettingsModule';
import getCookieValue from './utils/cookies/getCookieValue';
import UserModule, { tokenKey } from './app/core/store/modules/UserModule';

// client-specific bootstrapping logic...

const { app, router, store } = createApp();

// @ts-ignore
if (window.__INITIAL_STATE__) {
  // @ts-ignore
  store.replaceState(window.__INITIAL_STATE__);
}

const domainModule = getModule(DomainModule, store);
if (!domainModule.hostname) {
  domainModule.setHostname(window.location.host);
}

const cookieSettings = getModule(CookieSettingsModule, store);
cookieSettings.readCookie(document.cookie).finally(() => {
  cookieSettings.loadGTM();
});

const token = getCookieValue(tokenKey, document.cookie);
if (token) {
  const userModule = getModule(UserModule, store);
  userModule.loadUser(token).finally(() => {
    router.onReady(() => {
      app.$mount('#app');
    });
  });
} else {
  router.onReady(() => {
    app.$mount('#app');
  });
}

if ((module as any).hot) {
  (module as any).hot.accept();
}
