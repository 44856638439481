export default {
  app: {
    search: {
      button: 'Finden',
      noResults: 'Keine Ergebnisse gefunden',
      title: 'Suche',
      widgetTitle: 'Finden Sie heraus, was Sie im Land unternehmen können',
    },
  },
};
