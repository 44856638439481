import { Component, Prop } from 'vue-property-decorator';

import style from './Extended.scss';
import { GridItem } from '~/components/organisms/gridList/types';
import { VueComponent } from '~/utils/vue-component';
import { MediaBackground } from '~/components/molecules';
import { Button, Headline } from '~/components/atoms';
import { Align, LineColor } from '~/components/atoms/headline/Headline';
import { Style } from '~/components/atoms/button/Button';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { Sizes } from '../imageWrapper/ImageWrapper';

interface ExtendedGridItemInterface {
  item: GridItem;
}

const rootClass = 'czt-grid-list__item';

const sizes: Sizes[] = [
  {
    minWidth: 1264,
    size: (1150 / 12) * 6,
    unit: 'px',
  },
  {
    minWidth: 1008,
    size: (1000 / 12) * 6,
    unit: 'px',
  },
  {
    minWidth: 768,
    size: 710,
    unit: 'px',
  },
  {
    minWidth: 640,
    size: 640,
    unit: 'px',
  },
  {
    size: 100,
    unit: 'vw',
  },
];

@Component({
  style,
})
export default class ExtendedGridItem
  extends VueComponent<ExtendedGridItemInterface>
  implements ExtendedGridItemInterface {
  @Prop({ required: true })
  public item!: GridItem;

  public render() {
    // If grid list item width should ever be changed, sizes need to be adjusted too
    return (
      <v-col cols='12' lg='6' class={`${rootClass} ${rootClass}--extended`}>
        {(() => {
          if (this.item.image) {
            return (
              <MediaBackground
                image={this.item.image}
                imageFilter={this.item.imageFilter}
                sizes={sizes}
              >
                <v-responsive aspect-ratio={1}>
                  {this.renderContent()}
                </v-responsive>
              </MediaBackground>
            );
          }

          return (
            <v-sheet tile color='grey'>
              <v-responsive aspect-ratio={1}>
                {this.renderContent()}
              </v-responsive>
            </v-sheet>
          );
        })()}
      </v-col>
    );
  }

  protected renderContent() {
    return (
      <v-container class='fill-height flex-column flex-nowrap py-lg-8 py-md-4'>
        <div class='d-flex flex-grow-0 flex-shrink-0'>
          <Headline
            level={4}
            align={Align.CENTER}
            underscore
            lineColor={LineColor.WHITE}
            light
          >
            {this.item.title}
          </Headline>
        </div>
        <div class={`${rootClass}__content d-flex flex-grow-1`}>
          <HtmlRenderer
            content={this.item.description}
            light={this.item.image.src ? true : false}
          />
        </div>
        <div class='d-flex flex-grow-0 flex-shrink-0 mt-3'>
          <Button
            buttonStyle={Style.PRIMARY_LIGHT}
            url={this.item.link}
            forcedLocale={this.item.forcedLocale}
          >
            {this.item.linkText}
          </Button>
        </div>
      </v-container>
    );
  }
}
