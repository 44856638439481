export default {
  app: {
    user: {
      button: {
        logIn: '登录',
        logOut: '退出',
      },
      error: {
        username: '用户名不能为空',
        password: '密码不能为空',
        undefined: '无法登录',
      },
      title: '登录',
      placeholder: {
        user: '用户名',
        pass: '密码',
      },
      loggedIn: '您以{0}身份登录',
      admin: '管理',
    },
  },
};
