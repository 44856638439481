export default {
  app: {
    winter: {
      snowReport: 'Boletim de neve',
      snow: '{type} neve {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: 'Pó',
        3: 'Molhada',
        4: 'Congelada',
        5: 'Firme',
        6: 'Artificial',
        7: 'Úmida',
      },
      lifts: 'Teleféricos',
      open: 'aberto',
      slopes: 'Pistas',
      webcam: 'Webcam',
    },
  },
};
