export default {
  app: {
    search: {
      button: 'Trouver',
      noResults: 'Aucun résultat trouvé',
      title: 'Chercher',
      widgetTitle: 'Trouvez quoi faire dans le pays',
    },
  },
};
