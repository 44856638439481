export default {
  app: {
    panorama: {
      loadingText: "Chargement de l'image...",
      autorotate: 'Rotation automatique',
      zoom: 'Zoom',
      zoomOut: 'Réduire',
      zoomIn: 'Agrandir',
      move: 'Bouger',
      download: 'Télécharger',
      fullscreen: 'Plein écran',
      menu: 'Menu',
      twoFingers: 'Utilisez deux doigts pour naviguer',
      ctrlZoom: 'Appuyer ctr+ scroll pour agrandir l´image',
      loadError: 'Panorama ne peut pas chargé',
    },
  },
};
