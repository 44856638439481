export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Home',
      destinations: 'Destinos',
      thingsToDo: 'Qué hacer',
      new: 'Novedades',
      covid: 'Covid-19',
      travelInfo: 'Info práctica',
    },
  },
};
