const anchorPreposition = 'anchor_';

const createAnchorId = (id: string, prepend: boolean = true) => {
  const anchorId: string[] = [];
  anchorId.push(prepend ? `#${anchorPreposition}` : '#');
  anchorId.push(id.charAt(0) === '#' ? id.substring(1) : id);
  return anchorId.join('');
};

export { anchorPreposition, createAnchorId };
