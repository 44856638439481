export default {
  app: {
    panorama: {
      loadingText: 'Beeld wordt geladen...',
      autorotate: 'Automatisch keren',
      zoom: 'Zoom',
      zoomOut: 'Verkleinen',
      zoomIn: 'Vergroten',
      move: 'Verplaatsen',
      download: 'Downloaden',
      fullscreen: 'Volledig beeld',
      menu: 'Menu',
      twoFingers: 'Gebruik twee vingers om te navigeren',
      ctrlZoom: 'Gebruik ctrl + scroll om het beeld te vergroten',
      loadError: 'Het beeld wordt niet geladen',
    },
  },
};
