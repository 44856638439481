export default {
  app: {
    footer: {
      aboutUs: 'Chi siamo',
      contactUs: 'Contattaci',
      pressAndMedia: 'Press & Media',
      travelProfessionals: 'Area B2B',
      brochures: 'Cataloghi',
      news: 'Novità',
      covid19: 'Covid-19',
      destinations: 'Destinazioni',
      thingsToDo: 'Cosa fare',
      travelInfo: 'Info per viaggiatori',
      czechTourism: 'CzechTourism',
      menu: 'Sezioni',
      mobileApp: 'App mobile',
      visitCzechAndroid: 'VisitCzech Applicazione mobile per Android',
      followUs: 'Seguici',
      privacyPolicy: 'Politica Sulla Riservatezza',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/it-it/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Impostazione Cookies',
    },
  },
};
