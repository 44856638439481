import buildDistanceInWordsLocale from './build_distance_in_words_locale';
import buildFormatLocale from './build_format_locale';

/**
 * @category Locales
 * @summary LV locale.
 */
export default {
  distanceInWords: buildDistanceInWordsLocale(),
  format: buildFormatLocale(),
};
