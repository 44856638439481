import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class ExperienceIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--experience'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M75.8 111.6 122 145l-17-54.6 45.6-34.1-56.8-.5-18-54.3-18 54.3-56.8.5 45.6 34-17 54.7 46.2-33.3Z'
          />
        </svg>
      </i>
    );
  }
}
