export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: '首頁',
      destinations: '旅行目的地',
      regions: '地區特色',
      cities: '捷克城鎮',
      thingsToDo: '做什麼好？',
      alwaysVisit: '必訪景點',
      culturalHeritage: '文化遺產',
      activeHoliday: '活力假期',
      healthAndSpa: '養生與SPA',
      new: '最新活動',
      covid: '新冠病毒資訊',
      travelInfo: '旅行資訊',
    },
  },
};
