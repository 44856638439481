export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtrēt pēc stimula',
        itinerary: 'Filtrēt pēc atzīmes',
        region: 'Filtrēt pēc reģiona',
      },
    },
  },
};
