export default {
  app: {
    search: {
      button: 'Trova',
      noResults: 'Nessun risultato trovato',
      title: 'Ricerca',
      widgetTitle: 'Trova cosa fare nel paese',
    },
  },
};
