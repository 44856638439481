export default {
  app: {
    cookies: {
      title: '¡Respetamos su privacidad!',
      text:
        'Utilizamos cookies para personalizar el contenido, analizar las visitas de la página web y establecer el funcionamiento de las redes sociales. La información sobre su uso de nuestra página web la compartimos con nuestros socios en el ámbito de las redes sociales y los servicios analíticos. Los socios las pueden combinar con otros datos que les facilitaremos o que obtienen a consecuencia de usar sus servicios. Encontrará información más detallada {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'aquí',
      },
      buttons: {
        accept: 'Aceptar todas las cookies',
        save: 'Guardar seleccionado',
      },
      settings: {
        button: 'Gestionar opciones',
        required: 'Obligatorio (para un funcionamiento adecuado)',
        analytics:
          'Análisis (se entiende como el comportamiento de los visitantes de las páginas webs o como su movimiento cerca de ellas)',
        statistics:
          'Estadísticos (para entender como usan los visitantes las páginas web)',
        marketing:
          'Comercial (para monitorizar los visitantes con finalidad de visualizar los anuncios)',
        reject: 'Rechazar todo',
        accept: 'Aceptar todo',
      },
    },
  },
};
