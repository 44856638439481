import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import ItineraryPlanDay, { PlanDay } from './ItineraryPlanDay';
import { anchorPreposition } from '~/utils/anchor';

interface ItineraryPlanProps {
  data: PlanDay[];
}

const rootClass = 'czt-itinerary-plan';

@Component
export default class ItineraryPlan extends VueComponent<ItineraryPlanProps>
  implements ItineraryPlanProps {
  @Prop()
  public data!: PlanDay[];

  public render() {
    const classes = [rootClass];

    return (
      <div class={classes.join(' ')}>
        <v-container id={`${anchorPreposition}itinerary`}>
          <v-row justify='center'>
            <v-col cols={12} class='py-0'>
              {this.data.map((plan, i) => (
                <ItineraryPlanDay
                  planDay={plan}
                  key={plan.title + i.toString()}
                  class={i > 0 ? 'mt-3' : undefined}
                />
              ))}
            </v-col>
          </v-row>
        </v-container>
      </div>
    );
  }
}
