export default {
  app: {
    footer: {
      aboutUs: '회사 소개',
      contactUs: '문의하기',
      pressAndMedia: '미디어',
      travelProfessionals: '여행사',
      brochures: '브로셔',
      news: '뉴스',
      covid19: '코로나19',
      destinations: '여행지',
      thingsToDo: '추천 액티비티',
      travelInfo: '여행 정보',
      czechTourism: '체코관광청',
      menu: '메뉴',
      mobileApp: '모바일 앱',
      followUs: '팔로우',
      privacyPolicy: '개인정보 보호정책',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: '쿠키 & 분석',
      cookieSettings: '쿠키 설정',
    },
  },
};
