import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class NatureIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--nature'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M76 104.3a25.8 25.8 0 1 1 0-51.7 25.8 25.8 0 0 1 0 51.7m76-40.1a32.5 32.5 0 0 0-43.5-30.6v-1a32.5 32.5 0 1 0-65 0v1A32.4 32.4 0 0 0 0 64.2c0 14.7 9.9 27.2 23.4 31.2A32.5 32.5 0 1 0 76 133.6a32.5 32.5 0 1 0 52.6-38.2A32.5 32.5 0 0 0 152 64.2'
          />
        </svg>
      </i>
    );
  }
}
