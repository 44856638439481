export default {
  app: {
    maps: {
      more: 'Részletes térkép megnyitása',
      style: {
        tooltip: 'Térkép stílus',
        basic: 'Alapvető',
        outdoor: 'Turista',
        aerial: 'Légi',
        winter: 'Téli',
      },
      loadPoI: 'Helyi keresés',
      currentPosition: 'Jelenlegi pozíció',
      categories: 'Kategóriák',
      location: 'Helyszín',
    },
  },
};
