export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: "Je suis d'accord pour {0}",
          link: 'traitement des données personnelles.',
          error: 'Vous devez accepter le traitement des données personnelles.',
        },
      },
      send: 'Envoyer',
      sent: 'Le formulaire a été envoyé avec succès.',
    },
  },
};
