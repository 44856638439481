export default {
  app: {
    newsletter: {
      bottomText:
        'Ved tilmelding af nyhedsbrevet, accepterer du vores <a href="~/Privacy-Policy" target="_blank">databeskyttelsespolitik</a>.',
      button: 'Abonner',
      ctaDialogParagraph1:
        'Vil du vide mere om kommende begivenheder, eller hvilke seværdighederne du bør opleve i Tjekkiet?',
      ctaDialogParagraph2: '',
      ctaDialogParagraph3:
        'Tilmeld dig vores nyhedsbrev, og du vil modtage mange nyttige tip til din næste rejse!',
      ctaDialogTitle: 'BANK BANK!',
      ctaDialogBtn: 'KLICKE HIER',
      dialog: {
        headline: 'Vælg dit nyhedsbrev Land / region',
        notice:
          'Din e-mail adresse, ovenfor, vil kun blive brugt til abonnement på vores nyhedsbrev, og den vil ikke blive videregivet til andre. Vi registrerer din e-mail adresse til dette formål indtil den dag, du afmelder vores nyhedsbrev. Du kan til enhver tid afmelde dig ved at klikke på linket i meddelelsen, der leverer nyhedsbrevet. Din e-mail adresse behandles muligvis af it-firmaet, der administrerer driften og udviklingen af vores websteder. {0}',
        company: 'Den dataansvarlige er {0}, {1}',
        link: {
          privacy: 'Vores fortrolighedspolitik er tilgængelig her.',
          controller: 'Tjekkisk turistbureau – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Din email adresse',
      newsletterError:
        'Es scheint derzeit keine Newsletter zu geben, die Sie abonnieren können',
      serverError:
        'Beim Ausführen der Anforderung ist ein Fehler aufgetreten. Bitte versuche es erneut',
      success:
        'Bekræft registreringen i e-mailen for at abonnere på nyhedsbrevet',
      title: 'Modtag vores nyhedsbrev',
      topText:
        'Tilmeld dig for at modtage nyheder, tips, kommende begivenheder og nye historiefortællinger.',
      validityError: 'Ihre E-Mail Adresse scheint ungültig zu sein',
    },
  },
};
