export default {
  app: {
    cookies: {
      title: '우리는 귀하의 개인 정보를 존중합니다',
      text:
        '당사는 콘텐츠 개인화, 트래픽 분석, 소셜 네트워크에서 콘텐츠를 공유할 수 있도록 쿠키를 사용합니다. 당사는 사이트를 탐색하는 방법에 대한 정보를 당사의 소셜 네트워크 및 분석 파트너와 공유합니다. 파트너는 방문자가 제공한 정보 또는 서비스 사용 결과에서 얻은 다른 정보와 결합할 수 있습니다. {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: '자세한 내용은 여기에서 확인할 수 있습니다',
      },
      buttons: {
        accept: '모든 쿠키 허용',
        save: '선택 저장',
      },
      settings: {
        button: '세부 설정',
        required: '필수 (웹사이트의 정상적인 기능을 위해)',
        analytics:
          '분석 (방문자가 웹사이트에서 어떻게 행동하는지 또는 웹사이트에서 어떻게 이동하는지 이해하기 위해)',
        statistics: '통계 (방문자가 웹사이트를 사용하는 방식을 이해하기 위해)',
        marketing: '마케팅 (관련 광고를 표시 및 방문자 추적)',
        reject: '모두 거부',
        accept: '모두 허용',
      },
    },
  },
};
