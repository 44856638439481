export default {
  app: {
    cookies: {
      title: 'Mēs respektējam jūsu konfidencialitāti',
      text:
        'Mēs izmantojam sīkfailus, lai personalizētu saturu, analizētu mūsu datplūsmu un nodrošinātu sociālo saziņas līdzekļu funkcijas. Informāciju par to, kā jūs izmantojat mūsu vietni, mēs arī kopīgojam ar saviem sociālās saziņas līdzekļu un analīzes partneriem, kuri to var apvienot ar citu informāciju, ko viņiem sniedzat vai ko viņi apkopo, kad lietojat viņu pakalpojumus. Sīkāku informāciju varat atrast {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'šeit',
      },
      buttons: {
        accept: 'Pieņemt visus sīkfailus',
        save: 'Saglabāt atlasi',
      },
      settings: {
        button: 'Detalizēti iestatījumi',
        required: 'Nepieciešamie (lai vietne pareizi funkcionētu)',
        analytics:
          'Analītika (lai saprastu, kā apmeklētāji rīkojas tīmekļa vietnē vai kā viņi pārvietojas tīmekļa vietnē)',
        statistics:
          'Statistika (palīdz izprast, kā apmeklētāji mijiedarbojas ar vietnēm)',
        marketing:
          'Mārketings (seko līdzi apmeklētājiem, lai parādītu atbilstošas reklāmas)',
        reject: 'Noraidīt visu',
        accept: 'Atļaut visu',
      },
    },
  },
};
