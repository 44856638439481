import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class EventIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--event'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <circle
            cx='2065.9'
            cy='2418'
            r='43.6'
            fill='currentColor'
            transform='translate(-1989.9 -2344.1)'
          />
        </svg>
      </i>
    );
  }
}
