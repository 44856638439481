export default {
  app: {
    common: {
      backToMenu: 'Back to menu',
      blog: 'Blog',
      exploreButton: 'Explore',
      homepageBreadcrumb: 'Home',
      language: 'Language',
      loadMore: 'See more',
      moreInfo: 'More Information',
      opensInNewWindow: 'Opens in a new window',
      pageTypes: {
        article: 'Article',
        category: 'Category',
        event: 'Event',
        page: 'Page',
        place: 'Place',
        region: 'Region',
      },
      selectLanguage: 'Select language',
      source: 'Source',
      translationVoting: 'How do you like this translation?',
      translationVotingSuccess: 'Thank you for your feedback!',
    },
  },
};
