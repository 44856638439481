export default {
  app: {
    common: {
      backToMenu: 'Powrót do menu',
      blog: 'Blog',
      exploreButton: 'Odkryj',
      language: 'Język',
      loadMore: 'Zobacz kolejne',
      moreInfo: 'Więcej informacji',
      opensInNewWindow: 'Otwiera się w nowym oknie',
      pageTypes: {
        article: 'Artykuł',
        category: 'Kategoria',
        event: 'Zdarzenie',
        page: 'Strona',
        place: 'Miejsce',
        region: 'Region',
      },
      selectLanguage: 'Wybierz język',
      homepageBreadcrumb: 'Strona główna',
      translationVoting: 'Jak Ci się podoba to tłumaczenie?',
      translationVotingSuccess: 'Dziękujemy za Twoją opinię!',
    },
  },
};
