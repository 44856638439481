import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class TwFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--taiwan'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33 33'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M32.5 16.5c0 8.8-7.2 16-16 16s-16-7.2-16-16 7.2-16 16-16h1.9v.1c7.9.9 14.1 7.7 14.1 15.9z'
            fill='#fd0302'
          />
          <path d='M18.4.5v16H.5c0-8.8 7.2-16 16-16h1.9z' fill='#060593' />
          <path
            fill='#fff'
            d='M11.3 6.2c-.2 0-.4-.1-.7-.1-.2 0-.5 0-.7.1l.7-2.4.7 2.4z'
          />
          <path fill='#fff' d='M9.7 6.2c-.5.2-.9.4-1.2.7l-.6-2.4 1.8 1.7z' />
          <path fill='#fff' d='M8.3 7.1c-.3.3-.5.7-.7 1.2L5.9 6.5l2.4.6z' />
          <path fill='#fff' d='M7.5 9.2c0 .2 0 .5.1.7l-2.5-.7 2.4-.7v.7z' />
          <path fill='#fff' d='m8.3 11.3-2.4.6 1.8-1.8c.1.5.3.9.6 1.2z' />
          <path fill='#fff' d='M9.7 12.2 7.9 14l.6-2.4c.3.2.7.4 1.2.6z' />
          <path
            fill='#fff'
            d='m11.3 12.2-.7 2.4-.7-2.4c.2 0 .4.1.7.1.2 0 .5 0 .7-.1z'
          />
          <path fill='#fff' d='m13.3 13.9-1.8-1.8c.4-.1.8-.4 1.2-.7l.6 2.5z' />
          <path fill='#fff' d='m15.3 11.9-2.4-.6c.3-.3.5-.7.7-1.2l1.7 1.8z' />
          <path
            fill='#fff'
            d='M13.6 9.9c0-.2.1-.4.1-.7s0-.5-.1-.7l2.4.7-2.4.7z'
          />
          <path fill='#fff' d='m15.3 6.5-1.8 1.8c-.1-.4-.4-.8-.7-1.2l2.5-.6z' />
          <path fill='#fff' d='m13.3 4.5-.6 2.4c-.3-.3-.7-.5-1.2-.7l1.8-1.7z' />
          <circle fill='#fff' cx='10.6' cy='9.2' r='2.8' />
        </svg>
      </i>
    );
  }
}
