export default {
  app: {
    gridList: {
      label: {
        incentive: 'Nach Anreiz filtern',
        itinerary: 'Nach Stichwort filtern',
        region: 'Nach Region filtern',
      },
    },
  },
};
