export default {
  app: {
    weather: {
      title: 'Vejrudsigt',
      now: 'I dag',
      weatherTranslations: {
        clearsky_day: 'Klar himmel',
        clearsky_night: 'Klar himmel',
        clearsky_polartwilight: 'Klar himmel',
        cloudy: 'Skyet',
        fair_day: 'God',
        fair_night: 'God',
        fair_polartwilight: 'God',
        fog: 'Tåget',
        heavyrain: 'Kraftig regn',
        heavyrainandthunder: 'Kraftig regn og blæst',
        heavyrainshowers_day: 'Kraftig regnbyger',
        heavyrainshowers_night: 'Kraftig regnbyger',
        heavyrainshowers_polartwilight: 'Kraftig regnbyger',
        heavyrainshowersandthunder_day: 'Kraftig regnbyger og blæst',
        heavyrainshowersandthunder_night: 'Kraftig regnbyger og blæst',
        heavyrainshowersandthunder_polartwilight: 'Kraftig regnbyger og blæst',
        heavysleet: 'Kraftig slud',
        heavysleetandthunder: 'Kraftig slud og blæst',
        heavysleetshowers_day: 'Kraftige sludbyger',
        heavysleetshowers_night: 'Kraftige sludbyger',
        heavysleetshowers_polartwilight: 'Kraftige sludbyger',
        heavysleetshowersandthunder_day: 'Kraftige sludbyger og blæst',
        heavysleetshowersandthunder_night: 'Kraftige sludbyger og blæst',
        heavysleetshowersandthunder_polartwilight:
          'Kraftige sludbyger og blæst',
        heavysnow: 'Kraftigt snevejr',
        heavysnowandthunder: 'Kraftige snebyger og blæst',
        heavysnowshowers_day: 'Kraftige snebyger',
        heavysnowshowers_night: 'Kraftige snebyger',
        heavysnowshowers_polartwilight: 'Kraftige snebyger',
        heavysnowshowersandthunder_day: 'Kraftige snebyger og blæst',
        heavysnowshowersandthunder_night: 'Kraftige snebyger og blæst',
        heavysnowshowersandthunder_polartwilight: 'Kraftige snebyger og blæst',
        lightrain: 'Let regn',
        lightrainandthunder: 'Let regn og blæst',
        lightrainshowers_day: 'Let regnbyger',
        lightrainshowers_night: 'Let regnbyger',
        lightrainshowers_polartwilight: 'Let regnbyger',
        lightrainshowersandthunder_day: 'Let regnbyger og blæst',
        lightrainshowersandthunder_night: 'Let regnbyger og blæst',
        lightrainshowersandthunder_polartwilight: 'Let regnbyger og blæst',
        lightsleet: 'Let slud',
        lightsleetandthunder: 'Let slud og blæst',
        lightsleetshowers_day: 'Let slud byger',
        lightsleetshowers_night: 'Let slud byger',
        lightsleetshowers_polartwilight: 'Let slud byger',
        lightssleetshowersandthunder_day: 'Let sludbyger og blæst',
        lightssleetshowersandthunder_night: 'Let sludbyger og blæst',
        lightssleetshowersandthunder_polartwilight: 'Let sludbyger og blæst',
        lightsnow: 'Let sne',
        lightsnowandthunder: 'Let sne og blæst',
        lightsnowshowers_day: 'Lette snebyger',
        lightsnowshowers_night: 'Lette snebyger',
        lightsnowshowers_polartwilight: 'Lette snebyger',
        lightssnowshowersandthunder_day: 'Lette snebyger og blæst',
        lightssnowshowersandthunder_night: 'Lette snebyger og blæst',
        lightssnowshowersandthunder_polartwilight: 'Lette snebyger og blæst',
        rain: 'Regn',
        rainandthunder: 'Regn og blæst',
        rainshowers_day: 'Regnbyger',
        rainshowers_night: 'Regnbyger',
        rainshowers_polartwilight: 'Regnbyger',
        rainshowersandthunder_day: 'Regnbyger og blæst',
        rainshowersandthunder_night: 'Regnbyger og blæst',
        rainshowersandthunder_polartwilight: 'Regnbyger og blæst',
        sleet: 'Slud',
        sleetandthunder: 'Slud og blæst',
        sleetshowers_day: 'Sludbyger',
        sleetshowers_night: 'Sludbyger',
        sleetshowers_polartwilight: 'Sludbyger',
        sleetshowersandthunder_day: 'Sludbyger og blæst',
        sleetshowersandthunder_night: 'Sludbyger og blæst',
        sleetshowersandthunder_polartwilight: 'Sludbyger og blæst',
        snow: 'Sne',
        snowandthunder: 'Sne og blæst',
        snowshowers_day: 'Snebyger',
        snowshowers_night: 'Snebyger',
        snowshowers_polartwilight: 'Snebyger',
        snowshowersandthunder_day: 'Snebyger og blæst',
        snowshowersandthunder_night: 'Snebyger og blæst',
        snowshowersandthunder_polartwilight: 'Snebyger og blæst',
        partlycloudy_day: 'Let skyet',
        partlycloudy_night: 'Let skyet',
        partlycloudy_polartwilight: 'Let skyet',
      },
    },
  },
};
