export default {
  app: {
    panorama: {
      loadingText: '画像の読み込み中',
      autorotate: '自動回転',
      zoom: 'ズーム',
      zoomOut: 'ズームアウト',
      zoomIn: 'ズームイン',
      move: '動かす',
      download: 'ダウンロード',
      fullscreen: '全画面表示',
      menu: 'メニュー',
      twoFingers: '2本の指で操作する',
      ctrlZoom: 'Ctrlキーを押しながらスクロールして画像を拡大する',
      loadError: 'パノラマを読み込めません',
    },
  },
};
