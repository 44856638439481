export default {
  app: {
    newsletter: {
      bottomText:
        '通过时事新闻的订阅，您同意我们的<a href="~/Privacy-Policy" target="_blank">数据保护政</a>策',
      button: '訂閱',
      ctaDialogParagraph1: '您想知道布拉格即将举行的活动吗？',
      ctaDialogParagraph2: '不确定在您的捷克共和国之旅中要参观哪个城堡？',
      ctaDialogParagraph3:
        '订阅我们的时事通讯，您将在下次旅行中收到许多有用的提示！',
      ctaDialogTitle: '敲门!',
      ctaDialogBtn: '点击这里',
      dialog: {
        headline: '选择您的电子期刊国家/地区',
        notice:
          '您以上所列的电子邮件仅用于发送我们的简报，不会传递任何其他主题。直到您注销简报的那一天，我们都将以此为目的保留您的电子邮件地址。您可以随时通过点击发送给您的简报中的链接来退出。您的电子邮件地址可由我们网站和网站应用程序的IT公司来处理。 {0}',
        company: '个人数据管理员是 {0}, {1}',
        link: {
          privacy: '我们的隐私政策可以在这里找到。',
          controller: '捷克旅游局– CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: '您的电子邮件地址',
      newsletterError: '您似乎现在没有要订阅的新闻通讯',
      serverError: '执行请求时出错。 请再试一次',
      success:
        '为了成功接收电子期刊，请在您收到的邮件中进行确认以完成注册。 請前往您的信箱確認訂閱我們的電子報。',
      title: '接收我们的免费电子邮件',
      topText: '注册以接收最新信息、提示、即将发生的活动事件和要讲述的新故事',
      validityError: '您的电子邮件地址似乎无效',
    },
  },
};
