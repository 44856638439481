import { Component, Prop } from 'vue-property-decorator';

import style from './IconBlock.scss';
import { GridItem } from '~/components/organisms/gridList/types';
import { VueComponent } from '~/utils/vue-component';
import { Link } from '~/components/molecules';
import {
  getCDNSrc,
  getSrcSet,
  getWebpSrc,
} from '~/components/atoms/image/Image';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';

interface IconBlockProps {
  item: GridItem;
}

const rootClass = 'czt-grid-list__item';

@Component({
  style,
})
export default class BrochureGridItem extends VueComponent<IconBlockProps>
  implements IconBlockProps {
  @Prop({ required: true })
  public item!: GridItem;

  protected get src(): string {
    return getCDNSrc(getWebpSrc(this.item.image.src));
  }

  public render() {
    const content = [
      this.src && (
        <v-img
          class={`${rootClass}__image`}
          src={this.src}
          alt={this.item.title}
          aspect-ratio={1}
          srcset={getSrcSet(this.src)}
          contain
        />
      ),
      <Headline class={`${rootClass}__title`} align={Align.LEFT} level={4}>
        {this.item.title}
      </Headline>,
      <p class={`${rootClass}__description czt-description`}>
        {this.item.perex}
      </p>,
    ];

    return (
      <v-col
        class={`${rootClass} ${rootClass}--icon-block`}
        cols={12}
        sm={6}
        md={4}
        lg={3}
      >
        {this.item.link ? (
          <Link url={this.item.link} forcedLocale={this.item.forcedLocale}>
            {content}
          </Link>
        ) : (
          content
        )}
      </v-col>
    );
  }
}
