export default {
  app: {
    safeTravels: {
      search: 'Find anlæg',
      categories: 'Kategori',
      button: 'Søg',
      table: {
        name: 'Navn',
        link: 'Webside',
      },
      categoryTranslations: {
        eventy: 'Begivenhed',
        kongresy_a_veletrhy: 'Kongresser og messer',
        restaurace: 'Restaurante',
        ubytovací_zařízení: 'Indkvartering',
        sauny: 'Sauna',
        přírodní_a_umělá_koupaliště: 'Svømmepøl',
        lanová_doprava: 'Svævebane',
        maloobchod: 'Butik',
        fitness_centra: 'Gym',
        kina: 'Biograf',
        golfové_areály: 'Golf',
      },
    },
  },
};
