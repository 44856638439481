export default {
  app: {
    search: {
      button: 'Atrast',
      noResults: 'Nekas nav atrasts',
      title: 'Meklēt',
      widgetTitle: 'Atrodi, ko darīt valstī',
    },
  },
};
