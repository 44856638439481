export default {
  app: {
    url: {
      controller: 'https://www.visitczechia.com/de-DE/Contacts',
      facebook: 'https://www.facebook.com/czechrepublic.de',
      instagram: 'https://www.instagram.com/visitczechrepublic_de/',
      privacy: '~/Privacy-Policy',
      twitter: 'https://twitter.com/VisitCZ',
      web: 'https://www.visitczechia.com/',
      youtube: 'https://www.youtube.com/visitczech',
    },
  },
};
