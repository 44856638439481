import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class DeFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--germany'>
        <svg
          viewBox='0 0 33 33'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g fill-rule='nonzero'>
            <path
              d='M16.5 32.022c6.456 0 12.011-3.826 14.544-9.334H1.957c2.525 5.508 8.087 9.334 14.543 9.334z'
              fill='#fcd904'
            />
            <path
              d='M16.5.021C10.044.021 4.482 3.85 1.957 9.355h29.087C28.51 3.849 22.956.02 16.5.02z'
              fill='#030404'
            />
            <path
              d='M.5 16.022c0 2.38.52 4.636 1.457 6.666h29.087a15.975 15.975 0 000-13.333H1.957A15.861 15.861 0 00.5 16.022z'
              fill='#ed1f27'
            />
          </g>
        </svg>
      </i>
    );
  }
}
