export default {
  app: {
    maps: {
      more: 'Öppna detaljerad karta',
      style: {
        tooltip: 'Karta stil',
        basic: 'Grundläggande',
        outdoor: 'Turist',
        aerial: 'Flygfoto',
        winter: 'Vinter',
      },
      loadPoI: 'Lokal sökning',
      currentPosition: 'Nuvarande ställning',
      categories: 'Kategorier',
      location: 'Plats',
    },
  },
};
