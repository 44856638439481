import { CztWidgets } from '~/utils/views/widgets';
import { MapWidgetProps } from './types';

export default function(data: any): data is MapWidgetProps {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.MAP &&
    typeof data.id === 'string'
  );
}
