export default {
  app: {
    common: {
      homepageBreadcrumb: 'Domov',
      language: 'Jazyk',
      loadMore: 'Pozrieť viac',
      moreInfo: 'Viac informácií',
      translationVoting: 'Ako sa Vám páči tento preklad?',
      translationVotingSuccess: 'Ďakujeme za Vašu spätnú väzbu.',
    },
  },
};
