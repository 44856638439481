export default {
  app: {
    gridList: {
      label: {
        incentive: 'インセンティブでフィルター',
        itinerary: 'タグでフィルター',
        region: '地域でフィルター',
      },
    },
  },
};
