export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtrer etter insentiv',
        itinerary: 'Filtrer etter tagg',
        region: 'Filtrer etter region',
      },
    },
  },
};
