import { Component, Prop } from 'vue-property-decorator';
import VClamp from 'vue-clamp';

import style from './Classic.scss';
import ImageTile from '~/components/molecules/imageTile/ImageTile';
import { GridItem } from '~/components/organisms/gridList/types';
import { VueComponent } from '~/utils/vue-component';
import { format } from '~/utils/date-fns';
import { parse } from 'date-fns';
import { IconText } from '..';
import { pickDateFormat, shortMonthDateFormat } from '~/utils/dateTime';
import Icon, { isIconName } from '~/components/atoms/icons/Icon';
import { LinkTarget } from '~/utils/molecules';

interface ClassicGridItemInterface {
  item: GridItem;
  perex?: string;
  compact?: boolean;
  target?: LinkTarget;
}

const rootClass = 'czt-grid-list__item';

@Component({
  components: {
    VClamp,
  },
  style,
})
export default class ClassicGridItem
  extends VueComponent<ClassicGridItemInterface>
  implements ClassicGridItemInterface {
  @Prop({ required: true })
  public item!: GridItem;

  @Prop({ type: Boolean, default: false })
  public compact!: boolean;

  @Prop({ type: String })
  public target?: LinkTarget;

  @Prop({ type: String })
  public perex?: string;

  protected get flag(): JSX.Element | undefined {
    if (
      !this.item.showFlag ||
      !this.item.flag ||
      this.item.flag.trim() === ''
    ) {
      return;
    }
    const iconName = ['flags', this.item.flag];
    return isIconName(iconName) ? (
      <div class={`${rootClass}__flag`}>
        <Icon name={iconName} size={this.compact ? 0.5 : 0.75} />
      </div>
    ) : (
      undefined
    );
  }

  protected get dateText(): JSX.Element | null {
    const dateArray: JSX.Element[] = [];
    if (this.$slots.default) {
      dateArray.push(...this.$slots.default);
    }
    const defaultFormat: string = pickDateFormat(this.$i18n.locale);
    if (this.item.date && this.item.endDate) {
      let start = format(
        parse(this.item.date),
        defaultFormat,
        this.$i18n.locale
      );
      let end = format(
        parse(this.item.endDate),
        defaultFormat,
        this.$i18n.locale
      );
      if (start !== end) {
        const shortFormat: string = pickDateFormat(
          this.$i18n.locale,
          shortMonthDateFormat
        );
        start = format(parse(this.item.date), shortFormat, this.$i18n.locale);
        end = format(parse(this.item.endDate), shortFormat, this.$i18n.locale);
        dateArray.push(<time datetime={this.item.date}>{start}</time>);
        dateArray.push(
          <span> - </span>,
          <time datetime={this.item.endDate}>{end}</time>
        );
      } else {
        dateArray.push(<time datetime={this.item.date}>{start}</time>);
      }
    } else if (this.item.date) {
      dateArray.push(
        <time datetime={this.item.date}>
          {format(parse(this.item.date), defaultFormat, this.$i18n.locale)}
        </time>
      );
    }

    return dateArray.length > 0 ? (
      <div class={`${rootClass}__date`}>{dateArray}</div>
    ) : null;
  }

  public render() {
    return (
      <v-hover
        scopedSlots={{
          default: ({ hover }: { hover: boolean }) => {
            const classes = [rootClass, `${rootClass}--classic`];

            if (hover) {
              classes.push(`${rootClass}--hover`);
            }

            // If grid list item width should ever be changed, sizes in ImageTile.tsx need to be adjusted too

            return (
              <v-col
                class={classes.join(' ')}
                cols={6}
                md={this.compact ? 3 : 4}
              >
                <ImageTile
                  image={this.item.image}
                  imageFilter={this.item.imageFilter}
                  link={this.item.link}
                  forcedLocale={this.item.forcedLocale}
                  target={this.target}
                >
                  {this.flag}
                  {this.item.location && this.item.location.regionName && (
                    <div class={`${rootClass}__location`}>
                      <IconText icon={['common', 'Location']}>
                        {this.item.location.regionName}
                      </IconText>
                    </div>
                  )}
                  <v-fade-transition>
                    {(() => {
                      if (!hover || !this.perex) {
                        return (
                          <v-clamp tag='span' autoresize={true} max-lines={5}>
                            {this.item.title}
                          </v-clamp>
                        );
                      }
                    })()}
                  </v-fade-transition>
                  {this.dateText}
                </ImageTile>
                <v-fade-transition>
                  {hover && this.perex && (
                    <v-overlay absolute class={`${rootClass}__overlay`}>
                      <v-clamp
                        tag='p'
                        autoresize={true}
                        max-lines={5}
                        class={rootClass + '__overlay__content'}
                      >
                        {this.perex
                          .replace(/\s*&nbsp;\s*/g, '\u00A0')
                          .replace(/&#39;/g, "'")}
                      </v-clamp>
                    </v-overlay>
                  )}
                </v-fade-transition>
              </v-col>
            );
          },
        }}
      />
    );
  }
}
