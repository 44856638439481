import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class SpaIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--spa'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M17.6 78.1V41.6a26 26 0 0 1 52 0v48.8h13.6V40.9A40.2 40.2 0 0 0 64.4 8 39.5 39.5 0 0 0 4.1 41.6v36.6h13.5ZM88.5 25.8a26 26 0 0 1 46.7 15.8V78h13.5V41.6a39.5 39.5 0 0 0-67-28.4c3 3.8 5.2 8 6.8 12.6M147 98.6H5.3a76.5 76.5 0 0 0 141.9 0Z'
          />
        </svg>
      </i>
    );
  }
}
