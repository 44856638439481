export default {
  app: {
    user: {
      button: {
        logIn: 'Anmelden',
        logOut: 'Abmelden',
      },
      error: {
        username: 'Benutzername muss festgelegt werden',
        password: 'Passwort muss festgelegt werden',
        undefined: 'Anmeldung fehlgeschlagen',
      },
      title: 'Anmelden',
      placeholder: {
        user: 'Benutzername',
        pass: 'Passwort',
      },
      loggedIn: 'Sie sind angemeldet als {0}',
      admin: 'Administration',
    },
  },
};
