export default {
  app: {
    winter: {
      snowReport: 'Bollettino neve',
      snow: '{snow} cm di {type}',
      snowTypeTranslation: {
        1: '',
        2: 'neve farinosa',
        3: 'neve bagnata',
        4: 'neve ghiacciata',
        5: 'neve firn',
        6: 'neve artificiale',
        7: 'neve mista acqua',
      },
      lifts: 'Impianti di risalita',
      open: 'aperto',
      slopes: 'Piste da sci',
      webcam: 'Webcam',
    },
  },
};
