import { Component, Prop } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { IconText } from '~/components/molecules';
import { VueComponent } from '~/utils/vue-component';

import { mdiCar, mdiTrainBus } from '~/utils/iconPaths';
import { TranslateResult } from 'vue-i18n';

import style from './TransportInfo.scss';

export interface TransportInfoProps {
  publicTransportTime?: string;
  carTime?: string;
  carDistance?: string;
  title?: string | TranslateResult;
}

const rootClass = 'czt-transport-info';

@Component({ style })
export default class TransportInfo extends VueComponent<TransportInfoProps>
  implements TransportInfoProps {
  @Prop()
  public carDistance?: string;

  @Prop()
  public carTime?: string;

  @Prop()
  public publicTransportTime?: string;

  @Prop()
  public title?: string;

  public render() {
    if (!this.publicTransportTime && !this.carDistance && !this.carTime) {
      return;
    }

    return (
      <div class={rootClass}>
        {this.title && (
          <Headline align={Align.LEFT} level={3} underscore>
            {this.title}
          </Headline>
        )}
        {(this.carTime || this.carDistance) && (
          <div class={`${rootClass}__row`}>
            <IconText iconMargin='5px' iconSize={2} icon={mdiCar}>
              <v-row noGutters>
                <v-col cols={12}>
                  <Headline align={Align.LEFT} level={5}>
                    {this.$t('app.itinerary.car')}
                  </Headline>
                </v-col>
                <v-col cols={12}>
                  {[this.carTime, this.carDistance].join(' | ')}
                </v-col>
              </v-row>
            </IconText>
          </div>
        )}
        {this.publicTransportTime && (
          <div class={`${rootClass}__row`}>
            <IconText iconMargin='5px' iconSize={2} icon={mdiTrainBus}>
              <v-row noGutters>
                <v-col cols={12}>
                  <Headline align={Align.LEFT} level={5}>
                    {this.$t('app.itinerary.publicTransport')}
                  </Headline>
                </v-col>
                <v-col cols={12}>{this.publicTransportTime}</v-col>
              </v-row>
            </IconText>
          </div>
        )}
      </div>
    );
  }
}
