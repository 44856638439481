export default {
  app: {
    user: {
      button: {
        logIn: 'Iniciar sesión',
        logOut: 'Finalizar sesión',
      },
      error: {
        username: 'El nombre de usuario no puede quedar vacío',
        password: 'La contraseña no puede quedar vacía',
        undefined: 'Acceso fallido',
      },
      title: 'Iniciar sesión',
      placeholder: {
        user: 'Nombre de usuario',
        pass: 'Contraseña',
      },
      loggedIn: 'Estás conectado como {0}',
      admin: 'Administración',
    },
  },
};
