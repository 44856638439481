import { Component } from 'vue-property-decorator';

import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import NotFound from '~/components/templates/common/NotFound';
import PageLoader from '~/components/templates/common/PageLoader';
import { OneOfThePages } from '~/utils/views';
import createSeoTags, { createJsonLd, TYPE } from '~/utils/views/createSeoTags';
import { getPathWithLocale, getUrlFromPath } from '~/app/core/router';
import { CztWidgets, Widget } from '~/utils/views/widgets';
import { isNewsletterSupportedLocale } from '~/app/localization';
import NavigationModule from '~/app/core/store/modules/NavigationModule';
import { Banner } from '~/components/organisms';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { Headline } from '~/components/atoms';
import TopLayerImage from '~/components/templates/common/TopLayerImage';
import Button, { Style } from '~/components/atoms/button/Button';
import { NewsletterFormInterface } from '~/components/templates/common/newsletterForm/types';
import { isHtmlWidget } from '~/app/core/apiClient';
import Redirect from '~/utils/http/Redirect';
import { anchorPreposition, createAnchorId } from '~/utils/anchor';

@Component
export default class Homepage extends VueComponentMixin<{}, PrefetchComponent>(
  Prefetch,
  HeadManagement
) {
  public get found(): boolean {
    return getModule(RouterModule, this.$store).found;
  }

  public get loading(): boolean {
    return getModule(RouterModule, this.$store).loading;
  }

  public get pageData(): OneOfThePages | null {
    return getModule(RouterModule, this.$store).resource;
  }

  protected get widgets(): Widget[] {
    if (!this.pageData) {
      return [];
    }

    const widgets = [...this.pageData.widgets.main];

    if (this.pageData.firstWidgetAsHero && widgets.length > 0) {
      widgets.shift();
    }

    if (isNewsletterSupportedLocale(this.$i18n.locale)) {
      const newsletterWidget: NewsletterFormInterface = {
        className: CztWidgets.NEWSLETTER,
        guid: 'unknown',
      };

      widgets.push(newsletterWidget);
    }

    return widgets;
  }

  protected get firstWidget(): Widget | null {
    if (!this.pageData) {
      return null;
    }
    const widgets = [...this.pageData.widgets.main];
    return this.pageData.firstWidgetAsHero && widgets.length > 0
      ? widgets.shift() || null
      : null;
  }

  public title() {
    return this.pageData?.metadata.title;
  }

  public headTags(): string {
    return createSeoTags({
      title: this.pageData?.metadata.title || null,
      description: this.pageData?.metadata.description || '',
      canonicalUrl: getUrlFromPath('', this.$router),
      type: TYPE.WEBSITE,
      image: this.pageData?.image,
      imagesToPreload: this.pageData?.imagesToPreload,
      path: this.pageData?.canonicalPath || '/',
      mutations: this.pageData?.availableCultures,
    });
    // TODO: Add structured data for homepage
    // +
    // createJsonLd({
    //   image: this.pageData?.imagesToPreload || [],
    //   title: null,
    //   created: this.pageData?.created,
    //   modified: this.pageData?.modified,
    // })
  }

  public prefetch() {
    const routerModule = getModule(RouterModule, this.$store);
    const locale = this.$i18n.locale;
    return routerModule
      .loadResourceByPath({
        guid: 'Homepage',
        locale,
        query: this.$route.query || undefined,
        withNav: this.$isServer,
      })
      .then(() => {
        if (!this.$isServer) {
          return getModule(NavigationModule, this.$store).load({
            path:
              (routerModule.resource && routerModule.resource.navigation) ||
              undefined,
            locale,
            query: this.$route.query,
          });
        }
        return Promise.resolve();
      });
  }

  public render() {
    if (this.loading) {
      return <PageLoader />;
    }

    if (!this.found || this.pageData === null) {
      return <NotFound />;
    }

    if (
      this.$isServer &&
      this.$router.currentRoute.path !== getPathWithLocale('', this.$router)
    ) {
      return (
        <Redirect
          statusCode={303}
          location={getUrlFromPath('', this.$router, true)}
        />
      );
    }

    return (
      <v-container fluid class='czt-homepage pa-0' key={this.pageData.guid}>
        {this.renderHero()}
        {this.renderWidgets()}
      </v-container>
    );
  }

  protected renderWidgets() {
    if (this.widgets.length < 1) {
      return;
    }
    if (isHtmlWidget(this.firstWidget)) {
      const renderers = [];
      const w = [...this.widgets];
      const fw = w.shift();
      if (fw) {
        renderers.push(<WidgetRenderer widgets={[fw]} isFirst />);
      }
      if (w.length > 0) {
        renderers.push(
          <WidgetRenderer id={`${anchorPreposition}content`} widgets={w} />
        );
      }
      return renderers;
    }
    return (
      <WidgetRenderer
        id={`${anchorPreposition}content`}
        widgets={this.widgets}
      />
    );
  }

  protected renderHero(): JSX.Element | undefined {
    if (!this.pageData) {
      return;
    }
    let events = {};
    if (this.pageData.anchor && !this.pageData.anchorTitle) {
      events = {
        on: {
          click: this.anchorClick,
        },
      };
    }
    return this.firstWidget ? (
      <WidgetRenderer widgets={[this.firstWidget]} isFirst />
    ) : (
      <Banner
        backgroundImage={{
          src: this.pageData.image ? this.pageData.image : imageHeroDefault,
          alt: `${this.pageData.title}`,
        }}
        isFirst
        imageFilter={this.pageData.imageFilter}
        imageSource={this.pageData.imageSource}
        clickable={!!this.pageData.anchor && !this.pageData.anchorTitle}
        {...events}
        title={`${this.pageData.title}`}
        titleLevel={1}
        titlePosition={`${this.pageData.titlePosition}`}
      >
        <Headline level={3} light>
          {this.pageData.subtitle}
        </Headline>
        {(() => {
          if (this.pageData.anchor && this.pageData.anchorTitle) {
            return (
              <Button
                class='my-3'
                buttonStyle={Style.PRIMARY_LIGHT}
                onClick={this.anchorClick}
              >
                {this.pageData.anchorTitle}
              </Button>
            );
          }
        })()}
        <TopLayerImage pageData={this.pageData} />
      </Banner>
    );
  }

  protected anchorClick() {
    if (!this.pageData || !this.pageData.anchor) {
      return;
    }
    const anchorId =
      this.pageData.anchorCustom || this.pageData.anchor || 'content';
    const anchor = document.querySelector(
      createAnchorId(anchorId, !this.pageData.anchorCustom)
    );
    if (anchor && anchor instanceof HTMLElement) {
      this.$vuetify.goTo(anchor);
    } else {
      this.$vuetify.goTo(createAnchorId('content'));
    }
  }
}
