import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class GbFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--gb'>
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <g fill-rule='nonzero'>
            <path
              d='M1.22 9.84c-.182.437-.346.89-.489 1.342h2.504L1.22 9.839zM30.663 22.116c.185-.445.353-.904.498-1.364h-2.545l2.047 1.364zM4.63 27.2a15.952 15.952 0 006.532 3.983v-8.337L4.629 27.2zM11.162.75a16.006 16.006 0 00-6.549 3.998l6.549 4.37V.75zM31.161 11.182a16.21 16.21 0 00-.49-1.343l-2.013 1.343h2.503zM20.73 31.183a15.947 15.947 0 006.532-3.983l-6.532-4.354v8.337zM.731 20.752c.145.46.312.92.498 1.364l2.047-1.364H.731zM27.28 4.748A16.008 16.008 0 0020.73.75v8.367l6.55-4.37z'
              fill='#273376'
            />
            <path
              d='M31.643 18.76H18.737v12.905a16.463 16.463 0 001.993-.482v-8.337l6.532 4.354a15.753 15.753 0 001.985-2.443l-6.026-4.005h3.787l3.284 2.181c.13-.27.254-.54.371-.817l-2.047-1.364h2.545c.204-.65.363-1.313.482-1.991zM11.162.75v8.367l-6.549-4.37a16.13 16.13 0 00-1.936 2.379l6.101 4.056H4.991L1.628 8.949c-.145.292-.28.591-.407.89l2.014 1.343H.73C.527 11.83.37 12.495.248 13.18h12.908V.27c-.68.117-1.347.277-1.994.481zM.248 18.76c.121.679.279 1.343.483 1.992h2.545L1.23 22.116a15.88 15.88 0 001.416 2.648l6.031-4.012h2.486v.86L3.92 26.436c.23.262.464.517.71.765l6.532-4.354v8.337c.647.205 1.313.358 1.994.482V18.76H.248zM31.161 11.182h-2.503l2.013-1.343c-.4-.955-.891-1.874-1.464-2.728l-6.12 4.07H20.73v-.948l7.195-4.785c-.21-.24-.423-.474-.646-.7l-6.549 4.37V.75A15.2 15.2 0 0018.737.27V13.18h12.906c-.12-.685-.278-1.35-.482-1.998z'
              fill='#fff'
            />
            <path
              d='M31.643 13.18H18.737V.27c-.907-.16-1.84-.248-2.79-.248-.954 0-1.886.087-2.791.248V13.18H.248C.088 14.085 0 15.018 0 15.967c0 .955.088 1.882.248 2.794h12.908v12.904c.905.16 1.837.248 2.79.248.951 0 1.884-.088 2.791-.248V18.76h12.906c.162-.912.25-1.839.25-2.794 0-.949-.088-1.882-.25-2.787z'
              fill='#d1202d'
            />
            <path
              d='M23.221 20.752l6.026 4.005c.387-.584.736-1.19 1.045-1.824l-3.284-2.181h-3.787zM20.73 11.182h2.356l6.121-4.07a15.955 15.955 0 00-1.282-1.664l-7.195 4.785v.949zM11.162 20.752H8.676l-6.03 4.012c.387.584.813 1.138 1.274 1.67l7.242-4.821v-.861zM8.778 11.182L2.677 7.126a15.754 15.754 0 00-1.05 1.823l3.364 2.233h3.787z'
              fill='#d1202d'
            />
          </g>
        </svg>
      </i>
    );
  }
}
