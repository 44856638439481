export default {
  app: {
    safeTravels: {
      search: 'Einrichtung finden',
      categories: 'Kategorie auswählen',
      button: 'Suchen',
      table: {
        name: 'Name der Einrichtung',
        link: 'Webseiten',
      },
      categoryTranslations: {
        eventy: 'Veranstaltungen',
        kongresy_a_veletrhy: 'Kongresse und Messen',
        restaurace: 'Restaurant',
        ubytovací_zařízení: 'Unterkunftsausstattung',
        sauny: 'Saunen',
        přírodní_a_umělá_koupaliště: 'Natur- und Freibäder',
        lanová_doprava: 'Seilbahnen',
        maloobchod: 'Einzelhandel',
        fitness_centra: 'Fitnesscenter',
        kina: 'Kinos',
        golfové_areály: 'Golfplätze',
      },
    },
  },
};
