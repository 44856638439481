import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import ImageSource from '../templates/common/ImageSource';

import style from './ImageSourceWrapper.scss';

const rootClass = 'czt-image-source-wrapper';

export interface ImageSourceWrapperProps {
  imageSource: string;
}

/**
 * This component should never be used outside of the HTML Renderer.
 */
@Component({ style })
export default class ImageSourceWrapper extends VueComponent<
  ImageSourceWrapperProps
> {
  @Prop()
  public imageSource!: string;

  public render() {
    return (
      <div class={rootClass}>
        {this.$slots.default}
        <ImageSource imageSource={this.imageSource} />
      </div>
    );
  }
}
