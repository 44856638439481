export default {
  app: {
    user: {
      button: {
        logIn: 'Prisijungti',
        logOut: 'Atsijungti',
      },
      error: {
        username: 'Vartotojo pavadinimas negali būti tuščias',
        password: 'Slaptažodis negali būti tuščias',
        undefined: 'Prisijungimas nepavyko',
      },
      title: 'Prisijungti',
      placeholder: {
        user: 'Vartotojo pavadinimas',
        pass: 'Slaptažodis',
      },
      loggedIn: 'Jūs prisijungę kaip {0}',
      admin: 'Administravimas',
    },
  },
};
