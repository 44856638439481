export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Estoy de acuerdo con {0}',
          link: 'procesamiento de datos personales.',
          error: 'Debe aceptar el procesamiento de datos personales.',
        },
      },
      send: 'Enviar',
      sent: 'El formulario fue enviado con éxito.',
    },
  },
};
