export default {
  app: {
    safeTravels: {
      search: 'Encontrar lugar',
      categories: 'Categoria',
      button: 'Procurar',
      table: {
        name: 'Nome do estabelecimento',
        link: 'Website',
      },
      categoryTranslations: {
        eventy: 'Evento',
        kongresy_a_veletrhy: 'Salas de congressos',
        restaurace: 'Restaurante',
        ubytovací_zařízení: 'Alojamento',
        sauny: 'Sauna',
        přírodní_a_umělá_koupaliště: 'Piscina',
        lanová_doprava: 'Funiculares',
        maloobchod: 'Varejo',
        fitness_centra: 'Fitness center',
        kina: 'Cinema',
        golfové_areály: 'Golfe',
      },
    },
  },
};
