import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class SkFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--slovakia'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33 33'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            fill='#fff'
            d='M31.6 11.2H1.4C3.6 5 9.5.5 16.5.5S29.4 5 31.6 11.2z'
          />
          <path
            fill='#ed1c24'
            d='M31.6 21.8c-2.2 6.2-8.1 10.7-15.1 10.7S3.6 28 1.4 21.8h30.2z'
          />
          <path
            fill='#0f4ea0'
            d='M32.5 16.5c0 1.9-.3 3.7-.9 5.3H1.4a15.95 15.95 0 0 1 0-10.6h30.2c.6 1.6.9 3.4.9 5.3z'
          />
          <path
            fill='#fff'
            d='M19.7 13.7V13l-.1-1.8-.1-3.3H6l-.1 3.3-.1 1.8v.7c-.1 2.3-.3 5.3 2.3 8.1l.4.4.3.3c1 .9 2.1 1.6 3.3 2.2l.7.4.7-.4c1.2-.6 2.3-1.4 3.3-2.2l.3-.3.4-.4c2.4-2.7 2.3-5.8 2.2-8.1zm-1.8 6.5c-.3.6-.8 1.1-1.3 1.6l-.3.2c-1 .8-2 1.6-3.2 2.2l-.4.2-.4-.2c-1.1-.6-2.2-1.3-3.2-2.2l-.3-.2c-.5-.5-1-1.1-1.3-1.6C6 17.6 6.4 15 6.4 13l.1-1.8.1-2.7h12.3l.1 2.7V13c.1 2 .5 4.6-1.1 7.2z'
          />
          <path
            fill='#ed1c24'
            d='M19 13c.1 2 .4 4.6-1.1 7.2-1.4-2.2-2.6-.8-3.1-.4-.5-1-1.1-1.5-1.5-1.7v-2.6c1.1 0 2.2.1 3.3.4V14c-1.1.2-2.2.3-3.3.4v-1.7c.9 0 1.8.2 2.6.4v-1.9c-.8.2-1.7.3-2.6.4 0-.8.1-1.6.3-2.4h-1.7l.3 2.4c-.9 0-1.7-.2-2.6-.4v1.9c.9-.2 1.7-.3 2.6-.4v1.7c-1.1 0-2.2-.2-3.3-.4v2c1.1-.2 2.2-.3 3.3-.4v2.6c-.4.2-.9.6-1.5 1.7-.5-.5-1.7-1.9-3.1.3C6 17.6 6.4 15 6.4 13l.1-4.5h12.4L19 13z'
          />
          <path
            fill='#fff'
            d='M16.6 14.1V16c-1.1-.2-2.2-.3-3.3-.4v2.6c-.2-.1-.4-.1-.5-.1h-.2c-.1 0-.3 0-.5.1v-2.6c-1.1 0-2.2.1-3.3.4v-1.9c1.1.2 2.2.3 3.3.4v-1.8c-.9 0-1.8.2-2.6.4v-1.9c.8.2 1.7.3 2.6.4 0-.8-.1-1.6-.3-2.4h1.7l-.3 2.4c.9 0 1.7-.2 2.6-.4v1.9c-.9-.2-1.7-.3-2.6-.4v1.7c1.2 0 2.3-.1 3.4-.3z'
          />
          <path
            fill='#0f4ea0'
            d='M14.8 19.8c-.5-1-1.1-1.5-1.5-1.7-.2-.1-.4-.1-.5-.1h-.2c-.1 0-.3 0-.5.1-.4.2-.9.6-1.5 1.7-.4-.4-1.6-1.8-3 .4.3.6.8 1.1 1.3 1.6l.3.2c1 .8 2 1.6 3.2 2.2l.4.2.4-.2c1.1-.6 2.2-1.3 3.2-2.2l.3-.2c.5-.5 1-1.1 1.3-1.6-1.5-2.2-2.7-.8-3.2-.4z'
          />
        </svg>
      </i>
    );
  }
}
