export default {
  app: {
    url: {
      blog: 'https://www.visitczechia.com/en-US/blog',
      controller: 'https://www.visitczechia.com/en-US/Contacts',
      facebook: 'https://www.facebook.com/czechrepublic',
      instagram: 'https://www.instagram.com/visitcz/',
      privacy: '~/Privacy-Policy',
      twitter: 'https://twitter.com/VisitCZ',
      web: 'https://www.visitczechia.com/',
      youtube: 'https://www.youtube.com/visitczech',
    },
  },
};
