import { Component, Vue, Watch } from 'vue-property-decorator';
import { callPrefetch, PrefetchComponent } from './index';
import { Route } from 'vue-router';

@Component
export default class ClientPrefetch extends Vue implements PrefetchComponent {
  public loadingPrefetchData = false;

  protected prefetchRouteIsGoingToUpdate: boolean = false;

  public mounted() {
    return callPrefetch(this).finally(() => {
      this.loadingPrefetchData = false;
    });
  }

  public beforeRouteUpdate(to: Route, from: Route, next: () => any) {
    this.prefetchRouteIsGoingToUpdate = true;
    this.loadingPrefetchData = true;
    next();
  }

  public beforeRouteLeave(to: Route, from: Route, next: () => any) {
    this.prefetchRouteIsGoingToUpdate = true;
    this.loadingPrefetchData = true;
    next();
  }

  @Watch('$route')
  protected routeChanged() {
    if (this.prefetchRouteIsGoingToUpdate) {
      this.prefetchRouteIsGoingToUpdate = false;

      callPrefetch(this).finally(() => {
        this.loadingPrefetchData = false;
      });
    }
  }
}
