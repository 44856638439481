export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: '我同意 {0}',
          link: '個人數據處理。',
          error: '您必須同意處理個人數據。',
        },
      },
      send: '發送',
      sent: '表格已成功發送。',
    },
  },
};
