import { Component, Prop } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { mdiMapMarkerOutline } from '~/utils/iconPaths';
import { VueComponent } from '~/utils/vue-component';
import ItineraryPlanStop, { PlanStop } from './ItineraryPlanStop';
import { Colors } from '~/utils/theme/colors';

import style from './ItineraryPlanDay.scss';
import { Align } from '~/components/atoms/headline/Headline';

export interface PlanDay {
  title?: string;
  stops: PlanStop[];
}

interface ItineraryPlanDayProps {
  planDay: PlanDay;
}

const rootClass = 'czt-itinerary-plan-day';

@Component({ style })
export default class ItineraryPlanDay
  extends VueComponent<ItineraryPlanDayProps>
  implements ItineraryPlanDayProps {
  @Prop()
  public planDay!: PlanDay;

  public render() {
    return (
      <div class={rootClass}>
        {this.planDay.title && (
          <Headline level={3} align={Align.LEFT}>
            {this.planDay.title}
          </Headline>
        )}
        <v-expansion-panels class={`${rootClass}__accordion`} focusable>
          <v-timeline dense align-top>
            {this.planDay.stops.map((stop, i) => (
              <v-timeline-item
                fill-dot
                color={Colors.PRIMARY}
                key={stop.title + i}
              >
                <v-icon slot='icon' color={Colors.TEXT_LIGHT}>
                  {mdiMapMarkerOutline}
                </v-icon>
                <ItineraryPlanStop planStop={stop} />
              </v-timeline-item>
            ))}
          </v-timeline>
        </v-expansion-panels>
      </div>
    );
  }
}
