export default {
  app: {
    safeTravels: {
      search: '名称',
      categories: '类别',
      button: '搜索',
      table: {
        name: '名称',
        link: '网站',
      },
      categoryTranslations: {
        eventy: '活动',
        kongresy_a_veletrhy: '会展',
        restaurace: '餐厅',
        ubytovací_zařízení: '住宿',
        sauny: '桑拿',
        přírodní_a_umělá_koupaliště: '游泳池',
        lanová_doprava: '缆车',
        maloobchod: '商店',
        fitness_centra: '健身',
        kina: '电影院',
        golfové_areály: '高尔夫',
      },
    },
  },
};
