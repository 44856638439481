export default {
  app: {
    common: {
      backToMenu: 'Tillbaka till meny',
      blog: 'Blogg',
      exploreButton: 'Utforska',
      homepageBreadcrumb: 'Hemsida',
      language: 'Språk',
      loadMore: 'Läs mer',
      moreInfo: 'Mer information',
      opensInNewWindow: 'Öppnas i nytt fönster',
      pageTypes: {
        article: 'Artikel',
        category: 'Kategori',
        event: 'Händelse',
        page: 'Sida',
        place: 'Plats',
        region: 'Område',
      },
      selectLanguage: 'Välj språk',
      translationVoting: 'Vad tycker du om den här översättningen?',
      translationVotingSuccess: 'Tack för din feedback!',
    },
  },
};
