export default {
  app: {
    maps: {
      more: 'Åbn Detaljeret kort',
      style: {
        tooltip: 'Kort stil',
        basic: 'Grundlæggende',
        outdoor: 'Turist',
        aerial: 'Luftfoto',
        winter: 'Vinter',
      },
      loadPoI: 'Lokal søgning',
      currentPosition: 'Nuværende stilling',
      categories: 'Kategorier',
      location: 'Placering',
    },
  },
};
