export default {
  app: {
    user: {
      button: {
        logIn: 'Logga in',
        logOut: 'Logga ut',
      },
      error: {
        username: 'Användarnamnet får inte vara tomt',
        password: 'Lösenordet får inte vara tomt',
        undefined: 'Kunde inte logga in',
      },
      title: 'Logga in',
      placeholder: {
        user: 'Användarnamn',
        pass: 'Lösenord',
      },
      loggedIn: 'Du är inloggad som {0}',
      admin: 'Administration',
    },
  },
};
