export default {
  app: {
    footer: {
      aboutUs: 'À propos de nous',
      contactUs: 'Nous contacter',
      pressAndMedia: 'Presse & Médias',
      travelProfessionals: 'Professionnels du voyage',
      brochures: 'Des brochures',
      news: 'Nouveautés',
      covid19: 'Covid-19',
      destinations: 'Les destinations',
      thingsToDo: 'Choses à faire',
      travelInfo: 'Info voyage',
      czechTourism: 'CzechTourism',
      menu: 'Menu',
      mobileApp: 'Application mobile',
      visitCzechAndroid: 'VisitCzech Application mobile pour Android',
      followUs: 'Suivez nous',
      privacyPolicy: 'Politique de confidentialité',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/fr-fr/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Paramètres cookies',
    },
  },
};
