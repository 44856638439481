export default {
  app: {
    maps: {
      more: 'Gedetailleerde kaart openen',
      style: {
        tooltip: 'Kaartstijl',
        basic: 'Basis',
        outdoor: 'Toerist',
        aerial: 'Luchtfoto',
        winter: 'Winter',
      },
      loadPoI: 'Lokaal zoeken',
      currentPosition: 'Huidige positie',
      categories: 'Categorieën',
      location: 'Locatie',
    },
  },
};
