export default {
  app: {
    footer: {
      aboutUs: 'Rólunk',
      contactUs: 'Lépjen kapcsolatba velünk',
      pressAndMedia: 'Press & Media',
      travelProfessionals: 'Utazási szakemberek',
      brochures: 'Brosúrák',
      news: 'Új',
      covid19: 'Covid-19',
      destinations: 'Úticélok',
      thingsToDo: 'Tennivalók',
      travelInfo: 'Utazási információk',
      czechTourism: 'CzechTourism',
      menu: 'Menü',
      mobileApp: 'Mobilalkalmazás',
      visitCzechAndroid: 'VisitCzech Mobil alkalmazás Androidra',
      followUs: 'Kövessen minket',
      privacyPolicy: 'Adatvédelmi szabályzat',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Cookie-k és elemzések',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Sütik beállításait',
    },
  },
};
