import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { Dictionary } from 'vue-router/types/router';
import toBoolean from '~/utils/toBoolean';
import { createPage, OneOfThePages } from '~/utils/views';
import UserModule from './UserModule';
import NavigationModule, { createNavigationCommit } from './NavigationModule';
import Vue from 'vue';
import ServerTimingModule from './LoggerModule';

interface SetResourceCommit {
  resource: OneOfThePages | null;
}

@Module({
  name: 'RouterModule',
  stateFactory: true,
  namespaced: true,
})
export default class RouterModule extends AbstractModule {
  public resource: OneOfThePages | null = null;

  public loading: boolean = true;

  public found: boolean = false;

  public currentGuid: string | null = null;

  public currentLocale: string | null = null;

  public isCrawler: boolean = false;

  public isMobile: boolean = false;

  public afterLoadMiddleware: { [key: string]: () => unknown } = {};

  protected currentToken: string = '';

  @Action({ rawError: true })
  public loadResourceByPath({
    guid,
    locale,
    query,
    withNav,
  }: {
    guid: string;
    locale: string;
    query?: Dictionary<string | (string | null)[]>;
    withNav?: boolean;
  }): Promise<void> {
    const decodedGuid = decodeURIComponent(guid);
    const userModule = getModule(UserModule, this.store);
    const token = userModule.token;
    if (
      this.currentGuid === decodedGuid &&
      this.currentLocale === locale &&
      this.currentToken === token &&
      this.resource
    ) {
      return Promise.resolve();
    }

    this.resourceLoading(true);
    this.resourceFound(false);
    this.setCurrentGuid(decodedGuid);
    this.setCurrentLocale(locale);
    this.setCurrentToken(token);
    const isPreview =
      query &&
      query.hasOwnProperty('isPreview') &&
      typeof query.isPreview === 'string'
        ? toBoolean(query.isPreview)
        : undefined;

    const start = Date.now();

    const promise: Promise<void> = this.$api
      .pages()
      .pagesGetPage(
        decodedGuid,
        locale,
        undefined,
        isPreview,
        withNav,
        token !== '' ? `Bearer ${token}` : undefined
      )
      .then((response) => {
        if (
          this.currentGuid === decodedGuid &&
          this.currentLocale === locale &&
          this.currentToken === token
        ) {
          this.resourceFound(!!response);
          this.setResource({
            resource: response ? createPage(response) : null,
          });
          if (withNav && response.nearestNavigation) {
            const navigationModule = getModule(NavigationModule, this.store);
            const path = response.nearestNavigation.nodeAliasPath;
            const key = `${locale}|${path}`;
            const navigationCommit = createNavigationCommit(
              response.nearestNavigation,
              path,
              key
            );
            navigationModule.setNavigationCache(navigationCommit);
            navigationModule.setCurrentNavigationKey(key);
          }
        }
        const serverTimingModule = getModule(ServerTimingModule, this.store);
        if (serverTimingModule && serverTimingModule.addServerTiming) {
          serverTimingModule.addServerTiming(
            'apiGetPage;dur=' + (Date.now() - start)
          );
        }
      })
      .catch(() => {
        if (
          this.currentGuid === decodedGuid &&
          this.currentLocale === locale &&
          this.currentToken === token
        ) {
          this.resourceFound(false);
          this.setResource({ resource: null });
        }
      })
      .finally(() => {
        if (
          this.currentGuid === decodedGuid &&
          this.currentLocale === locale &&
          this.currentToken === token
        ) {
          this.resourceLoading(false);
          Object.values(this.afterLoadMiddleware).forEach((fn) => fn());
        }
      });

    return promise;
  }

  @Mutation
  public setIsCrawler(state: boolean) {
    this.isCrawler = state;
  }

  @Mutation
  public setIsMobile(state: boolean) {
    this.isMobile = state;
  }

  @Mutation
  public addMiddleware({
    id,
    middleware,
  }: {
    id: string;
    middleware: () => unknown;
  }) {
    Vue.set(this.afterLoadMiddleware, id, middleware);
  }

  @Mutation
  public removeMiddleware(id: string) {
    if (this.afterLoadMiddleware.hasOwnProperty(id)) {
      Vue.delete(this.afterLoadMiddleware, id);
    }
  }

  @Mutation
  protected setResource(data: SetResourceCommit) {
    this.resource = data.resource;
  }

  @Mutation
  protected resourceLoading(value: boolean) {
    this.loading = value;
  }

  @Mutation
  protected resourceFound(value: boolean) {
    this.found = value;
  }

  @Mutation
  protected setCurrentGuid(value: string | null) {
    this.currentGuid = value;
  }

  @Mutation
  protected setCurrentLocale(value: string | null) {
    this.currentLocale = value;
  }

  @Mutation
  protected setCurrentToken(value: string) {
    this.currentToken = value;
  }
}
