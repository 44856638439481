export default {
  app: {
    weather: {
      title: 'Previsioni meteo',
      now: 'Oggi',
      weatherTranslations: {
        clearsky_day: 'Soleggiato',
        clearsky_night: 'Soleggiato',
        clearsky_polartwilight: 'Soleggiato',
        cloudy: 'Nuvoloso',
        fair_day: 'Bel tempo',
        fair_night: 'Bel tempo',
        fair_polartwilight: 'Bel tempo',
        fog: 'Nebbia',
        heavyrain: 'Pioggia intensa',
        heavyrainandthunder: 'Pioggia intensa e fulmini',
        heavyrainshowers_day: 'Forti rovesci',
        heavyrainshowers_night: 'Forti rovesci',
        heavyrainshowers_polartwilight: 'Forti rovesci',
        heavyrainshowersandthunder_day: 'Forti rovesci e fulmini',
        heavyrainshowersandthunder_night: 'Forti rovesci e fulmini',
        heavyrainshowersandthunder_polartwilight: 'Forti rovesci e fulmini',
        heavysleet: 'Nevischio',
        heavysleetandthunder: 'Nevischio e fulmini',
        heavysleetshowers_day: 'Forti rovesci di nevischio',
        heavysleetshowers_night: 'Forti rovesci di nevischio',
        heavysleetshowers_polartwilight: 'Forti rovesci di nevischio',
        heavysleetshowersandthunder_day: 'Bufera di neve con fulmini',
        heavysleetshowersandthunder_night: 'Bufera di neve con fulmini',
        heavysleetshowersandthunder_polartwilight: 'Bufera di neve con fulmini',
        heavysnow: 'Forte nevicata',
        heavysnowandthunder: 'Forti nevicate e fulmini',
        heavysnowshowers_day: 'Forti rovesci di neve',
        heavysnowshowers_night: 'Forti rovesci di neve',
        heavysnowshowers_polartwilight: 'Forti rovesci di neve',
        heavysnowshowersandthunder_day: 'Forti nevicate e fulmini',
        heavysnowshowersandthunder_night: 'Forti nevicate e fulmini',
        heavysnowshowersandthunder_polartwilight: 'Forti nevicate e fulmini',
        lightrain: 'Pioggia leggera',
        lightrainandthunder: 'Pioggia leggera e fulmini',
        lightrainshowers_day: 'Deboli rovesci di pioggia',
        lightrainshowers_night: 'Deboli rovesci di pioggia',
        lightrainshowers_polartwilight: 'Deboli rovesci di pioggia',
        lightrainshowersandthunder_day: 'Pioggia debole e fulmini',
        lightrainshowersandthunder_night: 'Pioggia debole e fulmini',
        lightrainshowersandthunder_polartwilight: 'Pioggia debole e fulmini',
        lightsleet: 'Nevischio debole',
        lightsleetandthunder: 'Nevischio debole e temporale',
        lightsleetshowers_day: 'Deboli rovesci di nevischio',
        lightsleetshowers_night: 'Deboli rovesci di nevischio',
        lightsleetshowers_polartwilight: 'Deboli rovesci di nevischio',
        lightssleetshowersandthunder_day: 'Deboli nevischi e temporale',
        lightssleetshowersandthunder_night: 'Deboli nevischi e temporale',
        lightssleetshowersandthunder_polartwilight:
          'Deboli nevischi e temporale',
        lightsnow: 'Neve leggera',
        lightsnowandthunder: 'Neve leggera e temporale',
        lightsnowshowers_day: 'Deboli nevicate',
        lightsnowshowers_night: 'Deboli nevicate',
        lightsnowshowers_polartwilight: 'Deboli nevicate',
        lightssnowshowersandthunder_day: 'Deboli rovesci di neve e temporale',
        lightssnowshowersandthunder_night: 'Deboli rovesci di neve e temporale',
        lightssnowshowersandthunder_polartwilight:
          'Deboli rovesci di neve e temporale',
        rain: 'Pioggia',
        rainandthunder: 'Pioggia e fulmini',
        rainshowers_day: 'Scrosci di pioggia',
        rainshowers_night: 'Scrosci di pioggia',
        rainshowers_polartwilight: 'Scrosci di pioggia',
        rainshowersandthunder_day: 'Temporali',
        rainshowersandthunder_night: 'Temporali',
        rainshowersandthunder_polartwilight: 'Temporali',
        sleet: 'Nevischio',
        sleetandthunder: 'Nevischio e fulmini',
        sleetshowers_day: 'Nevischio',
        sleetshowers_night: 'Nevischio',
        sleetshowers_polartwilight: 'Nevischio',
        sleetshowersandthunder_day: 'Rovesci di nevischio e temporali',
        sleetshowersandthunder_night: 'Rovesci di nevischio e temporali',
        sleetshowersandthunder_polartwilight:
          'Rovesci di nevischio e temporali',
        snow: 'Neve',
        snowandthunder: 'Neve e fulmini',
        snowshowers_day: 'Nevicate',
        snowshowers_night: 'Nevicate',
        snowshowers_polartwilight: 'Nevicate',
        snowshowersandthunder_day: 'Nevicate e fulmini',
        snowshowersandthunder_night: 'Nevicate e fulmini',
        snowshowersandthunder_polartwilight: 'Nevicate e fulmini',
        partlycloudy_day: 'Parzialmente nuvoloso',
        partlycloudy_night: 'Parzialmente nuvoloso',
        partlycloudy_polartwilight: 'Parzialmente nuvoloso',
      },
    },
  },
};
