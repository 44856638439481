export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Jag godkänner behandlingen av {0}',
          link: 'personuppgifter.',
          error: 'Du måste godkänna behandlingen av personuppgifter.',
        },
      },
      send: 'Skicka',
      sent: 'Formuläret skickades framgångsrikt.',
    },
  },
};
