export default {
  app: {
    footer: {
      aboutUs: 'Apie mus',
      contactUs: 'Kontaktai',
      pressAndMedia: 'Informacija žiniasklaidai',
      travelProfessionals: 'Informacija kelionių organizatoriams',
      brochures: 'Brošiūros',
      news: 'Naujienos',
      covid19: 'Covid-19',
      destinations: 'Vietovės',
      thingsToDo: 'Ką nuveikti',
      travelInfo: 'Praktinė informacija',
      czechTourism: 'CzechTourism',
      menu: 'Meniu',
      mobileApp: 'Mobiliosios programėlės',
      visitCzechAndroid: 'VisitCzech Mobile Application for Android',
      followUs: 'Sekite mus',
      privacyPolicy: 'Privatumo politika',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Slapukai ir analizės duomenys',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Slapukų nustatymai',
    },
  },
};
