export default {
  app: {
    safeTravels: {
      search: 'Znajdź obiekt',
      categories: 'Wybierz kategorię',
      button: 'Znajdź',
      table: {
        name: 'Nazwa obiektu',
        link: 'Strona www',
      },
      categoryTranslations: {
        eventy: 'Imprezy',
        kongresy_a_veletrhy: 'Kongresy i targi',
        restaurace: 'Restauracja',
        ubytovací_zařízení: 'Obiekt zakwaterowania',
        sauny: 'Sauny',
        přírodní_a_umělá_koupaliště: 'Kąpieliska',
        lanová_doprava: 'Kolejki linowe',
        maloobchod: 'Sprzedaż detaliczna',
        fitness_centra: 'Kluby fitness',
        kina: 'Kina',
        golfové_areály: 'Pola golfowe',
      },
    },
  },
};
