export default {
  app: {
    user: {
      button: {
        logIn: 'Belépés',
        logOut: 'Kijelentkezés',
      },
      error: {
        username: 'A felhasználónév nem lehet üres',
        password: 'A jelszó nem lehet üres',
        undefined: 'Nem sikerült bejelentkezni',
      },
      title: 'Belépés',
      placeholder: {
        user: 'Felhasználónév',
        pass: 'Jelszó',
      },
      loggedIn: 'Ön mint {0} bejelentkezett',
      admin: 'Adminisztráció',
    },
  },
};
