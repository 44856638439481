export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Pagrindinis',
      destinations: 'Vietovės',
      regions: 'Regionai',
      cities: 'Miestai',
      thingsToDo: 'Ką nuveikti',
      alwaysVisit: 'Būtinai aplankykite',
      culturalHeritage: 'Kultūros paveldas',
      activeHoliday: 'Aktyvus poilsis',
      healthAndSpa: 'Sveikata ir Spa',
      new: 'Naujienos',
      covid: 'Covid-19',
      travelInfo: 'Praktinė informacija',
    },
  },
};
