import { Component, Prop } from 'vue-property-decorator';

import { VueComponent } from '~/utils/vue-component';
import { Headline } from '~/components/atoms';
import { ImageWrapper, Link } from '~/components/molecules';
import { Align } from '~/components/atoms/headline/Headline';
import { ThemeRatios } from '~/utils/theme';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { ImageTileInterface } from '../imageTile/ImageTile';
import { ImageFilterMixinInterface } from '~/mixins';

export type ListingItemInterface = ImageTileInterface &
  ImageFilterMixinInterface & {
    description: string;
    id: string;
    title: string;
    link: string;
    address?: string;
    info?: string;
    forcedLocale?: string;
  };

interface ListingItemComponentInterface {
  item: ListingItemInterface;
}

const rootClass = 'czt-listing-item';

@Component
export default class ListingItem
  extends VueComponent<ListingItemComponentInterface>
  implements ListingItemComponentInterface {
  @Prop({ required: true })
  public item!: ListingItemInterface;

  public render() {
    if (!this.item.link) {
      return;
    }

    return (
      <v-col class={`${rootClass} ${rootClass}--simple`} cols={12}>
        <v-row>
          <v-col cols='12' md='4'>
            <Link url={this.item.link} forcedLocale={this.item.forcedLocale}>
              <ImageWrapper
                ratio={ThemeRatios['4x3']}
                image={this.item.image}
              />
            </Link>
          </v-col>
          <v-col cols='12' md='8'>
            {this.item.info && (
              <div class='grey--text text--darken-2 font-weight-bold mb-1'>
                {this.item.info}
              </div>
            )}
            <Headline
              level={3}
              align={Align.LEFT}
              class='mb-2 font-weight-bold'
            >
              <Link url={this.item.link} forcedLocale={this.item.forcedLocale}>
                {this.item.title}
              </Link>
            </Headline>
            <div class={`${rootClass}__content font-weight-bold`}>
              <HtmlRenderer content={this.item.description} />
              <Link url={this.item.link} forcedLocale={this.item.forcedLocale}>
                {this.$t('app.common.moreInfo')}
              </Link>
            </div>
          </v-col>
        </v-row>
      </v-col>
    );
  }
}
