export default {
  app: {
    newsletter: {
      bottomText:
        'Door u in te schrijven voor de nieuwsbrief, gaat u akkoord met onze <a href="~/Privacy-Policy" target="_blank">gegevensbeschermingsbeleid</a>.',
      button: 'Abonneren',
      ctaDialogParagraph1: 'Wil je de aankomende evenementen in Praag weten?',
      ctaDialogParagraph2:
        'Weet niet zeker welk kasteel je moet bezoeken tijdens je tour door Tsjechië?',
      ctaDialogParagraph3:
        'Schrijf je in op onze nieuwsbrief en je ontvangt veel handige tips voor je volgende reis!',
      ctaDialogTitle: 'KLOP KLOP!',
      ctaDialogBtn: 'KLIK HIER',
      dialog: {
        headline: 'Selecteer het land / de regio van uw nieuwsbrief',
        notice:
          'Uw hierboven opgegeven e-mailadres wordt alleen gebruikt voor ons nieuwsbriefabonnement en wordt niet bekendgemaakt aan andere onderwerpen. Hiervoor registreren wij uw e-mailadres tot de dag dat u zich uitschrijft voor onze nieuwsbrief. U kunt zich op elk moment uitschrijven door op de link te klikken in het bericht waarin de nieuwsbrief wordt bezorgd. We bewaren de gegevens over uitschrijven gedurende een periode van 3 jaar. Na deze periode worden de gegevens verwijderd, tenzij er een andere reden is om ze opnieuw te verwerken. Uw e-mailadres kan worden verwerkt door het IT-bedrijf dat de werking en ontwikkeling van onze websites beheert. {0}',
        company: 'De gegevensbeheerder is {0}, {1}',
        link: {
          privacy: 'Ons privacybeleid is hier beschikbaar.',
          controller: 'Tsjechische hoofdkantoor voor toerisme – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Jouw e-mailadres',
      newsletterError:
        'Er lijken momenteel geen nieuwsbrieven te zijn waarop u zich kunt abonneren',
      serverError:
        'Er is een fout opgetreden bij het uitvoeren van het verzoek. Probeer het a.u.b. opnieuw',
      success:
        'Om de aanmelding voor de nieuwsbrief succesvol af te ronden, dient u de registratie te bevestigen in de naar u verzonden email.',
      title: 'Ontvang onze nieuwsbrief',
      topText:
        'Schrijf u in om de laatste informatie, tips, aankomende evenementen en nieuwe verhalen te ontvangen.',
      validityError: 'Uw e-mailadres lijkt ongeldig te zijn',
    },
  },
};
