export default {
  app: {
    user: {
      button: {
        logIn: 'Войти',
        logOut: 'Выйти',
      },
      error: {
        username: 'Имя пользователя не может быть пустым',
        password: 'Пароль не может быть пустым',
        undefined: 'Не удалось войти',
      },
      title: 'Войти',
      placeholder: {
        user: 'Имя пользователя',
        pass: 'Пароль',
      },
      loggedIn: 'Вы вошли как {0}',
      admin: 'Управление',
    },
  },
};
