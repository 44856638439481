export default {
  app: {
    panorama: {
      loadingText: 'Caricamento immagine...',
      autorotate: 'Rotazione automatica',
      zoom: 'Zoom',
      zoomOut: 'Rimpicciolire',
      zoomIn: 'Ingrandire',
      move: 'Muovere',
      download: 'Scaricare',
      fullscreen: 'Schermo intero',
      menu: 'Menu',
      twoFingers: 'Usa due dita per navigare',
      ctrlZoom: 'Usa ctrl + Scroll per usare lo zoom',
      loadError: 'Il panorama non può essere caricato',
    },
  },
};
