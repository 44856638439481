export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtruoti pagal paskatą',
        itinerary: 'Filtruoti pagal žymą',
        region: 'Filtruoti pagal regioną',
      },
    },
  },
};
