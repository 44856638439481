import { CztWidgets } from '~/utils/views/widgets';
import { GridListInterface } from './types';

export default function(data: any): data is GridListInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.GRID &&
    typeof data.guid === 'string' &&
    typeof data.type === 'string'
  );
}
