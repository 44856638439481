export default {
  app: {
    footer: {
      aboutUs: 'Om oss',
      contactUs: 'Kontakt oss',
      pressAndMedia: 'Presse og media',
      travelProfessionals: 'Reiseprofesjonelle',
      brochures: 'Brosjyrer',
      news: 'Nytt',
      covid19: 'Covid-19',
      destinations: 'Destinasjoner',
      thingsToDo: 'Aktiviteter',
      travelInfo: 'Reiseinformasjon',
      czechTourism: 'CzechTourism',
      menu: 'Meny',
      mobileApp: 'Mobile app',
      visitCzechAndroid: 'VisitCzech Mobile Application for Android',
      followUs: 'Follow us',
      privacyPolicy: 'Privacy Policy',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookie Settings',
    },
  },
};
