export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtruj według zachęty',
        itinerary: 'Filtruj według tagu',
        region: 'Filtruj według regionu',
      },
    },
  },
};
