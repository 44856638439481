export default {
  app: {
    footer: {
      aboutUs: 'About Us',
      contactUs: 'Contact Us',
      pressAndMedia: 'Press & Media',
      travelProfessionals: 'Travel Professionals',
      brochures: 'Brochures',
      news: 'New',
      covid19: 'Covid-19',
      destinations: 'Destinations',
      thingsToDo: 'Things to Do',
      travelInfo: 'Travel Info',
      czechTourism: 'CzechTourism',
      menu: 'Menu',
      mobileApp: 'Mobile app',
      visitCzechAndroid: 'VisitCzech Mobile Application for Android',
      followUs: 'Follow us',
      privacyPolicy: 'Privacy Policy',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookie Settings',
    },
  },
};
