export default {
  app: {
    safeTravels: {
      search: 'Hitta anläggning',
      categories: 'Kategori',
      button: 'Sök',
      table: {
        name: 'Namn',
        link: 'Webbsida',
      },
      categoryTranslations: {
        eventy: 'Evenemang',
        kongresy_a_veletrhy: 'Kongresser och mässor',
        restaurace: 'Restaurang',
        ubytovací_zařízení: 'Boende',
        sauny: 'Bastu',
        přírodní_a_umělá_koupaliště: 'Simbassäng',
        lanová_doprava: 'Linbana',
        maloobchod: 'Affär',
        fitness_centra: 'Gym',
        kina: 'Bio',
        golfové_areály: 'Golf',
      },
    },
  },
};
