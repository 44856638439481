export default {
  app: {
    footer: {
      aboutUs: 'O nas',
      contactUs: 'Skontaktuj się z nami',
      pressAndMedia: 'Media',
      travelProfessionals: 'Branża turystyczna',
      brochures: 'Broszury',
      news: 'Nowe',
      covid19: 'Covid-19',
      destinations: 'Cele podróży',
      thingsToDo: 'Atrakcje',
      travelInfo: 'Informacje o podróży',
      czechTourism: 'CzechTourism',
      menu: 'Menu',
      mobileApp: 'Mobilna aplikacja',
      visitCzechAndroid: 'VisitCzech aplikacja mobilna na Androida',
      followUs: 'Podążaj za nami',
      privacyPolicy: 'Polityka prywatności',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/pl-pl/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Ustawienia plików cookie',
    },
  },
};
