export default {
  app: {
    search: {
      button: 'Encontrar',
      noResults: 'Nenhum resultado encontrado',
      title: 'Procurar',
      widgetTitle: 'Descubra o que fazer no país',
    },
  },
};
