export default {
  app: {
    gridList: {
      label: {
        incentive: 'Фильтровать по стимулу',
        itinerary: 'Фильтровать по тегу',
        region: 'Фильтровать по региону',
      },
    },
  },
};
