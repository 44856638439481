export default {
  app: {
    cookies: {
      title: '私たちは、お客様のプライバシーを尊重します',
      text:
        'Cookieを使用して、コンテンツをパーソナライズし、トラフィックを分析し、ソーシャルメディア機能を提供します。当局は、お客様が当局のウェブサイトをどのように使用しているかに関する情報を、ソーシャルメディアおよび分析パートナーと共有します。パートナーはこの情報を、お客様がパートナーに提供する情報、またはお客様がサービスを使用した結果として取得する他の情報と組み合わせる場合があります。{0}。 {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: '詳細はこちらでご確認ください',
      },
      buttons: {
        accept: 'すべてのCookieを受け入れる',
        save: '選択したものを保存',
      },
      settings: {
        button: '詳細設定',
        required: '必須（ウェブサイトの正常な運用のため）',
        analytics:
          '分析（訪問者がWebサイト上でどのような行動をするか、またはWebサイト内をどのように移動するかを理解するため）',
        statistics:
          '統計（訪問者がウェブサイトをどのように使用しているかを理解するため）',
        marketing:
          'マーケティング（訪問者を追跡して関連する広告を表示するため）',
        reject: 'すべて拒否する',
        accept: 'すべて受け入れる',
      },
    },
  },
};
