export default {
  app: {
    tradeOffers: {
      country: 'Maa',
      loginPrompt:
        'Jos haluat nähdä yksityisiä kauppatarjouksia, sinun on {0}.',
      logIn: 'kirjaudu sisään',
    },
  },
};
