import { Action, Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import { SearchItemInterface } from '~/components/molecules/searchItem/SearchItem';
import { createSearchResult } from '~/utils/views/search';

interface SearchInputInterface {
  locale: string;
  fulltext: string;
  path: string;
}

interface SearchResultsCommit {
  page: number;
  results: SearchItemInterface[];
}

@Module({
  name: 'SearchModule',
  stateFactory: true,
  namespaced: true,
})
export default class SearchModule extends AbstractModule {
  public loading: boolean = false;

  public results: SearchItemInterface[] = [];

  public hasNextPage: boolean = true;

  protected page: number = 0;

  protected locale: string = '';

  protected term: string = '';

  protected path: string = '';

  @Action({ commit: 'noop', rawError: true })
  public search(data: SearchInputInterface): Promise<SearchResultsCommit> {
    this.initializeSearchData(data);
    return this.loadMore();
  }

  @Action({ commit: 'addSearchResults', rawError: true })
  public loadMore(): Promise<SearchResultsCommit> {
    this.setLoading(true);

    return this.$api
      .search()
      .searchGetSearchResult(
        this.term,
        this.page + 1,
        10,
        this.locale,
        this.path || undefined
      )
      .then((response) => {
        const hasMorePages: boolean = !!(
          response.itemsCountNextPage && response.itemsCountNextPage > 0
        );
        this.setHasNextPage(hasMorePages);

        if (!response.items) {
          return {
            page: this.page,
            results: [],
          };
        }

        this.setPage(this.page + 1);

        return {
          page: this.page,
          results: response.items.map(createSearchResult),
        };
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Mutation
  public setLoading(state: boolean) {
    this.loading = state;
  }

  @Mutation
  public initializeSearchData(data: SearchInputInterface) {
    this.term = data.fulltext;
    this.locale = data.locale;
    this.page = 0;
    this.results = [];
    this.hasNextPage = true;
    this.path = data.path;
  }

  @Mutation
  public setPage(page: number) {
    this.page = page;
  }

  @Mutation
  public setHasNextPage(state: boolean) {
    this.hasNextPage = state;
  }

  @Mutation
  public addSearchResults(data: SearchResultsCommit) {
    this.results = this.results.concat(data.results);
  }

  @Mutation
  public noop() {
    // Does nothing
  }
}
