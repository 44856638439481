export default {
  app: {
    footer: {
      aboutUs: 'Om os',
      contactUs: 'Kontakt os',
      pressAndMedia: 'Press & Media',
      travelProfessionals: 'Rejsebureauer',
      brochures: 'Brochurer',
      news: 'Nyhed',
      covid19: 'Covid-19',
      destinations: 'Destinationer',
      thingsToDo: 'Hvad kan man opleve',
      travelInfo: 'Rejseinfo',
      czechTourism: 'Tjekkisk Turistråd',
      menu: 'Menu',
      mobileApp: 'Mobilapp',
      visitCzechAndroid: 'VisitCzech mobilapplikation til Android',
      followUs: 'Følg os',
      privacyPolicy: 'Fortrolighedspolitik',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookies-indstillinger',
    },
  },
};
