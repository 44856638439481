export default {
  app: {
    safeTravels: {
      search: 'Ieškoti objektų',
      categories: 'Pasirinkite kategoriją',
      button: 'Ieškoti',
      table: {
        name: 'Objekto pavadinimas',
        link: 'Interneto puslapis',
      },
      categoryTranslations: {
        eventy: 'Įvykiai',
        kongresy_a_veletrhy: 'Kongresai ir parodos',
        restaurace: 'Restoranas',
        ubytovací_zařízení: 'Apgyvendinimo vieta',
        sauny: 'Saunos',
        přírodní_a_umělá_koupaliště: 'Paplūdimiai',
        lanová_doprava: 'Kalnų keltuvai',
        maloobchod: 'Mažmeninė prekyba',
        fitness_centra: 'Fitneso klubas',
        kina: 'Kinoteatrai',
        golfové_areály: 'Golfo laukai',
      },
    },
  },
};
