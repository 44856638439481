import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { Button, Multiselect } from '~/components/atoms';
import { VueComponent } from '~/utils/vue-component';

import style from './GridListFilter.scss';
import { ThemeColors } from '~/utils/theme';
import { GridListInterface } from './types';
import capitalize from '~/utils/capitalize';

const rootClass = 'czt-grid-list-filter';

export type FilterEmit = Map<string, string[]>;

interface GridListFilterInterface {
  value?: FilterEmit;
  filterOptions: Required<GridListInterface>['taxonomyLists'];
  loading?: boolean;
  onFilter?: (data: FilterEmit) => void;
}

@Component({
  style,
})
export default class GridListFilter
  extends VueComponent<GridListFilterInterface>
  implements GridListFilterInterface {
  @Prop({ required: true })
  public filterOptions!: GridListFilterInterface['filterOptions'];

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop({ required: true })
  public value!: FilterEmit;

  protected innerState = this.value;

  public render() {
    return (
      <v-sheet class={`${rootClass} mb-3`} color={ThemeColors.PRIMARY}>
        <form onSubmit={this.emitFilter}>
          <v-col class='py-0'>
            <v-row>
              <v-col class='py-0'>
                <v-row no-gutters>
                  <v-col cols='12'>
                    <v-row>
                      {this.filterOptions.map((opts) => {
                        const translation = this.$te(
                          `app.gridList.label.${opts.taxonomy}`
                        )
                          ? this.$t(`app.gridList.label.${opts.taxonomy}`)
                          : capitalize(opts.taxonomy);
                        return (
                          <v-col cols='12' md='6' lg='4'>
                            <Multiselect
                              items={opts.tags.map((tax) => ({
                                text: tax.name,
                                value: tax.id,
                              }))}
                              label={translation}
                              value={this.innerState.get(opts.taxonomy) || []}
                              onInput={(value) =>
                                this.innerState.set(
                                  opts.taxonomy,
                                  value.map((val) => val.toString())
                                )
                              }
                            />
                          </v-col>
                        );
                      })}
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols='12' md='auto' class='text-center'>
                <Button loading={this.loading} submit>
                  {this.$t('app.search.title')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </form>
      </v-sheet>
    );
  }

  @Watch('value', { deep: true })
  protected setInnerState() {
    this.innerState = new Map(this.value);
  }

  @Emit('filter')
  protected emitFilter(e?: Event): FilterEmit {
    if (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return new Map(this.innerState);
  }
}
