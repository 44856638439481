import Facebook from './Facebook';
import Instagram from './Instagram';
import InstagramWhite from './InstagramWhite';
import Twitter from './Twitter';
import Vkontakte from './Vkontakte';
import Youtube from './Youtube';
import YoutubeSimple from './YoutubeSimple';

export default {
  Facebook,
  Instagram,
  InstagramWhite,
  Twitter,
  Vkontakte,
  Youtube,
  YoutubeSimple,
};
