export default {
  app: {
    panorama: {
      loadingText: '이미지를 불러오는 중…',
      autorotate: '자동 회전',
      zoom: '확대/축소',
      zoomOut: '축소',
      zoomIn: '확대',
      move: '이동',
      download: '다운로드',
      fullscreen: '전체 화면',
      menu: '메뉴',
      twoFingers: '두 손가락을 이용해 탐색',
      ctrlZoom: 'ctrl+화살표 버튼을 눌러 확대/축소',
      loadError: '불러올 수 없습니다',
    },
  },
};
