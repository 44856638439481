export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: '同意します {0}',
          link: '個人データ処理.',
          error: '個人データの処理に同意する必要があります.',
        },
      },
      send: '送信',
      sent: 'フォームは正常に送信されました.',
    },
  },
};
