export default {
  app: {
    winter: {
      snowReport: 'Raport śniegowy',
      snow: '{type} - {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: 'Puch',
        3: 'mokry śnieg',
        4: 'zamarznięty śnieg',
        5: 'firn',
        6: 'sztuczny śnieg',
        7: 'wilgotny śnieg',
      },
      lifts: 'Wyciągi',
      open: 'czynne',
      slopes: 'Trasy',
      webcam: 'Kamera online',
    },
  },
};
