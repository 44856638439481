import { CztWidgets } from '~/utils/views/widgets';
import { VenueFinderInterface } from './types';

export default function(data: any): data is VenueFinderInterface {
  return (
    data &&
    typeof data === 'object' &&
    data.className === CztWidgets.VENUE_FINDER &&
    typeof data.guid === 'string'
  );
}
