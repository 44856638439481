export default {
  app: {
    user: {
      button: {
        logIn: 'Zaloguj się',
        logOut: 'Wyloguj się',
      },
      error: {
        username: 'Nazwa użytkownika nie może być pusta',
        password: 'Hasło nie może być puste',
        undefined: 'Logowanie nie powiodło się',
      },
      title: 'Zaloguj się',
      placeholder: {
        user: 'Nazwa użytkownika',
        pass: 'Hasło',
      },
      loggedIn: 'Zalogowałeś się jako {0}',
      admin: 'Administracja',
    },
  },
};
