import { Component } from 'vue-property-decorator';

import StatusCode from '~/utils/http/StatusCode';
import { VueComponent } from '~/utils/vue-component';
import Headline from '~/components/atoms/headline/Headline';
import Link from '~/components/molecules/link/Link';
import { MainNavigationItem } from '~/utils/header/menu-items';
import { Banner } from '~/components/organisms';

import teaserImage from '~/assets/images/404_not_found.jpg';
import IconText from '~/components/molecules/iconText/IconText';
import { IconPosition } from '~/components/molecules/iconText/IconText';

@Component
export default class NotFound extends VueComponent<{}> {
  public render() {
    const navItems: MainNavigationItem[] = [
      {
        label: 'app.menu.homepage',
        url: '~/',
      },
      {
        label: 'app.menu.destinations',
        url: '~/Destinations',
      },
      {
        label: 'app.menu.travelInfo',
        url: '~/Travel-Info',
      },
      {
        label: 'app.menu.thingsToDo',
        url: '~/Things-to-Do',
      },
    ];

    const mainTitle = `${this.$t('app.notFound.title')}
      <div>${this.$t('app.notFound.subtitle')}</div>`;

    return (
      <v-container fluid class='pa-0'>
        <StatusCode code={404} />
        <Banner
          backgroundImage={{
            src: teaserImage,
            alt: this.$t('app.notFound.title') + '',
          }}
          title={mainTitle}
          titleLevel={1}
        >
          <Headline level={3} light>
            {this.$t('app.notFound.text')}
          </Headline>
          <div class='czt-banner__content__slots__links'>
            {navItems.map((item) => {
              if (!item.url) {
                return;
              }
              return (
                <Link url={item.url}>
                  <IconText
                    icon={['common', 'ArrowRight']}
                    bordered={true}
                    iconPosition={IconPosition.APPEND}
                  >
                    {this.$t(item.label)}
                  </IconText>
                </Link>
              );
            })}
          </div>
        </Banner>
      </v-container>
    );
  }
}
