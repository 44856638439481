export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'DE Translate: I agree to {0}',
          link: 'personal data processing.',
          error: 'DE Translate: You must agree to personal data processing.',
        },
      },
      send: 'DE Translate: Send',
      sent: 'DE Translate: The form was successfully sent.',
    },
  },
};
