import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './CookieBar.scss';
import { Button, Headline } from '~/components/atoms';
import { IconText, Link } from '~/components/molecules';
import { IconPosition } from '~/components/molecules/iconText/IconText';
import Checkbox from '~/components/atoms/checkbox/Checkbox';
import CookieSettingsModule from '~/app/core/store/modules/CookieSettingsModule';
import { getModule } from 'vuex-module-decorators';
import { IconName } from '~/components/atoms/icons/Icon';
import { Align } from '~/components/atoms/headline/Headline';
import VisitMetricsModule from '~/app/core/store/modules/VisitMetricsModule';
import RouterModule from '~/app/core/store/modules/RouterModule';

@Component({
  style,
})
export default class CookieBar extends VueComponent<{}> {
  protected rootClass: string = 'czt-cookie-bar';

  protected settingsOpen: boolean = false;

  protected analytics: boolean = false;
  protected statistics: boolean = false;
  protected marketing: boolean = false;

  protected get cookieSettings(): CookieSettingsModule {
    return getModule(CookieSettingsModule, this.$store);
  }

  protected get visitMetricsModule(): VisitMetricsModule {
    return getModule(VisitMetricsModule, this.$store);
  }

  protected get isCrawler(): boolean {
    return getModule(RouterModule, this.$store).isCrawler;
  }

  protected get visible(): boolean {
    return this.cookieSettings.cookieBarVisible;
  }

  protected get icon(): IconName {
    return ['common', !this.settingsOpen ? 'ArrowDown' : 'ArrowUp'];
  }

  protected get switchState(): boolean {
    return this.analytics && this.statistics && this.marketing;
  }

  public mounted() {
    this.cookieSettings.readCookie(document.cookie).then(() => {
      this.analytics = this.cookieSettings.analytics;
      this.statistics = this.cookieSettings.statistics;
      this.marketing = this.cookieSettings.marketing;
    });
  }

  public render() {
    if (this.isCrawler) {
      return;
    }
    const classes: string[] = [this.rootClass + '__backdrop'];
    if (!this.visible) {
      classes.push('d-none');
    }
    return (
      <v-sheet class={classes.join(' ')}>
        <v-sheet class={this.rootClass} elevation={5}>
          <v-card>
            <v-card-text class={this.rootClass + '__text'}>
              <Headline underscore align={Align.LEFT}>
                {this.$t('app.cookies.title')}
              </Headline>
              <i18n tag='div' path='app.cookies.text'>
                <Link
                  class={`${this.rootClass}__cookie-link`}
                  url={this.$t('app.cookies.moreInfo.link')}
                >
                  {this.$t('app.cookies.moreInfo.text')}
                </Link>
                <IconText
                  class={`${this.rootClass}__settings__button ${this.rootClass}__cookie-link`}
                  icon={this.icon}
                  iconPosition={IconPosition.APPEND}
                  onClick={() => {
                    this.settingsOpen = !this.settingsOpen;
                  }}
                >
                  {this.$t('app.cookies.settings.button')}
                </IconText>
              </i18n>
              <v-expansion-panels
                class={`${this.rootClass}__settings`}
                active-class={`${this.rootClass}__settings--active`}
                flat
                accordion
                value={this.settingsOpen ? 0 : undefined}
              >
                <v-expansion-panel>
                  <v-expansion-panel-content eager>
                    <Checkbox
                      label={this.$t('app.cookies.settings.required')}
                      disabled
                      readOnly
                      value={true}
                    />
                    <Checkbox
                      label={this.$t('app.cookies.settings.analytics')}
                      v-model={this.analytics}
                    />
                    <Checkbox
                      label={this.$t('app.cookies.settings.statistics')}
                      v-model={this.statistics}
                    />
                    <Checkbox
                      label={this.$t('app.cookies.settings.marketing')}
                      v-model={this.marketing}
                    />
                    <v-switch
                      inset
                      input-value={this.switchState}
                      label={
                        this.switchState
                          ? this.$t('app.cookies.settings.reject')
                          : this.$t('app.cookies.settings.accept')
                      }
                      nativeOnClick={() => {
                        const val = !this.switchState;
                        this.analytics = val;
                        this.statistics = val;
                        this.marketing = val;
                      }}
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <div class={this.rootClass + '__button'}>
                <Button
                  onClick={this.settingsOpen ? this.save : this.acceptAll}
                >
                  {this.settingsOpen
                    ? this.$t('app.cookies.buttons.save')
                    : this.$t('app.cookies.buttons.accept')}
                </Button>
              </div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-sheet>
    );
  }

  protected acceptAll(): void {
    this.cookieSettings
      .saveCookie({
        analytics: true,
        statistics: true,
        marketing: true,
      })
      .then(this.visitMetricsModule.recordVisit);
  }

  protected save(): void {
    this.cookieSettings
      .saveCookie({
        analytics: this.analytics,
        statistics: this.statistics,
        marketing: this.marketing,
      })
      .then(this.visitMetricsModule.recordVisit);
  }
}
