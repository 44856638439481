export default {
  app: {
    safeTravels: {
      search: '장소 찾기',
      categories: '카테고리 선택 ',
      button: '검색',
      table: {
        name: '이름',
        link: '웹사이트',
      },
      categoryTranslations: {
        eventy: '이벤트',
        kongresy_a_veletrhy: '회의 & MICE',
        restaurace: '레스토랑',
        ubytovací_zařízení: '숙소',
        sauny: '사우나',
        přírodní_a_umělá_koupaliště: '수영장',
        lanová_doprava: '케이블 카',
        maloobchod: '상점',
        fitness_centra: '피트니스 센터',
        kina: '영화관',
        golfové_areály: '골프 코스',
      },
    },
  },
};
