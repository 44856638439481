export default {
  app: {
    cookies: {
      title: 'Rispettiamo la vostra privacy',
      text:
        'Utilizziamo i cookies per personalizzare i contenuti, analizzare il traffico e fornire le funzionalità dei social media. Condividiamo informazioni su come utilizzi il nostro sito Web con i nostri partner di social media e analisi. I partner possono combinare queste informazioni con altre informazioni che fornisci loro o che ottengono come risultato del tuo utilizzo dei loro servizi. Potete trovare maggiori dettagli {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'qui',
      },
      buttons: {
        accept: 'Accettare tutti i cookie',
        save: 'Salva selezionati',
      },
      settings: {
        button: 'Impostazioni dettagliate',
        required: 'Obbligatorio (per il corretto funzionamento del sito)',
        analytics:
          "Analytics (per capire come si comportano i visitatori sul sito o come si muovono all'interno del sito)",
        statistics:
          'Statistiche (per capire come i visitatori utilizzano il sito)',
        marketing:
          'Marketing (per monitorare i visitatori e mostrare annunci pertinenti)',
        reject: 'Rifiuto tutto',
        accept: 'Accetto tutto',
      },
    },
  },
};
