export default {
  app: {
    notFound: {
      title: 'ページが見つかりません.',
      subtitle: '迷子になったようです.',
      text:
        '心配しないでください、私たちはあなたがここからナビゲートするのを手伝います',
    },
  },
};
