export default {
  app: {
    tradeOffers: {
      country: 'Šalis',
      loginPrompt: 'Norint pamatyti verslo pasiūlymus, reikia {0}',
      logIn: 'prisijungti',
      info: 'Informacijos',
      address: 'Adresas',
      email: 'E-mail',
      social: 'Socialiniai tinklai',
      phone: 'Telefonas',
      web: 'Web',
    },
  },
};
