export default {
  app: {
    newsletter: {
      bottomText:
        'Подписавшись на рассылку, вы согласитесь с <a href="~/Privacy-Policy" target="_blank">нашей политикой защиты персональных данных</a>.',
      button: 'Подписаться',
      ctaDialogParagraph1: 'Вы хотите знать о предстоящих событиях в Праге?',
      ctaDialogParagraph2:
        'Не знаете, какой замок посетить во время тура по Чехии?',
      ctaDialogParagraph3:
        'Подпишитесь на нашу рассылку, и вы получите много полезных советов для следующей поездки!',
      ctaDialogTitle: 'ТУК ТУК!',
      ctaDialogBtn: 'КЛИКНИТЕ СЮДА',
      dialog: {
        headline: 'Выберите страну для рассылки регулярной информации',
        notice:
          'Ваш указанный выше адрес электронной почты будет использован только с целью отправления нашего информационного бюллетеня и не будет передаваться какому-либо дальнейшему субъекту. Этот адрес мы будем хранить с данной целью до момента Вашего отказа от присылки информационного бюллетеня. Отказаться от присылки Вы можете в любой момент, щелкнув по ссылке в сообщении, посредством которого Вам приходит информационный бюллетень. Ваш адрес электронной почты может обрабатываться ИТ компанией, обеспечивающей работу нашего веб-сайта. {0}',
        company: 'Оператором персональных данных является {0}, {1}',
        link: {
          privacy: 'Нашу Privacy Policy Вы найдете здесь.',
          controller: ' Чешское управление по туризму – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Ваш электронный адрес',
      newsletterError:
        'Похоже, сейчас у вас нет информационных бюллетеней, на которые можно подписаться',
      serverError:
        'При выполнении запроса произошла ошибка. Пожалуйста, попробуйте еще раз',
      success:
        'Чтобы подписаться на рассылку новостей, пожалуйста, подтвердите регистрацию в отправленном электронном письме.',
      title: 'Подпишитесь на нашу рассылку',
      topText:
        'Для получения актуальной информации о предстоящих событиях, рекомендациях, новинках подпишитесь.',
      validityError: 'Ваш адрес электронной почты недействителен',
    },
  },
};
