export default {
  app: {
    search: {
      button: '找',
      noResults: '未找到結果',
      title: '搜尋',
      widgetTitle: '查找在該國做什麼',
    },
  },
};
