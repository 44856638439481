export default {
  app: {
    itinerary: {
      transportationInfoTitle: 'Next transit point',
      car: 'Car',
      publicTransport: 'Public transport',
      filterLabel: 'Filter by tag',
    },
  },
};
