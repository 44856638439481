import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class LifestyleIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--lifestyle'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            d='m151 50.4.2-2a37.5 37.5 0 0 0-73.5-10.7h-3.2a37.5 37.5 0 0 0-73 15.6C3.3 81.5 27 102 45 117.3c19.5 17 25 20.5 31.2 27.3 6.3-6.8 11.5-10.7 31.3-27.2 18.8-15.7 43-37.5 43.7-67h-.1Z'
          />
        </svg>
      </i>
    );
  }
}
