export default {
  app: {
    maps: {
      more: 'Otvorte podrobnú mapu',
      style: {
        tooltip: 'Štýl mapy',
        basic: 'Základné',
        outdoor: 'Turistická',
        aerial: 'Letecká',
        winter: 'Zimná',
      },
      loadPoI: 'Miestne vyhľadávanie',
      currentPosition: 'Aktuálna pozícia',
      categories: 'Kategórie',
      location: 'Poloha',
    },
  },
};
