export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtrovať podľa stimulu',
        itinerary: 'Filtrovať podľa značky',
        region: 'Filtrovať podľa regiónu',
      },
    },
  },
};
