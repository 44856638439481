export default {
  app: {
    weather: {
      title: 'Prognoza pogody',
      now: 'Dzisiaj',
      weatherTranslations: {
        clearsky_day: 'Słonecznie',
        clearsky_night: 'Słonecznie',
        clearsky_polartwilight: 'Słonecznie',
        cloudy: 'Pochmurno',
        fair_day: 'Zachmurzenie małe',
        fair_night: 'Zachmurzenie małe',
        fair_polartwilight: 'Zachmurzenie małe',
        fog: 'Mgła',
        heavyrain: 'Intensywne opady deszczu',
        heavyrainandthunder: 'Intensywne opady deszczu i burze',
        heavyrainshowers_day: 'Przelotne, intensywne opady deszczu',
        heavyrainshowers_night: 'Przelotne, intensywne opady deszczu',
        heavyrainshowers_polartwilight: 'Przelotne, intensywne opady deszczu',
        heavyrainshowersandthunder_day:
          'Przelotne, intensywne opady deszczu i burze',
        heavyrainshowersandthunder_night:
          'Przelotne, intensywne opady deszczu i burze',
        heavyrainshowersandthunder_polartwilight:
          'Przelotne, intensywne opady deszczu i burze',
        heavysleet: 'Intensywne opady deszczu ze śniegiem',
        heavysleetandthunder: 'Intensywne opady deszczu ze śniegiem i burze',
        heavysleetshowers_day:
          'Przelotne, intensywne opady deszczu ze śniegiem',
        heavysleetshowers_night:
          'Przelotne, intensywne opady deszczu ze śniegiem',
        heavysleetshowers_polartwilight:
          'Przelotne, intensywne opady deszczu ze śniegiem',
        heavysleetshowersandthunder_day:
          'Przelotne, intensywne opady deszczu ze śniegiem i burze',
        heavysleetshowersandthunder_night:
          'Przelotne, intensywne opady deszczu ze śniegiem i burze',
        heavysleetshowersandthunder_polartwilight:
          'Przelotne, intensywne opady deszczu ze śniegiem i burze',
        heavysnow: 'Intensywne opady śniegu',
        heavysnowandthunder: 'Intensywne opady śniegu i burze',
        heavysnowshowers_day: 'Przelotne, intensywne opady śniegu',
        heavysnowshowers_night: 'Przelotne, intensywne opady śniegu',
        heavysnowshowers_polartwilight: 'Przelotne, intensywne opady śniegu',
        heavysnowshowersandthunder_day:
          'Przelotne, intensywne opady śniegu i burze',
        heavysnowshowersandthunder_night:
          'Przelotne, intensywne opady śniegu i burze',
        heavysnowshowersandthunder_polartwilight:
          'Przelotne, intensywne opady śniegu i burze',
        lightrain: 'Lekkie opady deszczu',
        lightrainandthunder: 'Lekkie opady deszczu i burze',
        lightrainshowers_day: 'Przelotne, lekkie opady deszczu',
        lightrainshowers_night: 'Przelotne, lekkie opady deszczu',
        lightrainshowers_polartwilight: 'Przelotne, lekkie opady deszczu',
        lightrainshowersandthunder_day:
          'Przelotne, lekkie opady deszczu i burze',
        lightrainshowersandthunder_night:
          'Przelotne, lekkie opady deszczu i burze',
        lightrainshowersandthunder_polartwilight:
          'Przelotne, lekkie opady deszczu i burze',
        lightsleet: 'Lekkie opady deszczu ze śniegiem',
        lightsleetandthunder: 'Lekkie opady deszczu ze śniegiem i burze',
        lightsleetshowers_day: 'Przelotne, lekkie opady deszczu ze śniegiem',
        lightsleetshowers_night: 'Przelotne, lekkie opady deszczu ze śniegiem',
        lightsleetshowers_polartwilight:
          'Przelotne, lekkie opady deszczu ze śniegiem',
        lightssleetshowersandthunder_day:
          'Przelotne, lekkie opady deszczu ze śniegiem i burze',
        lightssleetshowersandthunder_night:
          'Przelotne, lekkie opady deszczu ze śniegiem i burze',
        lightssleetshowersandthunder_polartwilight:
          'Przelotne, lekkie opady deszczu ze śniegiem i burze',
        lightsnow: 'Lekkie opady śniegu',
        lightsnowandthunder: 'Lekkie opady śniegu i burze',
        lightsnowshowers_day: 'Przelotne, lekkie opady śniegu',
        lightsnowshowers_night: 'Przelotne, lekkie opady śniegu',
        lightsnowshowers_polartwilight: 'Przelotne, lekkie opady śniegu',
        lightssnowshowersandthunder_day:
          'Przelotne, lekkie opady śniegu i burze',
        lightssnowshowersandthunder_night:
          'Przelotne, lekkie opady śniegu i burze',
        lightssnowshowersandthunder_polartwilight:
          'Przelotne, lekkie opady śniegu i burze',
        rain: 'Opady deszczu',
        rainandthunder: 'Opady deszczu i burze',
        rainshowers_day: 'Przelotne opady deszczu',
        rainshowers_night: 'Przelotne opady deszczu',
        rainshowers_polartwilight: 'Przelotne opady deszczu',
        rainshowersandthunder_day: 'Przelotne opady deszczu i burze',
        rainshowersandthunder_night: 'Przelotne opady deszczu i burze',
        rainshowersandthunder_polartwilight: 'Przelotne opady deszczu i burze',
        sleet: 'Opady deszczu ze śniegiem',
        sleetandthunder: 'Opady deszczu ze śniegiem i burze',
        sleetshowers_day: 'Przelotne opady deszczu ze śniegiem',
        sleetshowers_night: 'Przelotne opady deszczu ze śniegiem',
        sleetshowers_polartwilight: 'Przelotne opady deszczu ze śniegiem',
        sleetshowersandthunder_day:
          'Przelotne opady deszczu ze śniegiem i burze',
        sleetshowersandthunder_night:
          'Przelotne opady deszczu ze śniegiem i burze',
        sleetshowersandthunder_polartwilight:
          'Przelotne opady deszczu ze śniegiem i burze',
        snow: 'Opady śniegu',
        snowandthunder: 'Opady śniegu i burze',
        snowshowers_day: 'Przelotne opady śniegu',
        snowshowers_night: 'Przelotne opady śniegu',
        snowshowers_polartwilight: 'Przelotne opady śniegu',
        snowshowersandthunder_day: 'Przelotne opady śniegu i burze',
        snowshowersandthunder_night: 'Przelotne opady śniegu i burze',
        snowshowersandthunder_polartwilight: 'Przelotne opady śniegu i burze',
        partlycloudy_day: 'Częściowe zachmurzenie',
        partlycloudy_night: 'Częściowe zachmurzenie',
        partlycloudy_polartwilight: 'Częściowe zachmurzenie',
      },
    },
  },
};
