export default {
  app: {
    user: {
      button: {
        logIn: 'Logg inn',
        logOut: 'Logg ut',
      },
      error: {
        username: 'Brukernavnet kan ikke være tomt',
        password: 'Passordet kan ikke være tomt',
        undefined: 'Kunne ikke logge inn',
      },
      title: 'Logg inn',
      placeholder: {
        user: 'Brukernavn',
        pass: 'Passord',
      },
      loggedIn: 'Du er logget inn som {0}',
      admin: 'Administrasjon',
    },
  },
};
