export default {
  app: {
    url: {
      controller: 'https://www.visitczechia.com/fr-FR/Contacts',
      facebook: 'https://www.facebook.com/czechrepublic.fr',
      instagram: 'https://www.instagram.com/destinationtchequie/',
      privacy: '~/Privacy-Policy',
      twitter: 'https://twitter.com/VisitCZ',
      web: 'https://www.visitczechia.com/',
      youtube: 'https://www.youtube.com/visitczech',
    },
  },
};
