export default {
  app: {
    maps: {
      more: 'Åpne detaljert kart',
      style: {
        tooltip: 'Kartstil',
        basic: 'Grunnleggende',
        outdoor: 'Turist',
        aerial: 'Luft',
        winter: 'Vinter',
      },
      loadPoI: 'Lokalt søk',
      currentPosition: 'Nåværende posisjon',
      categories: 'Kategorier',
      location: 'Plassering',
    },
  },
};
