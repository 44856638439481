export default {
  app: {
    weather: {
      title: '天气预报',
      now: '今天',
      weatherTranslations: {
        clearsky_day: '万里无云',
        clearsky_night: '万里无云',
        clearsky_polartwilight: '万里无云',
        cloudy: '多云',
        fair_day: '晴朗',
        fair_night: '晴朗',
        fair_polartwilight: '晴朗',
        fog: '雾',
        heavyrain: '大雨',
        heavyrainandthunder: '强雷雨',
        heavyrainshowers_day: '强阵雨',
        heavyrainshowers_night: '强阵雨',
        heavyrainshowers_polartwilight: '强阵雨',
        heavyrainshowersandthunder_day: '强阵雷雨',
        heavyrainshowersandthunder_night: '强阵雷雨',
        heavyrainshowersandthunder_polartwilight: '强阵雷雨',
        heavysleet: '大雨夹雪',
        heavysleetandthunder: '大雨夹雪伴随雷击',
        heavysleetshowers_day: '强阵雨夹雪',
        heavysleetshowers_night: '强阵雨夹雪',
        heavysleetshowers_polartwilight: '强阵雨夹雪',
        heavysleetshowersandthunder_day: '强阵雨夹雪伴随雷击',
        heavysleetshowersandthunder_night: '强阵雨夹雪伴随雷击',
        heavysleetshowersandthunder_polartwilight: '强阵雨夹雪伴随雷击',
        heavysnow: '大雪',
        heavysnowandthunder: '大雪伴随雷击',
        heavysnowshowers_day: '强阵雪',
        heavysnowshowers_night: '强阵雪',
        heavysnowshowers_polartwilight: '强阵雪',
        heavysnowshowersandthunder_day: '强阵雪伴随雷击',
        heavysnowshowersandthunder_night: '强阵雪伴随雷击',
        heavysnowshowersandthunder_polartwilight: '强阵雪伴随雷击',
        lightrain: '小雨',
        lightrainandthunder: '小雷雨',
        lightrainshowers_day: '小阵雨',
        lightrainshowers_night: '小阵雨',
        lightrainshowers_polartwilight: '小阵雨',
        lightrainshowersandthunder_day: '小阵雨伴随雷击',
        lightrainshowersandthunder_night: '小阵雨伴随雷击',
        lightrainshowersandthunder_polartwilight: '小阵雨伴随雷击',
        lightsleet: '微雨夹雪',
        lightsleetandthunder: '微雨夹雪伴随雷击',
        lightsleetshowers_day: '微阵雨夹雪',
        lightsleetshowers_night: '微阵雨夹雪',
        lightsleetshowers_polartwilight: '微阵雨夹雪',
        lightssleetshowersandthunder_day: '微阵雨夹雪伴随雷击',
        lightssleetshowersandthunder_night: '微阵雨夹雪伴随雷击',
        lightssleetshowersandthunder_polartwilight: '微阵雨夹雪伴随雷击',
        lightsnow: '小雪',
        lightsnowandthunder: '小雪伴随雷击',
        lightsnowshowers_day: '小阵雪',
        lightsnowshowers_night: '小阵雪',
        lightsnowshowers_polartwilight: '小阵雪',
        lightssnowshowersandthunder_day: '小阵雪伴随雷击',
        lightssnowshowersandthunder_night: '小阵雪伴随雷击',
        lightssnowshowersandthunder_polartwilight: '小阵雪伴随雷击',
        rain: '雨',
        rainandthunder: '雷雨',
        rainshowers_day: '阵雨',
        rainshowers_night: '阵雨',
        rainshowers_polartwilight: '阵雨',
        rainshowersandthunder_day: '阵雷雨',
        rainshowersandthunder_night: '阵雷雨',
        rainshowersandthunder_polartwilight: '阵雷雨',
        sleet: '雨夹雪',
        sleetandthunder: '雨夹雪伴随雷击',
        sleetshowers_day: '阵雨夹雪',
        sleetshowers_night: '阵雨夹雪',
        sleetshowers_polartwilight: '阵雨夹雪',
        sleetshowersandthunder_day: '阵雨夹雪伴随雷击',
        sleetshowersandthunder_night: '阵雨夹雪伴随雷击',
        sleetshowersandthunder_polartwilight: '阵雨夹雪伴随雷击',
        snow: '雪',
        snowandthunder: '雷打雪',
        snowshowers_day: '阵雪',
        snowshowers_night: '阵雪',
        snowshowers_polartwilight: '阵雪',
        snowshowersandthunder_day: '雷阵雪',
        snowshowersandthunder_night: '雷阵雪',
        snowshowersandthunder_polartwilight: '雷阵雪',
        partlycloudy_day: '部分多云',
        partlycloudy_night: '部分多云',
        partlycloudy_polartwilight: '部分多云',
      },
    },
  },
};
