export default {
  app: {
    winter: {
      snowReport: 'Snörapport',
      snow: '{type} {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: 'Pudersnö',
        3: 'Blötsnö',
        4: 'Is',
        5: 'Firnsnö',
        6: 'Konstsnö',
        7: 'Blötsnö',
      },
      lifts: 'Skidlift',
      open: 'öppet',
      slopes: 'Skidbackar',
      webcam: 'Webbkamera',
    },
  },
};
