import { ItineraryItem } from '~/app/core/apiClient/api';
import { PlanStop } from '~/components/templates/common/itinerary/ItineraryPlanStop';
import { createWidgets } from '../views/widgets';

export default function(
  data: ItineraryItem,
  nextStop?: ItineraryItem
): PlanStop {
  return {
    title: data.title || '',
    gps:
      data.latitude && data.longitude
        ? { lat: data.latitude, long: data.longitude }
        : undefined,
    information: data.perex || '',
    widgets: data.widgets ? createWidgets(data.widgets) : [],
    carDistance: data.carDistance || '',
    carTime: data.carTime || '',
    publicTransportTime: data.publicTransportTime || '',
    nextStopTitle: nextStop ? nextStop.title : undefined,
  };
}
