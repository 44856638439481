import { Component, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import HtmlRenderer from '../base/HtmlRenderer';
import { mdiInformationOutline } from '~/utils/iconPaths';
import { Colors } from '~/utils/theme/colors';

import style from './ImageSource.scss';

const rootClass = 'czt-image-source';

interface ImageSourceProps {
  imageSource: string;
  isTop?: boolean;
}

@Component({ style })
export default class ImageSource extends VueComponent<ImageSourceProps> {
  @Prop({ required: true })
  public imageSource!: string;

  @Prop()
  public isTop?: boolean;

  protected sourceOpen = false;

  public render() {
    const classes = [rootClass];

    if (this.isTop) {
      classes.push(`${rootClass}--top`);
    }

    return (
      <div onClick={(e: MouseEvent) => e.stopImmediatePropagation()}>
        <v-speed-dial
          absolute
          light
          value={this.sourceOpen}
          right
          bottom
          direction='top'
          class={classes.join(' ')}
        >
          <v-btn
            slot='activator'
            class={`${rootClass}__button`}
            fab
            x-small
            color={Colors.TEXT_LIGHT}
          >
            <v-icon color={Colors.TEXT}>{mdiInformationOutline}</v-icon>
          </v-btn>
          <v-sheet
            light
            class={`${rootClass}__menu pa-3`}
            elevation={3}
            onClick={(e: MouseEvent) => e.stopImmediatePropagation()}
          >
            <HtmlRenderer content={this.imageSource} />
          </v-sheet>
        </v-speed-dial>
      </div>
    );
  }
}
