import { Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { VueComponent } from '~/utils/vue-component';
import { Icons } from '~/components/atoms';
import UserModule, { UserData } from '~/app/core/store/modules/UserModule';

import style from './UserMenu.scss';
import { Target } from '~/utils/molecules/link';

const rootClass: string = 'czt-user-menu';

@Component({ style })
export default class UserMenu extends VueComponent<{}> {
  protected get userStore(): UserModule {
    return getModule(UserModule, this.$store);
  }

  protected get user(): UserData | null {
    return this.userStore.user;
  }

  protected menuVisible: boolean = false;

  // TODO: figure out why v-menu doesn't support SSR
  protected renderMenu: boolean = false;

  public mounted() {
    this.renderMenu = true;
  }

  public render() {
    if (!this.user || !this.renderMenu) {
      return;
    }
    return (
      <v-menu
        content-class={`${rootClass}`}
        close-on-content-click={true}
        v-model={this.menuVisible}
        eager
        scopedSlots={{
          activator: (scope: any) => {
            return (
              <a
                class={`${rootClass}__button`}
                {...{
                  on: scope.on,
                }}
              >
                <Icons.common.User size={2.5} />
              </a>
            );
          },
        }}
      >
        <v-list
          class={`${rootClass}__list overflow-y-auto`}
          min-width='260px'
          max-height='100vh'
        >
          <v-list-item>
            <v-list-item-content>{this.user.userfullname}</v-list-item-content>
          </v-list-item>
          {this.user.userroles.indexOf('B2BEditor') !== -1 && (
            <v-list-item>
              <a
                href='/cms'
                class='czt-link'
                target={Target.BLANK}
                title={this.$t('app.user.admin')}
                rel='noopener nofollow'
              >
                {this.$t('app.user.admin')}
              </a>
            </v-list-item>
          )}
          <v-list-item>
            <a class='czt-link' onClick={this.logOut}>
              {this.$t('app.user.button.logOut')}
            </a>
          </v-list-item>
        </v-list>
      </v-menu>
    );
  }

  protected logOut(): void {
    this.userStore.logOut();
  }
}
