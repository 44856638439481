export default {
  app: {
    newsletter: {
      bottomText:
        'En vous inscrivant à notre newsletter, vous acceptez notre <a href="~/Privacy-Policy" target="_blank">politique relative à la protection des données personnelles et les</a>.',
      button: 'Souscrire',
      ctaDialogParagraph1:
        'Voulez-vous être informé(e) sur les prochains événements en République tchèque\xA0?',
      ctaDialogParagraph2:
        'Vous hésitez quel château tchèque choisir lors de votre future visite\xA0?',
      ctaDialogParagraph3:
        'Inscrivez-vous à notre newsletter, on vous apportera toute information nécessaire pour réussir vos vacances\xA0!',
      ctaDialogTitle: 'Toc toc\xA0!',
      ctaDialogBtn: 'CLIQUEZ ICI',
      dialog: {
        headline: 'Sélectionnez votre   newsletter Pays/Région',
        notice:
          'Votre adresse e-mail susmentionnée ne sera utilisée que pour l’envoi de notre newsletter et ne sera transmise à aucune autre entité. Votre adresse mail restera enregistrée à cette fin jusqu’à ce que vous décidiez de vous désinscrire de la newsletter. Vous pouvez vous désinscrire à tout moment en cliquant sur le lien situé dans le message par lequel la newsletter vous est adressée. Votre adresse e-mail peut être traitée par la société informatique assurant l’exploitation de nos sites et applications web. {0}',
        company:
          'Le responsable du traitement des données à caractère personnel est l’{0}, {1}',
        link: {
          privacy:
            'Vous pouvez consulter ici notre Politique de confidentialité.',
          controller: 'Office National Tchèque de Tourisme - CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Votre adresse mail',
      newsletterError:
        'Il ne semble y avoir aucune newsletter à laquelle vous pouvez vous abonner pour le moment',
      serverError:
        "Une erreur s'est produite lors de l'exécution de la demande. Veuillez réessayer",
      success:
        'Afin d’activer votre inscription à la newsletter, merci de cliquer sur le lien envoyé par mail.',
      title: 'Recevez notre e-mailing',
      topText:
        'Inscrivez-vous et recevez les dernières nouvelles, la liste des prochains événements.',
      validityError: 'Votre adresse e-mail semble être invalide',
    },
  },
};
