export default {
  app: {
    maps: {
      more: 'Atvērt detalizētu karti',
      style: {
        tooltip: 'Kartes stils',
        basic: 'Pamati',
        outdoor: 'Tūristu',
        aerial: 'Aerial',
        winter: 'Ziema',
      },
      loadPoI: 'Vietējā meklēšana',
      currentPosition: 'Pašreizējais stāvoklis',
      categories: 'Kategorijas',
      location: 'Atrašanās vieta',
    },
  },
};
