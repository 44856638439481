export default {
  app: {
    cookies: {
      title: 'Vi respekterer dit privatliv',
      text:
        'Vi bruger cookies til at tilpasse indhold, analysere trafik og leverer funktioner på sociale medier. Vi deler information om, hvordan du bruger vores hjemmeside med vores sociale medie -og analysepartnere. Partnere kan kombinere disse oplysninger med andre oplysninger, du giver dem, eller som de får som et resultat af din brug af deres tjenester. Du kan finde flere oplysninger {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'her',
      },
      buttons: {
        accept: 'Accepter alle cookies',
        save: 'Gem udvalgte',
      },
      settings: {
        button: 'Detaljerede indstillinger',
        required: 'Nødvendige (for at websiden fungerer korrekt)',
        analytics:
          'Analyse (for at forstå, hvordan besøgende opfører sig på webstedet, eller hvordan de bevæger sig rundt på webstedet)',
        statistics:
          'Statistikker (for at følge de besøgendes brug af websiden)',
        marketing:
          'Marketing (for at spore de besøgende til vise relevante annoncer)',
        reject: 'Afvis alle',
        accept: 'Acceptér alle',
      },
    },
  },
};
