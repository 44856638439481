export default {
  app: {
    weather: {
      title: 'Orai',
      now: 'Šiandien',
      weatherTranslations: {
        clearsky_day: 'Giedra',
        clearsky_night: 'Giedra',
        clearsky_polartwilight: 'Giedra',
        cloudy: 'Debesuota',
        fair_day: 'Mažai debesuota',
        fair_night: 'Mažai debesuota',
        fair_polartwilight: 'Mažai debesuota',
        fog: 'Rūkas',
        heavyrain: 'Smarkus lietus',
        heavyrainandthunder: 'Smarkus lietus ir perkūnija',
        heavyrainshowers_day: 'Trumpas smarkus lietus',
        heavyrainshowers_night: 'Trumpas smarkus lietus',
        heavyrainshowers_polartwilight: 'Trumpas smarkus lietus',
        heavyrainshowersandthunder_day: 'Trumpas smarkus lietus ir perkūnija',
        heavyrainshowersandthunder_night: 'Trumpas smarkus lietus ir perkūnija',
        heavyrainshowersandthunder_polartwilight:
          'Trumpas smarkus lietus ir perkūnija',
        heavysleet: 'Smarki šlapdriba',
        heavysleetandthunder: 'Smarki šlapdriba ir perkūnija',
        heavysleetshowers_day: 'Trumpa smarki šlapdriba',
        heavysleetshowers_night: 'Trumpa smarki šlapdriba',
        heavysleetshowers_polartwilight: 'Trumpa smarki šlapdriba',
        heavysleetshowersandthunder_day: 'Trumpa smarki šlapdriba ir perkūnija',
        heavysleetshowersandthunder_night:
          'Trumpa smarki šlapdriba ir perkūnija',
        heavysleetshowersandthunder_polartwilight:
          'Trumpa smarki šlapdriba ir perkūnija',
        heavysnow: 'Smarkus sniegas',
        heavysnowandthunder: 'Smarkus sniegas ir perkūnija',
        heavysnowshowers_day: 'Trumpas smarkus sniegas',
        heavysnowshowers_night: 'Trumpas smarkus sniegas',
        heavysnowshowers_polartwilight: 'Trumpas smarkus sniegas',
        heavysnowshowersandthunder_day: 'Trumpas smarkus sniegas ir perkūnija',
        heavysnowshowersandthunder_night:
          'Trumpas smarkus sniegas ir perkūnija',
        heavysnowshowersandthunder_polartwilight:
          'Trumpas smarkus sniegas ir perkūnija',
        lightrain: 'Silpnas lietus',
        lightrainandthunder: 'Silpnas lietus ir perkūnija',
        lightrainshowers_day: 'Trumpas silpnas lietus',
        lightrainshowers_night: 'Trumpas silpnas lietus',
        lightrainshowers_polartwilight: 'Trumpas silpnas lietus',
        lightrainshowersandthunder_day: 'Trumpas silpnas lietus ir perkūnija',
        lightrainshowersandthunder_night: 'Trumpas silpnas lietus ir perkūnija',
        lightrainshowersandthunder_polartwilight:
          'Trumpas silpnas lietus ir perkūnija',
        lightsleet: 'Silpna šlapdriba',
        lightsleetandthunder: 'Silpna šlapdriba ir perkūnija',
        lightsleetshowers_day: 'Trumpa silpna šlapdriba',
        lightsleetshowers_night: 'Trumpa silpna šlapdriba',
        lightsleetshowers_polartwilight: 'Trumpa silpna šlapdriba',
        lightssleetshowersandthunder_day:
          'Trumpa silpna šlapdriba ir perkūnija',
        lightssleetshowersandthunder_night:
          'Trumpa silpna šlapdriba ir perkūnija',
        lightssleetshowersandthunder_polartwilight:
          'Trumpa silpna šlapdriba ir perkūnija',
        lightsnow: 'Silpnas sniegas',
        lightsnowandthunder: 'Silpnas sniegas ir audra',
        lightsnowshowers_day: 'Trumpas silpnas sniegas',
        lightsnowshowers_night: 'Trumpas silpnas sniegas',
        lightsnowshowers_polartwilight: 'Trumpas silpnas sniegas',
        lightssnowshowersandthunder_day: 'Trumpas silpnas sniegas ir perkūnija',
        lightssnowshowersandthunder_night:
          'Trumpas silpnas sniegas ir perkūnija',
        lightssnowshowersandthunder_polartwilight:
          'Trumpas silpnas sniegas ir perkūnija',
        rain: 'Lietus',
        rainandthunder: 'Lietus ir perkūnija',
        rainshowers_day: 'Trumpas lietus',
        rainshowers_night: 'Trumpas lietus',
        rainshowers_polartwilight: 'Trumpas lietus',
        rainshowersandthunder_day: 'Trumpas lietus ir perkūnija',
        rainshowersandthunder_night: 'Trumpas lietus ir perkūnija',
        rainshowersandthunder_polartwilight: 'Trumpas lietus ir perkūnija',
        sleet: 'Šlapdriba',
        sleetandthunder: 'Šlapdriba ir perkūnija',
        sleetshowers_day: 'Trumpa šlapdriba',
        sleetshowers_night: 'Trumpa šlapdriba',
        sleetshowers_polartwilight: 'Trumpa šlapdriba',
        sleetshowersandthunder_day: 'Trumpa šlapdriba ir perkūnija',
        sleetshowersandthunder_night: 'Trumpa šlapdriba ir perkūnija',
        sleetshowersandthunder_polartwilight: 'Trumpa šlapdriba ir perkūnija',
        snow: 'Sniegas',
        snowandthunder: 'Sniegas ir perkūnija',
        snowshowers_day: 'Trumpas sniegas',
        snowshowers_night: 'Trumpas sniegas',
        snowshowers_polartwilight: 'Trumpas sniegas',
        snowshowersandthunder_day: 'Trumpas sniegas ir perkūnija',
        snowshowersandthunder_night: 'Trumpas sniegas ir perkūnija',
        snowshowersandthunder_polartwilight: 'Trumpas sniegas ir perkūnija',
        partlycloudy_day: 'Debesuota su pragiedruliais',
        partlycloudy_night: 'Debesuota su pragiedruliais',
        partlycloudy_polartwilight: 'Debesuota su pragiedruliais',
      },
    },
  },
};
