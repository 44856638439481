import { Component, Emit, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { Headline, Button, Image } from '~/components/atoms';
import Dialog from '~/components/organisms/dialog/Dialog';

import background from '~/assets/images/Praha_popup_it-IT.jpg';
import logo from '~/assets/logo.svg';

import style from './CTAPopUp.scss';
import HtmlRenderer from '../base/HtmlRenderer';
import { MediaBackground } from '~/components/molecules';
import { ThemeColors } from '~/utils/theme';
import { Style } from '~/components/atoms/button/Button';
import { getLocaleFromRouter } from '~/app/core/router';

const rootClass = 'czt-cta-popup';

interface CTAPopUpInterface {
  active: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

@Component({ style })
export default class CTAPopUp extends VueComponent<CTAPopUpInterface>
  implements CTAPopUpInterface {
  @Prop({ required: true })
  public active!: boolean;

  public render() {
    return (
      <Dialog
        contentClass={rootClass}
        active={this.active}
        onCloseDialog={this.handleClose}
        backgroundColor={ThemeColors.TEXT_LIGHT}
        hideOverlay
        maxWidth={750}
        persist
      >
        <v-row>
          <v-col cols='12' md='6'>
            <Image
              class={`${rootClass}__logo d-md-none mb-2`}
              src={logo}
              alt='#VisitCzechRepublic Logo'
            />
            <div
              class={`${rootClass}__placeholder d-md-none`}
              style={`background-image: url(${background})`}
            ></div>
            <MediaBackground
              class={`${rootClass}__image d-none d-md-block pa-md-3`}
              image={{ src: background, alt: 'Popup background - Prague' }}
            >
              <v-row no-gutters>
                <v-col cols='12' md='8'>
                  <Image src={logo} alt='#VisitCzechRepublic Logo' />
                </v-col>
              </v-row>
            </MediaBackground>
          </v-col>
          <v-col class={`${rootClass}__content`} cols='12' md='6'>
            <v-row class='text-center text-md-right' no-gutters>
              <v-col cols='12' class='mb-2 mb-md-6'>
                <Headline level={4} class='text-md-right'>
                  {this.$t('app.newsletter.ctaDialogTitle')}
                </Headline>
              </v-col>
              <v-col cols='12' class='czt-perex pt-0 mb-2 mb-md-10'>
                <HtmlRenderer
                  key={`${getLocaleFromRouter(this.$router)}-1`}
                  content={this.$t('app.newsletter.ctaDialogParagraph1')}
                />
                <HtmlRenderer
                  key={`${getLocaleFromRouter(this.$router)}-2`}
                  content={this.$t('app.newsletter.ctaDialogParagraph2')}
                />
              </v-col>
              <v-col cols='12' class='mb-2 mb-md-10'>
                <HtmlRenderer
                  key={`${getLocaleFromRouter(this.$router)}-3`}
                  content={this.$t('app.newsletter.ctaDialogParagraph3')}
                />
              </v-col>
              <v-col cols='12'>
                <Button
                  url={
                    this.$te('app.newsletter.ctaDialogBtnLink')
                      ? this.$t('app.newsletter.ctaDialogBtnLink').toString()
                      : undefined
                  }
                  onClick={
                    this.$te('app.newsletter.ctaDialogBtnLink')
                      ? this.handleClose
                      : this.handleClick
                  }
                  buttonStyle={Style.TERTIARY}
                >
                  {this.$t('app.newsletter.ctaDialogBtn')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </Dialog>
    );
  }

  @Emit('confirm')
  protected handleClick() {
    return;
  }

  @Emit('close')
  protected handleClose() {
    return;
  }
}
