import { supportedLocales } from '~/app/localization';
import { LanguageLocaleInterface } from '~/utils/localization';

export default function(locale: string): LanguageLocaleInterface | null {
  return (
    supportedLocales.find(
      (supportedLocale) =>
        supportedLocale.slug.toLowerCase() === locale.toLowerCase()
    ) || null
  );
}
