export default {
  app: {
    newsletter: {
      bottomText:
        'ニュースレターへの登録をもって、<a href="~/Privacy-Policy" target="_blank">個人データ保護ポリシー</a>にご同意いただけたものとみなします。',
      button: '登録する',
      ctaDialogParagraph1: 'プラハでの今後のイベントを知りたいですか？',
      ctaDialogParagraph2:
        'チェコ共和国ツアーでどの城を訪れるべきかわからないですか？',
      ctaDialogParagraph3:
        'ニュースレターを購読すると、次の旅行に役立つヒントがたくさん届きます!',
      ctaDialogTitle: 'コンコン!',
      ctaDialogBtn: 'ここをクリック',
      dialog: {
        headline: 'ニュースレターの国を選ぶ',
        notice:
          '上記の貴方の電子メールアドレスは、チェコツーリズムのニュースレター送信のみに使用され、第三者に提供されることはありません。貴方のメールアドレスは、貴方がニュースレター配信を停止される日まで、この使用目的のために保存されます。配信停止は、ニュースレターとともに送信されるメールの中のリンクをクリックすることにより、いつでも手続き可能です。貴方の電子メールアドレスは、チェコツーリズムのウェブサイトおよびウェブアプリケーションの管理を行っているIT会社により処理されることがございます。 {0}',
        company: '個人データの管理者は、{0}, {1} です。',
        link: {
          privacy:
            'チェコツーリズムのプライバシー・ポリシーはこちらでご確認ください。',
          controller: 'チェコ政府観光局 – チェコツーリズム',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'メールアドレス',
      newsletterError: '現在購読できるニュースレターはないようです',
      serverError:
        'リクエストの実行中にエラーが発生しました. もう一度やり直してください',
      success:
        'ニュースレターの購読登録を完了させるためには、受信したメールをクリックしてください。',
      title: 'ニュースレターを受け取る',
      topText:
        '登録して最新情報、旅のヒント、イベント情報、新しいストーリーを受け取る',
      validityError: 'あなたのメールアドレスは無効のようです',
    },
  },
};
