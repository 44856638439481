import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class KrFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--korea'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33 33'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <circle cx='16.5' cy='16.5' r='16' fill='#fff' />
          <path
            d='M16.5 11c-2.2 0-4.1 1.3-5 3.2 0 .6 0 2.4 1.1 3.1 1 .6 2.3.9 3.8-.7s2.9-1.3 3.6-1c.7.3 1.6 1.1 1.4 3.3.3-.7.5-1.5.5-2.3.1-3.1-2.4-5.6-5.4-5.6z'
            fill='#c60c30'
          />
          <path
            d='M21.5 18.8c.2-2.2-.7-3-1.4-3.3-.7-.3-2-.6-3.6 1s-2.9 1.3-3.8.7c-1.1-.7-1.2-2.5-1.1-3.1a5.55 5.55 0 0 0 5 7.8c2.1.1 4-1.2 4.9-3.1z'
            fill='#003478'
          />
          <path d='m7.34 19.3.75-.5 3.05 4.58-.75.5zM5.03 20.81l.75-.5 3.05 4.58-.75.5z' />
          <g>
            <path d='m6.17 20.06.75-.5 1.38 2.09-.75.5zM7.8 22.6l1.4 2.1.8-.5-1.4-2.1z' />
          </g>
          <g>
            <path d='m25.82 22.48 1.38-2.09.75.5-1.38 2.08zM24.2 24.93l1.38-2.09.75.5-1.38 2.08zM24.66 21.66l1.39-2.09.75.5-1.39 2.08zM22.99 24.19l1.38-2.08.75.5-1.38 2.08zM21.92 23.42l1.38-2.08.75.5-1.38 2.08zM23.54 20.97l1.38-2.08.75.5-1.38 2.08z' />
          </g>
          <g>
            <path d='m26.8 12.9-.7.5L23 8.8l.8-.5zM25.8 10.58l.74-.5 1.38 2.1-.75.49zM24.9 7.6l-.7.5 1.4 2.1.7-.5zM23.57 12.04l.75-.5 1.38 2.08-.75.5zM21.87 9.57l.75-.5 1.39 2.08-.75.5z' />
            <g>
              <path d='m7.35 13.65 3.07-4.57.74.5-3.06 4.57zM6.9 13.4l-.7-.5 3-4.6.8.5zM5.8 12.6l-.8-.5 3.1-4.5.8.5z' />
            </g>
          </g>
        </svg>
      </i>
    );
  }
}
