export default {
  app: {
    common: {
      backToMenu: 'Вернуться в меню',
      blog: 'Блог',
      exploreButton: 'УЗНАТЬ БОЛЬШЕ',
      language: 'Язык',
      loadMore: 'Следующие',
      moreInfo: 'Больше информации',
      opensInNewWindow: 'Открывается в новом окне',
      pageTypes: {
        article: 'Статья',
        category: 'Категория',
        event: 'Мероприятие',
        page: 'Страница',
        place: 'Место',
        region: 'Oбласть',
      },
      selectLanguage: 'Выберите язык',
      homepageBreadcrumb: 'Главная страница',
      translationVoting: 'Как вам нравится этот перевод?',
      translationVotingSuccess: 'Спасибо за ваш отзыв!',
    },
  },
};
