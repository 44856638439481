import Vue from 'vue';
import { icons } from '~/components/atoms/icons';
import { ThemeColors } from '~/utils/theme';
import Vuetify, {
  colors,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAlert,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBanner,
  VBottomNavigation,
  VBottomSheet,
  VBreadcrumbs,
  VBreadcrumbsDivider,
  VBreadcrumbsItem,
  VBtn,
  VBtnToggle,
  VCard,
  VCardTitle,
  VCardSubtitle,
  VCardActions,
  VCardText,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VSimpleCheckbox,
  VChip,
  VChipGroup,
  VColorPicker,
  VColorPickerSwatches,
  VColorPickerCanvas,
  VCombobox,
  VCounter,
  VData,
  VDataIterator,
  VDataFooter,
  VDataTable,
  VEditDialog,
  VTableOverflow,
  VDataTableHeader,
  VSimpleTable,
  VDatePicker,
  VDatePickerTitle,
  VDatePickerHeader,
  VDatePickerDateTable,
  VDatePickerMonthTable,
  VDatePickerYears,
  VDialog,
  VDivider,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VFileInput,
  VFooter,
  VForm,
  VContainer,
  VCol,
  VRow,
  VSpacer,
  VMain,
  VLayout,
  VFlex,
  VHover,
  VIcon,
  VImg,
  VInput,
  VItem,
  VItemGroup,
  VLabel,
  VListItemActionText,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemAvatar,
  VListItemIcon,
  VListItemGroup,
  VMenu,
  VMessages,
  VNavigationDrawer,
  VOverflowBtn,
  VOverlay,
  VPagination,
  VSheet,
  VPicker,
  VProgressCircular,
  VProgressLinear,
  VRadioGroup,
  VRadio,
  VRangeSlider,
  VRating,
  VResponsive,
  VSelect,
  VSkeletonLoader,
  VSlider,
  VSlideGroup,
  VSlideItem,
  VSnackbar,
  VSpeedDial,
  VStepper,
  VStepperContent,
  VStepperStep,
  VStepperHeader,
  VStepperItems,
  VSubheader,
  VSwitch,
  VSystemBar,
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VTabsSlider,
  VTextarea,
  VTextField,
  VTimeline,
  VTimelineItem,
  VTimePicker,
  VTimePickerClock,
  VTimePickerTitle,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VTooltip,
  VTreeview,
  VTabTransition,
  VTabReverseTransition,
  VMenuTransition,
  VFabTransition,
  VDialogTransition,
  VDialogBottomTransition,
  VFadeTransition,
  VScaleTransition,
  VScrollXTransition,
  VScrollXReverseTransition,
  VScrollYTransition,
  VScrollYReverseTransition,
  VSlideXTransition,
  VSlideXReverseTransition,
  VSlideYTransition,
  VSlideYReverseTransition,
  VExpandTransition,
  VExpandXTransition,
  // @ts-ignore
} from '~/../node_modules/vuetify/lib';
// @ts-ignore
import { Intersect } from '~/../node_modules/vuetify/lib/directives';
import weatherIcons from '~/components/atoms/icons/weather';
import WeatherIcon from '~/components/atoms/icons/weather/WeatherIcon';

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAlert,
    VAutocomplete,
    VAvatar,
    VBadge,
    VBanner,
    VBottomNavigation,
    VBottomSheet,
    VBreadcrumbs,
    VBreadcrumbsDivider,
    VBreadcrumbsItem,
    VBtn,
    VBtnToggle,
    VCard,
    VCardTitle,
    VCardSubtitle,
    VCardActions,
    VCardText,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VSimpleCheckbox,
    VChip,
    VChipGroup,
    VColorPicker,
    VColorPickerSwatches,
    VColorPickerCanvas,
    VCombobox,
    VCounter,
    VData,
    VDataIterator,
    VDataFooter,
    VDataTable,
    VEditDialog,
    VTableOverflow,
    VDataTableHeader,
    VSimpleTable,
    VDatePicker,
    VDatePickerTitle,
    VDatePickerHeader,
    VDatePickerDateTable,
    VDatePickerMonthTable,
    VDatePickerYears,
    VDialog,
    VDivider,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VFileInput,
    VFooter,
    VForm,
    VContainer,
    VCol,
    VRow,
    VSpacer,
    VMain,
    VLayout,
    VFlex,
    VHover,
    VIcon,
    VImg,
    VInput,
    VItem,
    VItemGroup,
    VLabel,
    VListItemActionText,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemIcon,
    VListItemGroup,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VOverflowBtn,
    VOverlay,
    VPagination,
    VSheet,
    VPicker,
    VProgressCircular,
    VProgressLinear,
    VRadioGroup,
    VRadio,
    VRangeSlider,
    VRating,
    VResponsive,
    VSelect,
    VSkeletonLoader,
    VSlider,
    VSlideGroup,
    VSlideItem,
    VSnackbar,
    VSpeedDial,
    VStepper,
    VStepperContent,
    VStepperStep,
    VStepperHeader,
    VStepperItems,
    VSubheader,
    VSwitch,
    VSystemBar,
    VTabs,
    VTab,
    VTabItem,
    VTabsItems,
    VTabsSlider,
    VTextarea,
    VTextField,
    VTimeline,
    VTimelineItem,
    VTimePicker,
    VTimePickerClock,
    VTimePickerTitle,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VTooltip,
    VTreeview,
    VTabTransition,
    VTabReverseTransition,
    VMenuTransition,
    VFabTransition,
    VDialogTransition,
    VDialogBottomTransition,
    VFadeTransition,
    VScaleTransition,
    VScrollXTransition,
    VScrollXReverseTransition,
    VScrollYTransition,
    VScrollYReverseTransition,
    VSlideXTransition,
    VSlideXReverseTransition,
    VSlideYTransition,
    VSlideYReverseTransition,
    VExpandTransition,
    VExpandXTransition,
  },
  directives: {
    Intersect,
  },
});

const vuetifyIcons = {
  values: {} as { [key: string]: { component: typeof Vue; props?: any } },
};

for (const category in icons) {
  if (!icons.hasOwnProperty(category)) {
    continue;
  }

  const categoryIcons = (icons as { [key: string]: any })[category];

  for (const iconKey in categoryIcons) {
    if (!categoryIcons.hasOwnProperty(iconKey)) {
      continue;
    }

    vuetifyIcons.values[`${category}-${iconKey}`] = {
      component: categoryIcons[iconKey],
    };
  }
}

for (const icon in weatherIcons) {
  if (!weatherIcons.hasOwnProperty(icon)) {
    continue;
  }

  vuetifyIcons.values[`${icon}`] = {
    component: WeatherIcon,
    props: {
      name: icon,
    },
  };
}

export default {
  icons: {
    iconfont: 'mdiSvg',
    ...vuetifyIcons,
  },
  theme: {
    themes: {
      light: {
        primary: ThemeColors.PRIMARY,
        accent: ThemeColors.ACCENT,
        anchor: ThemeColors.SECONDARY,
        secondary: ThemeColors.SECONDARY,
        success: ThemeColors.PRIMARY,
        info: ThemeColors.PRIMARY,
        warning: ThemeColors.ACCENT,
        error: ThemeColors.ACCENT,
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024 - 16,
      xl: 1280 - 16,
    },
  },
};
