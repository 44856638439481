export default {
  app: {
    winter: {
      snowReport: 'Sneeuwbericht',
      snow: '{type} {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: 'Poedersneeuw',
        3: 'Natte sneeuw',
        4: 'Ijs',
        5: 'Sneeuw',
        6: 'Kunstmatige sneeuw',
        7: 'Natte sneeuw',
      },
      lifts: 'Skilift',
      open: 'open',
      slopes: 'Skipistes',
      webcam: 'Webcam',
    },
  },
};
