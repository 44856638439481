import { Component, Emit, Prop } from 'vue-property-decorator';
import { mdiArrowLeft } from '~/utils/iconPaths';
import { Colors } from '~/utils/theme/colors';
import { VueComponent } from '~/utils/vue-component';

interface ReturnButtonInterface {
  light?: boolean;
  onReturn?: () => any;
}

@Component
export default class ReturnButton extends VueComponent<ReturnButtonInterface> {
  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  public render() {
    return (
      <v-btn
        icon
        onClick={this.emitReturn}
        color={this.light ? Colors.TEXT_LIGHT : undefined}
      >
        <v-icon>{mdiArrowLeft}</v-icon>
      </v-btn>
    );
  }

  @Emit('return')
  protected emitReturn(): void {
    return;
  }
}
