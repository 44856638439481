import { LanguageLocaleInterface } from '../localization';
import { subscribedKey } from './config';
import getCTAName from './getCTAName';

export default function(locale: LanguageLocaleInterface): boolean {
  return locale.newsletterCta &&
    typeof window !== 'undefined' &&
    window.localStorage &&
    !window.localStorage.getItem(getCTAName(locale)) &&
    !window.localStorage.getItem(subscribedKey)
    ? true
    : false;
}
