export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filtra per incentivo',
        itinerary: 'Filtra per tag',
        region: 'Filtra per regione',
      },
    },
  },
};
