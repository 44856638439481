export default {
  app: {
    user: {
      button: {
        logIn: '登入',
        logOut: '登出',
      },
      error: {
        username: '使用者名稱不得空白',
        password: '密碼不得空白',
        undefined: '無法登入',
      },
      title: '登入',
      placeholder: {
        user: '使用者名稱',
        pass: '密碼',
      },
      loggedIn: '您以{0}的身份登入',
      admin: '管理',
    },
  },
};
