export default {
  app: {
    gridList: {
      label: {
        incentive: 'Filter op stimulans',
        itinerary: 'Filter op tag',
        region: 'Filter op regio',
      },
    },
  },
};
