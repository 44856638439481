export default {
  app: {
    winter: {
      snowReport: '雪の状況',
      snow: '{type} {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: '粉雪',
        3: 'ベタ雪',
        4: '粗目雪',
        5: '万年雪',
        6: '人工雪',
        7: '湿雪',
      },
      lifts: 'リフト',
      open: '営業中',
      slopes: 'コース',
      webcam: 'ウェブカメラ',
    },
  },
};
