export default {
  app: {
    safeTravels: {
      search: 'Yrityksen nimi',
      categories: 'Kategoriat',
      button: 'Haku',
      table: {
        name: 'Yrityksen nimi',
        link: 'Verkkosivut',
      },
    },
  },
};
