export default {
  app: {
    newsletter: {
      bottomText:
        'Tilaamalla uutiskirjeen hyväksyt <a href="~/Privacy-Policy" target="_blank">tietosuojakäytäntömme</a>.',
      button: 'Tilaa',
      ctaDialogParagraph1: 'Do you want to know the upcoming events in Prague?',
      ctaDialogParagraph2:
        'Not sure which castle to visit on your Czech Republic tour?',
      ctaDialogParagraph3:
        'Subscribe to our newsletter and you will receive many useful tips for your next trip!',
      ctaDialogTitle: 'KNOCK KNOCK!',
      ctaDialogBtn: 'CLICK HERE',
      dialog: {
        headline: 'Valitse uutiskirjeen kohdemaa/-alue',
        notice:
          'Yllä olevaa sähköpostiosoitettasi käytetään vain uutiskirjeen tilaamiseen, eikä sitä paljasteta muille tahoille. Tallennamme sähköpostiosoitteesi tätä tarkoitusta varten siihen päivään asti, jolloin peruutat uutiskirjeen tilaamisen. Voit peruuttaa tilauksen milloin tahansa näpäyttämällä uutiskirjeen sisältävässä sähköpostiviestissä olevaa linkkiä. Sähköpostiosoitettasi saattaa käsitellä IT-yritys, joka hallinnoi verkkosivujemme toimintaa ja kehitystä. {0}',
        company: 'Rekisterinpitäjä on {0}, {1}',
        link: {
          privacy: 'Tietosuojakäytäntömme on saatavilla täältä.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Sähköpostiosoite',
      newsletterError:
        'There seem to be no newsletters for you to subscribe to right now',
      serverError: 'Tietojen käsittelyssä tapahtui virhe. Yritä uudelleen.',
      success:
        'Rekisteröinnin loppuun saattamiseksi vahvista rekisteröityminen sinulle lähetetyssä sähköpostiviestissä.',
      title: 'Tilaa ilmainen uutiskirje',
      topText:
        'Rekisteröidy ja saa uusinta tietoa, vinkkejä, tietoja tulevista tapahtumista ja uusia kertomuksia',
      validityError: 'Sähköpostiosoitteessa on virhe.',
    },
  },
};
