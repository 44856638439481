import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class CultureIcon extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--map czt-icon--culture'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
          clip-rule='evenodd'
          viewBox='0 0 152 148'
        >
          <path
            fill='currentColor'
            fill-rule='nonzero'
            d='M126 52H88.7V40H126v12.2Zm-49.4 68A28.6 28.6 0 0 1 48 91.6H105a28.6 28.6 0 0 1-28.5 28.6M25.7 39.9H63V52H25.7V40Zm112.4 43.8v-82H13.6v82.4A62.3 62.3 0 1 0 138 84v-.4'
          />
        </svg>
      </i>
    );
  }
}
