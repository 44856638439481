export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Eu concordo {0}',
          link: 'Processamento de dados pessoais.',
          error: 'Você deve concordar com o processamento de dados pessoais.',
        },
      },
      send: 'Mandar',
      sent: 'O formulário foi enviado com sucesso.',
    },
  },
};
