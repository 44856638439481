export default {
  app: {
    user: {
      button: {
        logIn: '로그인',
        logOut: '로그아웃',
      },
      error: {
        username: '아이디를 입력하세요',
        password: '비밀번호를 입력하세요',
        undefined: '로그인을 할 수 없습니다',
      },
      title: '로그인',
      placeholder: {
        user: '아이디',
        pass: '비밀번호',
      },
      loggedIn: '{0} 으로 로그인 중입니다.',
      admin: '관리',
    },
  },
};
