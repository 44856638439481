export default {
  app: {
    newsletter: {
      bottomText:
        'Genom att prenumerera på nyhetsbrevet godkänner du vår <a href="~/Privacy-Policy" target="_blank">dataskyddspolicy</a>.',
      button: 'Prenumerera',
      ctaDialogParagraph1:
        'Vill du veta mer om kommande händelser och vad du måste se och göra i Tjeckien?',
      ctaDialogParagraph2: '',
      ctaDialogParagraph3:
        'Anmäl dig till vårt nyhetsbrev så får du många inspirerande tips inför din nästa resa!',
      ctaDialogTitle: 'KNOCK KNOCK!',
      ctaDialogBtn: 'KLICKA HÄR',
      dialog: {
        headline: 'Välj ditt nyhetsbrev land/region',
        notice:
          'Den angivna email adressen ovan kommer endast att användas för prenumeration av vårt nyhetsbrev och inte för något annat ändamål. Registreringen kommer att vara giltig tills ni väljer att avregistrera den angivna mailadressen   från vårt nyhetsbrev. Avregristrera när som helst genom att klicka på länken för avregistrering i nyhetsbrevet. Den angivna emailadressen kommer att hanteras av det IT företag som hanterar drift och utveckling av vår websida. {0}',
        company: 'Den registeransvarige är: {0}, {1}',
        link: {
          privacy: 'Vår integritetspolicy finns här.',
          controller: 'Tjeckiska Turistbyrån - CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Din e-postadress',
      newsletterError:
        'Det verkar inte finnas några nyhetsbrev som du kan prenumerera på just nu',
      serverError:
        'Ett fel uppstod när din förfrågan behandlades. Var vänlig försök igen.',
      success:
        'För att prenumerera på nyhetsbrevet, vänligen bekräfta registreringen i mailet.',
      title: 'Anmäl dig till vårt nyhetsbrev',
      topText:
        'Anmäl dig för att få de senaste nyheterna, tips, kommande evenemang och nya historier att berätta.',
      validityError: 'Det var verkar något fel på din e-postadress',
    },
  },
};
