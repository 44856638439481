export default {
  app: {
    safeTravels: {
      search: 'Buscar un establecimiento',
      categories: 'Seleccione una categoría',
      button: 'Buscar',
      table: {
        name: 'Nombre del establecimiento',
        link: 'Página web',
      },
      categoryTranslations: {
        eventy: 'Eventos',
        kongresy_a_veletrhy: 'Congresos y ferias',
        restaurace: 'Restaurante',
        ubytovací_zařízení: 'Alojamiento',
        sauny: 'Sauna',
        přírodní_a_umělá_koupaliště: 'Piscina',
        lanová_doprava: 'Funiculares y teleféricos',
        maloobchod: 'Tienda',
        fitness_centra: 'Gimnasio',
        kina: 'Cines',
        golfové_areály: 'Campos de golf',
      },
    },
  },
};
