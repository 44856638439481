export default {
  app: {
    cookies: {
      title: 'Wij respecteren uw privacy',
      text:
        'We gebruiken cookies om inhoud te personaliseren, verkeer te analyseren en functies voor sociale media te bieden. We delen informatie over hoe u onze website gebruikt met onze sociale media- en analysepartners. Partners kunnen deze informatie combineren met andere informatie die u aan hen verstrekt of die zij verkrijgen als gevolg van uw gebruik van hun diensten. Meer details vindt u {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'hier',
      },
      buttons: {
        accept: 'Accepteer alle cookies',
        save: 'Bewaar de geselecteerde gegevens',
      },
      settings: {
        button: 'Gedetailleerde instellingen',
        required: 'Vereist (om de website goed te laten fuctioneren)',
        analytics:
          'Analytics (om te begrijpen hoe bezoekers zich gedragen op de website of hoe ze zich door de website verplaatsen)',
        statistics:
          'Statistieken (om te begrijpen hoe bezoekers de site gebruiken)',
        marketing:
          'Marketing (om gebruiksgedrag bij te houden en relevante advertenties te kunnen tonen)',
        reject: 'Alles weigeren',
        accept: 'Accepteer alles',
      },
    },
  },
};
