export default {
  app: {
    search: {
      button: 'Encontrar',
      noResults: 'No se han encontrado resultados',
      title: 'Buscar',
      widgetTitle: 'Encuentra qué hacer en el país',
    },
  },
};
