export default {
  app: {
    panorama: {
      loadingText: 'Bild wird geladen...',
      autorotate: 'Automatische Drehung',
      zoom: 'Zoom',
      zoomOut: 'Verkleinern',
      zoomIn: 'Vergrößern',
      move: 'Bewegen',
      download: 'Herunterladen',
      fullscreen: 'Vollbildanzeige',
      menu: 'Menü',
      twoFingers: 'Zum Navigieren zwei Finger verwenden',
      ctrlZoom: 'Zum Zoomen die Taste CTRL verwenden und scrollen',
      loadError: 'Das Panorama kann nicht geladen werden',
    },
  },
};
