import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import UserModule, { UserData } from '~/app/core/store/modules/UserModule';
import { Button, Headline, InputField } from '~/components/atoms';
import { InputFieldState } from '~/components/atoms/inputField/InputField';
import { link } from '~/utils/molecules';

@Component
export default class LoginForm extends Vue {
  public static setPreviousRoute(route: Route) {
    LoginForm.previousRoute = route;
  }

  public static getPreviousRoute() {
    return LoginForm.previousRoute;
  }

  protected static previousRoute: Route | undefined;

  protected get userModule(): UserModule {
    return getModule(UserModule, this.$store);
  }

  protected get user(): UserData | null {
    return this.userModule.user;
  }

  protected get loading(): boolean {
    return this.userModule.loading;
  }

  protected fieldError: {
    username: InputFieldState;
    password: InputFieldState;
  } = {
    username: InputFieldState.DEFAULT,
    password: InputFieldState.DEFAULT,
  };

  protected errors: Error[] = [];

  protected username: string = '';

  protected password: string = '';

  public mounted() {
    // This is to prevent submition before the js loads
    this.userModule.setLoading(false);
  }

  public render() {
    if (this.user) {
      return (
        <v-row justify='center'>
          <v-col cols='12'>
            <Headline>
              <i18n tag='span' path='app.user.loggedIn'>
                {this.user.userfullname}
              </i18n>
            </Headline>
          </v-col>
          <v-col cols='auto'>
            <Button onClick={this.logOut}>
              {this.$t('app.user.button.logOut')}
            </Button>
          </v-col>
        </v-row>
      );
    }
    return (
      <form onsubmit={this.submit}>
        <v-row justify='center'>
          <v-col cols='12' md='8' lg='6'>
            <v-row no-gutters justify='center'>
              <v-col cols='12'>
                <InputField
                  placeholder={this.$t('app.user.placeholder.user')}
                  v-model={this.username}
                  clearable={false}
                  state={this.fieldError.username}
                />
              </v-col>
              <v-col cols='12'>
                <InputField
                  placeholder={this.$t('app.user.placeholder.pass')}
                  v-model={this.password}
                  type='password'
                  clearable={false}
                  state={this.fieldError.password}
                />
              </v-col>
              <v-col cols='12' class='mb-3 text-center'>
                {this.errors.join('\n')}
              </v-col>
              <v-col cols='auto'>
                <Button loading={this.loading} disabled={this.loading} submit>
                  {this.$t('app.user.button.logIn')}
                </Button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    );
  }

  protected logOut(): void {
    this.userModule.logOut();
  }

  protected submit(e: Event) {
    e.preventDefault();
    this.errors = [];
    this.fieldError.username = InputFieldState.DEFAULT;
    this.fieldError.password = InputFieldState.DEFAULT;

    if (!this.username) {
      this.fieldError.username = InputFieldState.ERROR;
      this.errors.push(
        new Error(this.$t('app.user.error.username').toString())
      );
      return;
    }

    if (!this.password) {
      this.fieldError.password = InputFieldState.ERROR;
      this.errors.push(
        new Error(this.$t('app.user.error.password').toString())
      );
      return;
    }

    this.userModule
      .getUser({
        password: this.password,
        username: this.username,
      })
      .then((success) => {
        if (success) {
          this.username = '';
          this.password = '';

          if (LoginForm.previousRoute) {
            this.$router.push(LoginForm.previousRoute.path);
            LoginForm.previousRoute = undefined;
          } else {
            this.$router.push(link.createInternal('/', this.$i18n.locale));
          }
        } else {
          this.errors.push(
            new Error(this.$t('app.user.error.undefined').toString())
          );
          this.fieldError.username = InputFieldState.ERROR;
          this.fieldError.password = InputFieldState.ERROR;
        }
      })
      .catch((err: Error) => {
        this.errors.push(err);
      });
  }

  @Watch('username')
  @Watch('password')
  protected resetError() {
    this.fieldError.username = InputFieldState.DEFAULT;
    this.fieldError.password = InputFieldState.DEFAULT;
    this.errors = [];
  }
}
