export default {
  app: {
    panorama: {
      loadingText: 'Vaizdas įkeliamas...',
      autorotate: 'Automatinė rotacija',
      zoom: 'Keisti mastelį',
      zoomOut: 'Tolinti',
      zoomIn: 'Artinti',
      move: 'Pastumti',
      download: 'Parsisiųsti',
      fullscreen: 'Visas ekranas',
      menu: 'Meniu',
      twoFingers: 'Norėdami naršyti, pasukite vaizdą dviem pirštais',
      ctrlZoom: 'Norėdami keisti mastelį, naudokite CTRL + pelės ratelį',
      loadError: 'Nepavyko įkelti panoramos',
    },
  },
};
