import { Component, Prop } from 'vue-property-decorator';
import { Image } from '~/components/atoms';
import { VueComponent } from '~/utils/vue-component';
import { OneOfThePages } from '~/utils/views';

import style from './TopLayerImage.scss';

interface TopLayerImageInterface {
  pageData: OneOfThePages | null;
  aside?: boolean;
}

const rootClass = 'czt-top-layer-image';

@Component({
  style,
})
export default class TopLayerImage extends VueComponent<TopLayerImageInterface>
  implements TopLayerImageInterface {
  @Prop({ required: true })
  public pageData!: OneOfThePages | null;

  @Prop({ default: false, type: Boolean })
  public aside!: boolean;

  public render() {
    const classes = [rootClass];
    if (this.aside) {
      classes.push(`${rootClass}--aside`);
    }
    if (!this.pageData || !this.pageData.topLayerImage) {
      return;
    }

    return (
      <v-row no-gutters justify='center' class={classes.join(' ')}>
        <Image
          src={this.pageData.topLayerImage}
          alt={this.pageData.title || ''}
        />
      </v-row>
    );
  }
}
