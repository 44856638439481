export default {
  app: {
    cookies: {
      title: 'Мы уважаем вашу конфиденциальность',
      text:
        'Мы используем файлы cookie для персонализации контента, анализа трафика и предоставления функций социальных сетей. Мы делимся информацией о том, как вы используете наш веб-сайт, с нашими партнерами по социальным сетям и аналитике. Партнеры могут объединять эту информацию с другой информацией, которую вы им предоставляете или которую они получают в результате использования вами их услуг. Более подробную информацию вы можете найти {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'здесь',
      },
      buttons: {
        accept: 'Принять все файлы cookie',
        save: 'Созранить выбранное',
      },
      settings: {
        button: 'Подробные настройки',
        required: 'Обязательно (для правильной работы сайта)',
        analytics:
          'Аналитика (дающая понять, как посетители ведут себя на сайте или как они перемещаются по сайту).',
        statistics: 'Статистика (чтобы понять, как посетители используют сайт)',
        marketing:
          'Маркетинг (для отслеживания посетителей для показа релевантной рекламы)',
        reject: 'Отклонить все',
        accept: 'Принять все',
      },
    },
  },
};
