export default {
  app: {
    user: {
      button: {
        logIn: 'Login',
        logOut: 'Disconnetti',
      },
      error: {
        username: 'Username non può essere vuoto',
        password: 'Password non può essere vuoto',
        undefined: 'Non è possibile accedere',
      },
      title: 'Login',
      placeholder: {
        user: 'Nome utente',
        pass: 'Password',
      },
      loggedIn: 'Sei connesso come {0}',
      admin: 'Amministrazione',
    },
  },
};
