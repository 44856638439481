import { Component, Emit, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { themeImageSizes } from '~/utils/theme';

import style from './Image.scss';
import { LocaleMessage } from 'vue-i18n';
import ImageObserverModule from '~/app/core/store/modules/ImageObserverModule';
import { getModule } from 'vuex-module-decorators';
import { lazyLoadedImageClass, noLazyImageClass } from '~/utils/atoms/image';
import appEnv from '~/app/core/appEnv';
import apiUrl from '~/app/core/apiClient/apiUrl';
import { getUrlParameterByName } from '~/utils/views/components';

export interface ImageInterface {
  alt: string | LocaleMessage;
  src: string;
  absolute?: boolean;
  fallback?: string;
  height?: number | string;
  isFirst?: boolean;
  lazy?: boolean;
  lazyTimeout?: number;
  sizes?: string;
  srcset?: string;
  title?: string;
  width?: number | string;
  onLoaded?: () => void;
}

function getDefaultSrc(imageSrc: string) {
  const srcParts = imageSrc.split('?');
  const src = srcParts[0];
  const query = srcParts[1] ? srcParts[1].split('&') : null;
  if (!src) {
    return '';
  }
  const params: string[] = [];

  query?.forEach((parameter) => {
    if (
      parameter.indexOf('width=') !== 0 &&
      parameter.indexOf('height=') !== 0
    ) {
      params.push(parameter);
    }
  });

  return `${src}?width=50${params.length > 0 ? '&' + params.join('&') : ''}`;
}

export function getWebpSrc(src: string): string {
  if (src.indexOf('.svg') === -1) {
    src = src.replace('getmedia', 'getwebpmedia');
  }
  return src;
}

export function getCDNSrc(src: string): string {
  if (appEnv.CDN_URL && apiUrl) {
    src = src.replace(apiUrl, appEnv.CDN_URL + '/cms');
  }
  return src;
}

export function setQuality(imageSrc: string) {
  const srcParts = imageSrc.split('?');
  const src = srcParts[0];
  const query = srcParts[1] ? srcParts[1].split('&') : null;

  if (!src) {
    return '';
  }

  let params: string[] = [];
  if (query) {
    params = [...query];
  }

  if (!params.some((param) => param.includes('quality='))) {
    params.push('quality=35');
  }

  return `${src}?${params.length > 0 ? params.join('&') : ''}`;
}

export function getSrcSet(imageSrc: string, sizes?: number[]) {
  const srcParts = imageSrc.split('?');
  const src = srcParts[0];
  const query = srcParts[1] ? srcParts[1].split('&') : null;

  if (!src) {
    return '';
  }

  const params: string[] = [];

  query?.forEach((parameter) => {
    if (
      parameter.indexOf('width=') !== 0 &&
      parameter.indexOf('height=') !== 0
    ) {
      params.push(parameter);
    }
  });

  const srcset: string[] = [];

  const imageSizes = sizes || themeImageSizes;

  imageSizes.forEach((size) => {
    let srcsetMember = `${src}?width=${size}${
      params.length > 0 ? '&' + params.join('&') : ''
    }`;
    srcsetMember = `${srcsetMember} ${size}w`;
    srcset.push(srcsetMember);
  });

  return srcset.join(', ');
}

const rootClass = 'czt-image';

@Component({
  style,
})
export default class ImageComponent extends VueComponent<ImageInterface>
  implements ImageInterface {
  @Prop({ default: false, type: Boolean })
  public absolute!: boolean;

  @Prop({ required: true })
  public alt!: string | LocaleMessage;

  @Prop()
  public height?: number | string;

  @Prop({ required: true, type: String })
  public src!: string;

  @Prop({ type: String })
  public fallback?: string;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  @Prop({ type: Boolean, default: true })
  public lazy!: boolean;

  @Prop({ type: Number, default: 0 })
  public lazyTimeout!: number;

  @Prop({ type: String })
  public srcset?: string;

  @Prop({ type: String })
  public sizes?: string;

  @Prop({ type: String })
  public title?: string;

  @Prop({})
  public width?: number;

  @Ref('imageRef')
  protected imageRef!: HTMLImageElement;

  protected error: boolean = false;

  protected get imageHeight(): number | string {
    return this.height || Number(getUrlParameterByName('height', this.src));
  }

  protected get imageWidth(): number | string {
    return this.width || Number(getUrlParameterByName('width', this.src));
  }

  protected get imageSrc(): string {
    let src = this.error && this.fallback ? this.fallback : this.src;
    if (this.isFirst) {
      src = setQuality(src);
    }
    src = getCDNSrc(getWebpSrc(src));
    return src;
  }

  protected get imageSrcset(): string {
    if (this.srcset !== undefined) {
      return this.srcset;
    }
    return getSrcSet(this.imageSrc);
  }

  protected get imageObserverModule(): ImageObserverModule {
    return getModule(ImageObserverModule, this.$store);
  }

  protected get shouldUseLazy(): boolean {
    return !this.isFirst && this.lazy && this.src.indexOf('.svg') === -1;
  }

  public mounted() {
    if (this.shouldUseLazy) {
      this.imageObserverModule.observeImage(this.imageRef);
    }
  }

  public beforeDestroy() {
    if (this.shouldUseLazy) {
      this.imageObserverModule.unobserveImage(this.imageRef);
    }
  }

  @Emit('loaded')
  protected imgLoaded() {
    return;
  }

  protected setError() {
    this.error = true;
  }

  public render() {
    const classes = [rootClass];
    if (!this.shouldUseLazy) {
      classes.push(noLazyImageClass);
    }
    return (
      <img
        class={classes.join(' ')}
        src={this.shouldUseLazy ? getDefaultSrc(this.imageSrc) : this.imageSrc}
        data-src={this.shouldUseLazy ? this.imageSrc : undefined}
        alt={this.alt}
        title={this.title}
        srcset={
          this.sizes && !this.shouldUseLazy ? this.imageSrcset : undefined
        }
        data-srcset={
          this.sizes && this.shouldUseLazy ? this.imageSrcset : undefined
        }
        data-lazy-timeout={
          this.shouldUseLazy && this.lazyTimeout ? this.lazyTimeout : undefined
        }
        sizes={this.sizes ? this.sizes : undefined}
        style={this.absolute ? 'position:absolute;' : ''}
        ref='imageRef'
        fetchpriority={this.isFirst ? 'high' : 'low'}
        loading={this.src.indexOf('.svg') !== -1 ? 'lazy' : undefined}
        height={this.imageHeight}
        width={this.imageWidth}
        {...{
          on: {
            load: (e: Event) => {
              if (
                e.target instanceof HTMLImageElement &&
                (e.target.classList.contains(noLazyImageClass) ||
                  e.target.classList.contains(lazyLoadedImageClass))
              ) {
                e.target.classList.add('is-loaded');
              }
              this.imgLoaded();
            },
            error: this.setError,
          },
        }}
      />
    );
  }
}
