export default {
  app: {
    footer: {
      aboutUs: 'Sobre nosotros',
      contactUs: 'Contacta con nosotros',
      pressAndMedia: 'Prensa & Medios',
      travelProfessionals: 'Profesionales del Turismo',
      brochures: 'Folletos',
      news: 'Novedades',
      covid19: 'Covid-19',
      destinations: 'Destinos',
      thingsToDo: 'Qué hacer',
      travelInfo: 'Info práctica',
      czechTourism: 'CzechTourism',
      menu: 'Menú',
      mobileApp: 'Aplicación movil',
      visitCzechAndroid: 'Aplicación móvil VisitCzech para Android',
      followUs: 'Síguenos',
      privacyPolicy: 'Política de privacidad',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/es-es/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Activar las cookies',
    },
  },
};
