export default {
  app: {
    safeTravels: {
      search: 'Trouver un établissement',
      categories: 'Choisissez une catégorie',
      button: 'Chercher',
      table: {
        name: 'Nom de l´établissement',
        link: 'Site web',
      },
      categoryTranslations: {
        eventy: 'Événements',
        kongresy_a_veletrhy: 'Salons et congrès',
        restaurace: 'Restaurant',
        ubytovací_zařízení: 'Hébérgement',
        sauny: 'Sauna',
        přírodní_a_umělá_koupaliště: 'Piscines et étangs',
        lanová_doprava: 'Funiculaires',
        maloobchod: 'Commerce de détail',
        fitness_centra: 'Club de gym',
        kina: 'Cinéma',
        golfové_areály: 'Terrains de golf',
      },
    },
  },
};
