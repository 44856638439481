export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: "Sono d'accordo {0}",
          link: 'trattamento dei dati personali.',
          error: 'Devi accettare il trattamento dei dati personali.',
        },
      },
      send: 'Spedire',
      sent: 'Il modulo è stato inviato con successo.',
    },
  },
};
