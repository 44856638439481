export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'Ik ga akkoord met {0}',
          link: 'verwerking van persoonsgegevens.',
          error:
            'U moet akkoord gaan met de verwerking van persoonlijke gegevens.',
        },
      },
      send: 'Sturen',
      sent: 'Het formulier is succesvol verzonden.',
    },
  },
};
