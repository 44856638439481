export default {
  app: {
    panorama: {
      loadingText: 'Ładowanie obrazu...',
      autorotate: 'Automatyczna rotacja',
      zoom: 'Zoom',
      zoomOut: 'Oddalić',
      zoomIn: 'Przybliżyć',
      move: 'Obracać',
      download: 'Załadować',
      fullscreen: 'Pełny ekran',
      menu: 'Menu',
      twoFingers: 'Przesuwaj za pomocą dwóch palców',
      ctrlZoom: 'Naciśnij ctrl + scroll, aby przybliżyć lub oddalić obraz',
      loadError: 'Nie można wczytać panoramy',
    },
  },
};
