export default {
  app: {
    search: {
      button: 'Megtalálja',
      noResults: 'Nincs találat',
      title: 'Keresés',
      widgetTitle: 'Keresse meg, mit kell tennie az országban',
    },
  },
};
