export default {
  app: {
    weather: {
      title: '天氣預報',
      now: '今天',
      weatherTranslations: {
        clearsky_day: '萬里無雲',
        clearsky_night: '萬里無雲',
        clearsky_polartwilight: '萬里無雲',
        cloudy: '多雲',
        fair_day: '晴朗',
        fair_night: '晴朗',
        fair_polartwilight: '晴朗',
        fog: '霧',
        heavyrain: '大雨',
        heavyrainandthunder: '大雷雨',
        heavyrainshowers_day: '強陣雨',
        heavyrainshowers_night: '強陣雨',
        heavyrainshowers_polartwilight: '強陣雨',
        heavyrainshowersandthunder_day: '強陣雷雨',
        heavyrainshowersandthunder_night: '強陣雷雨',
        heavyrainshowersandthunder_polartwilight: '強陣雷雨',
        heavysleet: '大雨夾雪',
        heavysleetandthunder: '大雨夾雪伴隨雷擊',
        heavysleetshowers_day: '強陣雨夾雪',
        heavysleetshowers_night: '強陣雨夾雪',
        heavysleetshowers_polartwilight: '強陣雨夾雪',
        heavysleetshowersandthunder_day: '強陣雨夾雪伴隨雷擊',
        heavysleetshowersandthunder_night: '強陣雨夾雪伴隨雷擊',
        heavysleetshowersandthunder_polartwilight: '強陣雨夾雪伴隨雷擊',
        heavysnow: '大雪',
        heavysnowandthunder: '大雪伴隨雷擊',
        heavysnowshowers_day: '強陣雪',
        heavysnowshowers_night: '強陣雪',
        heavysnowshowers_polartwilight: '強陣雪',
        heavysnowshowersandthunder_day: '強陣雪伴隨雷擊',
        heavysnowshowersandthunder_night: '強陣雪伴隨雷擊',
        heavysnowshowersandthunder_polartwilight: '強陣雪伴隨雷擊',
        lightrain: '小雨',
        lightrainandthunder: '小雷雨',
        lightrainshowers_day: '小陣雨',
        lightrainshowers_night: '小陣雨',
        lightrainshowers_polartwilight: '小陣雨',
        lightrainshowersandthunder_day: '小陣雨伴隨雷擊',
        lightrainshowersandthunder_night: '小陣雨伴隨雷擊',
        lightrainshowersandthunder_polartwilight: '小陣雨伴隨雷擊',
        lightsleet: '微雨夾雪',
        lightsleetandthunder: '微雨夾雪伴隨雷擊',
        lightsleetshowers_day: '微陣雨夾雪',
        lightsleetshowers_night: '微陣雨夾雪',
        lightsleetshowers_polartwilight: '微陣雨夾雪',
        lightssleetshowersandthunder_day: '微陣雨夾雪伴隨雷擊',
        lightssleetshowersandthunder_night: '微陣雨夾雪伴隨雷擊',
        lightssleetshowersandthunder_polartwilight: '微陣雨夾雪伴隨雷擊',
        lightsnow: '小雪',
        lightsnowandthunder: '小雪伴隨雷擊',
        lightsnowshowers_day: '小陣雪',
        lightsnowshowers_night: '小陣雪',
        lightsnowshowers_polartwilight: '小陣雪',
        lightssnowshowersandthunder_day: '小陣雪伴隨雷擊',
        lightssnowshowersandthunder_night: '小陣雪伴隨雷擊',
        lightssnowshowersandthunder_polartwilight: '小陣雪伴隨雷擊',
        rain: '雨',
        rainandthunder: '雷雨',
        rainshowers_day: '陣雨',
        rainshowers_night: '陣雨',
        rainshowers_polartwilight: '陣雨',
        rainshowersandthunder_day: '陣雷雨',
        rainshowersandthunder_night: '陣雷雨',
        rainshowersandthunder_polartwilight: '陣雷雨',
        sleet: '雨夾雪',
        sleetandthunder: '雨夾雪伴隨雷擊',
        sleetshowers_day: '陣雨夾雪',
        sleetshowers_night: '陣雨夾雪',
        sleetshowers_polartwilight: '陣雨夾雪',
        sleetshowersandthunder_day: '陣雨夾雪伴隨雷擊',
        sleetshowersandthunder_night: '陣雨夾雪伴隨雷擊',
        sleetshowersandthunder_polartwilight: '陣雨夾雪伴隨雷擊',
        snow: '雪',
        snowandthunder: '雷打雪',
        snowshowers_day: '陣雪',
        snowshowers_night: '陣雪',
        snowshowers_polartwilight: '陣雪',
        snowshowersandthunder_day: '雷陣雪',
        snowshowersandthunder_night: '雷陣雪',
        snowshowersandthunder_polartwilight: '雷陣雪',
        partlycloudy_day: '部分多雲',
        partlycloudy_night: '部分多雲',
        partlycloudy_polartwilight: '部分多雲',
      },
    },
  },
};
