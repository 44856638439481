export default {
  app: {
    winter: {
      snowReport: '雪况',
      snow: '{type} {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: '粉雪',
        3: '湿雪',
        4: '霜',
        5: '粒雪',
        6: '人造雪',
        7: '潮湿',
      },
      lifts: '滑雪缆车',
      open: '开放',
      slopes: '滑雪道',
      webcam: '现场实况',
    },
  },
};
