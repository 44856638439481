export default {
  app: {
    notFound: {
      title: 'Page non trouvée.',
      subtitle: "On dirait que tu t'es perdu.",
      text:
        "Ne vous inquiétez pas, nous vous aiderons à naviguer à partir d'ici",
    },
  },
};
