export default {
  app: {
    maps: {
      more: 'Abrir mapa detallado',
      style: {
        tooltip: 'Estilo del mapa',
        basic: 'Básico',
        outdoor: 'Turismo',
        aerial: 'Aéreo',
        winter: 'Invierno',
      },
      loadPoI: 'Búsqueda local',
      currentPosition: 'Posición actual',
      categories: 'Categorías',
      location: 'Ubicación',
    },
  },
};
