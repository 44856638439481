export default {
  app: {
    weather: {
      title: 'Parte meteorológico',
      now: 'Hoy',
      weatherTranslations: {
        clearsky_day: 'Soleado',
        clearsky_night: 'Soleado',
        clearsky_polartwilight: 'Soleado',
        cloudy: 'Nublado',
        fair_day: 'Buen tiempo',
        fair_night: 'Buen tiempo',
        fair_polartwilight: 'Buen tiempo',
        fog: 'Niebla',
        heavyrain: 'Lluvia intensa',
        heavyrainandthunder: 'Lluvia intensa con tormenta eléctrica',
        heavyrainshowers_day: 'Precipitaciones intensas',
        heavyrainshowers_night: 'Precipitaciones intensas',
        heavyrainshowers_polartwilight: 'Precipitaciones intensas',
        heavyrainshowersandthunder_day:
          'Precipitaciones intensas y tormenta eléctrica',
        heavyrainshowersandthunder_night:
          'Precipitaciones intensas y tormenta eléctrica',
        heavyrainshowersandthunder_polartwilight:
          'Precipitaciones intensas y tormenta eléctrica',
        heavysleet: 'Fuerte aguanieve',
        heavysleetandthunder: 'Fuerte aguanieve',
        heavysleetshowers_day: 'Fuerte aguanieve',
        heavysleetshowers_night: 'Fuerte aguanieve',
        heavysleetshowers_polartwilight:
          'Fuerte aguanieve con tormenta eléctrica',
        heavysleetshowersandthunder_day:
          'Fuerte aguanieve con tormenta eléctrica',
        heavysleetshowersandthunder_night:
          'Fuerte aguanieve con tormenta eléctrica',
        heavysleetshowersandthunder_polartwilight:
          'Fuerte aguanieve con tormenta eléctrica',
        heavysnow: 'Fuerte nevada',
        heavysnowandthunder: 'Fuerte nevada y tormenta eléctrica',
        heavysnowshowers_day: 'Chubasco de nieve',
        heavysnowshowers_night: 'Chubasco de nieve',
        heavysnowshowers_polartwilight: 'Chubasco de nieve',
        heavysnowshowersandthunder_day:
          'Chubasco de nieve con tormenta eléctrica',
        heavysnowshowersandthunder_night:
          'Chubasco de nieve con tormenta eléctrica',
        heavysnowshowersandthunder_polartwilight:
          'Chubasco de nieve con tormenta eléctrica',
        lightrain: 'Lluvia ligera',
        lightrainandthunder: 'Lluvia ligera con tormenta eléctrica',
        lightrainshowers_day: 'Lluvia ligera',
        lightrainshowers_night: 'Lluvia ligera',
        lightrainshowers_polartwilight: 'Lluvia ligera',
        lightrainshowersandthunder_day: 'Lluvia ligera con tormenta eléctrica',
        lightrainshowersandthunder_night:
          'Lluvia ligera con tormenta eléctrica',
        lightrainshowersandthunder_polartwilight:
          'Lluvia ligera con tormenta eléctrica',
        lightsleet: 'Aguanieve ligera',
        lightsleetandthunder: 'Aguanieve ligera y tormenta eléctrica',
        lightsleetshowers_day: 'Lluvia ligera de aguanieve',
        lightsleetshowers_night: 'Lluvia ligera de aguanieve',
        lightsleetshowers_polartwilight: 'Lluvia ligera de aguanieve',
        lightssleetshowersandthunder_day:
          'Lluvia ligera de aguanieve y tormenta eléctrica',
        lightssleetshowersandthunder_night:
          'Lluvia ligera de aguanieve y tormenta eléctrica',
        lightssleetshowersandthunder_polartwilight:
          'Lluvia ligera de aguanieve y tormenta eléctrica',
        lightsnow: 'Nevada ligera',
        lightsnowandthunder: 'Nevada ligera y tormenta eléctrica',
        lightsnowshowers_day: 'Lluvia ligera de nieve',
        lightsnowshowers_night: 'Lluvia ligera de nieve',
        lightsnowshowers_polartwilight: 'Lluvia ligera de nieve',
        lightssnowshowersandthunder_day:
          'Lluvias ligeras de nieve y tormenta eléctrica',
        lightssnowshowersandthunder_night:
          'Lluvias ligeras de nieve y tormenta eléctrica',
        lightssnowshowersandthunder_polartwilight:
          'Lluvias ligeras de nieve y tormenta eléctrica',
        rain: 'Lluvia',
        rainandthunder: 'Lluvia y tormenta eléctrica',
        rainshowers_day: 'Chubascos',
        rainshowers_night: 'Chubascos',
        rainshowers_polartwilight: 'Chubascos',
        rainshowersandthunder_day: 'Chubascos y tormenta eléctrica',
        rainshowersandthunder_night: 'Chubascos y tormenta eléctrica',
        rainshowersandthunder_polartwilight: 'Chubascos y tormenta eléctrica',
        sleet: 'Aguanieve',
        sleetandthunder: 'Aguanieve y tormenta eléctrica',
        sleetshowers_day: 'Lluvia de aguanieve',
        sleetshowers_night: 'Lluvia de aguanieve',
        sleetshowers_polartwilight: 'Lluvia de aguanieve',
        sleetshowersandthunder_day: 'Lluvia de aguanieve y tormenta eléctrica',
        sleetshowersandthunder_night:
          'Lluvia de aguanieve y tormenta eléctrica',
        sleetshowersandthunder_polartwilight:
          'Lluvia de aguanieve y tormenta eléctrica',
        snow: 'Nieve',
        snowandthunder: 'Nieve y tormenta eléctrica',
        snowshowers_day: 'Lluvias de nieve',
        snowshowers_night: 'Lluvias de nieve',
        snowshowers_polartwilight: 'Lluvias de nieve',
        snowshowersandthunder_day: 'Lluvias de nieve y tormenta eléctrica',
        snowshowersandthunder_night: 'Lluvias de nieve y tormenta eléctrica',
        snowshowersandthunder_polartwilight:
          'Lluvias de nieve y tormenta eléctrica',
        partlycloudy_day: 'Parcialmente nublado',
        partlycloudy_night: 'Parcialmente nublado',
        partlycloudy_polartwilight: 'Parcialmente nublado',
      },
    },
  },
};
