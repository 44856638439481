export default {
  app: {
    common: {
      homepageBreadcrumb: '홈',
      language: '언어',
      loadMore: '더 보기',
      moreInfo: '추가 정보',
      translationVoting: '번역이 마음에 드십니까?',
      translationVotingSuccess: '의견을 공유해 주셔서 감사합니다.',
    },
  },
};
