export default {
  app: {
    cookies: {
      title: 'Szanujemy Twoją prywatność',
      text:
        'Używamy plików cookie w celu personalizowania treści, analizowania ruchu na stronie i udostępniania funkcji mediów społecznościowych. Udostępniamy informacje o korzystaniu przez Ciebie z naszej strony internetowej naszym partnerom w zakresie mediów społecznościowych i analityki. Partnerzy mogą łączyć te informacje z innymi informacjami, które im przekazujesz lub które uzyskują podczas korzystania przez Ciebie z ich usług. Więcej szczegółów można znaleźć {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'tutaj',
      },
      buttons: {
        accept: 'Zaakceptuj wszystkie pliki cookie',
        save: 'Zachowaj wybrane',
      },
      settings: {
        button: 'Ustawienia szczegółowe',
        required: 'Wymagane (dla prawidłowego funkcjonowania strony)',
        analytics:
          'Analityka (w celu zrozumienia, jak odwiedzający zachowują się w witrynie lub w jaki sposób poruszają się po witrynie)',
        statistics:
          'Statystyczne (aby zrozumieć, w jaki sposób odwiedzający korzystają z witryny)',
        marketing:
          'Marketingowe (śledzenie odwiedzających w celu wyświetlania odpowiednich reklam)',
        reject: 'Odrzuć wszystko',
        accept: 'Akceptuj wszystko',
      },
    },
  },
};
