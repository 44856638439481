export const COOKIE_NAME: string = 'AllowCookieLevel';

export const COOKIES_ACCEPTED_EXPIRATION_TIME: number = 94670856000;

export const COOKIES_REJECTED_EXPIRATION_TIME: number = 86400000;

export const COOKIE_DELIMITER: string = '-';

export const VERSION_COOKIE_NAME: string = 'CookiesVersion';

export const VERSION: number = 2;

export enum CookieCategory {
  REQUIRED = 'B',
  ANALYTICS = 'A',
  STATISTICS = 'S',
  MARKETING = 'M',
}
