export default {
  app: {
    winter: {
      snowReport: 'Snow report',
      snow: '{snow} cm of {type} snow',
      snowTypeTranslation: {
        1: '',
        2: 'powder',
        3: 'wet',
        4: 'frozen',
        5: 'firn',
        6: 'artificial',
        7: 'moist',
      },
      lifts: 'Lifts',
      open: 'open',
      slopes: 'Slopes',
      webcam: 'Webcam',
    },
  },
};
