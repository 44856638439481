import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class AtFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--austria'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 33 33'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            fill='#fff'
            d='M32.5 16.5c0-1.9-.3-3.7-.9-5.3H1.4a15.95 15.95 0 0 0 0 10.6h30.2c.6-1.6.9-3.4.9-5.3z'
          />
          <path
            fill='#ec2938'
            d='M16.5.5C9.5.5 3.6 5 1.4 11.2h30.2C29.4 5 23.5.5 16.5.5z'
          />
          <path
            fill='#ec2938'
            d='M1.4 21.8C3.6 28 9.5 32.5 16.5 32.5S29.4 28 31.6 21.8H1.4z'
          />
        </svg>
      </i>
    );
  }
}
