export default {
  app: {
    footer: {
      aboutUs: 'Om oss',
      contactUs: 'Kontakta oss',
      pressAndMedia: 'Press & Media',
      travelProfessionals: 'Trade',
      brochures: 'Broschyrer',
      news: 'Nyheter',
      covid19: 'Covid-19',
      destinations: 'Resmål',
      thingsToDo: 'Se och göra',
      travelInfo: 'Reseinformation',
      czechTourism: 'CzechTourism',
      menu: 'Meny',
      mobileApp: 'Mobil app',
      visitCzechAndroid: 'VisitCzech mobilapplikation för Android',
      followUs: 'Följ oss',
      privacyPolicy: 'Integritetspolicy',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/en-us/privacy-policy',
      cookies: 'Cookies & Analys',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Inställningar kakor (cookies)',
    },
  },
};
