export default {
  app: {
    maps: {
      more: '打開詳細地圖',
      style: {
        tooltip: '地圖樣式',
        basic: '基本的',
        outdoor: '遊客',
        aerial: '天線',
        winter: '冬天',
      },
      loadPoI: '本地搜索',
      currentPosition: '當前位置',
      categories: '類別',
      location: '地點',
    },
  },
};
