export default {
  app: {
    newsletter: {
      bottomText:
        'Prenumeruodami naujienlaiškį sutinkate su mūsų <a href="~/Privacy-Policy" target="_blank">duomenų apsaugos politika</a>.',
      button: 'Prenumeruoti',
      ctaDialogParagraph1: 'Do you want to know the upcoming events in Prague?',
      ctaDialogParagraph2:
        'Not sure which castle to visit on your Czech Republic tour?',
      ctaDialogParagraph3:
        'Subscribe to our newsletter and you will receive many useful tips for your next trip!',
      ctaDialogTitle: 'KNOCK KNOCK!',
      ctaDialogBtn: 'CLICK HERE',
      dialog: {
        headline: 'Pasirinkite savo naujienlaiškio šalį/regioną.',
        notice:
          'Jūsų pateiktas el. pašto adresas bus naudojamas tik mūsų naujienlaiškio prenumeratos tikslais ir nebus atskleistas jokiems kitiems subjektams. Jūsų el. pašto adresas bus saugomas tol, kol neatsisakysite naujienlaiškio prenumeratos. Galite bet kada atsisakyti prenumeratos paspaudę nuorodą naujienlaiškyje. Jūsų el. pašto adresą gali tvarkyti IT įmonė, kuri valdo mūsų svetainių veikimą ir plėtrą. {0}',
        company: 'Jūsų duomenų tvarkytojas yra: {0}, {1}',
        link: {
          privacy: 'Mūsų privatumo politika pateikiama čia.',
          controller: 'Česká centrála cestovního ruchu – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Jūsų el. pašto adresas',
      newsletterError:
        'There seem to be no newsletters for you to subscribe to right now',
      serverError: 'Atsiprašome, įvyko klaida. Pabandykite dar kartą.',
      success:
        'Norėdami patvirtinti naujienlaiškio prenumeratą, paspauskite nuorodą el. laiške, kurį Jums atsiuntėme.',
      title: 'Užsisakykite mūsų naujienlaiškį',
      topText:
        'Registruokitės, kad žinotumėte paskutines naujienas, patarimus ir įdomybes',
      validityError: 'Jūsų el. pašto adresas neteisingas.',
    },
  },
};
