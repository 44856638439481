export default {
  app: {
    panorama: {
      loadingText: 'Načítava sa obrázok...',
      autorotate: 'Automatické otáčanie',
      zoom: 'Zoom',
      zoomOut: 'Oddialiť',
      zoomIn: 'Priblížiť',
      move: 'Hýbať',
      download: 'Stiahnuť',
      fullscreen: 'Celá obrazovka',
      menu: 'Menu',
      twoFingers: 'Na navigáciu použite dva prsty',
      ctrlZoom: 'Na priblíženie obrázka použite ctrl + scroll',
      loadError: 'Panorámu nie je možné načítať',
    },
  },
};
