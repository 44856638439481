export default {
  app: {
    winter: {
      snowReport: 'Sne vejrudsigt',
      snow: '{snow} cm af {type}',
      snowTypeTranslation: {
        1: '',
        2: 'puddersne',
        3: 'våd sne',
        4: 'is sne',
        5: 'fin sne',
        6: 'kunstsne',
        7: 'fugtig sne',
      },
      lifts: 'Skilifter',
      open: 'åben',
      slopes: 'Pisterne',
      webcam: 'Webcam',
    },
  },
};
