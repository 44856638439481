export default {
  app: {
    user: {
      button: {
        logIn: 'Prihlásenie',
        logOut: 'Odhlásenie',
      },
      error: {
        username: 'Užívateľské meno nemôže byť prázdne',
        password: 'Heslo nemôže byť prázdne',
        undefined: 'Prihlásenie sa nepodarilo',
      },
      title: 'Prihlásenie',
      placeholder: {
        user: 'Užívateľské meno',
        pass: 'Heslo',
      },
      loggedIn: 'Ste prihlásený ako {0}',
      admin: 'Administrácia',
    },
  },
};
