export const foreignRepresentationFlags = {
  '/B2B/Austria': 'At',
  '/B2B/Benelux': 'Nl',
  '/B2B/China': 'Cn',
  '/B2B/France': 'Fr',
  '/B2B/Germany': 'De',
  '/B2B/Italy': 'It',
  '/B2B/Japan-Taiwan': 'Jp',
  '/B2B/Korea': 'Kr',
  '/B2B/Latin-America': 'Mx',
  '/B2B/Poland-Lithuania-Latvia': 'Pl',
  '/B2B/Russia': 'Ru',
  '/B2B/Scandinavia': 'Se',
  '/B2B/Slovakia': 'Sk',
  '/B2B/Spain': 'Es',
  '/B2B/United-Kingdom': 'Gb',
  '/B2B/USA': 'Us',
};
