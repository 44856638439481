export default {
  app: {
    maps: {
      more: 'Abrir Mapa Detalhado',
      style: {
        tooltip: 'Estilo do Mapa',
        basic: 'Básico',
        outdoor: 'Turista',
        aerial: 'Aéreo',
        winter: 'Inverno',
      },
      loadPoI: 'Pesquisa local',
      currentPosition: 'Posição actual',
      categories: 'Categorias',
      location: 'Localização',
    },
  },
};
