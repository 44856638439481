import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import weatherIcons from '.';
import style from './WeatherIcon.scss';

export interface WeatherIconProps {
  name: keyof typeof weatherIcons;
}

const rootClass = 'czt-weather-icon';

@Component({
  style,
})
export default class WeatherIcon extends VueComponent<WeatherIconProps>
  implements WeatherIconProps {
  @Prop({ required: true })
  public name!: keyof typeof weatherIcons;

  public render() {
    const classes = [rootClass, `${rootClass}--${this.name}`];

    return (
      <i class={classes.join(' ')}>
        <img src={weatherIcons[this.name]} alt={this.name} title={this.name} />
      </i>
    );
  }
}
