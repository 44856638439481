export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Hemsida',
      destinations: 'Resmål',
      regions: 'Regioner',
      cities: 'Städer',
      thingsToDo: 'Se och göra',
      alwaysVisit: 'Alltid värt ett besök',
      culturalHeritage: 'Kulturarv och historia',
      activeHoliday: 'Aktiv semester',
      healthAndSpa: 'Hälsa och spa',
      new: 'Nyheter',
      covid: 'Covid-19',
      travelInfo: 'Reseinformation',
    },
  },
};
