import { Module, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';

@Module({
  name: 'ServerTimingModule',
  stateFactory: true,
  namespaced: true,
})
export default class ServerTimingModule extends AbstractModule {
  public addServerTiming: ((timing: string) => void) | null = null;

  @Mutation
  public setTimingSetter(fn: (timing: string) => void) {
    this.addServerTiming = fn;
  }
}
