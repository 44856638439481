export default {
  app: {
    maps: {
      more: 'Ouvrir la carte détaillée',
      style: {
        tooltip: 'Style de carte',
        basic: 'Base',
        outdoor: 'Tourisme',
        aerial: 'Aérien',
        winter: 'Hiver',
      },
      loadPoI: 'Recherche locale',
      currentPosition: 'Position actuelle',
      categories: 'Catégories',
      location: 'Localisation',
    },
  },
};
