export default {
  app: {
    footer: {
      aboutUs: 'О нас',
      contactUs: 'Контакты',
      pressAndMedia: 'Пресса и СМИ',
      travelProfessionals: 'Профессионалы туризма',
      brochures: 'Брошюры',
      news: 'Новинки',
      covid19: 'Covid-19',
      destinations: 'Регионы',
      thingsToDo: 'Чем заняться',
      travelInfo: 'Инфо для туристов',
      czechTourism: 'ЧехТуризм',
      menu: 'Меню',
      mobileApp: 'Мобильное приложение',
      visitCzechAndroid: 'VisitCzech Android мобильное приложение',
      followUs: 'Подписывайтесь на нас',
      privacyPolicy: 'политика конфиденциальности',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/ru-ru/privacy-policy',
      cookies: 'Cookies & Analytics',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Настройки файлов cookie',
    },
  },
};
