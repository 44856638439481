export default {
  app: {
    search: {
      button: 'Löytö',
      noResults: 'No results found',
      title: 'Haku',
      widgetTitle: 'Find what to do in the country',
    },
  },
};
