export default {
  app: {
    common: {
      backToMenu: 'Tilbage til menu',
      blog: 'Blog',
      exploreButton: 'Oplev',
      language: 'Sprog',
      loadMore: 'Se mere',
      moreInfo: 'Mere information',
      opensInNewWindow: 'Åbner i nyt vindue',
      pageTypes: {
        article: 'Artikel',
        category: 'Kategori',
        event: 'Begivenhed',
        page: 'Side',
        place: 'Placere',
        region: 'Område',
      },
      selectLanguage: 'Vælg sprog',
      homepageBreadcrumb: 'Forside',
      translationVoting: 'Hvordan er denne oversættelse?',
      translationVotingSuccess: 'Tak for svar!',
    },
  },
};
