export default {
  app: {
    form: {
      consent: {
        personalData: {
          wrapper: 'я согласен с {0}',
          link: 'обработка персональных данных.',
          error: 'Вы должны дать согласие на обработку персональных данных.',
        },
      },
      send: 'послать',
      sent: 'Форма успешно отправлена.',
    },
  },
};
