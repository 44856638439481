export default {
  app: {
    newsletter: {
      bottomText:
        'A hírlevélre történő feliratkozással Ön elfogadja az <a href="~/Privacy-Policy" target="_blank">adatvédelmi szabályzatunkat</a>.',
      button: 'Feliratkozás',
      ctaDialogParagraph1: 'Szeretné tudni a közelgő prágai eseményeket?',
      ctaDialogParagraph2:
        'Nem biztos benne, melyik kastélyt érdemes meglátogatni a cseh köztársaság turnéján?',
      ctaDialogParagraph3:
        'Iratkozzon fel hírlevelünkre, és sok hasznos tippet kap a következő útjára!',
      ctaDialogTitle: 'KOPP KOPP!',
      ctaDialogBtn: 'KATTINTS IDE',
      dialog: {
        headline: 'Válassza ki a hírlevél országát/régióját',
        notice:
          'A fent megadott e-mail címét kizárólag hírlevelünk előfizetéséhez használjuk fel, és másoknak nem adjuk ki. Erre a célra regisztráljuk e-mail címét mindaddig, amíg leiratkozik hírlevelünkről. A hírlevelet kézbesítő üzenetben található linkre kattintva bármikor leiratkozhat. Az Ön e-mail címét feldolgozhatja az az informatikai vállalat, amely kezeli a weboldalunk működését és fejlesztését. {0}',
        company: 'Az Adatkezelő az {0}, {1}',
        link: {
          privacy: 'Adatvédelmi irányelveink itt érhetők el.',
          controller: 'Cseh Idegenforgalmi Közösség – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'E-mail-cím',
      newsletterError:
        'Úgy tűnik, hogy jelenleg nincsenek hírlevelek, amelyekre feliratkozhatna',
      serverError:
        'Hiba történt a kérés végrehajtása során. Kérlek próbáld újra',
      success: 'Sikeresen feliratkozott hírlevelünkre',
      title: 'Feliratkozás az ingyenes hírlevelünkre',
      topText:
        'Regisztráljon, hogy értesüljön a legfrissebb információkról, ötletekről, a közelgő rendezvényekről és az új történetekről',
      validityError: 'Az e-mail-címe hibásnak tűnik.',
    },
  },
};
