export default {
  app: {
    newsletter: {
      bottomText:
        'Ao inscrever-se você concorda com <a href="~/Privacy-Policy" target="_blank">nossa política de proteção de dados</a>.',
      button: 'Assine',
      ctaDialogParagraph1: 'Você quer saber os próximos eventos em Praga?',
      ctaDialogParagraph2:
        'Não tem certeza de qual castelo visitar em sua excursão à República Tcheca?',
      ctaDialogParagraph3:
        'Assine a nossa newsletter e receberá muitas dicas úteis para a sua próxima viagem!',
      ctaDialogTitle: 'TOC TOC!',
      ctaDialogBtn: 'CLIQUE AQUI',
      dialog: {
        headline: 'Escolha seu Newsletter País/Região',
        notice:
          'O endereço de e-mail informado acima será utilizado apenas para o envio dos nossos newsletters e não será transmitido para qualquer outra pessoa. Com este intuito, manteremos o seu endereço de e-mail até o dia em que cancele o recebimento dos newsletters. Você poderá cancelar o recebimento a qualquer momento por meio do link indicado na mensagem recebida. O seu endereço de e-mail pode ser processado pela empresa de TI que garante o funcionamento das nossas páginas da web e dos nossos aplicativos web. {0}',
        company: 'O administrador de dados pessoais é: {0}, {1}',
        link: {
          privacy: 'Você pode encontrar a nossa Privacy Policy aqui.',
          controller: 'Agência do Turismo Tcheco – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Seu endereço de email.',
      newsletterError:
        'Parece que não há boletins informativos para você assinar agora',
      serverError:
        'Ocorreu um erro ao executar a solicitação. Por favor, tente novamente',
      success:
        'Para completar sua inscrição, por favor confirme através do email enviado a você.',
      title: 'Receba nosso email grátis',
      topText:
        'Inscreva-se para receber informação atualizada, dicas, calendário de eventos e novas histórias para contar.',
      validityError: 'Seu endereço de e-mail parece ser inválido',
    },
  },
};
