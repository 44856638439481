import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class UsFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--us'>
        <svg
          viewBox='0 0 32 32'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path fill='#fff' d='m31.2 22.6-1.3 2.5H3.1l-1.3-2.5h29.4z' />
          <path fill='#b12233' d='m32 20.1-.8 2.5H1.8L1 20.1h31z' />
          <path fill='#fff' d='m32.4 17.7-.4 2.5H1l-.4-2.5h31.8z' />
          <path
            fill='#b12233'
            d='M29.9 25 28 27.5H5c-.7-.8-1.4-1.6-1.9-2.5h26.8z'
          />
          <path fill='#b12233' d='M25 29.9a15.7 15.7 0 0 1-17 0h17z' />
          <path
            fill='#fff'
            d='M28 27.5c-.9.9-1.9 1.8-3 2.5H8c-1.1-.7-2.1-1.5-3-2.5h23z'
          />
          <path
            fill='#fff'
            d='M28 5.4H5c.9-.9 1.9-1.8 3-2.4h17c1.1.6 2.1 1.5 3 2.4z'
          />
          <path
            fill='#b12233'
            d='M25 3H8l1.6-.9.2-.1.6-.3.3-.1C12.5.9 14.5.4 16.6.4c3 .1 5.9 1 8.4 2.6z'
          />
          <path
            fill='#b12233'
            d='M29.9 7.9H3.1l.4-.6v-.1L5 5.4h23a15 15 0 0 1 1.9 2.5z'
          />
          <path fill='#b12233' d='M32 12.8H1l.8-2.5h29.4c.4.8.6 1.6.8 2.5z' />
          <path fill='#fff' d='M31.2 10.3H1.8l.2-.5.6-1.1.5-.9H30l1.2 2.5z' />
          <path
            fill='#fff'
            d='M32.4 15.2H.6v-.3l.1-.9.2-1.3h31c.3.9.4 1.7.5 2.5z'
          />
          <path fill='#b12233' d='M32.4 16.4v1.2H.6v-2.4h31.8v1.2z' />
          <path
            d='M16.5.5v17.2H.6V15l.1-.9.2-1.3.8-2.5.3-.5.6-1.1.5-.9.4-.6v-.1L5 5.4c.9-.9 1.9-1.8 3-2.4l1.6-.9.2-.1.6-.3.3-.1C12.4.9 14.4.5 16.5.5z'
            fill='#3c3b6d'
          />
          <path
            fill='#fff'
            d='m14.5 1.3.2.7h.7l-.6.4.3.7-.6-.4-.6.4.2-.7-.6-.4h.8z'
          />
          <path
            fill='#fff'
            d='M11 3v.1l-.2-.1-.4-.3-.3.3-.2.1V3l.2-.5-.5-.4.2-.1h.5l.1-.3.3-.1V2h.7l-.6.4.2.6z'
          />
          <path
            fill='#fff'
            d='m14.5 4.8.2.6h.7l-.6.5.3.7-.6-.5-.6.5.2-.7-.6-.5h.8z'
          />
          <path
            fill='#fff'
            d='m10.4 4.8.3.6h.7l-.6.5.2.7-.6-.5-.5.5.2-.7-.6-.5h.7z'
          />
          <path
            fill='#fff'
            d='m6.4 4.8.2.6h.8l-.6.5.2.7-.6-.5-.6.5.2-.7-.5-.5h.7z'
          />
          <path
            fill='#fff'
            d='m14.5 8.2.2.7h.7l-.6.4.3.7-.6-.4-.6.4.2-.7-.6-.4h.8z'
          />
          <path
            fill='#fff'
            d='m10.4 8.2.3.7h.7l-.6.4.2.7-.6-.4-.5.4.2-.7-.6-.4h.7z'
          />
          <path
            fill='#fff'
            d='m6.4 8.2.2.7h.8l-.6.4.2.7-.6-.4-.6.4.2-.7-.5-.4h.7z'
          />
          <path fill='#fff' d='m2.7 9.3.3.7-.6-.4-.4.2.6-1.1v.1h.7l-.6.5z' />
          <path
            fill='#fff'
            d='m14.5 11.6.2.7h.7l-.6.4.3.7-.6-.4-.6.4.2-.7-.6-.4h.8z'
          />
          <path
            fill='#fff'
            d='m10.4 11.6.3.7h.7l-.6.4.2.7-.6-.4-.5.4.2-.7-.6-.4h.7z'
          />
          <path
            fill='#fff'
            d='m6.4 11.6.2.7h.8l-.6.4.2.7-.6-.4-.6.4.2-.7-.5-.4h.7z'
          />
          <path
            fill='#fff'
            d='m2.4 11.6.2.7h.7l-.6.4.3.7-.6-.4-.6.4.2-.7-.6-.4h.7z'
          />
          <path
            fill='#fff'
            d='m14.5 15.1.2.6h.7l-.6.5.3.7-.6-.5-.6.5.2-.7-.6-.5h.8z'
          />
          <path
            fill='#fff'
            d='m10.4 15.1.3.6h.7l-.6.5.2.7-.6-.5-.5.5.2-.7-.6-.5h.7z'
          />
          <path
            fill='#fff'
            d='m6.4 15.1.2.6h.8l-.6.5.2.7-.6-.5-.6.5.2-.7-.5-.5h.7z'
          />
          <path
            fill='#fff'
            d='m2.4 15.1.2.6h.7l-.6.5.3.7-.6-.5-.6.5.2-.7-.6-.5h.7z'
          />
          <path
            fill='#fff'
            d='m12.5 13.3.2.7h.7l-.6.5.2.6-.5-.4-.6.4.2-.6-.6-.5h.7z'
          />
          <path
            fill='#fff'
            d='m8.4 13.3.2.7h.8l-.6.5.2.6-.6-.4-.6.4.3-.6-.6-.5h.7z'
          />
          <path
            fill='#fff'
            d='m4.4 13.3.2.7h.7l-.6.5.3.6-.6-.4-.6.4.2-.6-.6-.5h.8z'
          />
          <path fill='#fff' d='m.7 14.5.2.7-.3-.2.1-.9h.6l-.6.4z' />
          <path
            fill='#fff'
            d='m12.5 9.9.2.7h.7l-.6.4.2.7-.5-.4-.6.4.2-.7-.6-.4h.7z'
          />
          <path
            fill='#fff'
            d='m8.4 9.9.2.7h.8l-.6.4.2.7-.6-.4-.6.4.3-.7-.6-.4h.7z'
          />
          <path
            fill='#fff'
            d='m4.4 9.9.2.7h.7l-.6.4.3.7-.6-.4-.6.4.2-.7-.6-.4h.8z'
          />
          <path
            fill='#fff'
            d='m12.5 6.5.2.7h.7l-.6.4.2.7-.5-.5-.6.5.2-.7-.6-.4h.7z'
          />
          <path
            fill='#fff'
            d='m8.4 6.5.2.7h.8l-.6.4.2.7-.6-.5-.6.5.3-.7-.6-.4h.7z'
          />
          <path
            fill='#fff'
            d='m4.8 7.9.2.4-.6-.4-.6.4.1-.4.1-.3-.5-.4v-.1h.6l.2-.7.2.7h.7l-.5.5.1.3z'
          />
          <path
            fill='#fff'
            d='m12.5 3 .2.7h.7l-.6.5.2.6-.5-.4-.6.4.2-.6-.6-.5h.7z'
          />
          <path
            fill='#fff'
            d='m8.4 3 .2.7h.8l-.6.5.2.6-.6-.4-.6.4.3-.6-.6-.5h.7z'
          />
        </svg>
      </i>
    );
  }
}
