export default {
  app: {
    weather: {
      title: 'Previsão do tempo',
      now: 'Hoje',
      weatherTranslations: {
        clearsky_day: 'Céu claro',
        clearsky_night: 'Céu claro',
        clearsky_polartwilight: 'Céu claro',
        cloudy: 'Nublado',
        fair_day: 'Bom',
        fair_night: 'Bom',
        fair_polartwilight: 'Bom',
        fog: 'Neblina',
        heavyrain: 'Chuva forte',
        heavyrainandthunder: 'Chuva forte e raios',
        heavyrainshowers_day: 'Pancadas de chuva',
        heavyrainshowers_night: 'Pancadas de chuva',
        heavyrainshowers_polartwilight: 'Pancadas de chuva',
        heavyrainshowersandthunder_day: 'Pancadas de chiva e trovoadas',
        heavyrainshowersandthunder_night: 'Pancadas de chiva e trovoadas',
        heavyrainshowersandthunder_polartwilight:
          'Pancadas de chiva e trovoadas',
        heavysleet: 'Chuva e neve',
        heavysleetandthunder: 'Chuva, neve e trovoadas',
        heavysleetshowers_day: 'Pancadas de chuva e neve',
        heavysleetshowers_night: 'Pancadas de chuva e neve',
        heavysleetshowers_polartwilight: 'Pancadas de chuva e neve',
        heavysleetshowersandthunder_day: 'Pancadas de chuva, neve e trovoadas',
        heavysleetshowersandthunder_night:
          'Pancadas de chuva, neve e trovoadas',
        heavysleetshowersandthunder_polartwilight:
          'Pancadas de chuva, neve e trovoadas',
        heavysnow: 'Neve pesada',
        heavysnowandthunder: 'Neve pesada e trovoadas',
        heavysnowshowers_day: 'Pancadas de neve pesada',
        heavysnowshowers_night: 'Pancadas de neve pesada',
        heavysnowshowers_polartwilight: 'Pancadas de neve pesada',
        heavysnowshowersandthunder_day: 'Pancadas de neve pesadas e trovoadas',
        heavysnowshowersandthunder_night:
          'Pancadas de neve pesadas e trovoadas',
        heavysnowshowersandthunder_polartwilight:
          'Pancadas de neve pesadas e trovoadas',
        lightrain: 'Chuva leve',
        lightrainandthunder: 'Chuva leve e trovoadas',
        lightrainshowers_day: 'Pancadas de chuva leve',
        lightrainshowers_night: 'Pancadas de chuva leve',
        lightrainshowers_polartwilight: 'Pancadas de chuva leve',
        lightrainshowersandthunder_day: 'Pancadas de chuva leve e trovoadas',
        lightrainshowersandthunder_night: 'Pancadas de chuva leve e trovoadas',
        lightrainshowersandthunder_polartwilight:
          'Pancadas de chuva leve e trovoadas',
        lightsleet: 'Chuva e neve leves',
        lightsleetandthunder: 'Chuva e neve leve e trovoadas',
        lightsleetshowers_day: 'Pancadas leves de chuva e neve',
        lightsleetshowers_night: 'Pancadas leves de chuva e neve',
        lightsleetshowers_polartwilight: 'Pancadas leves de chuva e neve',
        lightssleetshowersandthunder_day:
          'Pancadas leves de chuva e neve com trovoadas',
        lightssleetshowersandthunder_night:
          'Pancadas leves de chuva e neve com trovoadas',
        lightssleetshowersandthunder_polartwilight:
          'Pancadas leves de chuva e neve com trovoadas',
        lightsnow: 'Neve leve',
        lightsnowandthunder: 'Neve leve e trovoadas',
        lightsnowshowers_day: 'Pancadas de neve leve',
        lightsnowshowers_night: 'Pancadas de neve leve',
        lightsnowshowers_polartwilight: 'Pancadas de neve leve',
        lightssnowshowersandthunder_day: 'Pancadas de neve leve e trovoadas',
        lightssnowshowersandthunder_night: 'Pancadas de neve leve e trovoadas',
        lightssnowshowersandthunder_polartwilight:
          'Pancadas de neve leve e trovoadas',
        rain: 'Chuva',
        rainandthunder: 'Chuva e trovoadas',
        rainshowers_day: 'Pancadas de chuva',
        rainshowers_night: 'Pancadas de chuva',
        rainshowers_polartwilight: 'Pancadas de chuva',
        rainshowersandthunder_day: 'Pancadas de chuva e trovoadas',
        rainshowersandthunder_night: 'Pancadas de chuva e trovoadas',
        rainshowersandthunder_polartwilight: 'Pancadas de chuva e trovoadas',
        sleet: 'Neve e chuva',
        sleetandthunder: 'Neve, chuva e trovoadas',
        sleetshowers_day: 'Pancadas de neve e chuva',
        sleetshowers_night: 'Pancadas de neve e chuva',
        sleetshowers_polartwilight: 'Pancadas de neve e chuva',
        sleetshowersandthunder_day: 'Pancadas de neve e chuva com trovoadas',
        sleetshowersandthunder_night: 'Pancadas de neve e chuva com trovoadas',
        sleetshowersandthunder_polartwilight:
          'Pancadas de neve e chuva com trovoadas',
        snow: 'Neve',
        snowandthunder: 'Neve e trovoadas',
        snowshowers_day: 'Pancadas de neve',
        snowshowers_night: 'Pancadas de neve',
        snowshowers_polartwilight: 'Pancadas de neve',
        snowshowersandthunder_day: 'Pancadas de neve e trovoadas',
        snowshowersandthunder_night: 'Pancadas de neve e trovoadas',
        snowshowersandthunder_polartwilight: 'Pancadas de neve e trovoadas',
        partlycloudy_day: 'Parcialmente nublado',
        partlycloudy_night: 'Parcialmente nublado',
        partlycloudy_polartwilight: 'Parcialmente nublado',
      },
    },
  },
};
