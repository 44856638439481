export default {
  app: {
    winter: {
      snowReport: 'Sniego sąlygos',
      snow: '{type} - {snow} cm',
      snowTypeTranslation: {
        1: '',
        2: 'Purus sniegas',
        3: 'Šlapias sniegas',
        4: 'Sušalęs sniegas',
        5: 'Firnas',
        6: 'Dirbtinis sniegas',
        7: 'Drėgnas sniegas',
      },
      lifts: 'Keltuvai',
      open: 'dirba',
      slopes: 'Trasos',
      webcam: 'Vaizdas gyvai',
    },
  },
};
