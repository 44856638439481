import { Component, Vue } from 'vue-property-decorator';
import {
  getTitle,
  getFavicons,
  getHeadTags,
  getInlineCss,
  replaceDocumentHeadContext,
} from '~/mixins/HeadManagement';

@Component
export default class HeadManagement extends Vue {
  public mounted() {
    this.replaceHead();
  }

  protected replaceHead() {
    const title = getTitle(this);

    if (title) {
      document.title = title;
    }

    const headTags = getHeadTags(this);

    if (headTags) {
      replaceDocumentHeadContext('headTags', headTags);
    }

    const favicons = getFavicons();

    if (favicons) {
      replaceDocumentHeadContext('favicons', favicons);
    }

    const inlineCss = getInlineCss(this);

    if (inlineCss) {
      replaceDocumentHeadContext('inlineCss', inlineCss);
    }
  }
}
