export default {
  app: {
    user: {
      button: {
        logIn: 'Login',
        logOut: 'Logout',
      },
      error: {
        username: 'Nome de usuário não pode estar em branco',
        password: 'Senha não pode estar em branco',
        undefined: 'Não foi possível fazer o login',
      },
      title: 'Login',
      placeholder: {
        user: 'Nome de usuário',
        pass: 'Senha',
      },
      loggedIn: 'Você está logado como {0}',
      admin: 'Administração',
    },
  },
};
