export default {
  app: {
    footer: {
      aboutUs: 'Über uns',
      contactUs: 'Kontakte',
      pressAndMedia: 'Presse & Medien',
      travelProfessionals: 'Travel Professionals',
      brochures: 'Broschüren',
      news: 'Nachrichten',
      covid19: 'Covid-19',
      destinations: 'Standorte',
      thingsToDo: 'Was tun',
      travelInfo: 'Reiseinformationen',
      czechTourism: 'CzechTourism',
      menu: 'Menü',
      mobileApp: 'Mobile app',
      visitCzechAndroid: 'VisitCzech Mobile Anwendung für Android',
      followUs: 'Folge uns',
      privacyPolicy: 'Datenschutz-Bestimmungen',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/de-de/privacy-policy',
      cookies: 'Cookies & Analytik',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Cookie Einstellungen',
    },
  },
};
