import { Banner as APIBanner } from '~/app/core/apiClient/api';
import { CztImage } from '../atoms/image';
import { ThemeRatios } from '../theme';
import { isAspectRatio } from '../theme/ratios';
import { createImage } from './components';

export enum AdvertisementThemeColors {
  BLUE = 'blue',
  DARK_BLUE = 'darkblue',
  GRAY = 'gray',
}

export function isThemeColorsEnum(data: any): data is AdvertisementThemeColors {
  for (const value in AdvertisementThemeColors) {
    if (AdvertisementThemeColors.hasOwnProperty(value)) {
      if (data === (AdvertisementThemeColors as any)[value]) {
        return true;
      }
    }
  }

  return false;
}

export interface Advertisement {
  id: string;
  title: string;
  link: string;
  content: string;
  theme: AdvertisementThemeColors;
  icon?: CztImage;
  image?: CztImage;
  ratio?: ThemeRatios;
}

export function createAdvertisements(data: APIBanner[]): Advertisement[] {
  return data.map((banner) => {
    return {
      id: banner.nodeGuid || 'unknown',
      title: banner.title || '',
      link: banner.link || '',
      content: banner.content || '',
      theme: isThemeColorsEnum(banner.theme)
        ? banner.theme
        : AdvertisementThemeColors.BLUE,
      icon: banner.icon
        ? createImage(banner.icon, banner.title || '')
        : undefined,
      image: banner.image
        ? createImage(banner.image, banner.imageText || banner.title || '')
        : undefined,
      ratio: isAspectRatio(banner.imageAspectRatio)
        ? (ThemeRatios as any)[banner.imageAspectRatio]
        : undefined,
    };
  });
}
