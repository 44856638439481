export default {
  app: {
    cookies: {
      title: 'Respeitamos sua privacidade ',
      text:
        'Nós usamos cookies para personalizar conteúdo, analisar o trágfego e oferecer opções de mídias sociais. Nós compartilhamos a informação de como você usa nosso website com nossos parceiros de mídias sociais e análise. Os parceiros podem combinar essas informações com outras que você fornece a eles ou com as que eles obtêm como resultado do seu uso dos serviços deles. Você pode encontrar mais detalhes {0}. {1}',
      moreInfo: {
        link: '~/c75a4044-af32-4ca1-a709-6ddc93d08c75/cookies',
        text: 'aqui',
      },
      buttons: {
        accept: 'Aceitar todos os cookies',
        save: 'Salvar',
      },
      settings: {
        button: 'Ajustes detalhados',
        required: 'Obrigatório (para funcionamento correto)',
        analytics:
          'Analytics (para entender como os visitantes se comportam no site ou como eles se movem pelo site)',
        statistics:
          'Estatísticos (para entender como os visitantes usam a página web)',
        marketing:
          'Comercial (para monitorar os visitantes com a finalidade de visualizar anúncios)',
        reject: 'Rejeitar tudo',
        accept: 'Aceitar tudo',
      },
    },
  },
};
