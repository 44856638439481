import { Component, Prop } from 'vue-property-decorator';
import Icon, { IconName } from '~/components/atoms/icons/Icon';
import isEnumGenerator from '~/utils/isEnumGenerator';
import { VueComponent } from '~/utils/vue-component';

import style from './MapCategoryPin.scss';

interface MapCategoryPinProps {
  categoryId: string;
}

export enum PinCategory {
  CULTURE = '4f51c90c-0b50-4084-9fdb-e06d4da76d91',
  LANDMARKS = '1c29d3b3-6eea-4173-a64d-1bee9ea2aaca',
  EXPERIENCES = '93c27220-7af5-4ca4-ad2b-dd985142097b',
  RESTAURANTS = 'a0cce1e6-900f-4adb-8149-b6d536ff4d4d',
  SUMMER_SPORTS = '03406599-5ceb-49a7-94b3-c05c157f832e',
  WINTER_SPORTS = '5fbb1a2d-8e9d-4bf7-ac7d-1b46f5318672',
  NATURE = '1489c074-c9ed-4c0f-a165-7b80e3aa9bc1',
  SPA = 'db20f94b-f1b0-4773-a3cf-85e04ea02fa3',
  LIFESTYLE = 'ed8b3112-f8c6-47e4-a66b-ad5425d2f2e5',
  ACCOMMODATION = 'c15be096-911c-460a-829b-2081e4b92c13',
  EVENT = 'event',
  DEFAULT = 'default',
}

export const isPinCategory = isEnumGenerator(PinCategory);

const rootClass = 'czt-map-category-pin';

export function getCategoryIcon(categoryId: string): IconName | null {
  switch (categoryId) {
    case PinCategory.ACCOMMODATION:
      return ['map', 'Accomodation'];
    case PinCategory.CULTURE:
      return ['map', 'Culture'];
    case PinCategory.EVENT:
      return ['map', 'Event'];
    case PinCategory.EXPERIENCES:
      return ['map', 'Experience'];
    case PinCategory.LANDMARKS:
      return ['map', 'Landmark'];
    case PinCategory.LIFESTYLE:
      return ['map', 'Lifestyle'];
    case PinCategory.NATURE:
      return ['map', 'Nature'];
    case PinCategory.RESTAURANTS:
      return ['map', 'Restaurant'];
    case PinCategory.SPA:
      return ['map', 'Spa'];
    case PinCategory.SUMMER_SPORTS:
      return ['map', 'SummerSports'];
    case PinCategory.WINTER_SPORTS:
      return ['map', 'WinterSports'];
  }
  return null;
}

export function getCategoryColor(categoryId: string): string {
  switch (categoryId) {
    case PinCategory.EVENT:
      return '#e6001e';
    case PinCategory.CULTURE:
    case PinCategory.RESTAURANTS:
    case PinCategory.LANDMARKS:
      return '#003c78';
    case PinCategory.EXPERIENCES:
    case PinCategory.LIFESTYLE:
    case PinCategory.SPA:
      return '#178fcf';
    case PinCategory.NATURE:
    case PinCategory.SUMMER_SPORTS:
    case PinCategory.WINTER_SPORTS:
      return '#006837';
    case PinCategory.ACCOMMODATION:
    default:
      return '#bdc4c2';
  }
}

@Component({ style })
export default class MapCategoryPin extends VueComponent<MapCategoryPinProps>
  implements MapCategoryPinProps {
  @Prop({ required: true })
  public categoryId!: string;

  protected get categoryIcon(): IconName | null {
    return getCategoryIcon(this.categoryId);
  }

  protected get categoryIconColor() {
    return getCategoryColor(this.categoryId);
  }

  public render() {
    return (
      <div class={rootClass}>
        <Icon
          name={['map', 'Pin']}
          style={{ color: this.categoryIconColor }}
          size={3.25}
        />
        {this.categoryIcon && (
          <div class={`${rootClass}__category-icon`}>
            <Icon name={this.categoryIcon} size={1.15} />
          </div>
        )}
      </div>
    );
  }
}
