export default {
  app: {
    newsletter: {
      bottomText:
        '訂閱電子報，表示您已同意我們的<a href="~/Privacy-Policy" target="_blank">個資保護條款</a>。',
      button: '訂閱',
      ctaDialogParagraph1: '您想知道布拉格即將舉行的活動嗎？',
      ctaDialogParagraph2: '不確定在您的捷克共和國之旅中要參觀哪個城堡？',
      ctaDialogParagraph3:
        '訂閱我們的時事通訊，您將在下次旅行中收到許多有用的提示！',
      ctaDialogTitle: '敲門!',
      ctaDialogBtn: '點擊這裡',
      dialog: {
        headline: '選擇您的通訊國家/地區',
        notice:
          '上面提供的您的電子郵件地址僅用於訂閱我們的新聞通訊，不會透露給其他任何主題。 為此，我們將註冊您的電子郵件地址，直到您取消訂閱新聞稿的那一天為止。 您可以隨時通過單擊傳遞時事通訊的消息中的鏈接來取消訂閱。 我們會將有關退訂的信息保留3年。 在這段時間之後，除非有其他原因要再次處理它，否則數據將被刪除。 您的電子郵件地址可能由管理我們網站的運營和開發的IT公司處理。 {0}',
        company: '數據控制器是 {0}, {1}',
        link: {
          privacy: '我們的隱私政策可在此處獲得。',
          controller: '捷克旅遊局 – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: '您的電子郵件地址',
      newsletterError: '您似乎現在沒有要訂閱的新聞通訊',
      serverError: '執行請求時出錯。 請再試一次',
      success: '請前往您的信箱確認訂閱我們的電子報。',
      title: '接收我們的電子報',
      topText:
        '註冊並收到最新消息、實用小撇步、近期活動以及更多我們想說的故事！',
      validityError: '您的電子郵件地址似乎無效',
    },
  },
};
