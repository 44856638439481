export default {
  app: {
    maps: {
      more: '打开详细地图',
      style: {
        tooltip: '地图风格',
        basic: '基本',
        outdoor: '游客',
        aerial: '航拍',
        winter: '冬季',
      },
      loadPoI: '本地搜索',
      currentPosition: '当前位置',
      categories: '类别',
      location: '地点',
    },
  },
};
