export default {
  app: {
    menu: {
      b2b: 'トレード',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Homepage',
      destinations: '行き先',
      thingsToDo: '体験したいこと',
      new: '最新情報',
      covid: '新型コロナウイルス',
      travelInfo: '旅行情報',
    },
  },
};
