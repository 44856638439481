export default {
  app: {
    maps: {
      more: 'Avaa yksityiskohtainen kartta',
      style: {
        tooltip: 'Karttatyyli',
        basic: 'Basic',
        outdoor: 'Turisti',
        aerial: 'Ilmakuvaus',
        winter: 'Talvi',
      },
      loadPoI: 'Paikallinen haku',
      currentPosition: 'Nykyinen asema',
      categories: 'Kategoriat',
      location: 'Sijainti',
    },
  },
};
