import { Component, Prop, Emit } from 'vue-property-decorator';
import { ValueProp, VueComponent } from '~/utils/vue-component';

import style from './InputField.scss';
import { LocaleMessage } from 'vue-i18n';

type InputFieldInterface<P> = ValueProp<P> & {
  disabled?: boolean;
  readOnly?: boolean;
  clearable?: boolean;
  state?: InputFieldState;
  placeholder?: string | LocaleMessage;
  label?: string | LocaleMessage;
  onInput?: (value: string) => void;
  type?: string;
  required?: boolean;
  errorMessage?: string | LocaleMessage;
  successMessage?: string | LocaleMessage;
  light?: boolean;
  onBlur?: (e: Event) => void;
  onFocus?: (e: Event) => void;
};

export enum InputFieldState {
  DEFAULT = 'default',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

@Component({
  style,
})
export default class InputField<T = string>
  extends VueComponent<InputFieldInterface<T>>
  implements InputFieldInterface<T> {
  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public readOnly!: boolean;

  @Prop({ default: true, type: Boolean })
  public clearable!: boolean;

  @Prop({ default: InputFieldState.DEFAULT, type: String })
  public state!: InputFieldState;

  @Prop({ default: '' })
  public placeholder!: string | LocaleMessage;

  @Prop({ default: '' })
  public label!: string | LocaleMessage;

  @Prop()
  public value!: T;

  @Prop({ default: 'text', type: String })
  public type!: string;

  @Prop({ default: false, type: Boolean })
  public required!: boolean;

  @Prop()
  public errorMessage?: string | LocaleMessage;

  @Prop()
  public successMessage?: string | LocaleMessage;

  @Prop({ default: false, type: Boolean })
  public light!: boolean;

  protected rootClass = 'czt-input-field';

  public render() {
    const classes: string[] = [this.rootClass];
    const showClearable = this.clearable && !this.readOnly;
    if (this.state === InputFieldState.ERROR) {
      classes.push(this.rootClass + '--error');
    }
    if (this.state === InputFieldState.SUCCESS) {
      classes.push(this.rootClass + '--success');
    }
    if (this.state === InputFieldState.LOADING) {
      classes.push(this.rootClass + '--loading');
    }
    if (this.light) {
      classes.push(this.rootClass + '--light');
    }
    return (
      <v-text-field
        disabled={this.disabled}
        readonly={this.readOnly}
        clearable={showClearable}
        error={this.state === InputFieldState.ERROR}
        success={this.state === InputFieldState.SUCCESS}
        loading={this.state === InputFieldState.LOADING}
        placeholder={this.placeholder}
        label={this.label}
        error-messages={this.errorMessage}
        class={classes}
        value={this.value}
        onInput={this.valueChanged}
        solo
        success-messages={this.successMessage}
        outlined={true}
        type={this.type}
        required={this.required}
        onFocus={this.focus}
        onBlur={this.blur}
      />
    );
  }

  public checkValidity(): boolean {
    return this.$el.querySelector('input')?.checkValidity() || false;
  }

  @Emit('input')
  protected valueChanged(value: string) {
    return value;
  }

  @Emit('focus')
  protected focus(e: Event) {
    return e;
  }

  @Emit('blur')
  protected blur(e: Event) {
    return e;
  }
}
