import buildFormattingTokensRegExp from 'date-fns/locale/_lib/build_formatting_tokens_reg_exp/index.js';

function buildFormatLocale() {
  const months3char = [
    'saus.',
    'vas.',
    'kov.',
    'bal.',
    'geg.',
    'birž.',
    'liep.',
    'rugp.',
    'rugs.',
    'spal.',
    'lapkr.',
    'gruod.',
  ];
  const monthsFull = [
    'sausis',
    'vasaris',
    'kovas',
    'balandis',
    'gegužė',
    'birželis',
    'liepa',
    'rugpjūtis',
    'rugsėjis',
    'spalis',
    'lapkritis',
    'gruodis',
  ];
  const weekdays2char = ['sk', 'pr', 'an', 'tr', 'kt', 'pn', 'št'];
  const weekdays3char = ['sk', 'pr', 'an', 'tr', 'kt', 'pn', 'št'];
  const weekdaysFull = [
    'sekmadienis',
    'pirmadienis',
    'antradienis',
    'trečiadienis',
    'ketvirtadienis',
    'penktadienis',
    'šeštadienis',
  ];
  const meridiemUppercase = ['PR. P.', 'POP.'];
  const meridiemLowercase = ['pr. p.', 'pop.'];
  const meridiemFull = ['priešpiet', 'popiet'];

  // TODO: try creating a type for this bs
  const formatters: any = {
    // Month: led, úno, ..., pro
    MMM: (date: Date) => {
      return months3char[date.getMonth()];
    },

    // Month: leden, únor, ..., prosinec
    MMMM: (date: Date) => {
      return monthsFull[date.getMonth()];
    },

    // Day of week: ne, po, ..., so
    dd: (date: Date) => {
      return weekdays2char[date.getDay()];
    },

    // Day of week: ned, pon, ..., sob
    ddd: (date: Date) => {
      return weekdays3char[date.getDay()];
    },

    // Day of week: neděle, pondělí, ..., sobota
    dddd: (date: Date) => {
      return weekdaysFull[date.getDay()];
    },

    // DOP., ODP.
    A: (date: Date) => {
      return date.getHours() / 12 >= 1
        ? meridiemUppercase[1]
        : meridiemUppercase[0];
    },

    // dop., odp.
    a: (date: Date) => {
      return date.getHours() / 12 >= 1
        ? meridiemLowercase[1]
        : meridiemLowercase[0];
    },

    // dopoledne, odpoledne
    aa: (date: Date) => {
      return date.getHours() / 12 >= 1 ? meridiemFull[1] : meridiemFull[0];
    },
  };

  // Generate ordinal version of formatters: M -> Mo, D -> Do, etc.
  const ordinalFormatters = ['M', 'D', 'DDD', 'd', 'Q', 'W'];
  ordinalFormatters.forEach((formatterToken: string) => {
    // TODO: try creating a type for this bs
    formatters[formatterToken + 'o'] = (date: Date, formatterObj: any) => {
      return ordinal(formatterObj[formatterToken](date));
    };
  });

  return {
    formatters,
    formattingTokensRegExp: buildFormattingTokensRegExp(formatters),
  };
}

function ordinal(nr: string) {
  return nr + '.';
}

export default buildFormatLocale;
