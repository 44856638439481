import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

@Component
export default class JpFlag extends VueComponent<{}> {
  public render() {
    return (
      <i class='czt-icon--flag czt-icon--japan'>
        <svg
          viewBox='0 0 33 34'
          xmlns='http://www.w3.org/2000/svg'
          fill-rule='evenodd'
          clip-rule='evenodd'
          stroke-linejoin='round'
          stroke-miterlimit='2'
        >
          <path
            d='M32.183 17.021c0 8.72-7.07 15.79-15.79 15.79C7.673 32.81.6 25.74.6 17.02c0-8.721 7.072-15.79 15.792-15.79 8.72 0 15.79 7.069 15.79 15.79z'
            fill='#fff'
            fill-rule='nonzero'
          />
          <path
            d='M32.683 17.021c0-8.997-7.294-16.29-16.29-16.29C7.397.731.1 8.024.1 17.021c0 8.996 7.296 16.29 16.292 16.29 8.996 0 16.29-7.294 16.29-16.29zm-.5 0c0 8.72-7.07 15.79-15.79 15.79C7.673 32.81.6 25.74.6 17.02c0-8.721 7.072-15.79 15.792-15.79 8.72 0 15.79 7.069 15.79 15.79z'
            fill='#eaeaea'
          />
          <path
            d='M24.287 17.021a7.894 7.894 0 01-7.894 7.894 7.893 7.893 0 01-7.895-7.894 7.894 7.894 0 1115.789 0z'
            fill='#be2029'
            fill-rule='nonzero'
          />
        </svg>
      </i>
    );
  }
}
