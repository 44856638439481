import apiUrl from './apiUrl';
import { Configuration } from './api/Configuration';
import {
  AuthApi,
  BannerStatitsticsApi,
  CategoriesApi,
  ContactVisitApi,
  MapPinsApi,
  MeatSpaceWrapperApi,
  NavigationsApi,
  NewsletterApi,
  PagesApi,
  ResourcesApi,
  SafeTravelStampsApi,
  SearchApi,
  TranslationsApi,
  WidgetsApi,
  YrNoWeatherWrapperApi,
} from './api';

const fetchWrapper = (input: string, init: RequestInit, logger: any) => {
  const time = Date.now();
  return fetch(input, init).finally(() => {
    if (!logger) {
      return;
    }
    logger('Call to url ' + input + ' took ' + (Date.now() - time), 'http');
  });
};

export default class {
  protected authApi: AuthApi;

  protected bannerStatisticsApi: BannerStatitsticsApi;

  protected categoriesApi: CategoriesApi;

  protected pagesApi: PagesApi;

  protected mapPinApi: MapPinsApi;

  protected meatspaceApi: MeatSpaceWrapperApi;

  protected navigationsApi: NavigationsApi;

  protected newsletterApi: NewsletterApi;

  protected resourcesApi: ResourcesApi;

  protected safeTravelsApi: SafeTravelStampsApi;

  protected searchApi: SearchApi;

  protected translationsApi: TranslationsApi;

  protected visitApi: ContactVisitApi;

  protected widgetsApi: WidgetsApi;

  protected weatherApi: YrNoWeatherWrapperApi;

  constructor(logger: any) {
    const configuration = new Configuration({
      basePath: apiUrl,
    });

    this.authApi = new AuthApi(configuration, undefined, (input, init) =>
      fetchWrapper(input, init, logger)
    );
    this.bannerStatisticsApi = new BannerStatitsticsApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.categoriesApi = new CategoriesApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.pagesApi = new PagesApi(configuration, undefined, (input, init) =>
      fetchWrapper(input, init, logger)
    );
    this.mapPinApi = new MapPinsApi(configuration, undefined, (input, init) =>
      fetchWrapper(input, init, logger)
    );
    this.meatspaceApi = new MeatSpaceWrapperApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.newsletterApi = new NewsletterApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.navigationsApi = new NavigationsApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.resourcesApi = new ResourcesApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.safeTravelsApi = new SafeTravelStampsApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.searchApi = new SearchApi(configuration, undefined, (input, init) =>
      fetchWrapper(input, init, logger)
    );
    this.translationsApi = new TranslationsApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.visitApi = new ContactVisitApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
    this.widgetsApi = new WidgetsApi(configuration, undefined, (input, init) =>
      fetchWrapper(input, init, logger)
    );
    this.weatherApi = new YrNoWeatherWrapperApi(
      configuration,
      undefined,
      (input, init) => fetchWrapper(input, init, logger)
    );
  }

  public auth() {
    return this.authApi;
  }

  public banner() {
    return this.bannerStatisticsApi;
  }

  public categories() {
    return this.categoriesApi;
  }

  public navigations() {
    return this.navigationsApi;
  }

  public venueFinder() {
    return this.meatspaceApi;
  }

  public newsletters() {
    return this.newsletterApi;
  }

  public pages() {
    return this.pagesApi;
  }

  public resources() {
    return this.resourcesApi;
  }

  public safeTravels() {
    return this.safeTravelsApi;
  }

  public search() {
    return this.searchApi;
  }

  public translations() {
    return this.translationsApi;
  }

  public visit() {
    return this.visitApi;
  }

  public widgets() {
    return this.widgetsApi;
  }

  public weather() {
    return this.weatherApi;
  }

  public mapPins() {
    return this.mapPinApi;
  }
}
