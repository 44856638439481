import { Action, Module, Mutation } from 'vuex-module-decorators';
import AbstractModule from './AbstractModule';

export type MapLanguage = 'en' | 'sk' | 'pl' | 'de';

@Module({
  name: 'MapsModule',
  stateFactory: true,
  namespaced: true,
})
export default class MapsModule extends AbstractModule {
  public apiLoaded: boolean = false;

  public loadingApi: boolean = true;

  public loadingScripts: boolean = true;

  protected currentLanguage: MapLanguage = 'en';

  protected scriptsLoaded: boolean = false;

  protected promise: Promise<void> | null = null;

  @Action({ rawError: true })
  public loadApi(language: MapLanguage) {
    if (this.promise) {
      return this.promise;
    } else {
      const promise: Promise<void> = new Promise((resolve) => {
        if (!this.apiLoaded) {
          this.setLoadingApi(true);
          const js = document.createElement('script');
          js.type = 'text/javascript';
          js.src = 'https://api.mapy.cz/loader.js';
          js.onload = () => {
            this.setApiLoaded(true);
            this.loadScripts(language)
              .then(() => {
                resolve();
              })
              .finally(() => {
                this.setLoadingApi(false);
                this.setPromise(null);
              });
          };
          document.body.appendChild(js);
        } else {
          this.loadScripts(language)
            .then(() => {
              resolve();
            })
            .finally(() => {
              this.setPromise(null);
            });
        }
      });

      this.setPromise(promise);
      return promise;
    }
  }

  @Action({ rawError: true })
  protected loadScripts(language: MapLanguage): Promise<void> {
    if (this.scriptsLoaded && language === this.currentLanguage) {
      return Promise.resolve();
    }
    this.setLoadingScripts(true);
    return new Promise((resolve) => {
      if (!this.scriptsLoaded) {
        window.Loader._loadAsync = (list: string[]) => {
          const head = document.getElementsByTagName('head')[0];

          function loadNext() {
            if (!list.length) {
              if (window.Loader._callback) {
                window.Loader._callback();
                window.Loader._callback = null;
              }
              return;
            }

            const name = list.shift();
            if (!name) {
              return;
            }
            if (
              name.includes('/js/lang/' + language + '.js') &&
              document.getElementsByClassName('szn-map-lang-script').length > 0
            ) {
              loadNext();
              return;
            }
            const script = document.createElement('script');
            script.addEventListener('load', loadNext, false);

            script.type = 'text/javascript';
            if (name.includes('/js/lang/' + language + '.js')) {
              script.classList.add('szn-map-lang-script');
            }
            script.src = name;
            script.async = true;
            head.appendChild(script);
          }

          loadNext();
        };
      }
      if (language !== this.currentLanguage) {
        const scripts = document.getElementsByClassName('szn-map-lang-script');
        if (scripts.length > 0) {
          [...scripts].forEach((element) => element.remove());
        }
      }
      window.Loader.async = true;
      window.Loader.lang = language;
      window.Loader.load(null, null, () => {
        this.setScriptsLoaded(true);
        this.setCurrentLanguage(language);
        this.setLoadingScripts(false);
        resolve();
      });
    });
  }

  @Mutation
  protected setLoadingApi(state: boolean) {
    this.loadingApi = state;
  }

  @Mutation
  protected setLoadingScripts(state: boolean) {
    this.loadingScripts = state;
  }

  @Mutation
  protected setApiLoaded(state: boolean) {
    this.apiLoaded = state;
  }

  @Mutation
  protected setScriptsLoaded(state: boolean) {
    this.apiLoaded = state;
  }

  @Mutation
  protected setCurrentLanguage(language: MapLanguage) {
    this.currentLanguage = language;
  }

  @Mutation
  protected setPromise(promise: Promise<void> | null) {
    this.promise = promise;
  }
}
