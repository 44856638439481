export default {
  app: {
    itinerary: {
      transportationInfoTitle: 'Następny punkt tranzytowy',
      car: 'Samochodem',
      publicTransport: 'Transportem publicznym',
      filterLabel: 'Filtruj według tagu',
    },
  },
};
