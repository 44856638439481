export default {
  app: {
    footer: {
      aboutUs: 'Sobre nós',
      contactUs: 'Contate-Nos',
      pressAndMedia: 'Imprensa',
      travelProfessionals: 'Profissionais de viagens',
      brochures: 'Brochuras',
      news: 'Notícia',
      covid19: 'Covid-19',
      destinations: 'Destinos',
      thingsToDo: 'Atividades',
      travelInfo: 'Info',
      czechTourism: 'CzechTourism',
      menu: 'Cardápio',
      mobileApp: 'Aplicativo móvel',
      visitCzechAndroid: 'VisitCzech Aplicativo móvel para Android',
      followUs: 'Siga-nos',
      privacyPolicy: 'Política de Privacidade',
      privacyPolicyUrl: 'https://partner.czechtourism.cz/pt-br/privacy-policy',
      cookies: 'Cookies e análises',
      czechConventionBureau: 'Czech Convention Bureau',
      cookieSettings: 'Ativar cookies',
    },
  },
};
