export default {
  app: {
    gridList: {
      label: {
        incentive: '按激勵篩選',
        itinerary: '按標籤篩選',
        region: '按地區篩選',
      },
    },
  },
};
