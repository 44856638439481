import { Component, Prop } from 'vue-property-decorator';

import { GalleryItem } from '~/components/molecules';
import { GalleryItemInterface } from '~/components/molecules/galleryItem/GalleryItem';
import { ThemeRatios } from '~/utils/theme';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';

import style from './Gallery.scss';
import { GalleryInterface } from './types';

@Component({
  style,
})
export default class Gallery extends VueComponent<GalleryInterface>
  implements GalleryInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true, type: Array })
  public items!: GalleryItemInterface[];

  @Prop()
  public ratio?: ThemeRatios;

  @Prop({ default: false, type: Boolean })
  public eager!: boolean;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  @Prop({ type: Boolean, default: false })
  public useContentWidth!: boolean;

  public className = CztWidgets.GALLERY;

  protected rootClass: string = 'czt-gallery';

  public render() {
    if (this.items.length < 1) {
      return;
    }
    const classes = [this.rootClass];
    if (this.ratio) {
      classes.push(`${this.rootClass}--has-ratio`);
    }

    const createGallery = () => {
      if (this.items.length === 1) {
        return (
          <div
            id={this.anchorId}
            class={classes.join(' ')}
            style={{
              height: this.ratio ? 'auto' : '600px',
            }}
          >
            <GalleryItem
              isFirst={this.isFirst}
              hideTitle={this.items[0].hideTitle}
              icon={this.items[0].icon}
              image={this.items[0].image}
              imageFilter={this.items[0].imageFilter}
              imageSource={this.items[0].imageSource}
              ratio={this.ratio || this.items[0].ratio}
              subtitle={this.items[0].subtitle}
              title={this.items[0].title}
              video={this.items[0].video}
              showVideoControls={this.items[0].showVideoControls}
            />
          </div>
        );
      }
      return (
        <v-carousel
          id={this.anchorId}
          class={classes.join(' ')}
          height={this.ratio ? 'auto' : '600px'}
          show-arrows-on-hover
          hide-delimiter-background
        >
          {this.items.map((item: GalleryItemInterface, key: number) => {
            return (
              <v-carousel-item key={item.title + key} eager={this.eager}>
                <GalleryItem
                  isFirst={this.isFirst && key === 0}
                  hideTitle={item.hideTitle}
                  icon={item.icon}
                  image={item.image}
                  imageFilter={item.imageFilter}
                  imageSource={item.imageSource}
                  ratio={this.ratio || item.ratio}
                  subtitle={item.subtitle}
                  title={item.title}
                  video={item.video}
                  showVideoControls={item.showVideoControls}
                />
              </v-carousel-item>
            );
          })}
        </v-carousel>
      );
    };

    if (this.useContentWidth) {
      return (
        <v-container>
          <v-row>{createGallery()}</v-row>
        </v-container>
      );
    }
    return createGallery();
  }
}
