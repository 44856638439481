export default {
  app: {
    safeTravels: {
      search: 'Létesítmény neve',
      categories: 'Kategóriák',
      button: 'Keresés',
      table: {
        name: 'Létesítmény neve',
        link: 'Weboldalak',
      },
    },
  },
};
