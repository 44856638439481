export default {
  app: {
    newsletter: {
      bottomText:
        'Subskrybując biuletyn, wyrażasz zgodę na naszą <a href="~/Privacy-Policy" target="_blank">politykę ochrony danych</a>.',
      button: 'Zapisz się',
      ctaDialogParagraph1: 'Chcesz poznać nadchodzące wydarzenia w Pradze?',
      ctaDialogParagraph2:
        'Nie wiesz, który zamek odwiedzić podczas wycieczki po Czechach?',
      ctaDialogParagraph3:
        'Zapisz się do naszego newslettera, a otrzymasz wiele przydatnych wskazówek na następną podróż!',
      ctaDialogTitle: 'PUK PUK!',
      ctaDialogBtn: 'KLIKNIJ TUTAJ',
      dialog: {
        headline: 'Wybierz kraj/region swojego newslettera',
        notice:
          'Państwa adres e-mail podany powyżej będzie wykorzystany wyłącznie w celu wysłania naszego newslettera i nie będzie przekazany żadnemu innemu podmiotowi. Państwa adres e-mail będziemy przechowywać w wyżej wymienionym celu do dnia, w którym odwołają Państwo przesyłanie newslettera. Wylogować mogą się Państwo w dowolnym momencie, klikając link w wiadomości, którą będzie biuletyn dostarczony. Państwa adres e-mail może być przetwarzany przez firmę informatyczną, która obsługuje naszą stronę internetową i aplikacje internetowe. {0}',
        company: 'Administratorem danych osobowych jest {0}, {1}',
        link: {
          privacy: 'Naszą Privacy Policy znajdą Państwo tutaj.',
          controller: 'Czeska Centrala Ruchu Turystycznego – CzechTourism',
          web: 'www.visitczechia.com',
        },
      },
      inputPlaceholder: 'Twój adres e-mail',
      newsletterError:
        'Wygląda na to, że w tej chwili nie ma żadnych biuletynów, które można subskrybować',
      serverError:
        'Wystąpił błąd podczas wykonywania żądania. Proszę spróbuj ponownie',
      success:
        'Właśnie wysłaliśmy do Ciebie e-mail. Kliknij na zawarty w nim link, aby potwierdzić swój zapis. Natychmiast odbierz Czeski PLANER POBYTÓW w prezencie!',
      successTitle: 'Dziękujemy! Jeszcze tylko jeden krok!',
      title: 'Odbierz nasz bezpłatny e-mail',
      topText:
        'Zarejestruj się, aby otrzymywać najnowsze informacje, wskazówki, przegląd wydarzeń i ciekawostek.',
      b2bTitle: 'Anofajny newsletter',
      b2bTopText:
        'Co miesiąc otrzymuj inspiracje na wymarzone podróże. Już teraz odbierz PLANER POBYTÓW!',
      pressTitle: 'Wiadomości prasowe CzechTourism',
      pressTopText:
        'Otrzymuj ciekawe i dopracowane treści wraz ze zdjęciami. Poinformujemy Cię o press tripach.',
      tradeTitle: 'Nowości dla Biur podróży',
      tradeTopText:
        'Uzyskaj informacje, które pomogą rozwinąć Twój biznes. Otrzymuj zaproszenia na fam tripy.',
      validityError: 'Your email address seems to be invalid',
    },
  },
};
