export default {
  app: {
    panorama: {
      loadingText: 'Carregando imagem...',
      autorotate: 'Rotação automática',
      zoom: 'Zoom',
      zoomOut: 'Menos zoom',
      zoomIn: 'Mais zoom',
      move: 'Mover',
      download: 'Baixar',
      fullscreen: 'Tela cheia',
      menu: 'Menu',
      twoFingers: 'Use dois dedos para navegar',
      ctrlZoom: 'Use ctrl + scroll para aumentar a imagem',
      loadError: 'O panorama não pode ser carregado',
    },
  },
};
