export default {
  app: {
    search: {
      button: 'Finde',
      noResults: 'Ingen resultater fundet',
      title: 'Søg',
      widgetTitle: 'Find hvad du skal gøre i landet',
    },
  },
};
