export default {
  app: {
    menu: {
      b2b: 'B2B',
      b2bUrl: 'https://www.czechtourism.com/trade-sites/',
      homepage: 'Hjemmeside',
      destinations: 'Destinationer',
      regions: 'Regioner',
      cities: 'Byer',
      thingsToDo: 'Hvad kan man opleve',
      alwaysVisit: 'Besøg altid',
      culturalHeritage: 'Kulturarv',
      activeHoliday: 'Aktiv Ferie',
      healthAndSpa: 'Wellness & Spa',
      new: 'Nyhed',
      covid: 'Covid-19',
      travelInfo: 'Rejseinfo',
    },
  },
};
